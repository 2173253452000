import React from 'react';

import signet from '../../styles/assets/images/nps-signet.jpg';
import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';

interface INpsComponentProps {
  label: string;
  // text: string;
  // url: string;
  linkTo: any;
  linkType: string;
}

const NpsComponent: React.FC<INpsComponentProps> = ({ label, linkTo, linkType }) => {
  return (
    <div className="m-disq">
      <div className="m-disq__column">
        <div
          style={{
            padding: '2rem 5rem',
            overflow: 'hidden'
          }}
        >
          <Image src={signet} alt="Stiftung Warentest - Beitragsniveau" sizes={{ h: 200 }} />
        </div>
      </div>
      <div className="m-disq__column m-disq__column--pad">
        <div>
          <p>
            “Unangenehmer Schadenfall, die Versicherung hat alles ohne Probleme geklärt und
            übernommen. Ich musste mich um nichts kümmern. Bin sehr zufrieden. Danke.”
          </p>
          <Link extraClass="m-button m-button--link" linkTo={linkTo} linkType={linkType}>
            <Icon type="arrow-right" className="ico-arrow-right" />
            {label}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NpsComponent;
