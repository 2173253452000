import React, { useState } from 'react';

import Icon from '../../components/ui/Icon/Icon';
import extractChildren from '../../helpers/extractChildren';
import useInterval from '../../hooks/useInterval';

const TestimonialArea = ({ children: rawChildren }) => {
  const children = extractChildren(rawChildren);

  const childrenLength = Object.keys(children).length - 1;

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const incrementActiveIndex = () => {
    if (activeIndex === childrenLength) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const incrementInterval = 5000;

  useInterval(() => {
    incrementActiveIndex();
  }, incrementInterval);

  const arrowHandler = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      incrementActiveIndex();
    } else {
      if (activeIndex === 0) {
        setActiveIndex(childrenLength);
      } else {
        setActiveIndex(activeIndex - 1);
      }
    }
  };

  return (
    <div className="m-carousel">
      {children && (
        <ul className="m-carousel__wrapper">
          {Object.keys(children).map((key, index) => {
            const child = children[key];

            return (
              <li key={key} className={`m-carousel__item${index === activeIndex ? ' active' : ''}`}>
                {child}
              </li>
            );
          })}
        </ul>
      )}
      {Object.keys(children).length > 0 && (
        <div className="m-carousel__controls">
          <ul className="m-carousel__pager">
            {Object.keys(children).map((key, index) => (
              <li
                key={key}
                onClick={() => setActiveIndex(index)}
                className={`${index === activeIndex ? 'active' : ''}`}
              />
            ))}
          </ul>
          <div className="m-carousel__prev" onClick={() => arrowHandler('prev')}>
            <Icon type="arrow-left" className="ico-arrow-left"></Icon>
          </div>
          <div className="m-carousel__next" onClick={() => arrowHandler('next')}>
            <Icon type="arrow-right" className="ico-arrow-right"></Icon>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestimonialArea;
