import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import ExpertSearchContext from './ExpertSearchContext';

interface ICorporateContactContextValue {
  isCorporateContactModalVisible: boolean;
  setIsCorporateContactModalVisible: Dispatch<SetStateAction<boolean>>;
  getExpertSearchCta: (
    showExpert: boolean,
    isExpertNameVariant?: boolean
  ) => {
    expertCallback: () => void;
    expertLabel: string;
    expertIconClass: string;
  };
}

const initialValue = {
  isCorporateContactModalVisible: false,
  setIsCorporateContactModalVisible: null,
  getExpertSearchCta: null
};

const CorporateContactContext = createContext<ICorporateContactContextValue>(initialValue);

export const CorporateContactContextProvider = ({ children }) => {
  const [isCorporateContactModalVisible, setIsCorporateContactModalVisible] = useState(
    initialValue.isCorporateContactModalVisible
  );

  const { expert, setIsExpertSearchModalVisible } = useContext(ExpertSearchContext) || {};

  const getExpertSearchCta = (showExpert: boolean, isExpertNameVariant: boolean) => {
    return {
      expertCallback: showExpert
        ? () => setIsExpertSearchModalVisible(true)
        : () => setIsCorporateContactModalVisible(true),
      expertLabel: showExpert
        ? expert
          ? isExpertNameVariant
            ? `${expert?.firstName || ''}${expert?.lastName ? ` ${expert.lastName}` : ''}`
            : 'Experten kontaktieren'
          : 'Experten vor Ort finden'
        : 'Beraten lassen',
      expertIconClass: `ico-${expert ? 'info' : 'hotline'} ga-content-header-contact`
    };
  };

  return (
    <CorporateContactContext.Provider
      value={{
        isCorporateContactModalVisible,
        setIsCorporateContactModalVisible,
        getExpertSearchCta
      }}
    >
      {children}
    </CorporateContactContext.Provider>
  );
};

export default CorporateContactContext;
