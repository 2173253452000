import React, { useContext, useState } from 'react';

import { ColumnsContext } from '../ColumnsComponent/ColumnsComponent';
import Video from '../ui/Video/Video';

interface IYouTubeComponent {
  autoplay?: boolean;
  id: string;
}

const YouTubeComponent: React.FC<IYouTubeComponent> = ({ autoplay, id }) => {
  const { videoSize } = useContext(ColumnsContext) || {};
  const [videoStatus, setVideoStatus] = useState(false);
  return id ? (
    <div
      className={`m-youtube${videoStatus ? ' playing' : ''}${
        videoStatus ? ' m-youtube--' + videoStatus : ''
      }`}
    >
      <Video
        id={id}
        onPause={() => {
          setVideoStatus(false);
        }}
        onPlay={() => {
          setVideoStatus(true);
        }}
        autoplay={autoplay}
        size={videoSize}
      />
    </div>
  ) : null;
};

export default YouTubeComponent;
