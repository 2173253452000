import Footer from '../components/ui/Footer/Footer';
import React from 'react';
import extractChildren from '../helpers/extractChildren';

const ContactDoorPage = ({ children }) => {
  const { headerArea, contentArea1, contentArea2, contactArea, contentHeaderArea } =
    extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content">
        {contentHeaderArea}
        {contentArea1}
        {contactArea}
        {contentArea2}
      </main>
      <Footer />
    </>
  );
};

export default ContactDoorPage;
