import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Image from '../../components/ui/Image/Image';
import SiteDataContext from '../../contexts/SiteDataContext';

const RelatedContentArea = () => {
  const { relatedTeasers, guideTags } = useContext(SiteDataContext) || {};
  const pageTags = guideTags?.map(tag => tag?.name).filter(tag => typeof tag === 'string');

  const filterTeasers = maxTeasers => {
    const teasers = relatedTeasers.filter(teaser => {
      const teaserTags = teaser.guideTags
        ?.map(tag => tag?.name)
        .filter(tag => typeof tag === 'string');
      return pageTags?.some(pageTag => teaserTags?.includes(pageTag));
    });

    // TODO: Fix if teasers smaller than the maxTeasers count, because infinity while loop.
    if (teasers.length <= maxTeasers) {
      return teasers;
    }

    const randomizedTeasers = [];
    while (randomizedTeasers.length < maxTeasers) {
      const randomIndex = Math.floor(Math.random() * teasers.length);
      !randomizedTeasers.includes(teasers[randomIndex]) &&
        randomizedTeasers.push(teasers[randomIndex]);
    }
    return randomizedTeasers;
  };

  const filteredTeasers = filterTeasers(2);

  return filteredTeasers ? (
    <section className="l-content__section">
      <div>
        <h2>Weitere Artikel</h2>
        <Columns gutter="s" extraClass="RelatedContentArea">
          {filteredTeasers.map((teaser, index) => {
            const { path, image, title } = teaser;
            return (
              <a className="m-promo-teaser m-promo-teaser--flat" href={path} key={index}>
                {/* backgrounf gradient  */}
                <figure>
                  <div className="m-promo-teaser__image">
                    {image && <Image src={image}></Image>}
                  </div>
                  <figcaption className="m-promo-teaser__caption ">
                    <h3 className="m-promo-teaser__caption__headline">{title}</h3>
                  </figcaption>
                </figure>
              </a>
            );
          })}
        </Columns>
      </div>
    </section>
  ) : null;
};

export default RelatedContentArea;
