import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import useOutsideClick from '../../../hooks/useOutsideClick';
import useVpHeight from '../../../hooks/useVpHeight';
import Icon from '../Icon/Icon';
import Link, { ILinkProps } from '../Link/Link';

interface IMultiButton {
  buttonVariant: string;
  label: string;
  links: ILinkProps[];
  hideIcon?: boolean;
  buttonCallback?: () => void;
}

const MultiButton: React.FC<IMultiButton> = ({
  buttonVariant,
  label,
  links,
  hideIcon,
  buttonCallback
}) => {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [contentClass, setContentClass] = useState('m-multi-button__content');
  const triggerRef = useRef(null);
  const contentRef = useRef(null);

  useOutsideClick(contentRef, () => setIsContentVisible(false), isContentVisible, triggerRef);

  const vpHeight = useVpHeight();

  const onTrigger = () => {
    if (triggerRef?.current?.getBoundingClientRect()?.top > vpHeight / 2) {
      setContentClass('m-multi-button__content m-multi-button__content--top');
    } else {
      setContentClass('m-multi-button__content');
    }
    setIsContentVisible(!isContentVisible);
  };
  return (
    links?.length > 0 && (
      <div className="m-multi-button">
        <button
          className={`m-button m-button--${
            buttonVariant || 'link'
          } m-multi-button__trigger${isContentVisible ? ' open' : ''}`}
          ref={triggerRef}
          onClick={() => onTrigger()}
        >
          <div>
            <span>
              {!hideIcon && <Icon type="download" className="ico-download" />}
              {label}
            </span>
            <span>
              <Icon type="close" className="ico-close" />
            </span>
          </div>
        </button>
        <CSSTransition
          in={isContentVisible}
          mountOnEnter
          unmountOnExit
          timeout={500}
          classNames={'fade'}
        >
          <div className={contentClass} ref={contentRef}>
            <div>
              {links &&
                links.map((link, index) => (
                  <Link
                    onClick={() => {
                      if (buttonCallback) {
                        buttonCallback();
                      }
                    }}
                    showIcon
                    key={index}
                    {...link}
                    extraClass="m-button m-button--sec m-button--condensed m-button--linebreak"
                  />
                ))}
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  );
};

export default MultiButton;
