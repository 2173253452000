import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const EventOverviewPage = ({ children }) => {
  const { headerArea, contentHeaderArea, eventsArea } = extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content l-content--corporate">
        {contentHeaderArea}
        {eventsArea}
      </main>
      <Footer />
    </>
  );
};

export default EventOverviewPage;
