import React from 'react';

interface ITabProps {
  children: any;
  extraClass?: string;
  variant?: 'cyber';
}

const Tab: React.FC<ITabProps> = ({ children, extraClass, variant }) => {
  return variant === 'cyber' ? (
    <>{children}</>
  ) : (
    <div className={`m-tab${extraClass ? ' ' + extraClass : ''}`}>
      <div style={{ minHeight: '23rem', marginTop: '-2.2rem' }}>{children}</div>
    </div>
  );
};

export default Tab;
