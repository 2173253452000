import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const ExpertDirectoryPage = ({ children }) => {
  const { headerArea, contentHeaderArea, contentArea1, directoryArea, contentArea2 } =
    extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content l-content--corporate">
        {contentHeaderArea}
        {contentArea1}
        {directoryArea}
        {contentArea2}
      </main>
      <Footer />
    </>
  );
};

export default ExpertDirectoryPage;
