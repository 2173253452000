import React from 'react';

const LeadTextArea = ({ richText }) => {
  return richText ? (
    <section className="l-content__section">
      <div>{richText}</div>
    </section>
  ) : null;
};

export default LeadTextArea;
