import React, { useContext } from 'react';

import { FaqContext } from '../FaqComponent/FaqComponent';
import Accordion from '../ui/Accordion/Accordion';
import Tile from '../ui/Tile/Tile';

const QuestionComponent = ({ question, answer }) => {
  const { variant } = useContext(FaqContext) || {};

  return variant === 'tiles' ? (
    <Tile question={question} answer={answer} />
  ) : (
    <Accordion question={question} answer={answer} />
  );
};

export default QuestionComponent;
