import React, { useContext } from 'react';

import CorporateContactContext from '../../../contexts/CorporateContactContext';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import FullscreenOverlaySlider from '../FullscreenOverlaySlider/FullscreenOverlaySlider';
import Icon from '../Icon/Icon';
import CorporateContact from './CorporateContact';

const CorporateContactModal = () => {
  const { isCorporateContactModalVisible, setIsCorporateContactModalVisible } =
    useContext(CorporateContactContext) || {};

  const hideOverlay = () => setIsCorporateContactModalVisible(false);
  return (
    <FullscreenOverlay isVisible={isCorporateContactModalVisible} hideOverlay={hideOverlay}>
      <FullscreenOverlaySlider>
        <div>
          <header>
            <Icon type="hotline" className="ico-hotline"></Icon>
            <h2>Wie können wir Ihnen helfen?</h2>
          </header>
          <div className="m-asp m-asp--overlay">
            <CorporateContact variant="modal" hideOverlay={hideOverlay} />
          </div>
        </div>
      </FullscreenOverlaySlider>
    </FullscreenOverlay>
  );
};

export default CorporateContactModal;
