import React from 'react';

import RichText from '../ui/RichText/RichText';

const ContactBoxComponent = ({ text, headline, children }) => {
  return (
    <div className="m-box m-custom-box spacer">
      <div className="l-grid">
        <div className="l-grid__col--8-12">
          <div className="m-custom-box__text">
            {headline && <h3> {headline} </h3>}
            <RichText richText={text} />
          </div>
        </div>
        <div className="l-grid__col--4-12">
          <div className="m-custom-box__buttons">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ContactBoxComponent;
