import React from 'react';

const BenefitComponent = ({ headline, description }) => {
  return (
    <li>
      <strong>{headline}</strong>
      {description}
    </li>
  );
};

export default BenefitComponent;
