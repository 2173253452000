import React, { useEffect, useRef } from 'react';

import extractChildren from '../../helpers/extractChildren';
import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';
import useBreakpoint from '../../hooks/useBreakpoint';
import Columns from '../ui/Columns/Columns';

const ComparisonComponentNew = props => {
  const { children } = props;
  const { rowArea, footerArea } = extractChildren(children);
  const BreakpointS = useBreakpoint('s');

  const packages = mapIndexedNodeProps(props, 'packages');

  const getWidths = () => {
    const widthArray = new Array(packages.length).fill(1);
    return widthArray;
  };

  const useSyncHorizontalScroll = (sourceRef, targetRef) => {
    const sourceScrollLeft = useRef(0);
    const targetScrollLeft = useRef(0);
    const isSettingScroll = useRef(false);

    const handleSourceScroll = () => {
      if (!isSettingScroll.current) {
        sourceScrollLeft.current = sourceRef.current.scrollLeft;
        isSettingScroll.current = true;
        targetRef.current.scrollLeft = sourceScrollLeft.current;
      } else {
        isSettingScroll.current = false;
      }
    };

    const handleTargetScroll = () => {
      if (!isSettingScroll.current) {
        targetScrollLeft.current = targetRef.current.scrollLeft;
        isSettingScroll.current = true;
        sourceRef.current.scrollLeft = targetScrollLeft.current;
      } else {
        isSettingScroll.current = false;
      }
    };

    useEffect(() => {
      const sourceElement = sourceRef.current;
      const targetElement = targetRef.current;

      sourceElement.addEventListener('scroll', handleSourceScroll);
      targetElement.addEventListener('scroll', handleTargetScroll);

      return () => {
        sourceElement.removeEventListener('scroll', handleSourceScroll);
        targetElement.removeEventListener('scroll', handleTargetScroll);
      };
    }, [sourceRef, targetRef]);

    return { sourceScrollLeft, targetScrollLeft };
  };

  const sourceRef = useRef(null);
  const targetRef = useRef(null);
  useSyncHorizontalScroll(sourceRef, targetRef);

  const getDynamicHeader = () => {
    return (
      <Columns
        widths={getWidths()}
        gutter={BreakpointS ? 'xs' : 'xl--content'}
        nowrap
        overflowx={false}
      >
        {Array.isArray(packages) &&
          packages.map((pkg, index) => {
            const { name, new: isNew } = pkg || {};

            return (
              <div
                className={`ComparisonComponentNew__th${
                  isNew ? ' ComparisonComponentNew__th--new' : ''
                }`}
                key={index}
              >
                {name}
                {isNew && <span className="ComparisonComponentNew__badge">NEU</span>}
              </div>
            );
          })}
      </Columns>
    );
  };

  const getTableHeader = () => {
    return (
      <>
        {BreakpointS ? (
          <>{getDynamicHeader()}</>
        ) : (
          <>
            <Columns widths={[1, 2]} gutter={'xl--content'} wrap>
              <div className="ComparisonComponentNew__first-col">Die wichtigsten Leistungen</div>
              <div className="ComparisonComponentNew__col">{getDynamicHeader()}</div>
            </Columns>
          </>
        )}
      </>
    );
  };

  return (
    <div>
      <div className="ComparisonComponentNew m-comparison">
        <div ref={targetRef} className={`ComparisonComponentNew__tableHeadWrapper`}>
          {getTableHeader()}
        </div>
        <div ref={sourceRef} className={`ComparisonComponentNew__contentWrapper`}>
          {rowArea}
        </div>
      </div>
      {footerArea}
    </div>
  );
};
export default ComparisonComponentNew;
