import React, { Children } from 'react';

const ContentArea = ({ children, parentTemplate }) => {
  const contentAreaVariant = () => {
    switch (parentTemplate) {
      case 'SectionComponent':
      case 'HomeSectionComponent':
        return Children.map(children, (child, index) => {
          return index < children.length - 1 ? (
            <div className="spacer">{child}</div>
          ) : (
            <div>{child}</div>
          );
        });
      default:
        return <>{children}</>;
    }
  };

  return children ? contentAreaVariant() : null;
};

export default ContentArea;
