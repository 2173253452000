import React from 'react';

import Icon from '../ui/Icon/Icon';

const ComparisonCellComponent = ({
  type,
  boolvalue,
  booldescription,
  value,
  description,
  value2,
  description2
}) => {
  const isBool = type === 'bool';
  const isSingle = type === 'single';

  const content = type => {
    switch (type) {
      case 'bool':
        return {
          isDone: boolvalue,
          strong: null,
          small: booldescription
        };
      default:
        return {
          isDone: false,
          strong: value,
          small: description
        };
    }
  };

  const { isDone, strong, small } = content(type);

  const getContent = (value, description) => {
    return (
      <>
        {strong && <strong>{value}</strong>}
        {isBool && (isDone ? <Icon type="check" className="ico-check"></Icon> : <>&mdash;</>)}
        {small && <small>{description}</small>}
      </>
    );
  };

  return isBool || isSingle ? (
    <div className="ComparisonCellComponent">{getContent(strong, small)}</div>
  ) : (
    <div className="ComparisonCellComponent">
      {getContent(strong, small)}
      {getContent(value2, description2)}
    </div>
  );
};
export default ComparisonCellComponent;
