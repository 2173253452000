import './VideoCookieBox.scss';
import useCookieByKey from '../../../hooks/useCookieByKey';

import React, { useEffect, useRef, useState } from 'react';

import Link from '../Link/Link';

const VideoCookieBox = () => {
  const [showConsentLayer, setShowConsentLayer] = useState<boolean>(true);

  const onetrustActiveGroups = useCookieByKey('onetrust_active_groups', true);

  useEffect(() => {
    const showLayer = !onetrustActiveGroups.includes('C0001,C0002,C0004');
    setShowConsentLayer(showLayer);
  }, [onetrustActiveGroups]);

  return showConsentLayer ? (
    <div className="VideoCookieBox">
      <div className="VideoCookieBox__box">
        <h3>Wir benötigen Ihre Einwilligung</h3>
        <p>
          Bitte passen Sie Ihre Cookie-Einstellungen an, um alle Funktionen der Website nutzen zu
          können.
        </p>
        <Link
          extraClass="m-button"
          linkTo="cookieOverlay"
          linkType="internal_app"
          label="Cookie Einstellungen"
        />
      </div>
    </div>
  ) : null;
};
export default VideoCookieBox;
