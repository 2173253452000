import React, { useRef, useState } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick';
import Icon from '../ui/Icon/Icon';

const UspTileComponent = ({ data }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const tileRef = useRef(null);
  useOutsideClick(tileRef, () => setIsFlipped(false), isFlipped);

  const handleClickCard = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div
      className={`UspTileComponent${isFlipped ? ' UspTileComponent--flipped' : ''}`}
      onClick={handleClickCard}
      ref={tileRef}
    >
      <div className="UspTileComponent__TileInner">
        <div className="UspTileComponent__TileFront">
          <Icon size="2.1rem" type="plus-circled" className="UspTileComponent__TilePlusIcon" />
          <div className="UspTileComponent__TileContentWrapper">
            <div className="UspTileComponent__TileUspIcon">
              <Icon type={data.icon} />
            </div>
            <div
              className="UspTileComponent__TileTitle"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          </div>
        </div>
        <div className="UspTileComponent__TileBack">
          <Icon size="2.1rem" type="plus-circled" className="UspTileComponent__TilePlusIcon" />
          <div>{data.description}</div>
        </div>
      </div>
    </div>
  );
};

export default UspTileComponent;
