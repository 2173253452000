import React, { useEffect, useState } from 'react';

import useBreakpoint from '../../hooks/useBreakpoint';
import barbaraImage from '../../styles/assets/images/phv-2022-stage/phv-2022-barbara.png';
import bgImageDesktop from '../../styles/assets/images/phv-2022-stage/phv-2022-bg.jpg';
import bgImageMobile from '../../styles/assets/images/phv-2022-stage/phv-2022-mobile.jpg';
import PriceCallToActionComponent from '../PriceCallToActionComponent/PriceCallToActionComponent';
import ContactBar from '../ui/ContactBar/ContactBar';
import Image from '../ui/Image/Image';

const Phv2022StageComponent = () => {
  const BreakpointS = useBreakpoint('s');
  const [bgImage, setBgImage] = useState(bgImageDesktop);

  useEffect(() => {
    setBgImage(BreakpointS ? bgImageMobile : bgImageDesktop);
  }, [BreakpointS]);

  return (
    <header className="l-phv-2022-stage">
      <div>
        <Image extraClass="l-phv-2022-stage__bg" isBackgroundImage src={bgImage} />
        {!BreakpointS && <Image extraClass="l-phv-2022-stage__barbara" src={barbaraImage} />}
        <div className="l-phv-2022-stage__wrapper">
          <div className="l-phv-2022-stage__text">
            <p className="l-phv-2022-stage__subline">Die Privathaftpflicht der VHV</p>
            <h2 className="l-phv-2022-stage__headline">
              ausgezeichnete Begleitung für jeden Anlass
            </h2>
            <div className="l-phv-2022-stage__button">
              <PriceCallToActionComponent
                leftButtonLabelTop="Bereits ab mtl."
                leftButtonLabelBottom="3,51"
                leftButtonPopup="Versichert ist eine alleinstehende Person ohne Kind(er) aus 26789 Leer. Tarif Klassik-Garant Single ohne Selbstbeteiligung und keine Vorschäden in den letzten 5 Jahren."
                rightButtonLabel="Jetzt Tarif berechnen"
                leftButtonLabel="Hello"
                linkTo="/versicherungen/haftpflichtversicherung/privat/tarifrechner?appView=true"
                linkType="page"
              />
            </div>
          </div>
          <div className="l-phv-2022-stage__contact-area">
            <ContactBar />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Phv2022StageComponent;
