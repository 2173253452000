import axios from 'axios';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import NewsletterRegistrationContext from '../../contexts/NewsletterRegistrationContext';
import getFunctionUrl from '../../helpers/getFunctionUrl';
import { pushToDataLayer } from '../../hooks/useCookiePolicy';
import Columns from '../ui/Columns/Columns';
import EmailFieldComponent from '../ui/Form/EmailFieldComponent';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';
import Tabs from '../ui/Tabs/Tabs';

const NewsletterRegistrationDownloadComponent = ({
  headline,
  // text,
  buttonLabel,
  download
  // email,
}) => {
  const methods = useForm();
  const [isStatus, setIsStatus] = useState<string>('');
  const [customerMail, setCustomerMail] = useState(null);

  const { setIsRegistrationVisible } = useContext(NewsletterRegistrationContext) || {};
  // ToDo use internal Mail - email prop

  const formServiceHandler = async (data): Promise<any> => {
    try {
      const res = await axios.post(getFunctionUrl('mailingwork'), data);
      console.log(res);
      if (res.status === 200) return res;
    } catch (error) {
      console.error(error);
      setIsStatus('error');
    }
  };

  const onSubmit = (data: Record<string, string>) => {
    if (data) {
      setCustomerMail(data.address);
      formServiceHandler({
        isUserInList: { email: data.address, listname: 'bauexperten' }
      })
        .then(resp => {
          const { error, result } = resp.data || {};
          if (result === 1 && error === 0) {
            pushToDataLayer([
              { formularStatusGen3: 'abgeschickt' },
              { event: 'formularGen3abgeschickt' }
            ]);
            setIsStatus('success');
          } else if (result === 0 && error === 0) {
            setIsStatus('fail');
          } else {
            setIsStatus('error');
          }
        })
        .catch(err => console.error(err));
    }
  };
  return (
    <>
      <FormProvider {...methods}>
        <div className="m-box">
          <div className="m-box__hideBg"></div>
          <h3>Sie möchten weiterlesen?</h3>
          <p className="spacer">
            Wir freuen uns über Ihr Interesse. Als Newsletter-Abonnent können Sie die Studie
            weiterlesen. Sie sind noch kein Abonnent? Dann können einfach, schnell und kostenlos
            anmelden.
          </p>

          <Tabs
            tabTitles={[
              'Ich bin bereits Newsletter-Abonnent',
              'Ich bin noch kein Newsletter-Abonnent'
            ]}
            variant="buttons"
          >
            <div>
              <div className="m-box m-box--light">
                {{
                  success: (
                    <div>
                      <h3>Vielen Dank!</h3>
                      <div className="text-xl text-green spacer spacer-top">
                        <Icon type="check" className="ico-check"></Icon>
                      </div>
                      <p>Sie können die Studie nun kostenlos herunterladen.</p>
                      <Link
                        linkTo={download}
                        linkType="asset"
                        extraClass="m-button"
                        label={headline}
                        showIcon
                      />
                    </div>
                  ),
                  fail: (
                    <>
                      <h3>Hoppla!</h3>
                      <p className="spacer">
                        Die angegebene E-Mail-Adresse <strong>{customerMail}</strong> ist noch nicht
                        für den Newsletter registriert. Bitte beachten Sie, dass Sie nach der
                        Anmeldung Ihre E-Mail-Adresse zunächst bestätigen müssen. Schauen Sie in
                        Ihrem Postfach nach.
                      </p>
                      <div className="spacer">
                        <button className="m-button" onClick={() => setIsRegistrationVisible(true)}>
                          <Icon type="arrow-right" className="ico-arrow-right"></Icon>
                          {buttonLabel}
                        </button>
                      </div>
                      <div>
                        <button
                          className="m-button m-button--link"
                          onClick={() => setIsStatus('from')}
                        >
                          <Icon type="arrow-left" className="ico-arrow-left"></Icon>
                          Zurück zur Anmeldung
                        </button>
                      </div>
                    </>
                  ),
                  error: (
                    <>
                      <div ng-if="ctrl.screen === 'error'">
                        <h3>Hoppla!</h3>
                        <p className="spacer">
                          Es ist leider ein Fehler aufgetreten oder die angegebene E-Mail-Adresse
                          war nicht korrekt!
                        </p>
                        <button
                          className="m-button m-button--link"
                          onClick={() => setIsStatus('from')}
                        >
                          <Icon type="arrow-left" className="ico-arrow-left"></Icon>
                          Zurück
                        </button>
                      </div>
                    </>
                  )
                }[isStatus] || (
                  <div>
                    <form
                      noValidate
                      name="form"
                      id="form"
                      onSubmit={methods.handleSubmit(onSubmit)}
                      className="m-form m-form--centered m-form--with-vaidation"
                    >
                      <p className="text-left">
                        Prima! Bitte geben Sie hier Ihre E-Mail-Adresse ein, mit der Sie den
                        Newsletter beziehen.
                      </p>
                      <Columns gutter="xs" widths={[2, 1]}>
                        <EmailFieldComponent label="E-Mail-Adresse" name="address" />
                        <button
                          className="m-button m-button--full-width m-button--size-m"
                          type="submit"
                          style={{ marginTop: '1rem' }}
                        >
                          <Icon type="arrow-right" className="ico-arrow-right"></Icon>
                          <span>Studie anfordern</span>
                        </button>
                      </Columns>
                    </form>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className="m-box m-box--light">
                <div className="spacer">
                  Unsere Studien – exklusiv für unsere Newsletter-Abonnenten. Schnell, einfach und
                  kostenlos.
                </div>
                <button onClick={() => setIsRegistrationVisible(true)} className="m-button">
                  <Icon type="arrow-right" />
                  {buttonLabel}
                </button>
              </div>
            </div>
          </Tabs>
        </div>
      </FormProvider>
    </>
  );
};

export default NewsletterRegistrationDownloadComponent;
