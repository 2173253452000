import React, { useContext, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import CorporateContactContext from '../../contexts/CorporateContactContext';
import ExpertSearchContext from '../../contexts/ExpertSearchContext';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import SiteDataContext from '../../contexts/SiteDataContext';
import useSplitPathname from '../../hooks/useSplitPathname';

const OffcanvasMenu = ({ nav, visibleSubMenus, dispatchVisibleSubMenus, closeOffcanvas }) => {
  //Todo @Ilja: expand useOutsideClick to allow an array of refs to reference all <li/> children in mapping

  const splitPathname = useSplitPathname();

  const { activeProduct = {} } = useContext(ProductGroupsContext) || {};

  const { showExpert } = activeProduct || {};
  const { pageScope } = useContext(SiteDataContext) || {};
  const { setIsExpertSearchModalVisible } = useContext(ExpertSearchContext) || {};
  const { setIsCorporateContactModalVisible } = useContext(CorporateContactContext) || {};
  const { [splitPathname?.length - 1]: currentPath, ...parentPaths } = splitPathname || [];

  const showContactModal = () =>
    !showExpert ? setIsCorporateContactModalVisible(true) : setIsExpertSearchModalVisible(true);

  useEffect(() => {
    if (parentPaths && visibleSubMenus && visibleSubMenus.length === 0) {
      dispatchVisibleSubMenus({
        type: 'set',
        value: [...Object.values(parentPaths)]
      });
    }
  }, []);
  return (
    <>
      <div className="l-offcanvas-navigation__main-nav">
        {nav && (
          <ul>
            {nav.map((navItem, index) => {
              const { name, path, template, title, label, children, navIcon, pathType } =
                navItem || {};

              const { icon } = navIcon || {};

              const pathName = path && path.split('/').pop();
              const isActive = visibleSubMenus.includes(name);
              const className = `${currentPath === pathName ? 'selected' : ''}`;
              const isCorporateContact = pageScope === 'corporate' && name === 'kontakt';

              const navLinkLabel = label || title;

              return (
                path &&
                title && (
                  <li key={index}>
                    {children ? (
                      <>
                        <a
                          className={className}
                          onClick={() => {
                            dispatchVisibleSubMenus({
                              type: 'push',
                              value: name
                            });
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {navLinkLabel}
                          <Icon type="arrow-right" className="ico-arrow-right" />
                        </a>
                        <CSSTransition
                          in={isActive}
                          mountOnEnter
                          unmountOnExit
                          timeout={500}
                          classNames={'transform-offcanvas'}
                        >
                          <div className="l-offcanvas-navigation__level l-offcanvas-navigation__level--sub">
                            <header className="l-offcanvas-navigation__header">
                              <h4
                                onClick={() => {
                                  dispatchVisibleSubMenus({
                                    type: 'pop'
                                  });
                                }}
                              >
                                <button className="l-offcanvas-navigation__header__button">
                                  <Icon type="arrow-left" className="ico-arrow-left" />
                                </button>
                                {navLinkLabel}
                              </h4>
                              <button
                                className="l-offcanvas-navigation__header__close-button"
                                onClick={() => closeOffcanvas()}
                              >
                                <Icon type="close" className="ico-close" />
                              </button>
                            </header>
                            <OffcanvasMenu
                              nav={children}
                              visibleSubMenus={visibleSubMenus}
                              dispatchVisibleSubMenus={dispatchVisibleSubMenus}
                              closeOffcanvas={closeOffcanvas}
                            />
                          </div>
                        </CSSTransition>
                      </>
                    ) : isCorporateContact ? (
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          closeOffcanvas();
                          showContactModal();
                        }}
                      >
                        {navLinkLabel}
                      </a>
                    ) : (
                      <Link extraClass={className} linkTo={{ path, template }} linkType={pathType}>
                        {icon && <Icon type={icon} />}
                        {`${icon ? ' ' : ''}${navLinkLabel}`}
                      </Link>
                    )}
                  </li>
                )
              );
            })}
          </ul>
        )}
      </div>
      <ul className="l-offcanvas-navigation__sec-nav">
        <li className="tag-branch-selection-button">
          {pageScope === 'corporate' ? (
            <Link linkTo="/" extraClass="m-button m-button--light">
              <Icon type="profil" className="ico-profil"></Icon>Privat
            </Link>
          ) : (
            <Link linkTo="/firmen" extraClass="m-button m-button--light">
              <Icon type="firmen" className="ico-firmen"></Icon> Firmen-Kunden
            </Link>
          )}
        </li>
      </ul>
    </>
  );
};
export default OffcanvasMenu;
