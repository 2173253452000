import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import SiteDataContext from '../../contexts/SiteDataContext';
import useBreakpoint from '../../hooks/useBreakpoint';

const ProductArea = ({ productGroups: productGroupIds }) => {
  const { pageScope } = useContext(SiteDataContext) || {};

  const { productGroups: productGroupsRaw, getProductGroup } =
    useContext(ProductGroupsContext) || {};

  const productGroups = productGroupsRaw && productGroupsRaw[pageScope];

  const tileCount = productGroupIds
    ? productGroupIds.length
    : productGroups
      ? Object.keys(productGroups).length
      : 0;

  // const articleColVariant =
  //   tileCount < 5 ? 6 : tileCount === 5 ? 4 : tileCount === 7 ? 3 : 2;

  const productGroupKeys =
    productGroupIds ||
    (productGroups &&
      Object.values(productGroups).map((productGroup: any) => {
        return productGroup.id;
      }));

  const headline = productGroupIds
    ? 'Unsere Produkte auf einen Blick'
    : 'Zu diesen Themen beraten wir Sie gerne';

  const BreakpointL = useBreakpoint('l');
  const BreakpointM = useBreakpoint('m');

  return productGroups ? (
    <section className="l-content__section">
      <div>
        <h2>{headline}</h2>
        <div className="m-content-tiles m-content-tiles--products">
          <Columns
            gutter="xs"
            breakpoint="s"
            wrap
            widths={
              BreakpointL ? (BreakpointM ? [1, 1, 1, 1] : [1, 1, 1, 1, 1]) : [1, 1, 1, 1, 1, 1]
            }
          >
            {productGroupKeys &&
              productGroupKeys.map(productGroupId => {
                const productGroup = getProductGroup('id', productGroupId);
                const { path, icon, title, name } = productGroup || {};

                return (
                  productGroup &&
                  //temporary solution to filter out the "vorsorge" product
                  name !== 'vorsorge' && (
                    <Link linkTo={path} key={productGroupId}>
                      <article className={`m-content-tiles__tile`} key={productGroupId}>
                        <div>
                          <h4 className={`size-${tileCount}`}>
                            <Icon type={icon} className={`ico-${icon}`}></Icon>
                            {title}
                          </h4>
                        </div>
                      </article>
                    </Link>
                  )
                );
              })}
          </Columns>
        </div>
      </div>
    </section>
  ) : null;
};

export default ProductArea;
