import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import AddressFieldComponent from '../components/ui/Form/AddressFieldComponent';
import CheckboxComponent from '../components/ui/Form/CheckboxComponent';
import DateFieldComponent from '../components/ui/Form/DateFieldComponent';
import EmailFieldComponent from '../components/ui/Form/EmailFieldComponent';
import FooterArea from '../components/ui/Form/FooterArea';
import HeadlineComponent from '../components/ui/Form/HeadlineComponent';
import LocalityFieldComponent from '../components/ui/Form/LocalityFieldComponent';
import RadioGroupComponent from '../components/ui/Form/RadioGroupComponent';
import SelectFieldComponent from '../components/ui/Form/SelectFieldComponent';
import TextComponent from '../components/ui/Form/TextComponent';
import TextFieldComponent from '../components/ui/Form/TextFieldComponent';
import Icon from '../components/ui/Icon/Icon';
import Link from '../components/ui/Link/Link';
import RichText from '../components/ui/RichText/RichText';
import extractChildren from '../helpers/extractChildren';
import getFunctionUrl from '../helpers/getFunctionUrl';
import { triggerDownload } from '../helpers/triggerDownload';
import useBreakpoint from '../hooks/useBreakpoint';
import { pushToDataLayer } from '../hooks/useCookiePolicy';
import vhvLogoMark from '../styles/assets/images/vhv-logo-mark.png';
import vhvLogoSubhead from '../styles/assets/images/vhv-logo-subhead.png';
import vhvLogoSw from '../styles/assets/images/vhv-logo-sw.png';

export interface FormPageProps {
  helpBoxText?: string;
  children: React.ReactNode;
  created: string;
  description: string;
  footerArea: {
    created: string;
    id: string;
    name: string;
    path: string;
    nodes: string[];
  };
  formArea: any;
  icon: string;
  id: string;
  name: string;
  nodes: string[];
  path: string;
  hideInOverviews: string;
  template: string;
  title: string;
  title_original: string;
  internalMail: any;
  replyTo: string;
  maxFillTime: string;
  minFillTime: string;
  confirmationMailTemplateName: string;
  freeTextPayload?: boolean;
  confirmationPageTemplate: string;
  customerEmail?: boolean;
  emailPdf?: boolean;
  emailXml?: boolean;
}

interface IApiHandler {
  timestamp?: boolean;
  formFields?: Record<string, string>;
  customerEmail?: boolean;
  emailPdf?: boolean;
  emailXml?: boolean;
  config?: {
    pagePath: string;
    maxFillTime: string;
    minFillTime: string;
    freeTextPayload?: boolean;
    confirmationMailTemplateName: string;
    confirmationPageTemplate: string;
    startTime: string;
    endTime: string;
  };
}

const formServiceHandler = async (data: IApiHandler): Promise<any> => {
  try {
    const res = await axios.post(getFunctionUrl('formservice'), data);
    if (res.status === 200) {
      return res;
    }
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const FormPage = ({
  path,
  title,
  icon,
  children,
  helpBoxText,
  maxFillTime,
  minFillTime,
  confirmationMailTemplateName,
  confirmationPageTemplate,
  freeTextPayload,
  customerEmail,
  emailPdf,
  emailXml,
  pageScope
}: FormPageProps) => {
  const [startTime, setStartTime] = useState<string>('0');
  const [isSucess, setIsSucess] = useState<boolean>(false);
  const [downloadPdf, setDownloadPdf] = useState('');
  const [pdfName, setPdfName] = useState(confirmationMailTemplateName);
  const [confirmationTex, setConfirmationTex] = useState(confirmationPageTemplate);

  const { formArea, footerArea } = extractChildren(children);
  const methods = useForm();
  const { register } = methods;

  const onSubmit = async data => {
    if (data && data['defaultValue'] === '') {
      await formServiceHandler({
        formFields: data,
        customerEmail,
        emailPdf,
        emailXml,
        config: {
          pagePath: path,
          maxFillTime,
          minFillTime,
          freeTextPayload,
          confirmationMailTemplateName,
          confirmationPageTemplate,
          startTime,
          endTime: new Date().toString()
        }
      })
        .then(resp => {
          if (resp.data.statusCode === 200) {
            if (resp.data.pdf !== undefined && resp.data.pdf !== '') {
              if (data.vor_nachname) {
                const createPdfName = data?.schadennummer
                  ? data?.schadennummer
                  : data.vor_nachname
                    ? data.vor_nachname
                    : data?.vnnummer
                      ? data?.vnnummer
                      : '1';
                setPdfName(createPdfName.replace(' ', '_'));
              }
              setDownloadPdf(resp.data.pdf);
            }

            pushToDataLayer([
              { formularStatusGen3: 'abgeschickt' },
              { event: 'formularGen3abgeschickt' }
            ]);

            setIsSucess(true);
          } else {
            setConfirmationTex(
              'Ein unerwarteter Fehler ist aufgetreten! Bitte versuchen Sie es später noch einmal.'
            );
            setIsSucess(true);
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            const statusCode = error.response.status;
            const errorMessage = error.response.data.message;

            if (statusCode === 401 && errorMessage?.config === 'Incorrect fill in time') {
              setConfirmationTex(
                'Die maximal zulässige Dauer zum Ausfüllen des Formulars wurde überschritten. Bitte füllen Sie das Formular aus Sicherheitsgründen erneut aus.'
              );
              setIsSucess(true);
            } else if (statusCode === 500 && errorMessage?.startsWith('Error from mail server')) {
              setConfirmationTex(
                'In der Validierung des Formulars ist ein Fehler aufgetreten! Bitte versuchen Sie es später noch einmal.'
              );
              setIsSucess(true);
            } else {
              setConfirmationTex(
                'Ein unerwarteter Fehler ist aufgetreten! Bitte versuchen Sie es später noch einmal.'
              );
              setIsSucess(true);
            }
          } else if (error.request) {
            setConfirmationTex(
              'Ein Netzwerkfehler ist aufgetreten! Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.'
            );
            setIsSucess(true);
          } else {
            setConfirmationTex(
              'Ein unerwarteter Fehler ist aufgetreten! Bitte versuchen Sie es später noch einmal.'
            );
            setIsSucess(true);
          }
        });
    }
  };

  useEffect(() => {
    pushToDataLayer([
      {
        formularGeneration: '3formularbaukasten'
      },
      {
        formularStatusGen3: 'geoeffnet'
      },
      {
        formularNameGen3: path?.split('/').slice(1).join('-')
      },
      {
        type: 'formular'
      },
      {
        event: 'formularGen3geoeffnet'
      }
    ]);

    if (startTime === '0') {
      formServiceHandler({ timestamp: true })
        .then(res => {
          setStartTime(res?.data?.timestamp);
        })
        .catch(error => {
          console.error(error);
          setStartTime('0');
        });
    }
  }, []);

  const isMaxVpS = useBreakpoint('s');

  return (
    <div className="privat js l-iframe l-calculator-privat-kontakt-email container">
      <header>
        <Link linkTo="/" extraClass="l-header__logo">
          {isMaxVpS ? (
            <img className="mobile" src={vhvLogoSw} alt="VHV" />
          ) : (
            <>
              <img src={vhvLogoMark} />
              <img className="subhead" src={vhvLogoSubhead} alt="Versicherungen" />
            </>
          )}
        </Link>
        <i className={`ico-${icon}`} />
        <h2 className="h2">{title}</h2>
      </header>
      <div className="page">
        {!isSucess ? (
          <FormProvider {...methods}>
            <form
              noValidate
              name="form"
              id="form"
              className={classNames({
                'ng-submitted': methods.formState.isSubmitted
              })}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <div className="m-form">
                <div className="l-calculator m-box m-box--light spacer-small">
                  <div className="l-calculator__form l-calculator__form--sidebar">
                    <fieldset>{formArea}</fieldset>
                  </div>
                  <div className="l-calculator__sidebar">
                    {helpBoxText && <RichText extraClass="m-help-box" richText={helpBoxText} />}
                  </div>
                </div>
                <FooterArea>{footerArea}</FooterArea>
              </div>
              <label className="default_value" htmlFor="defaultValue"></label>
              <input
                className="default_values"
                autoComplete="off"
                {...register('defaultValue')}
                type="text"
                id="defaultValue"
                placeholder="Your name here"
              />
            </form>
          </FormProvider>
        ) : (
          <div style={{ padding: '1.5%', textAlign: 'center' }}>
            <p style={{ marginBottom: '20px' }}>{confirmationTex}</p>
            {downloadPdf !== undefined && downloadPdf !== '' && (
              <button
                style={{ background: 'transparent' }}
                className="Link m-button--medium"
                onClick={() => triggerDownload(downloadPdf, `${pdfName}.pdf`)}
              >
                <span style={{ fontSize: '2.5rem' }}>
                  <Icon className=" Icon--middle" type="download" />
                  Herunterladen
                </span>
              </button>
            )}
          </div>
        )}
      </div>
      <a
        href="/"
        className="l-fullscreen-overlay__close-button l-fullscreen-overlay__close-button--with-label"
      >
        {pageScope === 'expert' ? 'Zur VHV Bauexperten Startseite' : 'Zur VHV Startseite'}
        <Icon type="close" className="ico-close" />
      </a>
    </div>
  );
};

FormPage.AddressField = AddressFieldComponent;
FormPage.CheckBox = CheckboxComponent;
FormPage.EmailField = EmailFieldComponent;
FormPage.SelectField = SelectFieldComponent;
FormPage.RadioGroup = RadioGroupComponent;
FormPage.LocalityField = LocalityFieldComponent;
FormPage.TextFieldComponent = TextFieldComponent;
FormPage.DateField = DateFieldComponent;
FormPage.Headline = HeadlineComponent;
FormPage.TextField = TextComponent;

export default FormPage;
