import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

import SiteDataContext from './SiteDataContext';

interface IProductFinderContextValue {
  isProductFinderModalVisible: boolean;
  setIsProductFinderModalVisible: Dispatch<SetStateAction<boolean>>;
  selectedOccupation: string;
  setSelectedOccupation: (string) => void;
  selectedBranch: string;
  setSelectedBranch: (string) => void;
  branchTags: any[];
  occupationTags: any[];
  filteredProducts: { primary: any[]; secondary: any[] };
  isBranchSelected: boolean;
}

const initialValue = {
  isProductFinderModalVisible: false,
  setIsProductFinderModalVisible: null,
  selectedOccupation: 'noOccupation',
  setSelectedOccupation: null,
  selectedBranch: 'noBranch',
  setSelectedBranch: null,
  branchTags: [],
  occupationTags: [],
  filteredProducts: { primary: [], secondary: [] },
  isBranchSelected: false
};

const ProductFinderContext = createContext<IProductFinderContextValue>(initialValue);

export const corporateOccupationTagMap = {
  noOccupation: [],
  unternehmer: ['dienstleistungen', 'handel', 'hersteller', 'energie', 'it', 'bau'],
  freiberufler: [
    'architekten',
    'aerzte',
    'heilnebenberufe',
    'anwaelte',
    'medien',
    'berater',
    'makler'
  ],
  bildung: ['vereine', 'verbaende']
};

export const ProductFinderContextProvider = ({ children }) => {
  const [isProductFinderModalVisible, setIsProductFinderModalVisible] = useState<boolean>(
    initialValue.isProductFinderModalVisible
  );

  const [selectedOccupation, selectedOccupationDispatcher] = useState<string>(
    initialValue.selectedOccupation
  );

  const [selectedBranch, selectedBranchDispatcher] = useState<string>(initialValue.selectedBranch);

  const { corporateProducts = [] } = useContext(SiteDataContext) || {};

  const allBranchTags = corporateProducts
    ? [
        ...new Set(
          corporateProducts.flatMap(prod => {
            const { corporateBranchTags = [], secondaryCorporateBranchTags = [] } = prod;
            return [...corporateBranchTags, ...secondaryCorporateBranchTags];
          })
        )
      ]
    : [];

  const occupationTags = Object.keys(corporateOccupationTagMap).filter(key =>
    allBranchTags.some(branchTag => corporateOccupationTagMap[key].includes(branchTag))
  );

  const filteredBranchTags = allBranchTags.filter(
    tag =>
      corporateOccupationTagMap[selectedOccupation] &&
      corporateOccupationTagMap[selectedOccupation].includes(tag)
  );

  const branchTags = filteredBranchTags.length > 0 ? filteredBranchTags : allBranchTags;

  const setSelectedBranch = (value: string) => {
    selectedBranchDispatcher(value);

    const derivedOccupation = Object.keys(corporateOccupationTagMap).find(key =>
      corporateOccupationTagMap[key].includes(value)
    );

    if (selectedOccupation !== derivedOccupation) {
      selectedOccupationDispatcher(derivedOccupation);
    }
  };

  const setSelectedOccupation = (value: string) => {
    selectedOccupationDispatcher(value);
    setSelectedBranch(corporateOccupationTagMap[value].find(tag => allBranchTags.includes(tag)));
  };

  const filteredProducts = { primary: [], secondary: [] };

  corporateProducts.forEach(prod => {
    const { corporateBranchTags, secondaryCorporateBranchTags } = prod;
    if (corporateBranchTags && corporateBranchTags.includes(selectedBranch)) {
      filteredProducts.primary.push(prod);
    } else if (
      secondaryCorporateBranchTags &&
      secondaryCorporateBranchTags.includes(selectedBranch)
    ) {
      filteredProducts.secondary.push(prod);
    }
  });

  const isBranchSelected = selectedBranch !== 'noBranch';

  return (
    <ProductFinderContext.Provider
      value={{
        isProductFinderModalVisible,
        setIsProductFinderModalVisible,
        selectedOccupation,
        setSelectedOccupation,
        selectedBranch,
        setSelectedBranch,
        branchTags,
        occupationTags,
        filteredProducts,
        isBranchSelected
      }}
    >
      {children}
    </ProductFinderContext.Provider>
  );
};

export default ProductFinderContext;
