import React from 'react';

const GalleryArea = props => {
  const { headline, children } = props;
  return (
    <section className="l-content__section">
      <div>{headline && <h2>{headline}</h2>}</div>
      {children}
    </section>
  );
};

export default GalleryArea;
