import { useEffect, useState } from 'react';

function useHorizontalScroll(elementRef) {
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const handleScroll = () => {
      setScrollLeft(element.scrollLeft);
    };

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [elementRef]);

  return scrollLeft;
}

export default useHorizontalScroll;
