import { format, isBefore } from 'date-fns';
import React, { useContext, useEffect, useRef } from 'react';

import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import { EventPageContext } from '../../containers/EventPage';
import useBreakpoint from '../../hooks/useBreakpoint';
import useGeoPosition from '../../hooks/useGeoPosition';
import useGoogleMap from '../../hooks/useGoogleMap';
import markerIcon from '../../styles/assets/images/location-marker-pin.png';

const EventHeaderArea = () => {
  const {
    city,
    locality,
    postalCode,
    startDate: startDateRaw,
    startTime,
    street,
    title,
    kind,
    backgroundImage,
    type,
    icon,
    emailAddress,
    customForm,
    downloadLink,
    zip,
    registrationButtonLabel
  } = useContext(EventPageContext) || {};

  const startDate = new Date(startDateRaw);
  const today = new Date();
  const formattedDate = format(startDate, 'dd.MM.yyyy');

  const canRegister = isBefore(today, startDate) && emailAddress;
  const isMaxVpS = useBreakpoint('s');

  const mapRef = useRef(null);

  const position = street + ' ' + zip + ' ' + city;
  const [currentPos, isGeoLoading]: any = useGeoPosition(
    //Todo@ ilja use vhv-tu api key instead of partner api key,
    //once the vhv-tu key is configured to use the geoposition api
    'AIzaSyC6_hdZenit19lPzCl6JiljQzB_w5-zji4',
    position
  );

  const offset =
    typeof window !== 'undefined' && window.innerWidth > 568 ? 0.000006 * window.innerWidth : 0;

  const { lat, lng } = currentPos || {};
  const mapCenter = { lat, lng: lng - offset };

  const map = useGoogleMap(
    mapRef,

    {
      center: mapCenter,
      zoom: 15,
      fullscreenControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      backgroundColor: 'transparent',
      scrollwheel: false
    }
  );
  const { googleMap, google } = map;
  useEffect(() => {
    google &&
      !isGeoLoading &&
      new google.maps.Marker({
        position: currentPos,
        title: position,
        map: googleMap,
        icon: {
          url: markerIcon,
          anchor: new google.maps.Point(22, 58)
        }
      });
  }, [google, isGeoLoading]);
  useEffect(() => {
    //overwrite initial position (0,0) when useGeoPosition loading is complete.
    if (googleMap) {
      googleMap.setOptions({
        center: mapCenter,
        zoomControlOptions: {
          position: isMaxVpS
            ? google.maps.ControlPosition.RIGHT_TOP
            : google.maps.ControlPosition.RIGHT_BOTTOM
        }
      });
    }
  }, [mapCenter, isMaxVpS]);

  return (
    <>
      {type === 'virtual' ? (
        <Image
          isBackgroundImage
          src={backgroundImage}
          extraClass="l-content__header l-content__header--event"
        >
          <div>
            <div className="m-box m-box--light" id="event-box">
              <h1>{title}</h1>
              <time>
                {formattedDate} | {startTime}
              </time>
              {kind && icon && (
                <div className="spacer">
                  <Icon type={icon} className={`l-content__event-icon ico-${icon}`} />
                  <strong>{kind}</strong> <br />
                </div>
              )}
              {canRegister &&
                (customForm ? (
                  <Link
                    extraClass="m-button m-button--sec m-button--condensed"
                    linkTo={customForm}
                    label={registrationButtonLabel ? registrationButtonLabel : 'Online anmelden'}
                    linkType="page"
                  />
                ) : (
                  <>
                    {/* <Link
                      extraClass="m-button m-button--sec m-button--condensed"
                      linkTo={customForm}
                      label="Termin speichern"
                      linkType="page"
                    /> */}
                  </>
                ))}
            </div>
            <Link
              linkTo="/aktuelles/veranstaltungen"
              linkType="internal"
              extraClass="l-content__header__back-button"
            >
              <Icon type="arrow-left" />
            </Link>
          </div>
        </Image>
      ) : (
        <header className="l-content__header l-content__header--event">
          <div style={{ pointerEvents: 'none' }}>
            <div className="m-box m-box--light" id="event-box" style={{ pointerEvents: 'visible' }}>
              <h1>{title}</h1>
              {formattedDate} | {startTime}
              <div className="spacer">
                {locality && (
                  <>
                    <strong>{locality}</strong>
                    <br />
                  </>
                )}
                {street} <br />
                {postalCode}&nbsp;{city}
              </div>
              {canRegister && (
                <>
                  <Link
                    extraClass="m-button m-button--sec m-button--condensed"
                    linkTo={customForm}
                    label={registrationButtonLabel ? registrationButtonLabel : 'Online anmelden'}
                    linkType="page"
                  />
                  {/* <Link
                    extraClass="m-button m-button--medium m-button--condensed"
                    linkTo={downloadLink}
                    label="Termin speichern"
                  /> */}
                </>
              )}
            </div>
            <Link
              linkTo="/aktuelles/veranstaltungen"
              linkType="internal"
              extraClass="l-content__header__back-button"
            >
              <Icon type="arrow-left" />
            </Link>
          </div>
          <div id="map" className="m-map m-map--bc" ref={mapRef} />
        </header>
      )}
    </>
  );
};

export default EventHeaderArea;
