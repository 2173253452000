import React from 'react';

import extractChildren from '../../helpers/extractChildren';
import Columns from '../ui/Columns/Columns';

const TeaserListComponent = ({ children }) => {
  const { teaserArea } = extractChildren(children) || {};
  return (
    <div>
      <Columns widths={[1, 1]} gutter="s" wrap>
        {teaserArea?.props?.children}
      </Columns>
    </div>
  );
};
export default TeaserListComponent;
