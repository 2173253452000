import React, { useState } from 'react';

import AnimateHeight from 'react-animate-height';
import Columns from '../ui/Columns/Columns';
import RichText from '../ui/RichText/RichText';
import extractChildren from '../../helpers/extractChildren';

const ComparisonRowComponent = ({ children, description, benefit }) => {
  const { cellArea } = extractChildren(children);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="ComparisonRowComponent">
      <Columns widths={[1, 2]} gutter="xl--content" centered wrap>
        {description ? (
          <>
            <div
              className="ComparisonRowComponent__cell"
              onClick={() => {
                if (description) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              <RichText richText={benefit} />
              <div>
                <i
                  className={`ico-help${!isOpen ? ' is-visible' : ''}`}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                />
                <i
                  className={`ico-close${isOpen ? ' is-visible' : ''}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
              </div>
            </div>
            <AnimateHeight animateOpacity={true} height={isOpen ? 'auto' : 0} duration={500}>
              <div className={`m-comparison__help-text`}>{description}</div>
            </AnimateHeight>
          </>
        ) : (
          <div
            className="ComparisonRowComponent__cell"
            onClick={() => {
              if (description) {
                setIsOpen(!isOpen);
              }
            }}
          >
            {benefit}
          </div>
        )}
        <div className="ComparisonRowComponent__content">{cellArea}</div>
      </Columns>
    </div>
  );
};
export default ComparisonRowComponent;
