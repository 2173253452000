import React, { createContext, useContext, useEffect, useState } from 'react';

import Footer from '../components/ui/Footer/Footer';
import Link from '../components/ui/Link/Link';
import ProductGroupsContext from '../contexts/ProductGroupsContext';
import extractChildren from '../helpers/extractChildren';
import useUrlParams from '../hooks/useUrlParams';

export const CalculatorSeoPageContext = createContext(null);

const CalculatorSeoPage = ({
  children,
  headline,
  productPageUrl: productPageUrlId,
  calculatorUrl,
  calculatorLabel,
  appView: propAppView = false
}) => {
  const [isCalculatorVisible, setIsCalculatorVisible] = useState<boolean>(propAppView);

  const { headerArea, stageArea, contentArea } = extractChildren(children);

  const { getProductGroup } = useContext(ProductGroupsContext) || {};

  const productPageUrl = getProductGroup('id', productPageUrlId)?.path;

  const [appView] = useUrlParams('appview');
  const [standalone] = useUrlParams('standalone');

  useEffect(() => {
    if (appView === 'true' || standalone === '1') {
      setIsCalculatorVisible(true);
    }
  }, [appView, standalone]);

  const className = productPageUrl
    ? 'l-grid__col--4-12 l-grid__col--m-6-12'
    : 'l-grid__col--8-12 l-grid__col--m-9-12';

  const [ivmnr] = useUrlParams('ivmnr');

  const appenedBrokerNumber = ivmnr
    ? `${calculatorUrl?.includes('?') ? '&' : '?'}iVMNr=${ivmnr}`
    : '';

  return isCalculatorVisible ? (
    <iframe
      src={calculatorUrl + appenedBrokerNumber}
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0
      }}
    ></iframe>
  ) : (
    <>
      {headerArea}
      <main className="l-content">
        <CalculatorSeoPageContext.Provider
          value={{ headline, productPageUrl, setIsCalculatorVisible }}
        >
          {stageArea}
          {contentArea}
        </CalculatorSeoPageContext.Provider>
        <section className="l-content__section l-content__section--wide l-landingseo__footer">
          <div>
            <div className="l-grid">
              <div className={className}>
                <h2 className="text-uppercase">{headline}</h2>
              </div>
              <div className="l-grid__col--4-12 l-grid__col--m-3-12">
                <a
                  className="m-button m-button--size-m m-button--shadow"
                  onClick={() => setIsCalculatorVisible(true)}
                >
                  {calculatorLabel || 'Jetzt Tarif berechnen'}
                </a>
              </div>

              {productPageUrl && (
                <div className="l-grid__col--4-12 l-grid__col--m-3-12">
                  <Link
                    extraClass="m-button m-button--size-m m-button--shadow m-button--light"
                    linkTo={productPageUrl}
                  >
                    Zur Produktseite
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default CalculatorSeoPage;
