import axios from 'axios';
import { useEffect, useState } from 'react';

import getFunctionUrl from '../helpers/getFunctionUrl';
import normalizeIvmnr from '../helpers/normalizeIvmnr';
import useUrlParams from './useUrlParams';

interface IContactInfo {
  photoIsLogo?: boolean;
  photoUrl?: string;
  name?: string;
  contact?: string;
  telephoneNumber?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  googleMapsUrl?: string;
  firstName?: string;
  lastName?: string;
}

const useBroker = (propIvmnr?, opts?: { api: 'brokercontact' | 'sales-representatives' }) => {
  const [urlIvmnr] = useUrlParams('ivmnr');
  const [contactInfo, setContactInfo] = useState<IContactInfo>(null);

  const ivmnr = normalizeIvmnr(propIvmnr || urlIvmnr);

  const api = opts?.api || 'brokercontact';
  useEffect(() => {
    ivmnr &&
      !contactInfo &&
      axios
        .get(`${getFunctionUrl(api)}/${ivmnr}`)
        .then(req => {
          setContactInfo(req.data);
        })
        // eslint-disable-next-line no-console
        .catch(err => console.log(err));
  }, [ivmnr]);

  return contactInfo;
};

export default useBroker;
