import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import useDisableScroll from '../../../hooks/useDisableScroll';
import useKeyDown from '../../../hooks/useKeyDown';
import Icon from '../Icon/Icon';

interface IFullscreenOverlayProps {
  isVisible: boolean;
  hideOverlay: () => void;
  contentVariants?: TContentVariant[];
  children?: any;
}
type TContentVariant = 'wide' | 'narrow' | 'full-width' | 'squeezed' | 'vcenter';
const FullscreenOverlay: React.FC<IFullscreenOverlayProps> = ({
  isVisible,
  hideOverlay,
  contentVariants,
  children
}) => {
  const contentVariantClasses =
    contentVariants && contentVariants.map(variant => `l-fullscreen-overlay__content--${variant}`);

  const contentClassName = `l-fullscreen-overlay__content${
    contentVariantClasses && contentVariantClasses.length > 0
      ? ` ${contentVariantClasses.join(' ')}`
      : ''
  }`;

  useDisableScroll(isVisible);

  const isEscClicked = useKeyDown('Escape');

  useEffect(() => {
    if (isEscClicked) {
      hideOverlay();
    }
  }, [isEscClicked]);

  return (
    <CSSTransition
      in={isVisible}
      mountOnEnter
      unmountOnExit
      timeout={200}
      classNames={'fade-transform-top-2'}
    >
      <div style={{ display: 'block' }} className="l-fullscreen-overlay">
        <button className="l-fullscreen-overlay__close-button" onClick={hideOverlay}>
          <Icon type="close" className="ico-close" />
        </button>
        <div className={contentClassName} style={{ display: 'block' }}>
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

export default FullscreenOverlay;
