import './Container.scss';

import React from 'react';

import getVariantClasses from '../../../helpers/getVariantClasses';

export interface IContainerProps {
  variants?: TContainerVariants[];
  children?: any;
}

export type TContainerVariants = '' | 'gray';

const Container: React.FC<IContainerProps> = ({ variants, children }) => {
  return <section className={getVariantClasses('Container', variants)}>{children}</section>;
};

export default Container;
