import './FadingCollapseBox.scss';

import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import useVpWidth from '../../../hooks/useVpWidth';
import { IInsuranceAddon } from '../../InsuranceAddonsComponent/InsuranceAddonsComponent';

interface IFadingCollapseBox {
  data: IInsuranceAddon;
  trigger?: any;
}

const FadingCollapseBox = ({ data, trigger = false }: IFadingCollapseBox) => {
  const [isUnfolded, setIsUnfolded] = useState(false);
  const textSection = useRef(null);
  const VpWidth = useVpWidth();
  const animationDuration = 200;

  // line height * 2
  const maxBoxHeight = 56;
  const [neededBoxHeight, setNeededBoxHeight] = useState(maxBoxHeight);

  const needsFading = neededBoxHeight > maxBoxHeight;

  const displayBoxHeight = needsFading ? maxBoxHeight : neededBoxHeight;

  useEffect(() => {
    setTimeout(() => {
      const boxHeight = textSection?.current?.scrollHeight;
      if (neededBoxHeight != boxHeight && boxHeight != 0) {
        setNeededBoxHeight(boxHeight);
      }
    }, 0);
  }, [VpWidth, trigger]);

  return (
    <div
      className={`FadingCollapseBox${isUnfolded ? ' FadingCollapseBox--unfolded' : ''}${!isUnfolded && needsFading ? ' FadingCollapseBox--faded' : ''}`}
      onClick={() => setIsUnfolded(state => !state)}
    >
      <h3>{data.headline}</h3>
      <AnimateHeight
        animateOpacity
        duration={animationDuration}
        height={isUnfolded ? 'auto' : displayBoxHeight}
      >
        <div ref={textSection} className="FadingCollapseBox__content">
          {data.text}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default FadingCollapseBox;
