import './ContactTeaserComponent.scss';

import React, { useContext } from 'react';

import ContactInformationContext from '../../contexts/ContactInformationContext';
import SiteDataContext from '../../contexts/SiteDataContext';
import ContactMethodComponent from '../ContactMethodComponent/ContactMethodComponent';
import CustomerPortalTeaserComponent from '../CustomerPortalTeaserComponent/CustomerPortalTeaserComponent';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

const AnnouncementBanner = () => {
  const { announcement } = useContext(SiteDataContext) || {};
  const hasAnnounement = announcement?.visible;
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  if (!hasAnnounement) return <></>;

  return (
    <div className="ContactTeaserComponent__banner">
      <div className="ContactTeaserComponent__bannerIcon">
        <Icon type={announcement.icon} />
      </div>
      <div className="ContactTeaserComponent__bannerText">
        <span
          dangerouslySetInnerHTML={{
            __html: announcement.text
          }}
        />{' '}
        {announcement.linkType != 'none' &&
          (announcement.linkType != 'damage' ? (
            <Link
              linkTo={announcement.linkTo}
              linkType={announcement.linkType}
              label={announcement.label}
            />
          ) : (
            <Link
              linkTo=""
              label="Schaden melden"
              onClick={() => {
                setIsContactInformationModalVisible(true, {
                  isDamageVariant: true
                });
              }}
            />
          ))}
      </div>
    </div>
  );
};

const ContactTeaserComponent = () => {
  return (
    <Container variants={['gray']}>
      <div className="ContactTeaserComponent">
        <h2>
          Wenn es <strong>drauf ankommt</strong> – <br /> wir sind <strong>für Sie</strong> da!{' '}
        </h2>
        <AnnouncementBanner />
        <div className="ContactTeaserComponent__contentWrapper">
          <div className="ContactTeaserComponent__contactMethods">
            <ContactMethodComponent method="phone" variants={['light']} />
            <ContactMethodComponent method="mail" variants={['light']} />
            <ContactMethodComponent method="damage" variants={['light']} />
            <span>
              <span>
                Oder Suchen Sie einen unserer über <strong>8.000 unabhängigen Vermittler</strong>?{' '}
              </span>
              <NewButtonComponent
                icon="arrow-line-right"
                label="Zur Makler-Suche"
                linkTo={'/maklersuche'}
                variant="light"
              />
            </span>
          </div>
          <div>
            <CustomerPortalTeaserComponent bigTeaser={true} withoutContainer startpage />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactTeaserComponent;
