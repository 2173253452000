import './CorporateProductBar.scss';

import React, { useContext } from 'react';

import ProductFinderContext from '../../../contexts/ProductFinderContext';
import { corporateOccupationTagMap } from '../../../contexts/ProductFinderContext';
import mapIndexedNodeProps from '../../../helpers/mapIndexedNodeProps';
import useBreakpoint from '../../../hooks/useBreakpoint';
import Columns from '../Columns/Columns';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';
import MultiButton from '../MultiButton/MultiButton';
import { getLabel } from '../ProductFinder/ProductFinderLabelMap';

const CorporateProductBar = ({ branchTags, sideProducts: sideProductsRaw }) => {
  const { isBranchSelected, setIsProductFinderModalVisible, selectedBranch } =
    useContext(ProductFinderContext) || {};

  const BreakpointL = useBreakpoint('l');

  const sideProducts = mapIndexedNodeProps(sideProductsRaw, '') || [];

  const sideProductObj =
    sideProducts?.find(sideProductObj => {
      return sideProductObj.branch === selectedBranch;
    }) || sideProducts?.find(sideProductObj => sideProductObj.branch === 'all');

  const { sideProductReference: sideProduct } = sideProductObj || {};

  const {
    headline,
    text,
    targetAssets: targetAssetsRaw,
    targetLps: targetLpsRaw,
    targetprinted_media: druckstueckRaw
  } = sideProduct || {};

  const isSideProductVariant = isBranchSelected && !!sideProduct;

  const ctaText = isBranchSelected
    ? 'Speziell für Ihre Branche'
    : 'Dieses Produkt ist geeignet für';
  const finderLabel = isBranchSelected ? 'Meine Produkte' : 'Produktfinder starten';

  const occupationTagValidationMap = Object.keys(corporateOccupationTagMap)
    .filter(key => key !== 'noOccupation')
    .map(key => {
      return {
        label: getLabel(key),
        isValidTag: branchTags.some(branchTag => corporateOccupationTagMap[key].includes(branchTag))
      };
    });

  occupationTagValidationMap.sort((a, b) => {
    return a.isValidTag === b.isValidTag ? 0 : a.isValidTag ? -1 : 1;
  });

  const targetAssets = targetAssetsRaw?.nodes?.map(node => targetAssetsRaw[node]) || [];

  const druckstueck = druckstueckRaw?.nodes?.map(node => druckstueckRaw[node]) || [];

  const targetLps = targetLpsRaw?.nodes?.map(node => targetLpsRaw[node]) || [];

  const productLinks = [...targetAssets, ...druckstueck, ...targetLps];

  productLinks.forEach(node => {
    if (node.targetasset || node.targetprinted_media) {
      node['linkType'] = node.targetprinted_media ? 'printed_media' : 'asset';
      node['linkTo'] = node.targetasset || node.targetprinted_media;
      if (!node.label) {
        node['label'] = 'Produktbroschüre';
      }
      delete node.targetasset;
    } else if (node.targetpage) {
      node['linkType'] = 'page';
      node['linkTo'] = node.targetpage;
      if (!node.label) {
        node['label'] = 'Zur Landingpage';
      }
      delete node.targetpage;
    }
  });

  return (
    <div className="CorporateProductBar">
      <Columns widths={BreakpointL ? [1, 3] : [1, 4]} gutter="s" wrap={BreakpointL ? true : false}>
        <div className="CorporateProductBar__cta">
          <div className="CorporateProductBar__cta-wrapper">
            <div className="CorporateProductBar__text">{ctaText}</div>
            <div
              className="CorporateProductBar__finder"
              onClick={() => setIsProductFinderModalVisible(true)}
            >
              <Icon type="Union" viewBox="0 0 512 512" size={{ width: '2rem', height: '2rem' }} />
              <span>{finderLabel}</span>
            </div>
          </div>
        </div>
        <div className="CorporateProductBar__tiles">
          {isSideProductVariant ? (
            <div className="CorporateProductBar__tile">
              <div>
                <div className="CorporateProductBar__headline">{headline}</div>
                <div>{text}</div>
              </div>
              {productLinks &&
                (productLinks.length === 1 ? (
                  <Link
                    showIcon
                    linkType={productLinks[0].linkType}
                    linkTo={productLinks[0].linkTo}
                    label={productLinks[0].label}
                  />
                ) : (
                  <MultiButton links={productLinks} label="Produktinhalte" buttonVariant="" />
                ))}
            </div>
          ) : (
            occupationTagValidationMap &&
            occupationTagValidationMap.map((validationMap, key) => {
              const { label, isValidTag } = validationMap;
              return (
                <div
                  className={`CorporateProductBar__tile${
                    isSideProductVariant ? '' : ' CorporateProductBar__tile--has-icon'
                  }`}
                  key={key}
                >
                  {label}
                  <i className={`ico-${isValidTag ? 'check' : 'close'}`} />
                </div>
              );
            })
          )}
        </div>
      </Columns>
    </div>
  );
};

export default CorporateProductBar;
