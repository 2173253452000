import './BenefitPointComponent.scss';

import React from 'react';

import Image from '../ui/Image/Image';
import RichText from '../ui/RichText/RichText';

const BenefitPointComponent = ({ image, text }) => {
  return (
    <div className="BenefitPointComponent">
      <div className="BenefitPointComponent__image">
        <Image src={image} sizes={{ w: 85, h: 65 }} />
      </div>
      <RichText extraClass="BenefitPointComponent__text" richText={text} />
    </div>
  );
};

export default BenefitPointComponent;
