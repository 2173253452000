import React from 'react';

import Icon from '../Icon/Icon';

interface IContactInfoLightBox {
  telephoneNumber?: string;
  availability?: string;
  showLightBox: boolean;
  hideLightBox: (boolean) => void;
}

const ContactInfoLightBox: React.FC<IContactInfoLightBox> = props => {
  const { telephoneNumber, availability, showLightBox, hideLightBox } = props;
  return showLightBox ? (
    <div className="m-modal">
      <p className="spacer-small text-l">
        <strong>Können wir Ihnen weiterhelfen?</strong>
      </p>
      <p className="spacer">
        Unsere Berater stehen Ihnen per Telefon zur Verfügung. Wir freuen uns auf Ihren Anruf!
      </p>
      <a className="l-landing__contact-tel ga-tel-bottom" href={`tel:${telephoneNumber}`}>
        {telephoneNumber}
      </a>
      <p className="text-s spacer-top-small">
        <strong>{availability}</strong>
      </p>

      <button onClick={() => hideLightBox(false)} className="m-modal__close-button">
        <Icon type="close" className="ico-close"></Icon>
      </button>
    </div>
  ) : null;
};
export default ContactInfoLightBox;
