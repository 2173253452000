import axios from 'axios';

export interface IBrokerSearchResult {
  brokernr?: string;
  name1?: string;
  name2?: string;
  name3?: string;
  street?: string;
  zip?: string;
  city?: string;
  photo?: string;
  contact?: {
    phone?: string;
    mobile?: string;
    fax?: string;
    homepage?: string;
    cryptomail?: string;
  };
  geoinfo: {
    dist?: string;
    formatdist?: string;
    latlng: {
      lat: string;
      lng: string;
    };
    utm?: {
      string?: string;
      zone?: string;
      east?: string;
      north?: string;
    };
  };
}

export interface IBrokerSearchResults {
  version: string;
  build: string;
  timestamp: string;
  searchtime: string;
  listsize: string;
  listvm: IBrokerSearchResult[];
  error: {
    msg: string;
    code: string;
  };
}

export const getBrokerSearchResults = async ({ zip }: { zip: string }) => {
  const { data } = await axios.get<IBrokerSearchResults>(`/xml/brokersearch.xml?zip=${zip}`);
  return data;
};
