import React, { Children, createContext } from 'react';

import FooterLinkComponent from '../../components/FooterLinkComponent/FooterLinkComponent';

export const FooterLinkAreaContext = createContext(null);
const FooterLinkArea = ({ children }) => {
  return children
    ? Children.map(children, (child, index) => (
        <FooterLinkAreaContext.Provider value={index === children.length - 1}>
          {child}
          {index + 1 === Object.keys(children).length && (
            <FooterLinkComponent
              key={'cookieSettings'}
              {...{
                label: 'Cookie-Einstellungen',
                extraClass: 'ot-sdk-show-settings',
                linkTo: 'cookieOverlay',
                linkType: 'internal_app',
                id: 'ot-sdk-btn'
              }}
            />
          )}
        </FooterLinkAreaContext.Provider>
      ))
    : null;
};

export default FooterLinkArea;
