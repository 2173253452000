import React, { useContext, useEffect, useState } from 'react';

import ContactInformationContext from '../../../contexts/ContactInformationContext';
import InfoBox from '../InfoBox/InfoBox';
import TransferLinkBoxContext from '../../../contexts/TransferLinkBoxContext';
import useIdleTime from '../../../hooks/useIdleTime';

const CompetenceSegment = () => {
  const [show, setShow] = useState(false);
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const { showLinkBox } = useContext(TransferLinkBoxContext) || {};

  const idleTime = useIdleTime(300);
  useEffect(() => {
    !showLinkBox && setShow(idleTime);
  }, [idleTime]);

  const handleClick = () => {
    setIsContactInformationModalVisible(true);
    setShow(false);
  };

  return show ? (
    <InfoBox
      title={'Können wir Ihnen weiterhelfen?'}
      text={
        'Unsere Berater stehen Ihnen per Telefon, Online-Formular oder E-Mail zur Verfügung. Wir freuen uns auf Sie!'
      }
      linkProps={{
        label: 'Zur Kontakt-Übersicht',
        onClick: handleClick,
        extraClass: 'm-button',
        linkTo: ''
      }}
      show={show}
      onClose={() => setShow(false)}
    />
  ) : null;
};
export default CompetenceSegment;
