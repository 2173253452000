const getPageScope = location => {
  const { host, pathname } = location || {};

  const rootPath = pathname && pathname.replace('/', '').split('/')[0];

  if (host === 'vhv-bauexperten') {
    return 'expert';
  } else {
    switch (rootPath) {
      case 'firmen':
        return 'corporate';
      case 'ms':
        return 'ms';
      case 'bauexperten':
        return 'expert';
      default:
        return 'private';
    }
  }
};

export default getPageScope;
