import React, { Children } from 'react';

import useBreakpoint from '../../hooks/useBreakpoint';
import Collapsible from '../ui/Collapsible/Collapsible';

const FooterColumnComponent = props => {
  const { headline, children } = props;

  const isMaxVpS = useBreakpoint('xs');

  return (
    <div>
      {isMaxVpS ? (
        <Collapsible variants={['reverse', 'no-border-bottom', 'header-yellow']} header={headline}>
          <ul>
            {Children.map(children, child => (
              <li>{child}</li>
            ))}
          </ul>
        </Collapsible>
      ) : (
        <>
          <p>{headline}</p>
          <ul>
            {Children.map(children, child => (
              <li>{child}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default FooterColumnComponent;
