import { useEffect, useState } from 'react';

interface IIntersectOptions {
  root?: any;
  rootMargin?: string;
  threshold?: any;
}

const useIntersect = (ref, firstIntersection = false, options: IIntersectOptions) => {
  const [isIntersected, setIsIntersected] = useState(false);
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      entry.isIntersecting && setHasIntersected(true);
      setIsIntersected(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref.current, options]);

  return firstIntersection ? hasIntersected : isIntersected;
};

export default useIntersect;
