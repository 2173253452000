import './RatingSignetComponent.scss';

import React from 'react';

import background from '../../styles/assets/images/background_x_sprite.jpg';
import check24Logo from '../../styles/assets/images/c24_170.png';
import yellowStar from '../../styles/assets/images/star32.png';
import grayStar from '../../styles/assets/images/star32_bw.png';

const RatingSignetComponent = ({ rating }) => {
  return (
    <div
      className="RatingSignetComponent"
      style={{
        background: `url(${background}) repeat-x
    scroll 0 0 transparent`
      }}
    >
      <div className="RatingSignetComponent__title">Kundenbewertungen</div>
      <div className="RatingSignetComponent__wrapper">
        <div
          className="RatingSignetComponent__wrapper__image"
          style={{ backgroundImage: `url(${grayStar})` }}
        >
          <div
            className="RatingSignetComponent__wrapper__image__star"
            style={{
              backgroundImage: `url(${yellowStar})`,
              width: `${160 * (rating && rating.stars / 5)}px`
            }}
            title={`${rating && rating.stars} von 5 Sternen`}
          />
        </div>
        <span>{96}% Empfehlungsquote</span>
        <div className="RatingSignetComponent__wrapper__footer">
          <span>
            <img
              src={check24Logo}
              alt="CHECK24 Vergleichsportal"
              title="CHECK24 Vergleichsportal"
              width="110"
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default RatingSignetComponent;
