import React, { useContext, useState } from 'react';

import ContactInfoLightBox from '../../components/ui/ContactInfoLightBox/ContactInfoLightBox';
import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import { LandingPageContext } from '../../containers/LandingPage';
import BrokerBcContext from '../../contexts/BrokerBcContext';
import getCalculatorPath from '../../helpers/getCalculatorPath';
import useBreakpoint from '../../hooks/useBreakpoint';
import useScrollPosition from '../../hooks/useScrollPosition';

const LandingPageHeaderArea = () => {
  const {
    landingPageStyle,
    telephoneNumber,
    availability,
    stageAreaComponent,
    lpContact,
    disableBroker,
    isContactHidden
  } = useContext(LandingPageContext) || {};
  const isMaxVpM = useBreakpoint('m');
  const [showLightBox, setShowLightBox] = useState(false);
  const scrollPos = useScrollPosition();
  const isHeaderClone = scrollPos >= 200;
  const isHeaderSticky = scrollPos >= 500;

  const {
    photoIsLogo,
    photoUrl,
    name,
    contact,
    telephoneNumber: telephone,
    street,
    postalCode,
    city,
    googleMapsUrl
  } = lpContact || {};

  const [showInfoBubble, setShowInfoBubble] = useState(false);
  const {
    label,
    rightButtonLabel,
    rightButtonIcon = 'calculator',
    linkTo,
    linkType
  } = stageAreaComponent || {};
  const { setVisibleBrokerBc } = useContext(BrokerBcContext) || {};

  const getContent = (
    <div>
      <Link linkTo="/" extraClass="l-header__logo l-header__logo--landing">
        {isHeaderClone ? (
          <Icon type="vhv-logo" size={{ width: isMaxVpM ? '100px' : '130px', height: 'auto' }} />
        ) : (
          <Icon
            type="vhv-logo-full-alt"
            size={{ width: isMaxVpM ? '120px' : '180px', height: 'auto' }}
          />
        )}
      </Link>
      {linkTo && (
        <ul className="l-header__button-group">
          <li>
            <Link
              {...getCalculatorPath(linkTo, linkType)}
              extraClass="m-button m-button--light ga-cta-top-normal-sticky"
            >
              <Icon
                type={rightButtonIcon}
                className={`ico-${rightButtonIcon} ga-cta-top-normal-sticky`}
              ></Icon>
              <span className="ga-cta-top-normal-sticky">{rightButtonLabel || label}</span>
            </Link>
          </li>
        </ul>
      )}
      {!isContactHidden &&
        (!disableBroker && lpContact ? (
          <div
            className={`${
              photoIsLogo ? 'm-broker-info-bubble--with-logo' : ''
            }${!photoUrl ? ' m-broker-info-bubble--no-image' : ''}`}
            onMouseOver={() => setShowInfoBubble(true)}
            onMouseLeave={() => setShowInfoBubble(false)}
          >
            <div>
              <div
                className={`l-header__broker-info${
                  photoIsLogo ? ' l-header__broker-info--with-logo' : ''
                }${!photoUrl ? ' l-header__broker-info--no-image' : ''}`}
                onClick={() => setShowInfoBubble(!showInfoBubble)}
              >
                <div className="l-header__broker-info__name">
                  <strong>{'Ihr/e Ansprechpartner/in'}</strong>
                  {name}
                </div>
                {photoIsLogo || !photoUrl ? (
                  <Icon type="profil" className="ico-profil l-header__broker-info__icon"></Icon>
                ) : (
                  <div className="l-header__broker-info__image">
                    <img src={photoUrl} alt={name} />
                  </div>
                )}
              </div>
              {showInfoBubble && (
                <div
                  className={`m-broker-info-bubble${
                    !photoUrl && photoIsLogo ? ' m-broker-info-bubble--with-logo' : ''
                  }${!photoUrl ? ' m-broker-info-bubble--no-image' : ''}`}
                >
                  <div>
                    {!!photoUrl && (
                      <div className="m-broker-info-bubble__image">
                        <img src={photoUrl} alt={name} />
                      </div>
                    )}
                    <div className="m-broker-info-bubble__name">
                      <strong>{name}</strong>
                      {contact}

                      <>
                        <a className="m-button" onClick={() => setVisibleBrokerBc('termin')}>
                          Termin vereinbaren
                        </a>
                        <a
                          className="m-button m-button--outline-pri"
                          onClick={() => setVisibleBrokerBc('email')}
                        >
                          Nachricht schreiben
                        </a>
                      </>
                    </div>
                    <div className="m-broker-info-bubble__contact">
                      <div className="m-phone-number">
                        <a href={`tel:${telephone}`}>
                          <Icon type="phone" className="ico-phone"></Icon> {telephone}
                        </a>
                      </div>
                      <address>
                        {street}
                        <br />
                        {postalCode}&nbsp;{city}
                      </address>
                      <small>
                        <a target="_blank" href={googleMapsUrl} rel="noreferrer">
                          <Icon type="location" className="ico-location"></Icon> In Google Maps
                          öffnen
                        </a>
                      </small>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <ul className="l-header__landing-nav">
            <li onClick={() => setShowLightBox(true)} style={{ cursor: 'pointer' }}>
              <Icon type="phone" className="ico-phone ga-tel-top"></Icon>
            </li>
          </ul>
        ))}
    </div>
  );

  return (
    <>
      {isHeaderClone ? (
        <header
          className={`l-header l-header--landing${
            landingPageStyle ? ' l-header--landing-' + landingPageStyle : ''
          } l-header--landing-clone${isHeaderSticky ? ' l-header--sticky' : ''}`}
        >
          {getContent}
        </header>
      ) : (
        <header
          className={`l-header l-header--landing${
            landingPageStyle ? ' l-header--landing-' + landingPageStyle : ''
          }`}
        >
          {getContent}
        </header>
      )}
      <ContactInfoLightBox
        telephoneNumber={telephoneNumber}
        availability={availability}
        showLightBox={showLightBox}
        hideLightBox={setShowLightBox}
      />
    </>
  );
};

export default LandingPageHeaderArea;
