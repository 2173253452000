import React from 'react';

import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';
import RichText from '../ui/RichText/RichText';

const ContactComponent = props => {
  const { headline, telephone, text, email, fax, address, website } = props;
  const links = mapIndexedNodeProps(props, 'links') || [];
  const addressUrl = address ? '//www.google.de/maps/dir//' + encodeURI(address) : '';

  return (
    <div className="m-asp">
      <div>
        <div className="m-asp__information">
          {/* {photo && <img src="${photo.source}" alt="${photo.alternativeText}">} */}
          <div>
            {headline && <strong>{headline}</strong>}
            {text && <span>{text}</span>}
            {address && (
              <>
                <address>
                  <RichText richText={address} />
                </address>
                <a href={addressUrl}>
                  <Icon type="arrow-right" className="ico-arrow-right"></Icon>
                  Anfahrt planen
                </a>
              </>
            )}
          </div>
        </div>
        {(telephone || fax || email || website || links.length > 0) && (
          <div className="m-asp__cta">
            <div>
              {telephone && <address className="m-phone-number">{telephone}</address>}
              {fax && (
                <address>
                  <strong>Fax</strong> {fax}
                </address>
              )}
              {email && (
                <a href={`mailto:${email}`} className="m-button m-button--sec">
                  E-Mail-Nachricht
                </a>
              )}
              {website && (
                <Link
                  linkTo={website}
                  linkType="external"
                  extraClass="m-button m-button--medium"
                  label="Webseite aufrufen"
                />
              )}
              {links.length > 0 &&
                links.map((link, key) => {
                  const { linkTo, linkType, label } = link;
                  return (
                    <Link
                      key={key}
                      linkTo={linkTo}
                      linkType={linkType}
                      extraClass="m-button m-button--medium"
                      label={label}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ContactComponent;
