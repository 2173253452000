import React from 'react';

import Image from '../ui/Image/Image';
import Tooltip from '../ui/Tooltip/Tooltip';

const TileComponent = ({ variant, popupText, popupHeadline, signetImage, headline, note }) => {
  const hasPopup = popupHeadline || popupText;
  return (
    <div className={`l-landing__tile${variant == 'simple' ? ' l-landing__tile--medium' : ''}`}>
      {headline && variant == 'simple' && <div className="l-landing__tile-head">{headline}</div>}
      {signetImage && (
        <>
          {hasPopup && <Tooltip label={popupHeadline}>{popupText}</Tooltip>}
          <div className="l-landing__tile-img">
            <div className="l-landing__tile-img-wrapper">
              <Image src={signetImage} />
            </div>
          </div>
        </>
      )}
      {variant === 'simple' && <div className="l-landing__tile-note">{note}</div>}
    </div>
  );
};

export default TileComponent;
