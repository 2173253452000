import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import SiteDataContext from '../../contexts/SiteDataContext';

const SitemapArea = () => {
  const { navs, pageScope } = useContext(SiteDataContext) || {};
  const nav = navs && pageScope && navs[pageScope] ? navs[pageScope] : [];

  return (
    <section className="l-content__section">
      <div className="spacer-large">
        <article className="m-sitemap">
          {nav.map((link, index) => {
            const { path, title, children } = link;
            return path === '/' ? null : (
              <>
                <div className="m-sitemap__head" key={index}>
                  <a href={path}>{title}</a>
                </div>
                <Columns widths={[1, 1, 1]} wrap gutter="s">
                  {children &&
                    children.map((secondLayerLink, index) => {
                      const {
                        title: secondLayerTitle,
                        path: secondLayerPath,
                        children: secondLayerChildren
                      } = secondLayerLink || {};
                      return (
                        <div key={index}>
                          <ul className="m-sitemap__list">
                            <li>
                              <a href={secondLayerPath}>{secondLayerTitle}</a>
                              <ul>
                                {secondLayerChildren &&
                                  secondLayerChildren.map((thirdLayerlink, index) => {
                                    const { title: thirdLayerTitle, path: thirdLayerPath } =
                                      thirdLayerlink;
                                    return (
                                      <li key={index}>
                                        <a href={thirdLayerPath}>{thirdLayerTitle}</a>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                </Columns>
              </>
            );
          })}
        </article>
      </div>
    </section>
  );
};

export default SitemapArea;
