import React from 'react';
import TagManager from 'react-gtm-module';

import PageWrapper from './src/PageWrapper';

export const onClientEntry = () => {
  const tagManagerArgs = {
    vhvde: { gtmId: 'GTM-TKSNHGW' },
    bauexperten: {
      gtmId: 'GTM-T4CFHM',
    },
  };

  if (tagManagerArgs[process.env.GATSBY_SCOPE]) {
    TagManager.initialize(tagManagerArgs[process.env.GATSBY_SCOPE]);
  }
};

export const wrapPageElement = ({ element, props }) => {
  return React.createElement(PageWrapper, props, element);
};
