import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { CSSTransition } from 'react-transition-group';

import useBreakpoint from '../../../hooks/useBreakpoint';
import Icon from '../Icon/Icon';
import RichText from '../RichText/RichText';

const Accordion = ({ question, answer }) => {
  const [isRendered, setIsRendered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const isVpS = useBreakpoint('s');

  const showDetails = () => {
    setIsRendered(true);
    setTimeout(() => {
      setIsVisible(true);
    });
  };

  const hideDetails = () => {
    const delay = isVpS ? 0 : 250;

    setIsVisible(false);
    setTimeout(() => {
      setIsRendered(false);
    }, delay);
  };

  const toggleDetailsVisibility = () => {
    isRendered ? hideDetails() : showDetails();
  };

  const accordionDetails = (
    <div
      className={`m-accordion__tile__details`}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {question && isVpS && <h4>{question}</h4>}
      {answer && <RichText richText={answer} />}
      <button
        className={`m-accordion__close-button`}
        onClick={() => {
          hideDetails();
        }}
      >
        <Icon type="close" className="ico-close"></Icon>
      </button>
    </div>
  );

  return (
    <div>
      <article
        className={`tag-faq-question m-accordion__tile`}
        onClick={() => {
          toggleDetailsVisibility();
        }}
      >
        <div>
          <i className={`indicator${isVisible ? ' rotate' : ''}`}></i>
          {question && <h4>{question}</h4>}
        </div>
        {isVpS ? (
          <CSSTransition
            in={isRendered}
            mountOnEnter
            unmountOnExit
            timeout={250}
            classNames="fade-transform-top-2"
          >
            {accordionDetails}
          </CSSTransition>
        ) : (
          <CSSTransition in={isRendered} mountOnEnter unmountOnExit timeout={0}>
            <AnimateHeight animateOpacity={true} height={isVisible ? 'auto' : 0} duration={250}>
              {accordionDetails}
            </AnimateHeight>
          </CSSTransition>
        )}
      </article>
    </div>
  );
};

export default Accordion;
