import { useEffect } from 'react';

const useDisableScroll = (condition: boolean) => {
  useEffect(() => {
    if (condition) {
      document.body.style.overflow = 'hidden';
    } else if (!condition) {
      document.body.style.overflow = '';
    }
  }, [condition]);
};

export default useDisableScroll;
