import React, { useState } from 'react';

import Image from '../ui/Image/Image';
import LightBox from '../ui/LightBox/LightBox';

const ImageComponent = ({ image, caption }) => {
  const [isLightBoxVisible, setIsLightBoxVisible] = useState<boolean>(false);

  return (
    <>
      <figure className="m-gallery__figure js-magazine-item">
        <Image
          src={image}
          alt={image}
          onClick={() => setIsLightBoxVisible(true)}
          sizes={{ w: 540 }}
          //  srcset="${image.getSource("w=360")} 360w, ${image.getSource("w=540")} 540w"
          //  sizes="(min-width: 768px) 25vw, (min-width: 568px) 50vw, 100vw"/>
        />
      </figure>

      <LightBox
        media={image}
        onClick={() => setIsLightBoxVisible(!isLightBoxVisible)}
        caption={caption}
        isVisible={isLightBoxVisible}
      />
    </>
  );
};

export default ImageComponent;
