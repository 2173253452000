import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import SiteDataContext from '../../contexts/SiteDataContext';
import { getFormatedDate } from '../../helpers/getFormatedDate';

const RatingsArea: React.FC = () => {
  const { customerRatings = [] } = useContext(SiteDataContext) || {};

  const ratings = customerRatings.slice(0, 10);

  const descriptionText = [
    `Alle Kunden, die über CHECK24 ihre Privathaftpflichtversicherung abgeschlossen haben, können ihren gewählten Versicherer vier Wochen nach Versicherungsbeginn bewerten. Dabei wurde dem Kunden die Frage gestellt: Wie wahrscheinlich ist es, dass Sie die Versicherung einem Freund oder Kollegen weiterempfehlen?`,
    `Eine Bewertung von 10 bis 7 gilt als Empfehlung, eine Bewertung von 3 bis 0 als ein Abraten. Kunden, die ihre neue Versicherung mit einem Wert zwischen 6 und 4 bewertet haben, gelten als neutral und werden bei der Berechnung der Empfehlungsquote nicht berücksichtigt.`
  ];

  const maxScore = 10;

  return (
    <>
      <section className="l-content__section">
        <div>
          <div>
            <div className="l-richtext">
              <p>{descriptionText[0]}</p>
              <p>{descriptionText[1]}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="l-content__section l-content__section--medium">
        <div>
          <h2>Die neuesten Kommentare</h2>

          <div className="spacer-small">
            <div className="l-magazine-masonry">
              <Columns widths={[1, 1]} wrap gutter="s">
                {ratings?.map(rating => {
                  const { '@id': id, comment, name, datum, rating: score } = rating;
                  return (
                    <div
                      key={id}
                      className="l-magazine-masonry__item js-magazine-item spacer-small"
                    >
                      <div className="m-box m-box--condensed">
                        <blockquote className="m-quote m-quote--simple spacer-small">
                          <p>{comment}</p>
                          <footer>
                            <cite>{`${name}${datum ? `- ${getFormatedDate(datum)}` : ''}`}</cite>
                          </footer>

                          <div className="m-quote__rating">
                            <ul>
                              {[...Array(maxScore + 1)].map((_, index) => {
                                const className = index < score ? 'checked' : '';
                                return (
                                  <li
                                    style={{ marginRight: '4px' }}
                                    key={index}
                                    className={className}
                                  />
                                );
                              })}
                            </ul>
                            <span>{`${score} / ${maxScore} Punkten`}</span>
                          </div>
                        </blockquote>
                      </div>
                    </div>
                  );
                })}
              </Columns>
            </div>
          </div>

          <div className="text-right">
            <small>
              Mit freundlicher Unterstützung von{' '}
              <a
                target="_blank"
                href="http://www.check24.de/privathaftpflicht/kundenbewertung/vhv/"
                rel="noreferrer"
              >
                CHECK24
              </a>
            </small>
          </div>
        </div>
      </section>
    </>
  );
};

export default RatingsArea;
