import React, { createContext, useContext } from 'react';

import ContactInformationContext from '../../contexts/ContactInformationContext';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

export const FaqContext = createContext(null);

const FaqFooterComponent = ({
  linkTo,
  linkType,
  hideContactButton,
  mailButtonLabel,
  hideMailButton,
  label,
  hideLabel,
  contactButtonLabel
}) => {
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};
  const isLabelVisible = !hideLabel;
  const isContactVisible = !hideContactButton;
  const isMailVisible = !hideMailButton;
  const defaultLinkTo = '/kontaktformular/email-anfrage';

  return (
    <footer className="m-component-footer">
      <div>
        {isLabelVisible && <div>{label || 'Ihre Frage ist nicht dabei?'}</div>}
        <div>
          {isContactVisible && (
            <button
              className="m-button m-button--light ga-faq-call"
              onClick={() => {
                setIsContactInformationModalVisible(true, {
                  isActiveProductVariant: true
                });
              }}
            >
              <Icon type="hotline" className="ico-hotline ga-faq-call"></Icon>
              {contactButtonLabel || 'Rufen Sie uns an'}
            </button>
          )}

          {isMailVisible && (
            <Link
              linkTo={linkTo || defaultLinkTo}
              linkType={linkType}
              extraClass="m-button ga-faq-mail"
            >
              <Icon type="mail" className="ico-mail ga-faq-mail"></Icon>
              {mailButtonLabel || 'Schreiben Sie uns'}
            </Link>
          )}
        </div>
      </div>
    </footer>
  );
};

export default FaqFooterComponent;
