import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import FormErrorMessage from './FormErrorMessage';
import FormTooltip from './FormTooltip';

interface TextFieldComponentProps {
  label: string;
  optional?: string;
  name: string;
  placeholder?: string;
  multiline?: string;
  tooltipText?: string;
  regex?: string;
  autocompleteRestricted?: boolean;
  autocompleteList?: string;
  errorMessage?: string;
}

const TextFieldComponent: React.FC<TextFieldComponentProps> = ({
  label,
  optional,
  name,
  placeholder,
  multiline,
  tooltipText,
  regex,
  autocompleteRestricted,
  autocompleteList,
  errorMessage
}) => {
  const {
    register,
    unregister,
    formState: { errors, dirtyFields }
  } = useFormContext();

  errorMessage = errorMessage && errorMessage.replace('<p>', '').replace('</p>', '');

  let autocompleteListItems = undefined;
  if (autocompleteList) {
    autocompleteListItems = autocompleteList.split('<br/>');
  }

  let pattern = undefined;
  if (autocompleteRestricted && autocompleteListItems) {
    pattern = autocompleteListItems.join('|');
  } else if (regex && !autocompleteRestricted) {
    pattern = regex;
  }

  const hasError = errors[name];
  const getActualErrorMessage = () => {
    if (errors[name]) {
      if (errors[name].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie korrekten Text ein';
    }
    return undefined;
  };
  const actualErrorMessage = getActualErrorMessage();
  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  return (
    <>
      <div className="m-form__group">
        <label htmlFor={name}>
          {label} {optional && <em>optional</em>}
        </label>
        <div className="m-form__group-fields">
          <div className="l-grid">
            <div className="l-grid__col--12-12">
              {multiline ? (
                <textarea
                  id={name}
                  placeholder={placeholder || undefined}
                  {...register(name, {
                    required: !optional,
                    pattern: pattern ? new RegExp(pattern.replace(/\\\\/g, '\\')) : undefined
                  })}
                  className={classNames({
                    'ng-invalid': errors[name],
                    'ng-touched': dirtyFields[name]
                  })}
                />
              ) : (
                <>
                  <input
                    type="text"
                    id={name}
                    placeholder={placeholder || undefined}
                    {...register(name, {
                      pattern: pattern ? new RegExp(pattern.replace(/\\\\/g, '\\')) : undefined,
                      required: !optional
                    })}
                    list={autocompleteListItems ? `${name}-list` : undefined}
                    className={classNames({
                      'ng-invalid': errors[name],
                      'ng-touched': dirtyFields[name]
                    })}
                  />
                  {autocompleteListItems && (
                    <datalist id={`${name}-list`}>
                      {autocompleteListItems.map(item => (
                        <option value={item} key={item} />
                      ))}
                    </datalist>
                  )}
                </>
              )}
            </div>
          </div>
          {tooltipText && <FormTooltip label={label} name={name} tooltipText={tooltipText} />}
        </div>
      </div>
      {hasError && actualErrorMessage && <FormErrorMessage errorMessage={actualErrorMessage} />}
    </>
  );
};

export default TextFieldComponent;
