import React from 'react';
import Slider from 'react-slick';

interface ISlidesProps {
  infinite?: boolean;
  slidesToShow: number;
  slidesToScroll: number;
  children: any;
}

const Slides: React.FC<ISlidesProps> = ({ infinite, slidesToShow, slidesToScroll, children }) => {
  const settings = {
    dots: true,
    infinite: infinite,
    speed: 400,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll
  };
  return <Slider {...settings}>{children}</Slider>;
};

export default Slides;
