import React, { useContext } from 'react';

import Icon from '../components/ui/Icon/Icon';
import SiteDataContext from '../contexts/SiteDataContext';
import extractChildren from '../helpers/extractChildren';
import { getCopyrightLabel } from '../helpers/getCurrentYear';

const CustomPage = ({ bodyArea, children }) => {
  const { footer: { homeFooterLinkArea = null } = {} } = useContext(SiteDataContext) || {};
  const { headerArea, contactArea } = extractChildren(children);
  const { html } = bodyArea;

  const regex = /url\(https:\/\/www.vhv.de([0-9a-zA-Z-/._]*)\)\\;/g;
  const htmlReplaced = html.replace(regex, `url\\(https:/\\/archiv.vhv-partner.de$1\\)\\;`);

  return (
    <>
      <div style={{ zIndex: 2, position: 'fixed' }}>{headerArea}</div>
      <main className="l-content l-content--landing">
        <div
          dangerouslySetInnerHTML={{
            __html: htmlReplaced
          }}
        />
      </main>
      {contactArea}
      <footer className={`l-footer l-footer--landing`}>
        <div>
          <div className="l-footer__windup l-footer__windup--minimal l-footer__windup--landing">
            <div className="float-left">
              {homeFooterLinkArea}{' '}
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                {getCopyrightLabel()}
              </span>
            </div>
            <div className="float-right">
              <a href="/">
                <Icon type="link" className="ico-link-extern"></Icon>&nbsp;
                <strong> Zur Website vhv.de</strong>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default CustomPage;
