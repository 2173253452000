import './Video.scss';

import React, { useEffect, useRef, useState } from 'react';
import useCookieByKey from '../../../hooks/useCookieByKey';

import ReactPlayer from 'react-player';
import VideoCookieBox from '../VideoCookieBox/VideoCookieBox';

interface IVideoProps {
  id: string;
  onPause?: () => void;
  onPlay?: () => void;
  autoplay?: boolean;
  size?: 's' | 'm' | 'l';
}

const Video: React.FC<IVideoProps> = ({ id, onPause, onPlay, autoplay, size }) => {
  const [playerLoaded, setPlayerLoaded] = useState<boolean>(false);

  const videoRef = useRef(null);
  const [videoWidth, setVideoWidth] = useState(480);
  const [hasRendered, setHasRendered] = useState(false);
  const [showConsentLayer, setShowConsentLayer] = useState<boolean>(true);

  const width = () => {
    switch (size) {
      case 's':
        return 480;
      case 'm':
        return 640;
      default:
        return 960;
    }
  };

  const setWindowWidths = () => {
    if (typeof window !== 'undefined') {
      const currentWidth = window.innerWidth;
      setVideoWidth(currentWidth > width() ? width() : currentWidth - 30);
    }
  };

  useEffect(() => {
    setHasRendered(true);
    setWindowWidths();
    window.addEventListener('resize', setWindowWidths);
    return () => {
      window.removeEventListener('resize', setWindowWidths);
    };
  }, []);

  const onetrustActiveGroups = useCookieByKey('onetrust_active_groups', true);

  useEffect(() => {
    const showLayer = !onetrustActiveGroups.includes('C0001,C0002,C0004');
    setShowConsentLayer(showLayer);
  }, [onetrustActiveGroups]);

  return (
    <>
      <div
        className="Video"
        ref={videoRef}
        style={{
          width: `${videoWidth}px`,
          height: `calc(${videoWidth}px *(9/16)`
        }}
      >
        {!showConsentLayer && (
          <div
            className={`Video__player${
              playerLoaded ? '' : ' Video__player--loading'
            }${size ? ' Video__player--' + size : ''}`}
          >
            {hasRendered && (
              <ReactPlayer
                url={`https://youtu.be/${id}`}
                controls
                autoPlay={autoplay}
                onReady={() => setPlayerLoaded(true)}
                onPause={() => onPause && onPause()}
                onPlay={() => onPlay && onPlay()}
                width={videoWidth}
                height={videoWidth * (9 / 16)}
              />
            )}
          </div>
        )}
      </div>
      <VideoCookieBox />
    </>
  );
};

export default Video;
