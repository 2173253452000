export const brokerSurvey = (
  brokerIdentifier: string,
  brokerIdentifierGender: string,
  surveyId: string
): any => {
  const config: {
    getSurveyUrl: string;
    postDataUrl: string;
    credentials: { username: string; surveyKeyVHV: string };
    props: {
      brokerIdentifier: string;
      surveyId: string;
      brokerIdentifierGender: string;
    };
  } = {
    getSurveyUrl: 'https://feedback.callexa.com/api/v2/survey.json',
    postDataUrl: 'https://feedback.callexa.com/api/v2/replies',
    credentials: {
      username: 'nalten@vhv.de',
      surveyKeyVHV: '8WXPnDplhLavw3ynoAg5'
    },
    props: { brokerIdentifier, surveyId, brokerIdentifierGender }
  };

  const cases = {
    nominative: { male: 'Ihr', female: 'Ihre', neutral: 'Ihr' },
    dative: { male: 'Ihrem', female: 'Ihrer', neutral: 'Ihrem' },
    accusative: { male: 'Ihren', female: 'Ihre', neutral: 'Ihr' },
    genitive: { male: 'Ihres', female: 'Ihrer', neutral: 'Ihres' }
  };

  const getArticle = (wordCase: string): string => {
    return cases[wordCase][brokerIdentifierGender] || cases[wordCase]['male'];
  };

  config['strings'] = {
    headline: {
      text: 'Bewerten Sie ' + getArticle('accusative') + ' ' + brokerIdentifier
    },
    Zufriedenheit: {
      questionBroker:
        'Wie zufrieden sind Sie mit  ' + getArticle('dative') + ' ' + brokerIdentifier + '?',
      questionVHV: 'Wie zufrieden sind Sie mit der VHV?',
      ratings: [
        '',
        'gar nicht zufrieden',
        'weniger zufrieden',
        'mäßig zufrieden',
        'eher zufrieden',
        'sehr zufrieden'
      ],
      type: 'radio'
    },
    Engagement: {
      questionBroker:
        'Wie engagiert schätzen Sie ' + getArticle('accusative') + ' ' + brokerIdentifier + ' ein?',
      questionVHV: '',
      ratings: [
        '',
        'gar nicht engagiert',
        'weniger engagiert',
        'mäßig engagiert',
        'eher engagiert',
        'sehr engagiert'
      ],
      type: 'radio'
    },
    Weiterempfehlung: {
      questionBroker:
        'Würden Sie ' +
        getArticle('accusative') +
        ' ' +
        brokerIdentifier +
        ' einem Kollegen weiterempfehlen?',
      questionVHV: 'Würden Sie die VHV einem Kollegen weiterempfehlen?',
      ratings: ['', 'Nein', '', '', '', 'Ja'],
      type: 'button'
    },
    Kompetenz: {
      questionBroker:
        'Wie kompetent ist ' + getArticle('nominative') + ' ' + brokerIdentifier + '?',
      questionVHV: 'Wie kompetent sind die Mitarbeiter der VHV?',
      ratings: [
        '',
        'gar nicht kompetent',
        'weniger kompetent',
        'mäßig kompetent',
        'eher kompetent',
        'sehr kompetent'
      ],
      type: 'radio'
    },
    Betreuung: {
      questionBroker: '',
      questionVHV: 'Wie fühlen Sie sich durch die VHV betreut?',
      ratings: ['', 'gar nicht gut', 'weniger gut', 'mäßig gut', 'eher gut', 'sehr gut'],
      type: 'radio'
    },
    Servicequalität: {
      questionBroker:
        'Wie schätzen Sie die Servicequalität ' +
        getArticle('genitive') +
        ' ' +
        brokerIdentifier +
        (brokerIdentifierGender === 'female' ? '' : 's') +
        ' ein?',
      questionVHV: 'Wie schätzen Sie die Servicequalität der VHV ein?',
      ratings: ['', 'sehr gering', 'gering', 'weder hoch noch gering', 'hoch', 'sehr hoch'],
      type: 'radio'
    }
  };

  return { config };
};
