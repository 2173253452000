import React, { useContext } from 'react';

import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import MultiButton from '../../components/ui/MultiButton/MultiButton';
import { LandingPageContext } from '../../containers/LandingPage';
import ContactInformationContext from '../../contexts/ContactInformationContext';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import getCalculatorPath from '../../helpers/getCalculatorPath';
import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';
import proofBoolean from '../../helpers/proofBoolean';

const ComparisonFooterArea = props => {
  const links = mapIndexedNodeProps(props, 'links');

  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const { stageAreaComponent } = useContext(LandingPageContext) || {};

  const { activeProduct: product } = useContext(ProductGroupsContext) || {};

  const { linkTo: calculatorPageFromProduct, hideContact, calcIcon } = product || {};
  const {
    linkTo: calculatorPageFromProps,
    hideCalculatorButton,
    hideContactButton,
    hideDownloadButton,
    calculatorButtonLabel,
    contactButtonLabel
  } = props || {};

  const { linkTo: calculatorPageFromStage } = stageAreaComponent || {};

  const calculatorPage =
    calculatorPageFromProduct || calculatorPageFromProps || calculatorPageFromStage;

  return proofBoolean(hideDownloadButton) &&
    proofBoolean(hideContactButton) &&
    proofBoolean(hideCalculatorButton) ? null : (
    <footer className="m-component-footer">
      <div>
        {!proofBoolean(hideDownloadButton) && (
          <div>
            <MultiButton
              links={links}
              label="Weitere Informationen herunterladen"
              buttonVariant=""
            />
          </div>
        )}
        <div>
          {!proofBoolean(hideContactButton) && !hideContact && (
            <button
              className="m-button m-button--light m-button--condensed tag-comparison-contact ga-comparison-contact ng-isolate-scope"
              onClick={() => {
                setIsContactInformationModalVisible(true, {
                  isActiveProductVariant: true
                });
              }}
            >
              <Icon type="hotline" className="ico-hotline ga-comparison-contact" />{' '}
              {contactButtonLabel || 'Beraten lassen'}
            </button>
          )}
          {!proofBoolean(hideCalculatorButton) && getCalculatorPath(calculatorPage)?.linkTo && (
            <Link
              {...getCalculatorPath(calculatorPage)}
              extraClass="m-button m-button--condensed tag-comparison-calc ga-comparison-calc"
            >
              <i className={`ico-${calcIcon} ga-comparison-calc`} />
              {calculatorButtonLabel || 'Beitrag berechnen'}
            </Link>
          )}
        </div>
      </div>
    </footer>
  );
};

export default ComparisonFooterArea;
