import React from 'react';
import RichText from '../../components/ui/RichText/RichText';
import extractChildren from '../../helpers/extractChildren';

const FurtherLinksArea = ({ headline, text, children }) => {
  // Alter gepflegter Content soll nicht angezeigt werden

  const template = children && children[0] && children[0].props?.template;
  const isOldTemplate =
    template === 'SmallCallToActionComponent' || template === 'LinkListComponent';

  const { linkArea } = extractChildren(children);
  return isOldTemplate ? null : (
    <div className="l-landing__contact-cta">
      <div>
        <strong>{headline}</strong>
      </div>
      <RichText style={{ textAlign: 'left' }} richText={text} />
      {linkArea}
    </div>
  );
};

export default FurtherLinksArea;
