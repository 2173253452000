import React, { createContext, useContext, useEffect, useState } from 'react';

import { ProductGroup } from './ProductGroupsContext';
import ProductGroupsContext from './ProductGroupsContext';

interface IContactInformationContextValue {
  isContactInformationModalVisible: boolean;
  setIsContactInformationModalVisible: (
    isVisible: boolean,
    options?: {
      isDamageVariant?: boolean;
      isActiveProductVariant?: boolean;
      activeProduct?: ProductGroup;
    }
  ) => void;
  isActiveProductVariant: boolean;
  isDamageVariant: boolean;
  resetState: () => void;
}

const initialValue = {
  isContactInformationModalVisible: false,
  setIsContactInformationModalVisible: null,
  isActiveProductVariant: false,
  isDamageVariant: false,
  resetState: null
};

const ContactInformationContext = createContext<IContactInformationContextValue>(initialValue);

export const ContactInformationContextProvider = ({ children }) => {
  const { setActiveProduct } = useContext(ProductGroupsContext) || {};

  const [isContactInformationModalVisible, dispatchIsContactInformationModalVisible] = useState(
    initialValue.isContactInformationModalVisible
  );

  const [isActiveProductVariant, setIsActiveProductVariant] = useState(
    initialValue.isActiveProductVariant
  );

  const [isDamageVariant, setIsDamageVariant] = useState(initialValue.isDamageVariant);

  const setIsContactInformationModalVisible = (
    isVisible: boolean,
    options?: {
      isDamageVariant?: boolean;
      isActiveProductVariant?: boolean;
      activeProduct?: ProductGroup;
    }
  ) => {
    dispatchIsContactInformationModalVisible(isVisible);

    const { isDamageVariant, isActiveProductVariant, activeProduct } = options || {};

    if (options) {
      if (isDamageVariant) {
        setIsDamageVariant(true);
      }

      if (isActiveProductVariant) {
        setIsActiveProductVariant(isActiveProductVariant);
      }

      if (activeProduct) {
        if (activeProduct) {
          setActiveProduct(activeProduct);
        }
      }
    }
  };

  const resetState = () => {
    setTimeout(() => {
      setIsDamageVariant(initialValue.isDamageVariant);
      setIsActiveProductVariant(initialValue.isActiveProductVariant);
    }, 500);
  };

  useEffect(() => {
    if (!isContactInformationModalVisible) {
      resetState();
    }
  }, [isContactInformationModalVisible]);

  return (
    <ContactInformationContext.Provider
      value={{
        isContactInformationModalVisible,
        setIsContactInformationModalVisible,
        isActiveProductVariant,
        isDamageVariant,
        resetState
      }}
    >
      {children}
    </ContactInformationContext.Provider>
  );
};

export default ContactInformationContext;
