import './Phv2024StageComponent.scss';

import React, { useEffect, useState } from 'react';

import useBreakpoint from '../../hooks/useBreakpoint';
import barbaraImage from '../../styles/assets/images/phv-2024-stage/Phv24_barbara.webp';
import glasImage from '../../styles/assets/images/phv-2024-stage/Phv24_glasscheibe-50.webp';
import bgImage from '../../styles/assets/images/phv-2024-stage/Phv24_kueche.webp';
import signetImage from '../../styles/assets/images/phv-2024-stage/VHV_Stiftung_Warentest_09_2023.jpg';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import PriceCallToActionComponent from '../PriceCallToActionComponent/PriceCallToActionComponent';
import ContactBar from '../ui/ContactBar/ContactBar';
import Image from '../ui/Image/Image';

const Phv2024StageComponent = () => {
  const BreakpointM = useBreakpoint('m');
  // const BreakpointS = useBreakpoint('s');
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <header className="Phv2024StageComponent">
      <div>
        <>
          <Image extraClass="Phv2024StageComponent__bg" isBackgroundImage src={bgImage} />
          <Image extraClass="Phv2024StageComponent__signet" src={signetImage} />
          <Image
            extraClass={`Phv2024StageComponent__glas${isLoaded ? ' isLoaded' : ''}`}
            src={glasImage}
          ></Image>
          <Image
            extraClass={`Phv2024StageComponent__barbara${isLoaded ? ' isLoaded' : ''}`}
            src={barbaraImage}
          />
        </>

        <div className="Phv2024StageComponent__wrapper">
          {BreakpointM && <div className="Phv2024StageComponent__fade" />}
          <div className="Phv2024StageComponent__cta">
            <div className="Phv2024StageComponent__text">
              <p className="Phv2024StageComponent__subline">Die neue VHV Privathaftpflicht</p>
              <h2 className="Phv2024StageComponent__headline">
                Der bruchsicherste Schutz aller Zeiten
              </h2>
            </div>
            <div className="Phv2024StageComponent__button">
              {!BreakpointM && <div className="Phv2024StageComponent__fade" />}
              <PriceCallToActionComponent
                rightButtonLabel="Jetzt Tarif berechnen"
                linkTo="/versicherungen/haftpflichtversicherung/privat/tarifrechner?appView=true"
                linkType="page"
              />
              <ButtonComponent
                label="Mehr zur VHV Privathaftpflicht"
                linkTo="/versicherungen/haftpflichtversicherung/privat"
                linkType="page"
                variant="underline"
                alignment="center"
              />
            </div>
          </div>
          <div className="Phv2024StageComponent__contact-area">
            <ContactBar />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Phv2024StageComponent;
