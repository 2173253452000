import React from 'react';

import bgImage from '../../styles/assets/images/phv-2021-stage/barbara.jpg';
import ContactBar from '../ui/ContactBar/ContactBar';
import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';

const PhvBarbaraStageComponent = () => {
  return (
    <header className="l-phvbarbara-stage">
      <div>
        <Image extraClass="l-phvbarbara-stage__bg" isBackgroundImage src={bgImage} />
        <div className="l-phvbarbara-stage__wrapper">
          <div className="l-phvbarbara-stage__text">
            <p className="l-phvbarbara-stage__subline">Mehr Leistung – weniger zahlen</p>
            <h2 className="l-phvbarbara-stage__headline">
              Was kann es <br />
              schöneres geben?
            </h2>
            <Link
              linkTo="/versicherungen/kfz-versicherung/auto"
              extraClass="m-button m-button--size-l m-button-barbara"
            >
              <Icon type="arrow-right" />
              <span className="">Jetzt Tarif berechnen</span>
            </Link>
          </div>
          <div className="l-phvbarbara-stage__contact-area">
            <ContactBar />
          </div>
        </div>
      </div>
    </header>
  );
};

export default PhvBarbaraStageComponent;
