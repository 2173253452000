import { format } from 'date-fns';
import React, { useContext } from 'react';

import ExpertEventsContext from '../../contexts/ExpertEventsContext';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

const EventsComponent = () => {
  const { upcomingEvents } = useContext(ExpertEventsContext) || {};
  return (
    <>
      <div className="m-event-list spacer">
        <ul className="m-event-list__list">
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((event, key) => {
              const { type, kind, locality, startDate, path, title } = event;

              return (
                <li key={key}>
                  <time>{format(new Date(startDate), 'dd.MM.yyyy')}</time>
                  <Link linkTo={path} linkType="internal" label={title} />
                  <span>{type === 'virtual' ? kind : locality}</span>
                </li>
              );
            })
          ) : (
            <div>
              <Icon type="no-result" />
              <p>
                <em>
                  Aktuell sind leider keine Veranstaltungen verfügbar. In unserem Archiv erhalten
                  Sie einen Überblick aller vergangenen Veranstaltungen.
                </em>
              </p>
            </div>
          )}
        </ul>
      </div>
      <Link
        linkTo="/aktuelles/veranstaltungen"
        linkType="internal"
        label="Alle Veranstaltungen"
        extraClass="m-button m-button--size-m m-button--sec m-button--full-width"
        showIcon
      />
    </>
  );
};
export default EventsComponent;
