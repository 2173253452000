import './Link.scss';

import { Link as GatsbyLink } from 'gatsby-link';
import React, { useContext } from 'react';

import NewsletterRegistrationContext from '../../../contexts/NewsletterRegistrationContext';
import TransferLinkBoxContext, {
  TKnownExternalLinks
} from '../../../contexts/TransferLinkBoxContext';
import getAsset from '../../../helpers/getAsset';
import getFunctionUrl from '../../../helpers/getFunctionUrl';
import useLinkFunction from '../../../hooks/useLinkFunction';
import useUrlParams from '../../../hooks/useUrlParams';
import Icon from '../Icon/Icon';

export interface ILinkProps {
  linkTo: string | { path: string; template: string; pageScope?: string };
  id?: string;
  label?: string;
  linkType?: TLinkType | string;
  linkParam?: string;
  linkHash?: string;
  //Todo allow string[] for linkParam & linkHash
  noBaseClass?: boolean;
  clickClass?: any;
  extraClass?: string;
  showIcon?: boolean;
  children?: any;
  onClick?: () => void;
  isTransferLink?: boolean;
}

export type TLinkType =
  | 'none'
  | 'page'
  | 'external'
  | 'asset'
  | 'printed_media'
  | 'proxy_page'
  | 'form_servlet'
  | 'internal_app'
  | 'mail'
  | 'phone';

const Link: React.FC<ILinkProps> = ({
  label,
  linkTo,
  id,
  linkType = 'page',
  linkParam = '',
  linkHash = '',
  noBaseClass,
  clickClass,
  extraClass,
  showIcon,
  children,
  onClick,
  isTransferLink
}) => {
  const [ivmnr] = useUrlParams('ivmnr');

  const brokerNumberPrefix = linkType === 'form_servlet' ? 'brokerNumber' : 'iVMNr';

  const { setShowLinkBox, setLinkToBox } = useContext(TransferLinkBoxContext) || {};

  const isKnownExternal = (external: TKnownExternalLinks) =>
    !!linkTo &&
    !isTransferLink &&
    (typeof linkTo === 'string'
      ? linkTo.includes(external + '.de') && external
      : linkTo['path']?.includes(external + '.de') && external);

  const isKnownExternalLink = isKnownExternal('hannoversche') || isKnownExternal('hansemerkur');

  const appendedBrokerNumber =
    (linkType === 'page' ||
      linkType === 'form_servlet' ||
      (linkType === 'external' &&
        typeof linkTo === 'string' &&
        (linkTo?.includes('.vhv.de') || linkTo?.includes('/tarifrechner')))) &&
    ivmnr
      ? `${linkParam === '' ? '?' : '&'}${brokerNumberPrefix}=${ivmnr}`
      : '';

  const urlParam = linkParam !== '' ? `?${linkParam}${appendedBrokerNumber}` : appendedBrokerNumber;

  const urlHash = linkHash !== '' ? `#${linkHash}` : '';

  const targetTemplate = typeof linkTo !== 'string' && linkTo?.template;

  const to =
    linkType === 'form_servlet'
      ? getFunctionUrl('baddebt') + urlParam + urlHash
      : linkTo && (typeof linkTo === 'string' ? linkTo : linkTo.path) + urlParam + urlHash;

  const iconClass = () => {
    switch (linkType) {
      case 'external':
        return 'external-link';
      case 'asset':
      case 'printed_media':
        return 'download';
      case 'proxy_page':
        return 'fullscreen';
      case 'mail':
        return 'mail';
      default:
        return 'arrow-right';
    }
  };

  const proxyTemplates = ['CustomPage', 'ProxyPage'];

  const isPrintedMedia = linkType === 'printed_media';
  const isExternal =
    linkType === 'external' ||
    linkType === 'form_servlet' ||
    isPrintedMedia ||
    (linkType === 'page' && proxyTemplates.includes(targetTemplate));
  const isAsset = linkType === 'asset';
  const isMail = linkType === 'mail';
  const isPhone = linkType === 'phone';

  const isInternalApp = ['internal_app', 'form_servlet'].includes(linkType);

  const icon = showIcon && <Icon type={iconClass()} />;

  const defaultLabel = isAsset ? 'Herunterladen' : 'Mehr erfahren';

  const content = children || (
    <span>
      {icon}
      {label || defaultLabel}
    </span>
  );
  const assetPath = isAsset && getAsset(linkTo).path;

  const unixTimestamp = Date.now();
  const printedMediaPath = isPrintedMedia && `https://www.vhv.de/ds/${to}.pdf?ts=${unixTimestamp}`;

  const className = `${!noBaseClass ? 'Link' : ''}${
    clickClass ? ' ga-' + clickClass : ''
  }${extraClass ? ' ' + extraClass : ''}${
    isInternalApp && to === 'cookieOverlay' ? ' ot-sdk-show-settings' : ''
  }`;

  const { setIsRegistrationVisible } = useContext(NewsletterRegistrationContext) || {};
  const functionLink = useLinkFunction(to);

  return linkType !== 'none' ? (
    to ? (
      isKnownExternalLink ? (
        <a
          className={className}
          onClick={() => {
            setLinkToBox(to);
            setShowLinkBox(isKnownExternalLink);
          }}
        >
          {content}
        </a>
      ) : isExternal || isAsset ? (
        <a
          className={className}
          href={assetPath || printedMediaPath || to}
          download={isAsset}
          target={isExternal ? '_blank' : ''}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          rel="noopener noreferrer"
        >
          {content}
        </a>
      ) : isInternalApp && to === 'cookieOverlay' ? (
        <a
          className={className}
          onClick={event => {
            event.preventDefault();
            const OneTrust = (window as any).OneTrust;

            if (OneTrust && OneTrust.ToggleInfoDisplay) {
              OneTrust.ToggleInfoDisplay();
            }
          }}
        >
          {content}
        </a>
      ) : isInternalApp ? (
        <a
          className={className}
          onClick={() => {
            if (onClick) {
              onClick();
            }
            if (functionLink) {
              functionLink();
            }
            if (to === 'newsletterRegistration') {
              setIsRegistrationVisible(true);
            }
          }}
        >
          {content}
        </a>
      ) : isMail ? (
        <a className={className} href={`mailto:${linkTo}`}>
          {content}
        </a>
      ) : isPhone ? (
        <a className={className} href={`tel:${linkTo}`}>
          {label}
        </a>
      ) : (
        <GatsbyLink
          className={className}
          to={to}
          id={id}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          {content}
        </GatsbyLink>
      )
    ) : (
      <a
        className={className}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {content}
      </a>
    )
  ) : null;
};

export default Link;
