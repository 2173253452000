import React from 'react';

import Link from '../ui/Link/Link';

const LinkComponent = props => {
  const { linkType } = props;

  return (
    <li className={linkType}>
      <Link {...props} />
    </li>
  );
};
export default LinkComponent;
