import './ProductStageComponent.scss';

import React, { useContext, useState } from 'react';

import ContactInformationContext from '../../contexts/ContactInformationContext';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import getCalculatorPath from '../../helpers/getCalculatorPath';
import Lightbox from '../Lightbox/Lightbox';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';

type TSignetVariants = 'signet' | 'price' | undefined;

interface ProductStageComponentProps {
  stageVariant: TSignetVariants;
  barbaraImage: any;
  priceValue?: string;
  priceTooltipHeadline?: string;
  priceTooltipDescription?: string;
  signetImage?: any;
  title: string;
  description: string;
}

const ProductStageComponent: React.FC<ProductStageComponentProps> = props => {
  const switchField: TSignetVariants = props.stageVariant;

  const { activeProduct: product } = useContext(ProductGroupsContext) || {};
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const { linkTo: calculatorPage, calcIcon, calcLabel, contact } = product || {};

  const [showPriceLightBox, setShowPriceLightbox] = useState(false);

  return (
    <section className="ProductStageComponent">
      <Lightbox visible={showPriceLightBox} turnOffHandler={() => setShowPriceLightbox(false)}>
        {props.priceTooltipHeadline && <h4>{props.priceTooltipHeadline}</h4>}
        {props.priceTooltipHeadline && (
          <p dangerouslySetInnerHTML={{ __html: props.priceTooltipDescription }}></p>
        )}
      </Lightbox>
      <div>
        <div className="ProductStageComponent__badgeSide">
          <Image extraClass="ProductStageComponent__barbara" src={props.barbaraImage} />
          {switchField &&
            (switchField === 'price' ? (
              <div
                className="ProductStageComponent__badge ProductStageComponent__abPreis"
                onClick={() => setShowPriceLightbox(true)}
              >
                <div className="ProductStageComponent__badgeTextNormal">schon ab</div>
                <div className="ProductStageComponent__badgeTextStrong">{props.priceValue}</div>
                <div className="ProductStageComponent__badgeTextNormal">
                  <span>monatlich</span>
                  <Icon type="info-circle" />
                </div>
              </div>
            ) : (
              <div>
                <div className="ProductStageComponent__badge ProductStageComponent__signet">
                  <Image src={props.signetImage} />
                </div>
              </div>
            ))}
        </div>
        <div className="ProductStageComponent__contentSide">
          <div className="ProductStageComponent__text">
            <h1>{props.title}</h1>
            <p>{props.description}</p>
          </div>
          <div className="ProductStageComponent__buttons">
            {calculatorPage && (
              <NewButtonComponent
                {...getCalculatorPath(calculatorPage)}
                label={calcLabel || 'Jetzt berechnen'}
                icon={calcIcon || 'calculator'}
              />
            )}

            {contact && (
              <NewButtonComponent
                label="Beraten lassen"
                icon="hotline"
                variant="white"
                onClick={() => {
                  setIsContactInformationModalVisible(true, {
                    isActiveProductVariant: true
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductStageComponent;
