import './MaintenanceBannerComponent.scss';

import React, { useContext, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import ContactInformationContext from '../../contexts/ContactInformationContext';
import SiteDataContext from '../../contexts/SiteDataContext';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

const MaintenanceBannerComponent = () => {
  const { maintenance } = useContext(SiteDataContext) || {};
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getInitialOpenState = () => {
      if (!maintenance.visible) {
        return false;
      }

      const bannerClosedData = localStorage.getItem('maintenanceBannerClosed');
      if (bannerClosedData) {
        const { timestamp } = JSON.parse(bannerClosedData);
        const oneDayInMillis = 24 * 60 * 60 * 1000;
        const now = new Date().getTime();
        if (now - timestamp < oneDayInMillis) {
          return false;
        }
      }
      return true;
    };

    setIsOpen(getInitialOpenState());
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    const bannerClosedData = {
      timestamp: new Date().getTime()
    };
    localStorage.setItem('maintenanceBannerClosed', JSON.stringify(bannerClosedData));
  };

  return (
    <AnimateHeight height={isOpen ? 'auto' : 0}>
      <div className="MaintenanceBannerComponent">
        <div className="MaintenanceBannerComponent__announcement">
          <div
            dangerouslySetInnerHTML={{
              __html: maintenance.text
            }}
          ></div>{' '}
          {maintenance.linkType !== 'none' &&
            (maintenance.linkType !== 'damage' ? (
              <Link
                linkTo={maintenance.linkTo}
                linkType={maintenance.linkType}
                label={maintenance.label}
              />
            ) : (
              <Link
                linkTo=""
                label="Schaden melden"
                onClick={() => {
                  setIsContactInformationModalVisible(true, {
                    isDamageVariant: true
                  });
                }}
              />
            ))}
        </div>
        <Icon type="close" onClick={handleClose} />
      </div>
    </AnimateHeight>
  );
};

export default MaintenanceBannerComponent;
