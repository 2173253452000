import React, { Component } from 'react';

import SurveyArea from '../areas/SurveyArea/SurveyArea';
import extractChildren from '../helpers/extractChildren';

interface IBrokerSurveyPage {
  children: Component;
  surveyArea: any;
}

const BrokerSurveyPage: React.FC<IBrokerSurveyPage> = ({
  children,
  surveyArea: surveyAreaProps
}) => {
  const { headerArea, contentHeaderArea } = extractChildren(children);
  // const getCtrl = ctrl(surveyArea);

  return (
    <>
      {headerArea}
      <main className="l-content">
        {contentHeaderArea}
        <SurveyArea surveyAreaProps={surveyAreaProps} />
      </main>
    </>
  );
};

export default BrokerSurveyPage;
