const ProductFinderLabelMap = {
  noOccupation: 'Ihr Unternehmenstyp',
  noBranch: 'Ihre Branche',
  unternehmer: 'Unternehmer / Gewerbetreibende',
  freiberufler: 'Freiberufler',
  bildung: 'Bildungseinrichtung',
  // bildung: 'Bildungs-/ gemeinnützige Einrichtung (& Kultur)',
  dienstleistungen: 'Dienstleistungen',
  handel: 'Handel',
  hersteller: 'Produzierendes Gewerbe (Hersteller)',
  energie: 'Energie',
  it: 'IT',
  bau: 'Bau',
  architekten: 'Architeken / Planer / Ingenieure',
  aerzte: 'Ärzte',
  heilnebenberufe: 'Heilnebenberufe',
  makler: 'Makler / Berater',
  anwaelte: 'Anwälte / Steuerprüfer / Wirtschaftsprüfer',
  medien: 'Medien',
  berater: 'Berater',
  vereine: 'Vereine / Stiftungen',
  verbaende: 'Verbände'
};

export const getLabel = name => ProductFinderLabelMap[name];

export default ProductFinderLabelMap;
