import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

interface IBrokerSearchContextValue {
  isBrokerSearchModalVisible: boolean;
  setIsBrokerSearchModalVisible: Dispatch<SetStateAction<boolean>>;
  inputPLZ: string;
  setInputPLZ: Dispatch<SetStateAction<string>>;
}

const initialValue = {
  isBrokerSearchModalVisible: false,
  setIsBrokerSearchModalVisible: null,
  inputPLZ: null,
  setInputPLZ: null
};

const BrokerSearchContext = createContext<IBrokerSearchContextValue>(initialValue);

export const BrokerSearchContextProvider = ({ children }) => {
  const [isBrokerSearchModalVisible, setIsBrokerSearchModalVisible] = useState(
    initialValue.isBrokerSearchModalVisible
  );

  const [inputPLZ, setInputPLZ] = useState(initialValue.inputPLZ);

  return (
    <BrokerSearchContext.Provider
      value={{
        isBrokerSearchModalVisible,
        setIsBrokerSearchModalVisible,
        inputPLZ,
        setInputPLZ
      }}
    >
      {children}
    </BrokerSearchContext.Provider>
  );
};

export default BrokerSearchContext;
