import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';
import useBreakpoint from '../../hooks/useBreakpoint';
import ComparisonCellComponentNew from '../ComparisonCellComponenNew/ComparisonCellComponentNew';
import Columns from '../ui/Columns/Columns';
import RichText from '../ui/RichText/RichText';

interface IComparisonRow {
  benefit: string;
  description?: string;
}

const ComparisonRowComponentNew: React.FC<IComparisonRow> = props => {
  const { description, benefit } = props;
  const [isOpen, setIsOpen] = useState(false);
  const cells = mapIndexedNodeProps(props, 'cells');
  const BreakpointS = useBreakpoint('s');

  const getWidths = () => {
    const widthArray = new Array(cells.length).fill(1);
    return widthArray;
  };
  const getColumns = () => (
    <Columns
      widths={getWidths()}
      gutter={BreakpointS ? 'xs' : 'xl--content'}
      nowrap
      overflowx={false}
    >
      {Array.isArray(cells) &&
        cells.map((cell, index) => {
          return <ComparisonCellComponentNew key={index} {...cell}></ComparisonCellComponentNew>;
        })}
    </Columns>
  );

  return (
    <div className="ComparisonRowComponentNew">
      <Columns widths={[1, 2]} gutter={'xl--content'} wrap>
        {description ? (
          <div className="ComparisonRowComponentNew__innerWrapper">
            <div
              className="ComparisonRowComponentNew__cell TitleRow"
              onClick={() => {
                if (description) {
                  setIsOpen(!isOpen);
                }
              }}
            >
              <RichText richText={benefit} />
              <div>
                <i
                  className={`ico-help${!isOpen ? ' is-visible' : ''}`}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                />
                <i
                  className={`ico-close${isOpen ? ' is-visible' : ''}`}
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
              </div>
            </div>
            <AnimateHeight
              className="AnimateHeight"
              animateOpacity={true}
              height={isOpen ? 'auto' : 0}
              duration={500}
            >
              <div className={`m-comparison__help-text`}>{description}</div>
            </AnimateHeight>
          </div>
        ) : (
          <div className="ComparisonRowComponentNew__cell TitleRow">{benefit}</div>
        )}
        <div className="ComparisonRowComponentNew__content">{getColumns()}</div>
      </Columns>
    </div>
  );
};
export default ComparisonRowComponentNew;
