import React from 'react';

import extractChildren from '../../helpers/extractChildren';

const BenefitTableComponent = ({ children }) => {
  const { cellArea, footerArea } = extractChildren(children);

  return (
    <>
      <ul className="m-feature-list">{cellArea}</ul>
      {footerArea}
    </>
  );
};

export default BenefitTableComponent;
