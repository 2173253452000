const sortList = (list, propName) => {
  const sortContent = (keyA, keyB) => {
    const prioA = parseInt(list[keyA][propName]) || 0;
    const prioB = parseInt(list[keyB][propName]) || 0;
    if (!prioA && !prioB) {
      return 0;
    } else if (!prioA && !!prioB) {
      return 1;
    } else if (!!prioA && !prioB) {
      return -1;
    } else {
      return prioA > prioB ? 1 : -1;
    }
  };

  return (
    list &&
    (Array.isArray(list)
      ? list.sort((keyA, keyB) => {
          return sortContent(keyA, keyB);
        })
      : Object.keys(list).sort((keyA, keyB) => {
          return sortContent(keyA, keyB);
        }))
  );
};

export default sortList;
