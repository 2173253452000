import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const ErrorPage = ({ children }) => {
  const { headerArea, contentArea, contentHeaderArea } = extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content">
        {contentHeaderArea}
        {contentArea}
      </main>
      <Footer />
    </>
  );
};

export default ErrorPage;
