import { Loader } from '@googlemaps/js-api-loader';
// import { REACT_APP_GOOGLE_API_KEY } from '../../gatsby-config';

const loader = new Loader({
  apiKey: 'AIzaSyDZKVE-duQSTEFwgZsNZQnwbRFjNRWerJs',
  version: '3.43.3',
  libraries: ['places']
});

export const loadGoogle = async () => {
  if (typeof google !== 'undefined') {
    return google;
  }
  await loader.load();
  return google;
};
