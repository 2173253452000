export default (richText: string): string => {
  const scope = process.env.GATSBY_SCOPE;
  if (!richText || typeof richText === 'object') {
    return;
  } else {
    const damHrefRegex = /href="\/assets/g;

    //TODO: @neoMelnik Bitte einmal checken!!!
    const privatePathRegex = /((href=")(\/vhvde)?\/privat)/g;
    const internalLinkRegex = /((href=")(http.?:\/\/)?(www\.vhv\.de)?\/vhvde)/g;

    const externalLinkRegex = /href="http/g;
    const formattedRichText = richText
      // External link
      .replace(externalLinkRegex, `target=_blank href="http`)
      // Dam link from href
      .replace(damHrefRegex, 'target=_blank href="/assets')
      // remove leading /privat from internal links
      .replace(privatePathRegex, '$2')
      //remove leading ...vhv.de/vhvde/
      .replace(internalLinkRegex, 'href="')
      // Old druckstück url
      //TODO: @alt-systeme hier auf neue aoi umstellen
      .replace('https://www.vhv-partner.de/docroot/', `https://archiv.vhv-partner.de/docroot/`);
    // Todo: adjust this when richtext config in mgnl allows druckstuecknr. links (instead of dam)
    // Druckstück-Nr
    // .replace(
    //   /(^\d{3}.\d{4}.\d{2,3}$)/,
    //   `https://archiv.vhv-partner.de/docroot/vhvpartner/druckstueck/download.jsp?$1.pdf`
    // );

    return formattedRichText;
  }
};
