import React, { useEffect, useState } from 'react';

import scopedHostMap from '../../helpers/scopedHostMap';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

const CyberInfoComponent = ({ dark = false }) => {
  const [initiator, setInitiator] = useState(null);
  const isBrowser = typeof window !== 'undefined';

  const SCOPE = process.env.GATSBY_SCOPE;
  const GATSBYENV = process.env.GATSBY_ENV;
  const path =
    scopedHostMap[SCOPE] && scopedHostMap[SCOPE][GATSBYENV]
      ? scopedHostMap[SCOPE][GATSBYENV] + '/firmen/produkte/cyber/cyberversicherung'
      : '';

  useEffect(() => {
    isBrowser && setInitiator(document.getElementById('counter'));
  }, []);
  const values = [17.7, 100, 322000];
  const counters = [0, 0, 0];
  const increments = [0.1, 1, 3000];
  const counterSpans = [];
  const finished = [false, false, false];
  for (let i = 0; i < values.length; i++) {
    isBrowser && counterSpans.push(document.getElementById('counter' + i));
  }
  const initCounter = function () {
    if (initiator && isBrowser && initiator.getBoundingClientRect().top < window?.innerHeight) {
      const intervalId = setInterval(function () {
        for (let j = 0; j < values.length; j++) {
          if (counters[j] < values[j]) {
            counters[j] += increments[j] * 1.5 * (1.01 - counters[j] / values[j]);
            let string = '';
            if (increments[j] % 1 !== 0) {
              // Include decimals
              string += Math.min(values[j], Math.round(counters[j] * 10) / 10);
              string += string.indexOf('.') === -1 ? '.0' : '';
              string = string.replace('.', ',');
            } else {
              string += Math.min(values[j], Math.round(counters[j]));
              if (string.length >= 4) {
                string = string.substr(0, string.length - 3) + '.' + string.substr(-3);
              }
            }
            counterSpans[j].innerText = string;
          } else {
            finished[j] = true;
            if (finished[0] && finished[1] && finished[2]) {
              clearInterval(intervalId);
            }
          }
        }
      }, 20);
      isBrowser && window.removeEventListener('scroll', initCounter);
    }
  };
  isBrowser && window.addEventListener('scroll', initCounter);
  return (
    <div className="l-grid spacer-large l-cyber" id="counter">
      <div className="l-grid__col--4-12 l-grid__col--keep-width">
        <div className="l-cyber__circle-wrapper">
          <div className={`l-cyber__circle${dark ? ' l-cyber__circle--dark' : ''}`}>
            <div className="l-cyber__circle-icon">
              <Icon type="flash" viewBox="0 0 57 70" />
            </div>
            <div className="l-cyber__circle-counter">
              <span id="counter0">0</span> Mio.
            </div>
            <div className="l-cyber__circle-title">
              Cyber-Opfer <br />
              in Deutschland
              <br />
              in 2019
            </div>
          </div>
        </div>
        <div className="l-cyber__circle-title--mobile">
          Cyber-Opfer <br />
          in Deutschland
          <br />
          in 2019
        </div>
      </div>
      <div className="l-grid__col--4-12 l-grid__col--keep-width">
        <div className="l-cyber__circle-wrapper">
          <div className={`l-cyber__circle${dark ? ' l-cyber__circle--dark' : ''}`}>
            <div className="l-cyber__circle-icon">
              <Icon type="skull" viewBox="0 0 57 70" />
            </div>
            <div className="l-cyber__circle-counter">
              <span id="counter1">0</span> Mrd. €
            </div>
            <div className="l-cyber__circle-title">
              Schäden <br />
              durch Cybercrime
            </div>
          </div>
        </div>
        <div className="l-cyber__circle-title--mobile">
          Schäden <br />
          durch Cybercrime
        </div>
      </div>
      <div className="l-grid__col--4-12 l-grid__col--keep-width">
        <div className="l-cyber__circle-wrapper">
          <div className={`l-cyber__circle${dark ? ' l-cyber__circle--dark' : ''}`}>
            <div className="l-cyber__circle-icon">
              <Icon type="bomb" viewBox="0 0 57 70" />
            </div>
            <div className="l-cyber__circle-counter">
              <span id="counter2">0</span>
            </div>
            <div className="l-cyber__circle-title">
              Neue Schadprogramme
              <br />
              täglich
            </div>
          </div>
        </div>
        <div className="l-cyber__circle-title--mobile">Neue Schad&shy;programme täglich</div>
      </div>
      {!dark && (
        <>
          <div className="l-cyber__button">
            <div>
              <Link linkTo={path} linkType="external" extraClass="m-button" showIcon />
            </div>
          </div>
          <div className="l-cyber__subline">
            <p>*) &nbsp;in den letzten 2 Jahren in Deutschland</p>

            <p>
              Quellen:&nbsp;
              <a
                target="_blank"
                href="https://www.nortonlifelock.com/content/dam/nortonlifelock/pdfs/reports/2019-nortonlifelock%20-cyber-safety-insights-report-global-results-en.pdf"
                rel="noreferrer"
              >
                NortonLifeLock – Cyber Safety Insights Report&nbsp;
              </a>
              &nbsp;|&nbsp;&nbsp;
              <a
                target="_blank"
                href="https://www.bitkom.org/Presse/Presseinformation/Angriffsziel-deutsche-Wirtschaft-mehr-als-100-Milliarden-Euro-Schaden-pro-Jahr"
                rel="noreferrer"
              >
                bitkom – Wirtschaftsschutz in der Industrie&nbsp;&nbsp;
              </a>
              |&nbsp;&nbsp;
              <a
                target="_blank"
                href="https://www.bsi.bund.de/SharedDocs/Downloads/DE/BSI/Publikationen/Lageberichte/Lagebericht2020.pdf?__blob=publicationFile&amp;v=2"
                rel="noreferrer"
              >
                Bundesamt für Sicherheit in der Informationstechnik
              </a>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default CyberInfoComponent;
