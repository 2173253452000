import React from 'react';

import RichText from '../ui/RichText/RichText';

const ContentListItem = ({ desc }) => {
  return (
    <li>
      <RichText richText={desc} />
    </li>
  );
};

export default ContentListItem;
