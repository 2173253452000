// @ts-ignore
import { useWindowSize } from '@react-hook/window-size';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import Icon from '../Icon/Icon';

const breakpoints = {
  s: 568,
  m: 768
};

const getOffset = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  const offset = {
    top: rect.top + window.scrollY,
    left: rect.left + window.scrollX
  };
  return offset;
};

const padding = 14;

interface FormTooltipProps {
  label: string;
  name: string;
  tooltipText: string;
}

const FormTooltip: React.FC<FormTooltipProps> = ({ name, label, tooltipText }) => {
  const [isVisible, setIsVisible] = useState(false);
  const trigger = useRef<HTMLElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const hide = () => {
    setIsVisible(false);
  };
  const [style, setStyle] = useState({});

  const [windowWidth, windowHeight] = useWindowSize();
  // const viewPort =
  //   windowWidth < breakpoints.s ? 's' : windowWidth < breakpoints.m ? 'm' : 'l'

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    const handleWindowClick = (event: MouseEvent) => {
      if (!wrapper.current) {
        return;
      }
      //@ts-ignore
      if (wrapper.current.contains(event.target)) {
        return;
      }
      hide();
    };
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, [isVisible]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    const element = trigger.current;
    if (!element) {
      return;
    }
    const sidebar = document.querySelector('.l-calculator__sidebar');
    if (!sidebar) {
      throw new Error('tooltip requires the side bar to exist');
    }
    if (windowWidth < breakpoints.s) {
      setStyle({
        width: windowWidth - padding * 2,
        left: -element.getBoundingClientRect().left + windowWidth / 2,
        top: -element.getBoundingClientRect().top + windowHeight / 2
      });
    } else if (windowWidth < breakpoints.m) {
      setStyle({});
    } else {
      const contentRight = getOffset(element).left + element.offsetWidth;
      //@ts-ignore
      const sidebarRight = getOffset(sidebar).left + sidebar.offsetWidth;
      setStyle({
        right: -(sidebarRight - contentRight),
        transformOrigin: 'top left'
        // width: '190px',
      });
    }
  }, [windowWidth, windowHeight, isVisible]);
  return (
    <div
      id={`${name}-tooltip`}
      className={classNames('m-tooltip', 'm-tooltip--sidebar', {
        visible: isVisible
      })}
      data-tooltip
      data-tooltip-direction="horizontal"
      ref={wrapper}
    >
      {/* TODO @ilja replace with <Icon/>*/}
      <i
        className="ico-info"
        role="button"
        tabIndex={0}
        aria-label="Info anzeigen"
        onClick={handleClick}
        ref={trigger}
      ></i>
      <div
        className={classNames({
          'm-tooltip__popup': windowWidth < breakpoints.m,
          'm-tooltip__popup--sidebar': windowWidth >= breakpoints.m,
          'm-tooltip__popup--plain': windowWidth < breakpoints.s,
          'm-tooltip__popup--left': windowWidth > breakpoints.s && windowWidth < breakpoints.m
        })}
        style={{
          ...style,
          pointerEvents: isVisible ? 'auto' : 'none',
          // display: isVisible ? 'block' : '',
          display: 'block'
          // display: isVisible ? 'block' : 'none',
        }}
      >
        <div className="h5">{label}</div>
        <p>{tooltipText}</p>
        <button
          className="m-tooltip__close-button"
          aria-label="Schliessen"
          type="button"
          onClick={hide}
        >
          <Icon type="close" className="ico-close"></Icon>
        </button>
      </div>
    </div>
  );
};

export default FormTooltip;
