import React, { useState } from 'react';

import FullscreenOverlay from '../ui/FullscreenOverlay/FullscreenOverlay';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

interface IBenefitOptionComponentProps {
  headline: string;
  description: string;
  linkTo?: string;
  label?: string;
  linkType?: string;
  icon: string;
  lightBox?: {
    headline: any;
    text: any;
  };
}

const BenefitOptionComponent: React.FC<IBenefitOptionComponentProps> = ({
  headline,
  description,
  linkTo,
  label,
  linkType,
  icon,
  lightBox
}) => {
  const [showLightBox, setShowLightBox] = useState(false);
  return (
    <>
      <li>
        {icon && <Icon type={icon} className={`ico-${icon}`}></Icon>}
        <div>
          <strong>{headline}</strong>
          <div>{description}</div>
          {lightBox ? (
            <button
              onClick={() => setShowLightBox(true)}
              className="m-button m-button--sec spacer-top"
            >
              Mehr Informationen
            </button>
          ) : (
            linkType && (
              <Link
                linkTo={linkTo}
                linkType={linkType}
                label={label}
                extraClass="m-button m-button--sec spacer-top"
              />
            )
          )}
        </div>
      </li>
      {lightBox && (
        <FullscreenOverlay
          isVisible={showLightBox}
          hideOverlay={() => {
            setShowLightBox(false);
          }}
        >
          <div className="l-iframe l-calculator-privat-kontakt-email container">
            <header></header>
            <div className="page">
              <div className="m-tab">
                <div>
                  <div style={{ textAlign: 'left' }}>
                    <h6 className="modal__title">{lightBox.headline}</h6>
                    <br />
                    {lightBox.text}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FullscreenOverlay>
      )}
    </>
  );
};
export default BenefitOptionComponent;
