import React, { useRef } from 'react';
import { useState } from 'react';

interface ICyberCalculatorProps {
  brokerNumber?: string;
  fullCalculatorPath?: string; // Detailrechner
}

const CyberCalculator: React.FC<ICyberCalculatorProps> = () => {
  const wrapperRef = useRef(null);
  const resultRef = useRef(null);

  // var settingsCalc = {
  //   infinite: false,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };
  const questions = [
    {
      question: 'Betreiben Sie einen Onlineshop?',
      highRiskValue: 'yes'
    },
    {
      question: 'Nutzen Sie in Ihren Räumlichkeiten WLAN?'
    },
    {
      question: 'Greifen Sie mit mobilen Endgeräten auf das Firmennetzwerk zu?',
      highRiskValue: 'yes'
    },
    {
      question: 'Nutzen Sie Cloud-Dienste?',
      highRiskValue: 'yes'
    },
    {
      question: 'Wechseln Sie und Ihre Mitarbeiter regelmäßig Ihre Passwörter?'
    },
    {
      question: 'Nutzen Sie eine Firewall oder ein Antivirenprogramm?',
      highRiskValue: 'no'
    },
    {
      question: 'Akzeptieren Sie Kreditkarten?',
      highRiskValue: 'yes'
    },
    {
      question: 'Gab es in Ihrem Unternehmen schon einmal einen Cyberangriff?',
      highRiskValue: 'yes'
    }
  ];

  const [highRisk, setHighRisk] = useState(false);
  const [step, setStep] = useState(0);

  const restart = () => {
    setStep(0);
    setHighRisk(false);
  };

  const submit = (value: string) => {
    value === questions[step].highRiskValue && setHighRisk(true);
    setStep(step + 1);
    // BreakpointS && calcHeight();
  };
  return (
    <>
      <div className="l-cyber__calc">
        <div
          className={`l-cyber__calc-question-wrapper${
            step < questions.length - 1 ? ' visible' : ''
          }`}
          ref={wrapperRef}
        >
          <h2 className="spacer">Bedarfs-Check: Wie hoch ist Ihr Risiko?</h2>
          <div className="l-cyber__calc-slide">
            <div className="l-cyber__calc-questions">
              <div ng-repeat="question in questions">
                <div className="l-cyber__calc-question">{questions[step].question}</div>
              </div>
            </div>
            <div>
              <button className="m-button m-button--light" onClick={() => submit('yes')}>
                Ja
              </button>
              <button className="m-button m-button--light" onClick={() => submit('no')}>
                Nein
              </button>
            </div>
            <div className="l-cyber__calc-dots">
              {questions.map(
                (question, index) =>
                  index < questions.length - 1 && (
                    <span key={index} className={index === step ? 'active' : ''} />
                  )
              )}
            </div>
          </div>
        </div>

        <div
          className={`l-cyber__calc-result${step === questions.length - 1 ? ' visible' : ''}`}
          ref={resultRef}
        >
          {highRisk ? (
            <>
              <div className="text-center spacer">
                <svg width="65px" height="86px" style={{ color: '#F5A623' }}>
                  <use href="#shield" />
                </svg>
                &nbsp;
                <svg width="65px" height="86px" style={{ color: '#474747' }}>
                  <use href="#shield" />
                </svg>
                &nbsp;
                <svg width="65px" height="86px" style={{ color: '#474747' }}>
                  <use href="#shield" />
                </svg>
              </div>

              <div className="l-cyber__calc-result-title">Sie sind stark gefährdet</div>
              <p className="spacer">
                Ihr Risiko einer Cyber&shy;attacke zum Opfer zu fallen ist sehr hoch.
                Cyber&shy;kriminelle wollen möglichst schnell an Ihr Geld. Passen Sie deshalb auf
                Ihre Daten, die Ihrer Mitarbeiter und Kunden auf. Ergreifen Sie schon heute
                geeignete Sicherheits&shy;maßnahmen, um das Risiko eines Vermögens&shy;schadens
                gering zu halten. Trotz Sicherheits&shy;maßnahmen besteht ein Restrisiko und dafür
                gibt es VHV CYBERPROTECT.
              </p>
            </>
          ) : (
            <>
              <div className="text-center spacer">
                <svg width="65px" height="86px" style={{ color: '#F5A623' }}>
                  <use href="#shield" />
                </svg>
                &nbsp;
                <svg width="65px" height="86px" style={{ color: '#F5A623' }}>
                  <use href="#shield" />
                </svg>
                &nbsp;
                <svg width="65px" height="86px" style={{ color: '#474747' }}>
                  <use href="#shield" />
                </svg>
              </div>
              <div className="l-cyber__calc-result-title">Sie sind gefährdet</div>
              <p className="spacer">
                Trotz hoher Sicherheits&shy;maßnahmen können Sie nie zu 100% sicher sein. Das Risiko
                einer Cyber&shy;attacke zum Opfer zu fallen besteht auch bei Ihnen. Es ist wichtig,
                präventive Maßnahmen wie zum Beispiel einen professionellen Viren&shy;schutz, eine
                mehr&shy;stufige Firewall-Landschaft und einen Krisen&shy;plan zur Ergreifung der
                richtigen Schritte und Eindämmung des Schadens zu haben. Trotz
                Sicherheits&shy;maßnahmen besteht ein Restrisiko und dafür gibt es VHV CYBERPROTECT.
              </p>
            </>
          )}
        </div>
        {step === questions.length - 1 && (
          <>
            <div className="spacer">
              <a
                className="m-button m-button--light m-button--size-m"
                // href={`https://tarifrechner-cyber.vhv.de/tarifrechner/cyber/intro${
                //   brokerNumber ? '?iVMNr=' + brokerNumber : ''
                // }`}
                href="www.google.de"
              >
                Beitrag berechnen
              </a>
              {/* TODO @sophie contactInformation */}
              {/* {!hideContact && !!contactInfo && (
              <button
                className="m-button m-button--light m-button--size-m"
                fullscreen-overlay-app-trigger="brokerContact"
                fullscreen-overlay-args="{ contactType: 'message', brokerNumber: '${
                contactInfo.brokerNumber
              }' }"
              >
                Experten kontaktieren
              </button>
            )} */}
            </div>
            <p className="spacer">
              oder rufen Sie uns an: <a href="tel:+495119073838">0511-907 38 38</a>
            </p>
            <p className="text-s">
              <a onClick={() => restart()} style={{ cursor: 'pointer' }}>
                <svg
                  style={{ verticalAlign: '-0.2em' }}
                  width="17px"
                  height="20.5px"
                  viewBox="0 0 34 41"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="currentColor" fillRule="evenodd">
                    <path d="M28.5204138,18.7869704 C29.2151023,20.3873336 29.5650631,22.0907918 29.5650631,23.8536021 C29.5650631,30.8421368 23.9278149,36.5255487 17,36.5255487 C10.0704111,36.5255487 4.43493687,30.8403478 4.43493687,23.8536021 C4.43493687,17.37182 9.28720129,12.0167016 15.522279,11.2778178 L15.522279,17.8906489 L27.3493687,8.94532443 L15.522279,0 L15.522279,6.78592311 C6.83955964,7.54591788 0,14.9029105 0,23.8553912 C0,33.3110464 7.62587394,41 17,41 C26.3759,41 34,33.3110464 34,23.8553912 C34,21.4705677 33.5235991,19.1644631 32.5846342,16.9943274 L28.5204138,18.7869704 Z"></path>
                  </g>
                </svg>
                &nbsp;&nbsp; Bedarfscheck wiederholen
              </a>
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default CyberCalculator;
