import { Children } from 'react';

const mapIndexedNodeProps = (props, propName) => {
  const { nodes, children } = props || {};

  const re = RegExp(`${propName}\\d+`);

  const indexedNodeProps = nodes
    ? nodes.flatMap(node => (node.match(re) && props[node]) || [])
    : Children.map(children, child => child.props);

  return indexedNodeProps;
};

export default mapIndexedNodeProps;
