import './CustomerPortalTeaser.scss';

import React from 'react';

import useBreakpoint from '../../hooks/useBreakpoint';
import Phone from '../../styles/assets/images/static-vhv-customerPortal-phone.png';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';
interface ICustomerPortalTeaserComponentProps {
  bigTeaser: boolean;
  withoutContainer: boolean;
  startpage?: boolean;
}

const CustomerPortalTeaserComponent: React.FC<ICustomerPortalTeaserComponentProps> = ({
  bigTeaser,
  withoutContainer,
  startpage
}) => {
  const isMaxVpXS = useBreakpoint('xs');
  const isMaxVpS = useBreakpoint('m');
  const isMaxVpM = useBreakpoint('m');

  const teaserClass = `CustomerPortalTeaser${bigTeaser ? ' CustomerPortalTeaser--big' : ''}${startpage ? ' CustomerPortalTeaser--startpage' : ''}`;

  const portalBenefits = [
    'Alle Verträge im Blick',
    'Einfach Schaden melden',
    'Egal, ob im Web oder als App'
  ];

  const headline = startpage ? (
    <h3>
      Das <strong>VHV Kundenportal</strong> – immer dabei!
    </h3>
  ) : (
    <h3>
      Das <strong>VHV Kundenportal</strong> – kostenlos und immer online dabei!
    </h3>
  );

  const statpageBreakM = startpage && isMaxVpM;
  const statpageBreakS = startpage && isMaxVpS;

  const customerPortalTeaser = (
    <div className={teaserClass}>
      <div className="CustomerPortalTeaser__leftSide">
        <div>
          {headline}
          {(isMaxVpXS || statpageBreakM) && <Image src={Phone} />}
        </div>
        <ul className="CustomerPortalTeaser__benefits">
          {portalBenefits.map((benefit, i) => (
            <li key={i}>
              <Icon type="check-green" size={'20'} />
              <span>{benefit}</span>
            </li>
          ))}
        </ul>
        {!startpage && (
          <div className="CustomerPortalTeaser__buttons">
            <NewButtonComponent
              icon="arrow-line-right"
              label="Zum Kundenportal"
              linkTo={'https://www.vhv.de/kundenportal'}
            />
            <NewButtonComponent
              icon="arrow-line-right"
              label="Online-Services"
              linkTo={'/kundenservice'}
              variant="light"
            />
          </div>
        )}
      </div>
      {!isMaxVpXS && !statpageBreakS && (
        <div className="CustomerPortalTeaser__image">
          <Image alt="VHV Kundenportal App" src={Phone} />
        </div>
      )}
      {startpage && (
        <div className="CustomerPortalTeaser__buttons">
          <NewButtonComponent
            icon="arrow-line-right"
            label="Zum Kundenportal"
            linkTo={'https://www.vhv.de/kundenportal'}
          />
          <NewButtonComponent
            icon="arrow-line-right"
            label="Online-Services"
            linkTo={'/kundenservice'}
            variant="light"
          />
        </div>
      )}
    </div>
  );

  return withoutContainer ? customerPortalTeaser : <Container>{customerPortalTeaser}</Container>;
};

export default CustomerPortalTeaserComponent;
