import { useState } from 'react';

import {
  getBrokerSearchResults,
  IBrokerSearchResult,
  IBrokerSearchResults
} from '../helpers/getBrokerSearchResults';
import { loadGoogle } from '../helpers/loadGoogle';
import markerIcon from '../styles/assets/images/location-marker.png';

let id = 0;

export interface Marker {
  id: number;
  brokernr: IBrokerSearchResult['brokernr'];
  name1: IBrokerSearchResult['name1'];
  name2: IBrokerSearchResult['name2'];
  name3: IBrokerSearchResult['name3'];
  street: IBrokerSearchResult['street'];
  zip: IBrokerSearchResult['zip'];
  city: IBrokerSearchResult['city'];
  phone?: string;
  fax?: string;
  email?: string;
  callbackUrl: string;
  emailUrl: string;
  latlng: {
    latitude: string;
    longitude: string;
  };
  distance: any;
  organization: string;
  photo?: string;
  icon: string;
}

const toMarker = (item: IBrokerSearchResult, index: number): Marker => ({
  id: index,
  brokernr: item.brokernr,
  name1: item.name1,
  name2: item.name2,
  name3: item.name3,
  street: item.street,
  zip: item.zip,
  city: item.city,
  phone: item.contact?.phone,
  fax: item.contact?.fax,
  email: item.contact?.cryptomail,
  callbackUrl: '',
  emailUrl: '',
  latlng: {
    latitude: item.geoinfo.latlng.lat,
    longitude: item.geoinfo.latlng.lng
  },
  distance: item.geoinfo?.dist,
  organization: item.name3 + ' ' + item.name1 + ' ' + item.name2,
  photo: item.photo,
  icon: markerIcon
});

const MAX_MARKERS = 20;

const getMarkers = (searchResults: IBrokerSearchResults): Marker[] => {
  const seen: string[] = [];
  const markers: Marker[] = [];
  for (let i = 0; i < searchResults?.listvm?.length; i++) {
    const item = searchResults.listvm[i];
    const key = `${item.geoinfo.latlng.lat}|${item.geoinfo.latlng.lng}`;
    if (seen.includes(key)) {
      continue;
    }
    seen.push(key);
    markers.push(toMarker(item, i));
    if (markers.length === MAX_MARKERS) {
      break;
    }
  }
  return markers;
};

let _geocoder: google.maps.Geocoder;

const getGoogleGeolocation = async (geoLocation: GeolocationPosition) => {
  const google = await loadGoogle();
  const geocoder = _geocoder || new google.maps.Geocoder();
  const { results, status } = await new Promise(resolve => {
    geocoder.geocode(
      {
        location: {
          lat: geoLocation.coords.latitude,
          lng: geoLocation.coords.longitude
        }
      },
      (results, status) => resolve({ results, status })
    );
  });
  return {
    results,
    status
  };
};

export const useBrokerSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const [markers, setMarkers] = useState<Marker[]>([]);
  const [error, setError] = useState<{ code?: string }>();
  const [state, setState] = useState<'default' | 'loading' | 'loaded'>('default');
  const [selectedMarker, setSelectedMarker] = useState<Marker>();

  const updateSearchResults = async (inputValue: string, callback?: () => void) => {
    setSearchValue(inputValue);
    setError(null);
    if (inputValue.length !== 5) {
      setError({ code: '80100' });
      return;
    }
    if (inputValue === '') {
      setState('default');
      return;
    }
    setState('loading');
    const currentRequestId = ++id;
    let searchResults;
    try {
      searchResults = await getBrokerSearchResults({ zip: inputValue });
    } catch (error) {
      // @ts-ignore
      console.error(error);
      setError({
        code: '500' // TODO this could also be client error so 500 doesn't necessarily make sense
      });
      setState('loaded');
      return;
    }
    if (currentRequestId !== id) {
      return;
    }
    if (searchResults.error) {
      setState('loaded');
      setMarkers([]);
      return setError({ code: `${searchResults.error.code}` });
    }
    const markers = getMarkers(searchResults);
    setState('loaded');
    setMarkers(markers);
    callback();
  };

  const geoLocate = () => {
    setError({});
    if (!navigator.geolocation) {
      return;
    }
    setState('loading');
    navigator.geolocation.getCurrentPosition(
      async loc => {
        const { results, status } = await getGoogleGeolocation(loc);
        if (status !== 'OK') {
          setError({
            code: 'geoApiFail'
          });
          setMarkers([]);
        } else if (results.length === 0) {
          setError({
            code: 'geoFail'
          });
        } else {
          let zip = '';
          outer: for (const result of results) {
            for (const addressComponent of result.address_components) {
              if (addressComponent.types[0] === 'postal_code') {
                zip = addressComponent.long_name;
                break outer;
              }
            }
          }
          if (zip) {
            return updateSearchResults(zip);
          }
          setError({
            code: 'geoFail'
          });
        }
        setState('loaded');
      },
      () => {
        setError({
          code: 'geoFail'
        });
        setState('loaded');
        setMarkers([]);
      }
    );
  };

  const reset = () => {
    if (state === 'default') {
      return;
    }
    id++;
    setState('default');
  };
  const selectMarker = (marker: Marker) => {
    setSelectedMarker(marker);
  };
  return {
    markers,
    state,
    reset,
    geoLocate,
    error,
    selectMarker,
    selectedMarker,
    searchValue,
    updateSearchResults
  };
};
