import React, { useState } from 'react';

import Lightbox from '../Lightbox/Lightbox';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';
import YouTubeComponent from '../YouTubeComponent/YouTubeComponent';
import { IMediaTeaser } from './MediaTeaserComponent';

const MediaTeaserCardComponent: React.FC<IMediaTeaser> = ({
  mediaType,
  youtubeId,
  videoLabel,
  image,
  alignment,
  title,
  description,
  buttonType,
  label,
  icon,
  linkTo
}) => {
  const [showYouTubeLightbox, setShowYouTubeLightbox] = useState(false);

  const handleYouTubeClick = () => {
    setShowYouTubeLightbox(true);
  };

  const hasVideo = mediaType && mediaType === 'youtube';

  return (
    <div
      className={`MediaTeaserCardComponent${alignment === 'right' ? ' MediaTeaserCardComponent--reverse' : ''}`}
    >
      <div className={`MediaTeaserCardComponent__imageSide${hasVideo ? ' video' : ''}`}>
        <Image onClick={hasVideo && handleYouTubeClick} src={image} sizes={{ w: 600 }} />
      </div>
      <div className="MediaTeaserCardComponent__textSide">
        <h3 dangerouslySetInnerHTML={{ __html: title }}></h3>
        <div
          className="MediaTeaserCardComponent__content"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>

        <Link linkTo={linkTo}>
          <NewButtonComponent
            icon={icon ? icon : 'arrow-line-right'}
            label={label}
            variant={buttonType === 'button' ? '' : 'light'}
          />
        </Link>

        {hasVideo && (
          <NewButtonComponent
            onClick={handleYouTubeClick}
            icon={'video-play'}
            label={videoLabel}
            variant="light"
          />
        )}

        <Lightbox
          visible={showYouTubeLightbox}
          variants={['video']}
          turnOffHandler={() => setShowYouTubeLightbox(false)}
        >
          <YouTubeComponent id={youtubeId} />
        </Lightbox>
      </div>
    </div>
  );
};

export default MediaTeaserCardComponent;
