import classNames from 'classnames';
import React, { useEffect } from 'react';

import type { Marker } from '../../../hooks/useBrokerSearch';

const formatDistance = (distance: string) => parseFloat(distance).toFixed(1);

function BrokerSearchResults({
  markers,
  selectMarker,
  selectedMarker
}: {
  markers: Marker[];
  selectMarker: (marker: Marker) => void;
  selectedMarker: Marker | undefined;
}) {
  useEffect(() => {
    if (!selectedMarker) {
      return;
    }
    const $Element = document.querySelector(`.tag-broker-search-result-${selectedMarker.id}`);
    if (!$Element) {
      return;
    }
    $Element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [selectedMarker]);
  return (
    <div className="l-broker-search__results m-box m-box--paper">
      <ul>
        {markers.map(marker => (
          <li
            className={classNames(`tag-broker-search-result-${marker.id}`, {
              active: marker === selectedMarker
            })}
            key={marker.id}
            onClick={() => selectMarker(marker)}
          >
            <div>
              <span className="l-broker-search__result-badge">
                <span>{formatDistance(marker.distance)} km</span>
              </span>
              <address>
                <strong>
                  {marker.name3} {marker.name1} {marker.name2}
                </strong>
                {marker.street} <br />
                {marker.zip} {marker.city}
              </address>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default BrokerSearchResults;
