import './GuidebookTeaserComponent.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';

import SiteDataContext from '../../contexts/SiteDataContext';
import useBreakpoint from '../../hooks/useBreakpoint';
import useHorizontalScroll from '../../hooks/useHorizontalScroll';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Container from '../ui/Container/Container';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';
import GuidebookTeaserCardComponent from './GuidebookTeaserCardComponent';

const GuidebookTeaserComponent = props => {
  const { guidebookData = { guidebookTeasers: [], tags: {} } } = useContext(SiteDataContext) || {};
  const { tags, guidebookTeasers: guidebookTeaserData } = guidebookData || {};
  const { ratgeber } = tags || {};
  const ratgeberTags =
    ratgeber && ratgeber.thema && ratgeber.produkt && ratgeber.saison ? ratgeber : null;

  // Filter guidebookTeasers based on props.guidebookTeasers
  const guidebookTeasers = Array.isArray(props.guidebookTeasers)
    ? props.guidebookTeasers
        .map(id => guidebookTeaserData.find(teaser => teaser.id === id))
        .filter(teaser => teaser !== undefined)
    : guidebookTeaserData.slice(0, 10);

  const isMaxVpM = useBreakpoint('m');
  const teaserSection = useRef(null);
  const scrollLeft = useHorizontalScroll(teaserSection);
  const [arrowLeftState, setArrowLeftState] = useState(false);
  const [arrowRightState, setArrowRightState] = useState(true);

  const [cardWidth, setCardWidth] = useState<number>(0);
  const [gapSize, setGapSize] = useState<number>(0);

  const scrollPoints = guidebookTeasers.map((art, index) => index * (cardWidth + gapSize));

  const getScrollPointLeft = () => {
    let scrollAnchor = 0;
    scrollPoints.forEach((anchor, i) => {
      if (scrollLeft > scrollPoints[i - 1] && scrollLeft > scrollPoints[i]) {
        scrollAnchor = scrollPoints[i];
      }
    });
    return scrollAnchor;
  };

  const getScrollPointRight = () => {
    let scrollAnchor = 0;
    scrollPoints.forEach((anchor, i) => {
      if (scrollLeft >= anchor && scrollLeft < scrollPoints[i + 1]) {
        scrollAnchor = scrollPoints[i + 1];
      }
    });
    return scrollAnchor;
  };

  useEffect(() => {
    const gapValue = getComputedStyle(teaserSection.current).getPropertyValue('gap').split('px')[0];
    const firstCardWidth = teaserSection.current?.firstChild.clientWidth;
    setGapSize(parseInt(gapValue));
    setCardWidth(firstCardWidth);

    const minScroll = 0;
    setArrowLeftState(scrollLeft === minScroll ? false : true);

    const maxScroll = teaserSection.current.scrollWidth - teaserSection.current.clientWidth;
    setArrowRightState(scrollLeft === maxScroll ? false : true);
  }, [scrollLeft, teaserSection]);

  const handleScroll = (direction: string) => {
    const stepSize = (gapSize + cardWidth) * 2;
    const scrollPoint =
      direction === 'left' ? getScrollPointLeft() - stepSize : getScrollPointRight() + stepSize;
    teaserSection.current.scrollTo({
      left: scrollPoint,
      behavior: 'smooth'
    });
  };

  return (
    <Container variants={['gray']}>
      <div className="GuidebookTeaserComponent">
        {props?.title ? (
          <h2 dangerouslySetInnerHTML={{ __html: props.title }}></h2>
        ) : (
          <h2>
            Aktuelle Themen, die <strong>uns bewegen</strong>
          </h2>
        )}
        <div className="GuidebookTeaserComponent__teaserSectionWrapper">
          <div className="GuidebookTeaserComponent__teaserSection" ref={teaserSection}>
            {guidebookTeasers.map((article, i) => (
              <Link linkTo={article.path} key={i}>
                <GuidebookTeaserCardComponent article={article} ratgeberTags={ratgeberTags} />
              </Link>
            ))}
            <Link
              linkTo={'/kundenservice/ratgeber'}
              extraClass="GuidebookTeaserComponent__showMoreButton"
            >
              <div className="GuidebookTeaserCardComponent">
                <Icon type="arrow-line-right" />
                <div>Zum Ratgeber</div>
              </div>
            </Link>
          </div>
        </div>
        <div className="GuidebookTeaserComponent__teaserControls">
          <NewButtonComponent
            linkTo={'/kundenservice/ratgeber'}
            label="Zum Ratgeber"
            icon="arrow-line-right"
            variant={isMaxVpM ? 'light' : ''}
          />
          <div className="GuidebookTeaserComponent__arrows">
            <Icon
              type="arrow-line-left"
              onClick={() => handleScroll('left')}
              className={arrowLeftState ? 'GuidebookTeaserComponent__arrows--active' : ''}
            />
            <Icon
              type="arrow-line-right"
              onClick={() => handleScroll('right')}
              className={arrowRightState ? 'GuidebookTeaserComponent__arrows--active' : ''}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default GuidebookTeaserComponent;
