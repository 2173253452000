import React, { createContext, useState } from 'react';

import extractChildren from '../../helpers/extractChildren';
import FaqFooterComponent from '../FaqFooterComponent/FaqFooterComponent';
import Icon from '../ui/Icon/Icon';
import RichText from '../ui/RichText/RichText';

export const FaqContext = createContext(null);

interface IFaqComponentProps {
  children: any;
  showFooter: string;
  variant?: string;
  footerArea: any;
}

const FaqComponent: React.FC<IFaqComponentProps> = ({
  footerArea,
  showFooter,
  variant,
  children
}) => {
  const { questionArea } = extractChildren(children);

  const [question, setQuestion] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [isLightboxVisible, setIsLightboxVisible] = useState(null);
  const isTilesVariant = variant === 'tiles';

  const setLightBoxContent = (question, answer) => {
    setQuestion(question);
    setAnswer(answer);
    isTilesVariant && setIsLightboxVisible(!isLightboxVisible);
  };
  const isFooterVisible = showFooter;

  // ToDo durch die Schritte in der LightBox klicken können
  // const [selected, setSelected] = useState(0);
  // const [selectedElem, setSelectedElem] = useState(
  //   questionArea.props[nodes[0]]
  // );

  // useEffect(() => {
  //   setSelectedElem(questionArea.props[nodes[selected]]);
  //   setQuestion(selectedElem.question);
  //   setAnswer(selectedElem.answer);
  // }, [selected]);

  return (
    <div>
      <div className={`m-${isTilesVariant ? 'content-tiles' : 'accordion'}`}>
        <FaqContext.Provider value={{ variant, setLightBoxContent }}>
          {questionArea}
        </FaqContext.Provider>

        {isLightboxVisible && (
          <article
            className={`tag-faq-question m-content-tiles__tile l-grid__col--4-12 clone m-content-tiles__tile--fullscreen Tile__light-box`}
          >
            <div className="m-content-tiles__tile__details">
              <h4>{question}</h4>
              <RichText richText={answer} />

              <button
                className="m-content-tiles__close-button"
                onClick={() => setIsLightboxVisible(false)}
              >
                <Icon type="close" className="ico-close"></Icon>
              </button>
              {/* ToDo durch die Schritte klicken können (setSelected) & Namen des vorigen & nächsten Schritt dynamisch laden */}
              {/* <div className="m-content-tiles__tile__nav">
                {selected > 0 && (
                  <button
                    className="prev-button m-button m-button--outline-light m-button--icon-left"
                    onClick={() => setSelected(selected - 1)}
                  >
                    <Icon type="arrow-left" className="ico-arrow-left"></Icon>
                    Schritt zurück
                  </button>
                )}
                {selected < nodes.length - 1 && (
                  <button
                    className="next-button m-button m-button--outline-light m-button--icon-right"
                    onClick={() => setSelected(selected + 1)}
                  >
                    Schritt vor
                    <Icon type="arrow-right" className="ico-arrow-right"></Icon>
                  </button>
                )}
              </div> */}
            </div>
          </article>
        )}
      </div>
      {isFooterVisible && <FaqFooterComponent {...footerArea} showFooter={showFooter} />}
    </div>
  );
};

export default FaqComponent;
