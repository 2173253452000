import './Columns.scss';

import React, { CSSProperties, Children } from 'react';

interface ColumnsProps {
  gutter?: TColumnGutter;
  breakpoint?: 's' | 'm' | 'l' | 'xl';
  rtl?: boolean; // right to left
  widths?: number[]; // provide relative numbers or percent string values
  wrap?: boolean;
  nowrap?: boolean;
  flexContent?: boolean | string;
  spaceBetween?: boolean;
  centered?: boolean;
  extraClass?: string;
  children?: any;
  overflowx?: boolean;
}

export type TColumnGutter = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xl--content';

export default ({
  rtl,
  flexContent,
  breakpoint,
  gutter,
  wrap,
  nowrap,
  widths,
  centered,
  extraClass,
  children,
  overflowx
}: ColumnsProps) => {
  const flexContentOptions = ['hcentered', 'vcentered'];
  const classes =
    'Columns' +
    (rtl ? ' Columns--rtl' : '') +
    (wrap ? ' Columns--wrap' : '') +
    (nowrap ? ' Columns--nowrap' : '') +
    (flexContent ? ' Columns--flex-content' : '') +
    (typeof flexContent === 'string'
      ? flexContentOptions.includes(flexContent)
        ? ' Columns--flex-content-' + flexContent
        : ''
      : '') +
    (gutter ? ' Columns--' + gutter : ' Columns--l') +
    (breakpoint ? ' Columns--break-' + breakpoint : ' Columns--break-m') +
    (centered ? ' Columns--centered' : '') +
    (overflowx ? ' Columns--overflowx' : '');

  const columnClass = (index: number): string => {
    let colClass: string;
    const prefix = ' Columns__col--';
    if (widths && Array.isArray(widths)) {
      const sum = widths.reduce((previous, current) => previous + current);
      if (wrap) {
        const w = widths[index % widths.length];
        colClass = w < sum ? prefix + w + '-' + sum : '';
      } else if (index < widths.length) {
        const w = widths[index];
        colClass = w < sum ? prefix + w + '-' + sum : '';
      }
    }
    if (wrap === undefined && children) {
      colClass = prefix + 1 + '-' + children.filter((child: any) => !!child).length;
    }
    if (extraClass) {
      colClass += ' ' + extraClass;
    }
    return colClass ? colClass : '';
  };

  const styles = (index: number): CSSProperties => {
    const elStyles: CSSProperties = {};
    if (rtl) {
      elStyles.order = children.length - index;
    }
    return elStyles;
  };

  return (
    <div className={classes}>
      {children && Children.count(children)
        ? Children.map(
            children,
            (child, index) =>
              child && (
                <div
                  key={'Column' + index}
                  style={styles(index)}
                  className={'Columns__col' + columnClass(index)}
                >
                  {child}
                </div>
              )
          )
        : children}
    </div>
  );
};
