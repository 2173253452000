import './RatgeberTeaserComponent.scss';

import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';
import React from 'react';
import RichText from '../ui/RichText/RichText';
import de from 'date-fns/locale/de';
import { format } from 'date-fns';

interface IRatgeberTeaser {
  image?: any;
  headline: string;
  date?: string;
  text?: string;
  label?: string;
  linkTo?: string;
  flag?: string;
  linkedPage?: any;
  linkType: 'none' | 'page' | 'external';
}

const RatgeberTeaserComponent: React.FC<IRatgeberTeaser> = ({
  image,
  headline,
  date,
  text,
  label,
  linkTo,
  linkType,
  flag,
  linkedPage
}) => {
  const dateText = date
    ? format(new Date(date), 'dd. MMMM yyyy', {
        locale: de
      })
    : linkedPage?.created
      ? format(new Date(linkedPage?.created), 'dd. MMMM yyyy', {
          locale: de
        })
      : '';

  return linkTo && linkedPage ? (
    <div className="RatgeberTeaserComponent">
      <a href={linkedPage?.path} className="RatgeberTeaserComponent__image-wrapper">
        <div
          className={`RatgeberTeaserComponent__flag${
            flag ? '' : ' RatgeberTeaserComponent__flag--base'
          }`}
        >
          {flag || 'Ratgeber'}
        </div>
        {image || linkedPage?.image ? (
          <Image src={image || linkedPage?.image} alt={headline} />
        ) : (
          <div className="RatgeberTeaserComponent__img-placeholder" />
        )}
      </a>
      <div className="RatgeberTeaserComponent__content">
        <div className="RatgeberTeaserComponent__tags">
          <span>
            {dateText}
            {linkedPage?.tags?.length > 0 && ' / '}
          </span>
          {linkedPage?.tags?.map((tag, index) => (
            <>
              {index != 0 && ' / '}
              {tag?.name}
            </>
          ))}
        </div>
        <a href={linkedPage?.path}>
          <h3 className="RatgeberTeaserComponent__headline">{headline || linkedPage?.headline}</h3>
        </a>
        <RichText extraClass="RatgeberTeaserComponent__text" richText={text || linkedPage?.text} />
        {linkTo && (
          <Link
            linkTo={linkTo}
            linkType={linkType}
            label={label || 'Artikel lesen'}
            showIcon
            extraClass="RatgeberTeaserComponent__link"
          />
        )}
      </div>
    </div>
  ) : linkType && !linkedPage ? (
    <div className="RatgeberTeaserComponent">
      <div className="RatgeberTeaserComponent__image-wrapper">
        <div
          className={`RatgeberTeaserComponent__flag${
            flag ? '' : ' RatgeberTeaserComponent__flag--base'
          }`}
        >
          {flag || 'Ratgeber'}
        </div>
        {image || linkedPage?.image ? (
          <Image src={image || linkedPage?.image} alt={headline} />
        ) : (
          <div className="RatgeberTeaserComponent__img-placeholder" />
        )}
      </div>
      <div className="RatgeberTeaserComponent__content">
        <div className="RatgeberTeaserComponent__tags">
          <span>{dateText}</span>
          {linkedPage?.tags?.map((tag, index) => (
            <>
              {index != 0 && ' / '}
              {tag?.name}
            </>
          ))}
        </div>
        <div>
          <h3 className="RatgeberTeaserComponent__headline">{headline || linkedPage?.headline}</h3>
        </div>
        <RichText extraClass="RatgeberTeaserComponent__text" richText={text || linkedPage?.text} />
        {linkTo != 'none' && (
          <Link
            linkTo={linkTo}
            linkType={linkType}
            label={label}
            showIcon
            extraClass="RatgeberTeaserComponent__link"
          />
        )}
      </div>
    </div>
  ) : null;
};

export default RatgeberTeaserComponent;
