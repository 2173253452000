import React from 'react';

interface HeadlineComponentProps {
  text: string;
}

const HeadlineComponent: React.FC<HeadlineComponentProps> = ({ text }) => {
  return <legend>{text}</legend>;
};

export default HeadlineComponent;
