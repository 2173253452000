import React from 'react';

import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import RichText from '../../components/ui/RichText/RichText';
import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';

interface ILandingPageStageArea {
  children: any;
  backgroundImage: any;
  signet: any;
  benefits: string[];
  personImage: any;
  hasCalc: boolean;
  personImageLong: any;
  headline: string;
  subHeadline: string;
  text: string;
}

const LandingPageStageArea: React.FC<ILandingPageStageArea> = props => {
  const {
    children,
    backgroundImage,
    signet,
    benefits,
    personImage,
    hasCalc,
    personImageLong,
    headline,
    subHeadline,
    text
  } = props;
  const benefitsList = benefits || mapIndexedNodeProps(props, 'benefits');
  const templateName = ' l-content__header--landing';
  const className =
    'l-content__header' +
    templateName +
    ((signet ? templateName + '-signet' : '') +
      (backgroundImage
        ? templateName + '-dark' + templateName + '-left'
        : signet || benefitsList
          ? templateName + '-left'
          : !signet && !benefitsList
            ? templateName + '-centered'
            : templateName + '-pri') +
      (hasCalc ? templateName + '-calculator' : '') +
      (personImage ? templateName + '-til' : ''));

  const content = (
    <div className="l-content__header-content--landing">
      {!!backgroundImage && !!personImage && (
        <Image src={personImage} extraClass="l-content__header-til" sizes={{ w: 400 }} />
      )}
      {!!personImageLong && (
        <Image src={personImageLong} extraClass="l-content__header-til-mobile" sizes={{ w: 400 }} />
      )}
      <h1>{headline}</h1>
      {subHeadline && <h2>{subHeadline}</h2>}
      {text && <RichText richText={text} />}
      {benefitsList && (
        <ul>
          {benefitsList.map((benefit, index) => (
            <li key={index}>
              <Icon type="check" className="ico-check"></Icon>
              {benefit.description}
            </li>
          ))}
        </ul>
      )}
      {!!signet && <Image src={signet} extraClass="l-content__header-signet" sizes={{ w: 300 }} />}
      {children}
    </div>
  );

  return (
    <>
      {backgroundImage ? (
        <header>
          <Image extraClass={className} isBackgroundImage src={backgroundImage} sizes={{ w: 2000 }}>
            {content}
          </Image>
        </header>
      ) : (
        <header className={className}>{content}</header>
      )}
    </>
  );
};

export default LandingPageStageArea;
