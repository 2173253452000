import React from 'react';

import Icon from '../Icon/Icon';

interface FormErrorMessageProps {
  errorMessage: string;
}

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({ errorMessage }) => {
  return (
    <div
      className="m-form__validation-message m-form__validation-message--75"
      ng-show="(form.$submitted && form.${name}.$invalid)"
    >
      <div>
        <Icon type="warning" className="ico-warning"></Icon>
        <div>{errorMessage}</div>
      </div>
    </div>
  );
};

export default FormErrorMessage;
