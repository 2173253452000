import React, { useContext } from 'react';

import BrokerSearchContext from '../../../contexts/BrokerSearchContext';
import InfoBox from '../InfoBox/InfoBox';
import useCookieByKey from '../../../hooks/useCookieByKey';

const CookieBox = () => {
  const onetrustActiveGroups = useCookieByKey('onetrust_active_groups', true);

  const isVisible = !onetrustActiveGroups.includes('C0001,C0002,C0004');

  const { setIsBrokerSearchModalVisible } = useContext(BrokerSearchContext) || {};

  return (
    <div>
      <InfoBox
        title={'Wir benötigen Ihre Einwilligung'}
        text={
          'Bitte passen Sie Ihre Cookie-Einstellungen an, um alle Funktionen der Website nutzen zu können.'
        }
        key="cookieSettings"
        linkProps={{
          extraClass: 'm-button',
          linkTo: 'cookieOverlay',
          linkType: 'internal_app',
          label: 'Cookie Einstellungen'
        }}
        arrowLinkProps={{
          linkTo: '/',
          linkType: 'page',
          label: 'Zur Startseite',
          onClick: () => setIsBrokerSearchModalVisible(false)
        }}
        icon="check"
        show={isVisible}
      />
    </div>
  );
};
export default CookieBox;
