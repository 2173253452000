import React, { createContext } from 'react';

import enrichBrokerNumber from '../helpers/enrichBrokerNumber';
import extractChildren from '../helpers/extractChildren';
import useBroker from '../hooks/useBroker';

export const BusinessCardPageContext = createContext(null);

const BusinessCardPage = ({ stageImage, children, brokerNumber, pageScope }) => {
  const {
    headerArea,
    stageArea,
    introTextArea,
    contactArea,
    mapArea,
    topProductsArea,
    contentArea,
    footerContactArea,
    footerArea
  } = extractChildren(children);

  const broker = useBroker(enrichBrokerNumber(brokerNumber), {
    api: 'sales-representatives'
  });

  return (
    <BusinessCardPageContext.Provider
      value={{
        stageImage,
        broker
      }}
    >
      {headerArea}
      <main className="l-content l-content--corporate">
        {stageArea}
        {introTextArea}
        {contactArea}
        {mapArea}
        {contentArea}
      </main>
      {pageScope !== 'expert' && topProductsArea}
      {footerContactArea}
      {footerArea}
    </BusinessCardPageContext.Provider>
  );
};

export default BusinessCardPage;
