import React from 'react';

import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';
import Link from '../ui/Link/Link';
import MultiButton from '../ui/MultiButton/MultiButton';
import RichText from '../ui/RichText/RichText';

const BoxComponent = props => {
  const { headline, text, label, buttonVariant } = props || {};
  const links = mapIndexedNodeProps(props, 'links');
  return (
    <div className="m-box">
      <h3>{headline}</h3>
      <RichText richText={text} />

      {links?.length > 0 && (
        <div className="spacer-top-small">
          {links?.length === 1 ? (
            <Link showIcon {...links[0]} label={label} extraClass="m-button" />
          ) : (
            <MultiButton buttonVariant={buttonVariant} label={label} links={links} />
          )}
        </div>
      )}
    </div>
  );
};
export default BoxComponent;
