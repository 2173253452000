import React, { FormEvent, useContext } from 'react';

import BrokerSearchContext from '../../contexts/BrokerSearchContext';
import Icon from '../ui/Icon/Icon';
import RichText from '../ui/RichText/RichText';

// import { navigate } from 'gatsby-link';

const BrokerSearchComponent = ({ leftText, rightText }) => {
  const { setInputPLZ, inputPLZ } = useContext(BrokerSearchContext) || {};
  const path = `/maklersuche?plz=${inputPLZ}`;
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    // navigate(path);
    if (typeof window !== 'undefined') {
      window.location.href = 'https://www.vhv.de/' + path;
    }
  };
  return (
    <>
      <div className="m-broker-search-teaser">
        <RichText extraClass="m-broker-search-teaser__info" richText={leftText} />
        <div className="m-broker-search-teaser__form">
          <RichText richText={rightText} />
          <form onSubmit={handleSubmit} role="search">
            <div className="m-search-field">
              <input
                type="number"
                inputMode="numeric"
                placeholder="Ihre Postleitzahl…"
                onChange={e => {
                  setInputPLZ(e.target.value);
                }}
                onSubmit={handleSubmit}
              />
              <Icon type="location" className="ico-location"></Icon>
              <a href={path} className="ng-isolate-scope">
                <Icon type="arrow-right" className="ico-arrow-right"></Icon>
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default BrokerSearchComponent;
