import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import RichText from '../../components/ui/RichText/RichText';
import { EventPageContext } from '../../containers/EventPage';

const DescriptionArea = () => {
  const { organizerImage, agendaDownload, description } = useContext(EventPageContext) || {};

  return (
    <div className="l-content__section">
      <div>
        <div className="m-box text-left">
          <Columns widths={[2, 1]}>
            <div>
              <h4>Informationen zur Veranstaltung</h4>
              <RichText richText={description} />
            </div>
            {(organizerImage || agendaDownload) && (
              <div>
                {organizerImage && (
                  <div className="spacer">
                    <h5>Veranstalter</h5>
                    <Image src={organizerImage} />
                  </div>
                )}
                {agendaDownload && (
                  <Link
                    linkTo={agendaDownload}
                    linkType="asset"
                    extraClass="m-button m-button--full-width"
                    label="Agenda herunterladen"
                  />
                )}
              </div>
            )}
          </Columns>
        </div>
      </div>
    </div>
  );
};

export default DescriptionArea;
