import React from 'react';

import RichText from '../RichText/RichText';

interface TextComponentProps {
  text: string;
}

const TextComponent: React.FC<TextComponentProps> = ({ text }) => {
  return <RichText style={{ textAlign: 'left' }} richText={text} />;
};

export default TextComponent;
