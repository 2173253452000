import React from 'react';

import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

const SmallCallToActionComponent = ({ rightButtonIcon, rightButtonLabel, linkTo, linkType }) => {
  return (
    <Link linkTo={linkTo} linkType={linkType} extraClass="m-button ga-cta-bottom">
      {rightButtonIcon && <Icon type={rightButtonIcon} className={'ico-' + rightButtonIcon}></Icon>}
      <span className="ga-cta-bottom">{rightButtonLabel}</span>
    </Link>
  );
};

export default SmallCallToActionComponent;
