import React, { useContext } from 'react';

import contactData from '../../components/ui/ContactInformation/contactData';
import ContactInformationContext from '../../contexts/ContactInformationContext';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import SiteDataContext from '../../contexts/SiteDataContext';

const LossArea = ({ isDamageVariant = false }) => {
  const { pageScope } = useContext(SiteDataContext) || {};
  const { getProductGroup, productGroups = {} } = useContext(ProductGroupsContext) || {};
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const productsRaw = productGroups[pageScope] || {};

  const relevantProductKeys = (contactData[pageScope] || contactData['private']).flatMap(
    prodContact => prodContact?.activeProductSelectors
  );

  const products = {};

  relevantProductKeys.forEach(key => (products[key] = productsRaw[key]));

  return (
    <>
      <section className="l-content__section">
        <div>
          <h2>Bitte die Versicherung wählen</h2>
          <nav className="m-contact-selection">
            {Object.keys(products)
              .filter(
                key =>
                  products[key] && products[key].name && getProductGroup('name', products[key].name)
              )
              .map((key, _, arr) => {
                const { title, icon, name } = products[key];

                const activeProduct = getProductGroup('name', name);

                return (
                  <a
                    key={key}
                    className={`m-contact-selection__tile m-contact-selection__tile--count${arr.length} m-contact-selection__tile--static`}
                    onClick={() =>
                      setIsContactInformationModalVisible(true, {
                        isDamageVariant,
                        isActiveProductVariant: true,
                        activeProduct
                      })
                    }
                  >
                    <div>
                      <div className="m-contact-selection__tile__front">
                        <i className={`ico-${icon}`} />
                        <h4>{title}</h4>
                      </div>
                    </div>
                  </a>
                );
              })}
          </nav>
        </div>
      </section>
    </>
  );
};

export default LossArea;
