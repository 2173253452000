const normalizeIvmnr = (ivmnr): string => {
  if (!ivmnr || ivmnr.charAt(ivmnr.length - 3) === '-') {
    return ivmnr;
  }

  const sanitizedIvmnr = ivmnr.replace('-', '');

  const normalizedIvmnr =
    sanitizedIvmnr.substring(0, sanitizedIvmnr.length - 3) +
    '-' +
    sanitizedIvmnr.substring(sanitizedIvmnr.length - 3);

  return normalizedIvmnr;
};

export default normalizeIvmnr;
