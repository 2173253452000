import React from 'react';

import Columns from '../../components/ui/Columns/Columns';

const SignetArea = ({ children }) => {
  return children ? (
    <Columns gutter="xs" extraClass="l-footer__certificates">
      {children}
    </Columns>
  ) : null;
};

export default SignetArea;
