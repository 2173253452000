import './InsuranceAddonsComponent.scss';

import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import Cubes from '../../styles/assets/images/static-vhv-bausteine-cubes.png';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import Container from '../ui/Container/Container';
import FadingCollapseBox from '../ui/FadingCollapseBox/FadingCollapseBox';
import Image from '../ui/Image/Image';

export interface IInsuranceAddon {
  headline: string;
  text: string;
}

interface IInsuranceAddonsFromMagnolia {
  title: string;
  description: string;
}

const mapAddons = (addons: IInsuranceAddonsFromMagnolia): IInsuranceAddon[] => {
  return Object.values(addons)
    .filter(addon => addon.title || addon.description)
    .map(addon => ({
      headline: addon.title,
      text: addon.description
    }));
};

const InsuranceAddonsComponent = ({ title, description, insuranceAddons }) => {
  if (!insuranceAddons) return null;

  const [limitAddons, setLimitAddons] = useState(true);
  const initialDisplayedAddons = 5;

  const allAddons: IInsuranceAddon[] = mapAddons(insuranceAddons);
  const staticAddons = allAddons.slice(0, initialDisplayedAddons);
  const dynamicAddons = allAddons.slice(initialDisplayedAddons);

  return (
    <Container variants={['gray']}>
      <div className="InsuranceAddons">
        <div className="InsuranceAddons__textSection">
          <h2 className="InsuranceAddons__header" dangerouslySetInnerHTML={{ __html: title }} />
          <div
            className="InsuranceAddons__subline"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className="InsuranceAddons__addonWrapper">
          {staticAddons.map((addon, i) => {
            return <FadingCollapseBox key={i} data={addon} />;
          })}
          {dynamicAddons.map((addon, i) => {
            return (
              <AnimateHeight
                delay={i * 100}
                key={i}
                animateOpacity
                duration={200}
                height={limitAddons ? 0 : 'auto'}
              >
                <FadingCollapseBox trigger={limitAddons} data={addon} />
              </AnimateHeight>
            );
          })}
          {allAddons?.length > initialDisplayedAddons && (
            <NewButtonComponent
              onClick={() => setLimitAddons(prev => !prev)}
              label={limitAddons ? 'Alle Zusatzbausteine anzeigen' : 'verbergen'}
              icon="plus"
              variant="light"
              extraClass={`InsuranceAddons__showAllButton ${!limitAddons ? 'clicked' : ''}`}
            />
          )}
        </div>
        <Image src={Cubes} />
      </div>
    </Container>
  );
};

export default InsuranceAddonsComponent;
