import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import React from 'react';
import RichText from '../../components/ui/RichText/RichText';
import proofBoolean from '../../helpers/proofBoolean';

const BenefitArea = ({
  richText,
  backgroundImage,
  signetImage,
  headline,
  onTelematicsPage,
  linkType,
  linkTo,
  signetCaption,
  children,
  show,
  parentTemplate
}) => {
  const showArea =
    show !== undefined
      ? proofBoolean(show)
      : parentTemplate === 'ProductPage' ||
        parentTemplate === 'CorporateProductPage' ||
        parentTemplate === 'ExpertSolutionPage' ||
        parentTemplate === 'LandingPage';

  return showArea ? (
    <section className="l-content__section">
      <div className="l-benefit-placement">
        {richText && (
          <RichText
            extraClass={`l-richtext${backgroundImage ? ' l-richtext--with-image' : ''}`}
            richText={richText}
          />
        )}

        <div
          className={`l-benefit-placement__list${
            !backgroundImage && !signetImage ? ' l-benefit-placement__list--wide' : ''
          }`}
        >
          <div>
            <h3 className="l-benefit-placement__list__headline">{headline}</h3>

            <ol className="m-list m-list--ordered">{children}</ol>
          </div>

          {backgroundImage ? (
            <>
              <Image
                src={backgroundImage}
                alt={backgroundImage.name}
                isBackgroundImage
                extraClass="l-benefit-placement__image"
                sizes={{ w: 300 }}
              />
              {/* Image als children herausgenommen. Doppelt ?*/}
              {signetImage && (
                <Link linkTo={linkTo} linkType={linkType}>
                  <Image
                    extraClass="l-benefit-placement__signet"
                    src={signetImage}
                    alt={signetImage.name}
                    sizes={{ w: 300 }}
                  />
                </Link>
              )}
            </>
          ) : signetImage ? (
            <aside
              className={`l-benefit-placement__sidebar${
                onTelematicsPage ? '' : ' l-benefit-placement__sidebar--border'
              }`}
            >
              <div>
                {linkTo ? (
                  <Link linkTo={linkTo} linkType={linkType}>
                    <Image src={signetImage} alt={signetImage.name} sizes={{ w: 300 }} />
                  </Link>
                ) : (
                  <Image src={signetImage} alt={signetImage.name} sizes={{ w: 300 }} />
                )}
                {signetCaption && <p>{signetCaption}</p>}
              </div>
            </aside>
          ) : null}
        </div>
      </div>
    </section>
  ) : null;
};

export default BenefitArea;
