import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';

import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import SiteDataContext from '../../contexts/SiteDataContext';
import getFunctionUrl from '../../helpers/getFunctionUrl';

const DirectoryArea = () => {
  const [experts, setExperts] = useState<any[]>([]);
  const [foundExpert, setFoundExpert] = useState(null);

  const { businessCardReferences } = useContext(SiteDataContext);

  const alphabet = Array.from(Array(26)).map((_, i) => String.fromCharCode(i + 97));

  useEffect(() => {
    axios
      .get(`${getFunctionUrl('sales-representatives')}/`)
      .then(res => {
        if (res.data && Array.isArray(res.data)) {
          setExperts(res.data);
        }
      })
      // eslint-disable-next-line no-console
      .catch(err => console.log(err));
  }, []);

  const getExpertTile = (firstName, lastName, image) => {
    return (
      <>
        <div className="m-orga-tile m-orga-tile--link">
          {image ? (
            <Image src={image} extraClass="m-orga-tile__front" />
          ) : (
            <Icon type="avatar" size="100%" className="m-orga-tile__front" />
          )}

          <div className="m-orga-tile__back">Zum Online-Profil</div>
        </div>
        <div className="m-orga-tile__subline">
          {lastName}, {firstName}
        </div>
      </>
    );
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onInput = event => {
    event.preventDefault();
    const plz = event.target.value;

    if (plz.length === 5) {
      findExpert(plz);
    } else {
      setFoundExpert(null);
    }
  };

  const findExpert = (plz: string) => {
    setIsLoading(true);
    axios
      .get(`${getFunctionUrl('sales-representatives')}/${plz}`)
      .then((res: any) => {
        setIsLoading(false);
        if (res.data) {
          setFoundExpert(res.data);
        }
      })
      .catch((reason: any) => {
        if (reason && reason.response && reason.response.status) {
          // eslint-disable-next-line no-console
          console.log(reason.response.status);
        }
      });
  };

  return (
    <section className="l-content__section">
      <div>
        <div className="m-orga-tile__nav">
          {alphabet.map((letter, key) => {
            const hasExpert = experts?.find(expert =>
              expert?.lastName?.toLowerCase().startsWith(letter)
            );
            return hasExpert ? (
              <a href={`#letter-${letter}`}>{letter.toUpperCase()}</a>
            ) : (
              <span key={key}>{letter.toUpperCase()}</span>
            );
          })}
        </div>
        <div className="m-orga-tile__wrapper">
          <div className="m-orga-tile__item m-orga-tile__item--s m-orga-tile__item--subline m-orga-tile__item--zipfilter">
            <div>
              <div className="m-orga-tile">
                <label id="filterZip" className="m-orga-tile__zip-filter-label">
                  <span>
                    {isLoading ? (
                      <Icon type="spinner" className="ico-spinner" size="12px" />
                    ) : (
                      <Icon type="search" className="ico-search" size="11px" />
                    )}{' '}
                    Suchen nach
                  </span>
                </label>
                <input
                  type="text"
                  maxLength={5}
                  name="filterZip"
                  id="filterZip"
                  className="m-orga-tile__zip-filter"
                  onInput={onInput}
                  placeholder="PLZ"
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
          {alphabet.map((letter, key) => {
            const filteredContact = foundExpert
              ? experts.filter(expert => {
                  return (
                    expert?.lastName?.toLowerCase().startsWith(letter) &&
                    foundExpert.brokerNumber === expert?.brokerNumber
                  );
                })
              : experts.filter(expert => {
                  return expert?.lastName?.toLowerCase().startsWith(letter);
                });

            return experts.length > 0 ? (
              <>
                {filteredContact.length > 0 && (
                  <div
                    className="m-orga-tile__item m-orga-tile__item--s m-orga-tile__item--subline js-zip-filter"
                    key={key}
                    id={`letter-${letter}`}
                  >
                    <div>
                      <div className="m-orga-tile__plain">
                        <div>
                          <span className="m-orga-tile__letter">{letter.toUpperCase()}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {filteredContact.map((contact, key) => {
                  const { firstName, lastName, brokerNumber, photoUrl } = contact || {};

                  return (
                    <div
                      className="m-orga-tile__item m-orga-tile__item--s m-orga-tile__item--subline js-zip-filter"
                      key={key}
                    >
                      {businessCardReferences[brokerNumber] ? (
                        <Link linkTo={businessCardReferences[brokerNumber]}>
                          {getExpertTile(firstName, lastName, photoUrl)}
                        </Link>
                      ) : (
                        getExpertTile(firstName, lastName, photoUrl)
                      )}
                    </div>
                  );
                })}
              </>
            ) : null;
          })}
        </div>
      </div>
    </section>
  );
};

export default DirectoryArea;
