import './UspComponent.scss';

import React from 'react';

import Container from '../ui/Container/Container';
import UspTileComponent from './UspTileComponent';

interface IUsp {
  title: string;
  description: string;
  icon: any;
}
const UspComponent = ({
  title,
  description,
  uniqueSellingPoints
}: {
  title: string;
  description: string;
  uniqueSellingPoints: IUsp[];
}) => {
  const usps = uniqueSellingPoints
    ? Object.values(uniqueSellingPoints)
        .filter(uniqueSellingPoint => uniqueSellingPoint.title)
        .map(uniqueSellingPoint => {
          return {
            title: uniqueSellingPoint.title,
            description: uniqueSellingPoint.description,
            icon: uniqueSellingPoint.icon
          };
        })
    : null;

  return (
    <Container>
      <div className="UspComponent">
        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        {description && <p>{description}</p>}
        {usps && (
          <div className="UspComponent__TileSection">
            {usps.map((tile, i) => (
              <UspTileComponent key={i} data={tile} />
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default UspComponent;
