import { useEffect } from 'react';

const useOutsideClick = (ref, callback: () => void, condition?: boolean, additionalRef?) => {
  const isTargetInsideOnetrustConsentSDK = target => {
    while (target) {
      if (target.id === 'onetrust-consent-sdk') {
        return true;
      }
      target = target.parentNode;
    }
    return false;
  };

  const onOutsideClick = event => {
    if (isTargetInsideOnetrustConsentSDK(event.target)) {
      return; // Check whether the click event took place within the 'onetrust-consent-sdk' element
    }
    const conditionalCallback = () => {
      if (additionalRef) {
        if (
          additionalRef.current &&
          !additionalRef.current.contains(event.target) &&
          ref.current &&
          !ref.current.contains(event.target)
        ) {
          callback();
        }
      } else {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      }
    };

    if (typeof condition !== 'undefined') {
      if (condition) {
        conditionalCallback();
      }
    } else {
      conditionalCallback();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', onOutsideClick);
    return () => {
      document.removeEventListener('mousedown', onOutsideClick);
    };
  });
};

export default useOutsideClick;
