import { addYears, format, parse } from 'date-fns';
import React, { useEffect } from 'react';
// import PikaDay from 'pikaday';

export const usePikaday = (
  containerRef: React.RefObject<HTMLElement>,
  fieldRef: React.RefObject<HTMLElement>,
  triggerRef: React.RefObject<HTMLElement>
) => {
  useEffect(() => {
    const disposables: (() => void)[] = [];
    const createPicker = async () => {
      const { default: PikaDay } = await import('pikaday');
      const container = containerRef.current;
      const field = fieldRef.current;
      const trigger = triggerRef.current;
      if (!container || !field || !trigger) {
        return;
      }

      let minDate = undefined;
      const minYear = container.dataset.minYear;
      if (minYear) {
        const diff = parseInt(minYear);
        if (isNaN(diff)) {
          console.warn('invalid minYear option', minYear);
          return;
        }
        minDate = addYears(new Date(), diff);
      }
      let maxDate = undefined;
      const maxYear = container.dataset.maxYear;
      if (maxYear) {
        const diff = parseInt(maxYear);
        if (isNaN(diff)) {
          console.warn('invalid maxYear option', maxYear);
          return;
        }
        maxDate = addYears(new Date(), diff);
      }
      let yearRange = undefined;
      if (minDate && maxDate) {
        yearRange = [minDate.getFullYear(), maxDate.getFullYear()];
      }

      const picker = new PikaDay({
        bound: false,
        container,
        field,
        trigger,
        firstDay: 1,
        toString(date) {
          field.focus();
          return format(date, 'dd.MM.yyyy');
        },
        parse(dateString) {
          return parse(dateString, 'dd.MM.yyyy', new Date());
        },
        i18n: {
          previousMonth: 'Vorheriger Montat',
          nextMonth: 'Nächster Monat',
          months: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
          ],
          weekdays: [
            'Sonntag',
            'Montat',
            'Dienstag',
            'Mittwoch',
            'Donnerstag',
            'Freitag',
            'Samstag'
          ],
          weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
        },
        theme: 'm-pikaday',
        minDate,
        maxDate,
        yearRange
      });

      picker.hide();

      const windowHandleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          hide();
        }
      };

      const hide = () => {
        picker.hide();
        window.removeEventListener('keydown', windowHandleKeyDown);
      };

      const bodyHandleMousedown = (event: MouseEvent) => {
        const target = event.target;
        if (
          //@ts-ignore
          trigger.contains(target) ||
          //@ts-ignore
          (!target.classList.contains('pika-day') &&
            //@ts-ignore
            target.closest('.pika-lendar'))
        ) {
          return;
        }
        hide();
      };

      const show = () => {
        picker.show();
        picker.adjustPosition();
        window.addEventListener('keydown', windowHandleKeyDown);
      };

      const triggerHandleClick = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        if (picker.isVisible()) {
          hide();
        } else {
          show();
        }
      };
      trigger.addEventListener('click', triggerHandleClick);
      document.body.addEventListener('mousedown', bodyHandleMousedown);

      disposables.push(() => {
        trigger.removeEventListener('click', triggerHandleClick);
        window.removeEventListener('keydown', windowHandleKeyDown);
        document.body.removeEventListener('mousedown', bodyHandleMousedown);
      });
    };

    createPicker();
    return () => {
      for (const dispose of disposables) {
        dispose();
      }
    };
  }, []);
};
