import classNames from 'classnames';
import React, { useContext } from 'react';

import ContactBarContext from '../../../contexts/ContactBarContext';
import ContactInformationContext from '../../../contexts/ContactInformationContext';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';

export interface IContactBar {
  variant?: 'light' | 'gray' | 'dark';
}

const ContactBar: React.FC<IContactBar> = ({ variant }) => {
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const { variant: contextVariant } = useContext(ContactBarContext) || {};
  const contactBarVariant = variant || contextVariant;

  return (
    <nav
      className={classNames(
        'm-contact-bar',
        { 'm-contact-bar--light': contactBarVariant === 'light' },
        { 'm-contact-bar--gray': contactBarVariant === 'gray' }
      )}
    >
      <div>
        <ul>
          <li
            // fullscreen-overlay-app-trigger="contactInformation"
            // fullscreen-overlay-args="{ scope: 'private', informationType: 'damage' }"
            className="tag-service m-contact-bar__mobile-only"
          >
            <button
              onClick={() => {
                setIsContactInformationModalVisible(true, {
                  isDamageVariant: true
                });
              }}
            >
              <Icon type="warning" className="ico-warning"></Icon> Schaden melden
            </button>
          </li>
          <li
            // fullscreen-overlay-app-trigger="contactInformation"
            // fullscreen-overlay-args="{ scope: 'private' }"
            className="tag-contact-app"
          >
            <button
              onClick={() => {
                setIsContactInformationModalVisible(true);
              }}
            >
              <Icon type="phone" className="ico-phone"></Icon> Rufen Sie uns an
            </button>
          </li>
          <li
            // fullscreen-overlay-link="${generalContactPath}"
            className="tag-contact"
          >
            <Link linkTo="/kontaktformular/email-anfrage">
              <Icon type="mail" className="ico-mail"></Icon> Schreiben Sie uns
            </Link>
          </li>
          <li
            // fullscreen-overlay-app-trigger="contactInformation"
            // fullscreen-overlay-args="{ scope: 'private', informationType: 'damage' }"
            className="tag-service m-contact-bar__desktop-only"
          >
            <button
              onClick={() => {
                setIsContactInformationModalVisible(true, {
                  isDamageVariant: true
                });
              }}
            >
              <Icon type="warning" className="ico-warning"></Icon> Schaden melden
            </button>
          </li>
          <li
            // fullscreen-overlay-app-trigger="brokerSearch"
            // // fullscreen-overlay-className="l-fullscreen-overlay--hidden-overflow"
            className="tag-broker-search"
          >
            <a href="/maklersuche" className="Link">
              <Icon type="location" className="ico-location"></Icon> Vermittler-Suche
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default ContactBar;
