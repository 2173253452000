import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import FormErrorMessage from './FormErrorMessage';
import FormTooltip from './FormTooltip';

interface LocalityFieldComponentProps {
  label: string;
  optional?: string;
  name: string;
  errorMessage?: string;
  tooltipText?: string;
}

const LocalityFieldComponent: React.FC<LocalityFieldComponentProps> = ({
  name,
  label,
  optional,
  tooltipText,
  errorMessage
}) => {
  const {
    register,
    unregister,
    formState: { errors, dirtyFields }
  } = useFormContext();
  // const hasError = errors[`${name}City`] || errors[`${name}Zip`];
  useEffect(() => {
    return () => {
      unregister(`${name}City`);
      unregister(`${name}Zip`);
    };
  }, []);

  const getActualErrorMessage = () => {
    if (errors[`${name}City`]) {
      if (errors[`${name}City`].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie eine korrekte Stadt ein';
    }
    if (errors[`${name}Zip`]) {
      if (errors[`${name}Zip`].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie eine korrekte Postleitzahl ein';
    }
    return undefined;
  };

  const actualErrorMessage = getActualErrorMessage();

  return (
    <>
      <div className="m-form__group">
        <label>
          {label} {optional && <em>optional</em>}
        </label>
        <div className="m-form__group-fields">
          <div className="l-grid">
            <div className="l-grid__col--4-12 l-grid__col--keep-width">
              <input
                type="text"
                inputMode="numeric"
                id={`${name}Zip`}
                {...register(`${name}Zip`, {
                  maxLength: 5,
                  required: !optional,
                  pattern: /[\d]{5}/
                })}
                className={classNames({
                  'ng-invalid': errors[`${name}Zip`],
                  'ng-touched': dirtyFields[`${name}Zip`]
                })}
              />
            </div>
            <div className="l-grid__col--8-12 l-grid__col--keep-width">
              <input
                type="text"
                id={`${name}City`}
                {...register(`${name}City`, {
                  required: !optional
                })}
                className={classNames({
                  'ng-invalid': errors[`${name}City`],
                  'ng-touched': dirtyFields[`${name}City`]
                })}
              />
            </div>
          </div>
          {tooltipText && <FormTooltip label={label} name={name} tooltipText={tooltipText} />}
        </div>
      </div>
      {actualErrorMessage && <FormErrorMessage errorMessage={actualErrorMessage} />}
    </>
  );
};

export default LocalityFieldComponent;
