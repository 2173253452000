import React, { useContext, useEffect } from 'react';

import BrokerSearchContext from '../contexts/BrokerSearchContext';

const BrokerSearchPage = () => {
  const { setIsBrokerSearchModalVisible, isBrokerSearchModalVisible } =
    useContext(BrokerSearchContext) || {};

  useEffect(() => {
    !isBrokerSearchModalVisible && setIsBrokerSearchModalVisible(true);
  }, []);

  return <div className="BrokerSearchPage"></div>;
};

export default BrokerSearchPage;
