import React from 'react';

import getCalculatorPath from '../../helpers/getCalculatorPath';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

const CallToActionComponent = ({ linkTo, linkType, useIcon, icon, label }) => {
  return linkTo ? (
    <div className="l-content__header__button-group">
      <Link {...getCalculatorPath(linkTo, linkType)} extraClass="m-button">
        {useIcon && icon && <Icon type={icon} className={`ico-${icon}`}></Icon>}
        {label}
      </Link>
    </div>
  ) : null;
};
export default CallToActionComponent;
