import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const HomePage = ({ children }) => {
  const {
    headerArea,
    stageArea,
    productArea,
    contentArea,
    teaserArea,
    signetArea,
    footerColumnsArea,
    footerLinkArea
  } = extractChildren(children);

  return (
    <>
      {headerArea}
      {stageArea}
      <main className="l-content l-content--home">
        {productArea}
        {contentArea}
        {teaserArea}
      </main>
      <Footer
        signetArea={signetArea}
        footerColumnsArea={footerColumnsArea}
        footerLinkArea={footerLinkArea}
      />
    </>
  );
};

export default HomePage;
