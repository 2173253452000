import React, { useContext, useReducer } from 'react';

import ContactInformationContext from '../../../contexts/ContactInformationContext';
import SiteDataContext from '../../../contexts/SiteDataContext';
import FullscreenOverlaySlider from '../FullscreenOverlaySlider/FullscreenOverlaySlider';
import contactData from './contactData';
import ContactInformationSlide from './ContactInformationSlide';

interface IContactInformationSlidesProps {
  activeProduct?: any;
}

const ContactInformationSlides: React.FC<IContactInformationSlidesProps> = ({ activeProduct }) => {
  const productSlidesReducer = (selectedProducts, action) => {
    const { value, type } = action;

    switch (type) {
      case 'push':
        return !selectedProducts.includes(value) ? [...selectedProducts, value] : selectedProducts;
      case 'pop':
        return selectedProducts.length !== 0
          ? selectedProducts.slice(0, selectedProducts.length - 1)
          : selectedProducts;
    }
  };

  const { pageScope } = useContext(SiteDataContext) || {};

  const products = contactData[pageScope] || contactData['private'];

  const [selectedProducts, dispatchSelectedProducts] = useReducer(
    productSlidesReducer,
    activeProduct ? [activeProduct] : []
  );

  const { isDamageVariant } = useContext(ContactInformationContext) || {};

  return (
    <FullscreenOverlaySlider
      onBackClick={() => {
        setTimeout(() => dispatchSelectedProducts({ type: 'pop' }), 500);
      }}
    >
      {!activeProduct && (
        <ContactInformationSlide
          title="Bitte Versicherung wählen"
          products={products}
          pushProductSlide={selection => {
            dispatchSelectedProducts({ type: 'push', value: selection });
          }}
        />
      )}
      {selectedProducts.map((selectedProduct, index) => {
        const {
          subProducts,
          damageProducts,
          clientTypes: scopedClientTypes
        } = selectedProduct || {};

        return subProducts ? (
          <ContactInformationSlide
            title="Bitte ein Produkt auswählen"
            products={subProducts}
            pushProductSlide={selection => {
              dispatchSelectedProducts({ type: 'push', value: selection });
            }}
            key={index}
          />
        ) : isDamageVariant && damageProducts ? (
          <ContactInformationSlide
            title="Bitte wählen Sie Ihren Schaden"
            products={damageProducts}
            pushProductSlide={selection => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { name, title, icon, damageProducts, ...rest } = selectedProduct || {};
              dispatchSelectedProducts({
                type: 'push',
                value: {
                  ...selection,
                  ...rest
                }
              });
            }}
            key={index}
          />
        ) : !isDamageVariant && scopedClientTypes ? (
          <ContactInformationSlide
            title="Sind Sie Neu- oder Bestandskunde?"
            products={scopedClientTypes}
            pushProductSlide={selection => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { name, title, icon, clientTypes, ...rest } = selectedProduct || {};
              dispatchSelectedProducts({
                type: 'push',
                value: { ...selection, ...rest }
              });
            }}
            key={index}
          />
        ) : (
          <ContactInformationSlide products={selectedProduct} variant="contact" key={index} />
        );
      })}
    </FullscreenOverlaySlider>
  );
};

export default ContactInformationSlides;
