import React, { useContext } from 'react';

import ContactInformationContext from '../../../contexts/ContactInformationContext';
import Tab from '../Tab/Tab';
import Tabs from '../Tabs/Tabs';
import ContactInformationTab from './ContactInformationTab';

interface IContactInformationTabsProps {
  product: any;
}

const ContactInformationTabs: React.FC<IContactInformationTabsProps> = ({ product }) => {
  const { isDamageVariant } = useContext(ContactInformationContext) || {};
  const { name, newClient = {}, existingClient = {} } = product;
  const {
    hotline: newHotline = {},
    mailForm: newMailForm = { linkTo: '/kontaktformular/email-anfrage' }
  } = newClient;
  const {
    phone: newPhone = '0511-9078811',
    availability: newAvailbility = 'Montag-Freitag 8-18 Uhr',
    recallForm: newRecallForm = { linkTo: '/kontaktformular/rueckrufwunsch' }
  } = newHotline;
  const {
    hotline: existHotline = {},
    mailForm: existMailForm = { linkTo: '/kontaktformular/email-anfrage' },
    damage,
    additionalTabs
  } = existingClient;
  const {
    phone: existPhone = null,
    availability: existAvailbility = 'Montag-Freitag 8-18 Uhr',
    recallForm: existRecallForm = {
      linkTo: '/kontaktformular/rueckrufwunsch?appView=true'
    }
  } = existHotline;
  const {
    phone: damagePhone = null,
    availability: damageAvailbility = 'Montag-Freitag 8-18 Uhr',
    mailForm: damageMailForm = null,
    link: damageLink = null,
    additionalLinks = [],
    additionalTabs: additionalDamageTabs = []
  } = damage;

  const hasAdditionalLinks = additionalLinks?.length > 0;

  const isNewClient = name === 'newClient';

  return isDamageVariant ? (
    <Tabs tabTitles={['Schaden']}>
      <Tab extraClass={`${2 + additionalDamageTabs.length > 3 ? 'm-tab--foursquare' : null}`}>
        {damagePhone && (
          <ContactInformationTab
            number={2 + additionalDamageTabs.length}
            title="Telefonisch melden"
            phone={damagePhone}
            text={damageAvailbility}
          />
        )}
        {(damageMailForm || damageLink || hasAdditionalLinks) && (
          <ContactInformationTab
            number={2 + additionalDamageTabs.length}
            title="Online melden"
            text={`Informieren Sie uns in wenigen\u00A0Schritten, schnell und einfach –
                wir kümmern uns um den Rest`}
            links={
              damageMailForm
                ? [
                    {
                      label: 'Alle Schäden',
                      linkTo: `${
                        typeof damageMailForm === 'string'
                          ? damageMailForm
                          : typeof damageMailForm[name] === 'string'
                            ? damageMailForm[name]
                            : damageMailForm[name]?.linkTo
                      }`,
                      linkType: damageMailForm[name]?.linkType
                    },
                    ...(hasAdditionalLinks ? additionalLinks : [])
                  ]
                : [
                    { label: 'Alle Schäden', ...damageLink },
                    ...(hasAdditionalLinks ? additionalLinks : [])
                  ]
            }
            label={'Jetzt starten'}
          />
        )}
        {additionalDamageTabs?.map((info, index) => {
          const { title, text, link, label } = info || {};
          return (
            <ContactInformationTab
              key={index}
              number={2 + additionalDamageTabs.length}
              title={title || 'PDF-Formular'}
              text={
                text ||
                'Nutzen Sie unser PDF-Formular für eine Schadensmeldung per Fax, E-Mail oder Post.'
              }
              links={link && [link]}
              label={label || 'Herunterladen'}
            />
          );
        })}
      </Tab>
    </Tabs>
  ) : (
    <Tabs tabTitles={['']}>
      <Tab extraClass={`${!isNewClient ? 'm-tab--foursquare' : ''}`}>
        {isNewClient ? (
          <>
            <ContactInformationTab
              number={2}
              title="Telefon-Hotline"
              phone={newPhone}
              text={newAvailbility}
              links={newRecallForm && [newRecallForm]}
              label="Rückruf gewünscht?"
            />
            <ContactInformationTab
              number={2}
              title="E-Mail-Kontakt"
              text={'Nutzen Sie unser Online-Formular, um Kontakt aufzunehmen.'}
              links={newMailForm && [newMailForm]}
              label="Formular öffnen"
            />
            <ContactInformationTab
              number={2}
              title="Postanschrift"
              text={
                <>
                  VHV Allgemeine Versicherung AG <br />
                  30138 Hannover
                </>
              }
            />
          </>
        ) : (
          <>
            <ContactInformationTab
              number={2}
              title="Telefon-Hotline"
              phone={existPhone}
              text={existAvailbility}
              links={existRecallForm && [existRecallForm]}
              label="Rückruf gewünscht?"
            />
            <ContactInformationTab
              number={2}
              title="E-Mail-Kontakt"
              text={'Nutzen Sie unser Online-Formular, um Kontakt aufzunehmen.'}
              links={existMailForm && [existMailForm]}
              label="Formular öffnen"
            />
            <ContactInformationTab
              number={2}
              title="Postanschrift"
              text={
                <>
                  VHV Allgemeine Versicherung AG <br />
                  30138 Hannover
                </>
              }
            />
            <ContactInformationTab
              number={2}
              title="Schaden-Hotline"
              phone={damagePhone}
              text={damageAvailbility}
            />
            {additionalTabs?.map((tab, key) => {
              const { title, text, label, link } = tab;

              return (
                <ContactInformationTab
                  key={key}
                  number={3}
                  title={title}
                  text={text}
                  label={label}
                  links={link && [link]}
                />
              );
            })}
          </>
        )}
      </Tab>
    </Tabs>
  );
};

export default ContactInformationTabs;
