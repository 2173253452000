import './ContentTeaserComponent.scss';

import React from 'react';

import extractChildren from '../../helpers/extractChildren';
import Image from '../ui/Image/Image';
import RichText from '../ui/RichText/RichText';
export interface IContentTeaserProps {
  title: string;
  text: string;
  image: string;
  children: any;
}

export default ({ title, text, image, children }: IContentTeaserProps) => {
  const { buttonArea } = extractChildren(children);

  return (
    <div className="ContentTeaser">
      <h2 className="ContentTeaser__title">{title}</h2>
      <div className="ContentTeaser__wrapper">
        {image && (
          <div className="ContentTeaser__image">
            <Image src={image} sizes={{ w: 420, h: 295 }} />
          </div>
        )}
        <div
          className={`ContentTeaser__description${
            image ? '' : ' ContentTeaser__description-fullSize'
          }`}
        >
          <RichText extraClass="ContentTeaser__text" richText={text} />
          <div className="ContentTeaser__links">{buttonArea}</div>
        </div>
      </div>
    </div>
  );
};
