import { useCookies } from 'react-cookie';

export const pushToDataLayer = (obj: Record<string, any> | Record<string, any>[]) => {
  if (typeof window !== 'undefined' && typeof window['dataLayer'] !== 'undefined') {
    const dataLayer = window['dataLayer'];
    obj && obj.length ? obj.forEach(value => dataLayer.push(value)) : dataLayer.push(obj);
  }
};

const useCookiePolicy = () => {
  const [, setCookie] = useCookies();

  const setCookiePolicy = (value: number) => {
    pushToDataLayer([{ marketingCookieConsent: value === 1 }]);
    value === 1 && pushToDataLayer({ event: 'consentChange' });
    setCookie('cookie-policy', value);
  };

  return { setCookiePolicy };
};

export default useCookiePolicy;
