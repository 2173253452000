import axios from 'axios';
import React, { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import NewsletterRegistrationContext from '../../../contexts/NewsletterRegistrationContext';
import getFunctionUrl from '../../../helpers/getFunctionUrl';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { pushToDataLayer } from '../../../hooks/useCookiePolicy';
import vhvLogoMark from '../../../styles/assets/images/vhv-logo-mark.png';
import vhvLogoSubhead from '../../../styles/assets/images/vhv-logo-subhead.png';
import vhvLogoSw from '../../../styles/assets/images/vhv-logo-sw.png';
import CheckboxComponent from '../Form/CheckboxComponent';
import FooterArea from '../Form/FooterArea';
import SelectFieldComponent from '../Form/SelectFieldComponent';
import TextFieldComponent from '../Form/TextFieldComponent';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';

function NewsletterRegistrationDownloadForm() {
  const { isRegistrationVisible, setIsRegistrationVisible } =
    useContext(NewsletterRegistrationContext) || {};
  const methods = useForm();
  const isMaxVpS = useBreakpoint('s');
  const [isSucess, setIsSucess] = useState<boolean>(false);

  const formServiceHandler = async (data): Promise<any> => {
    try {
      const res = await axios.post(getFunctionUrl('mailingwork'), data);

      if (res.status === 200) return res;
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = (data: Record<string, string>) => {
    if (data) {
      formServiceHandler({
        addUserToList: { ...data, listname: 'bauexperten' }
      })
        .then(resp => {
          if (resp.data.statusCode === 200) {
            pushToDataLayer([
              { formularStatusGen3: 'abgeschickt' },
              { event: 'formularGen3abgeschickt' }
            ]);
            setIsSucess(true);
          } else {
            setIsSucess(true);
          }
        })
        .catch(err => console.error(err));
    }
  };

  return (
    <FullscreenOverlay
      isVisible={isRegistrationVisible}
      hideOverlay={() => setIsRegistrationVisible(false)}
    >
      <FormProvider {...methods}>
        <div className="l-iframe l-calculator-privat-kontakt-email container">
          {isSucess ? (
            <>
              <header>
                <Icon type="check" />
                <h2>Vielen Dank!</h2>
              </header>

              <div className="page m-box m-box--light m-final spacer-small">
                <p>Vielen Dank für Ihre Registrierung.</p>
                <p>
                  Gerade sollte die erste E-Mail in Ihrem Postfach eingehen. Bitte klicken Sie auf
                  den enthaltenen Link, um Ihre Anmeldung abzuschließen.
                </p>
              </div>
              <a
                id="downloadLink"
                href="{{newsletterRegistrationDownload.downloadUrl}}"
                rel="noreferrer"
                download
                target="_blank"
              ></a>
            </>
          ) : (
            <>
              <header>
                <Link linkTo="/" extraClass="l-header__logo">
                  {isMaxVpS ? (
                    <img className="mobile" src={vhvLogoSw} alt="VHV" />
                  ) : (
                    <>
                      <img src={vhvLogoMark} />
                      <img className="subhead" src={vhvLogoSubhead} alt="Versicherungen" />
                    </>
                  )}
                </Link>
                <Icon type="mail" />
                <h2 className="h2">Newsletter abonnieren</h2>
              </header>
              <div className="page">
                <form
                  noValidate
                  name="form"
                  id="form"
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className="m-form m-form--centered m-form--with-vaidation"
                >
                  <div>
                    <div className="m-box m-box--light spacer">
                      <fieldset>
                        <SelectFieldComponent
                          label="Anrede"
                          name="anrede"
                          errorMessage="Bitte wählen Sie Ihre Anrede."
                          options={[
                            {
                              label: 'Frau',
                              value: 'Frau'
                            },
                            {
                              label: 'Herr',
                              value: 'Herr'
                            },
                            { label: 'Divers', value: 'Divers' }
                          ]}
                        />
                        <TextFieldComponent
                          label="Vorname"
                          name="firstname"
                          errorMessage="Bitte geben Sie Ihren Vornamen ein."
                        />
                        <TextFieldComponent
                          label="Nachname"
                          name="lastname"
                          errorMessage="Bitte geben Sie Ihren Namen ein."
                        />
                        <TextFieldComponent
                          label="Firma"
                          name="company"
                          errorMessage="Bitte geben Sie den Namen Ihres Unternehmens an."
                          optional="true"
                        />
                        <SelectFieldComponent
                          label="Branche"
                          name="branch"
                          errorMessage="Bitte wählen Sie eine Branche."
                          options={[
                            {
                              label: 'Bauunternehmen',
                              value: 'Bauunternehmen'
                            },
                            {
                              label: 'Bauhandwerker',
                              value: 'Bauhandwerker'
                            },
                            {
                              label: 'Architekten und Ingenieure',
                              value: 'Architekten und Ingenieure'
                            },
                            {
                              label: 'Baustoffproduzenten und -händler',
                              value: 'Baustoffproduzenten und -händler'
                            },
                            {
                              label: 'Handel Handwerk Dienstleistung',
                              value: 'Handel Handwerk Dienstleistung'
                            },
                            {
                              label: 'Erneuerbare Energien',
                              value: 'Erneuerbare Energien'
                            },
                            {
                              label: 'IT-Dienstleister',
                              value: 'IT-Dienstleister'
                            }
                          ]}
                        />
                        <TextFieldComponent
                          label="Postleitzahl"
                          name="plz"
                          errorMessage="Bitte geben Sie die Stadt und PLZ an"
                        />
                        <TextFieldComponent
                          label="E-Mail-Adresse"
                          name="email"
                          errorMessage="Bitte geben Sie eine gültige E-Mail-Adresse an."
                          regex="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
                        />
                        <CheckboxComponent
                          label=""
                          name="nlEmailServ"
                          errorMessage="Bitte bestätigen Sie die Einwilligungserklärung."
                        >
                          <span>
                            Ich erkläre mich bereit, mich zum Download des Dokuments zunächst bei
                            der VHV zu registrieren: Ich habe die{' '}
                            <Link
                              linkTo="/firmen/meta/datenschutz/datenschutz-allgemein"
                              linkType="internal"
                              label="Datenschutzhinweise"
                            />{' '}
                            gelesen und willige in die Erhebung, Verarbeitung, Nutzung meiner im
                            Formular angegebenen Daten wie in der Datenschutzerklärung und
                            nachfolgend beschrieben ein. Die im Formular angegebenen Daten werden
                            von VHV Versicherungen gespeichert und zum Zwecke des Direktmarketings
                            (Newsletter) und der Marktforschung ausschließlich innerhalb der VHV zur
                            Verfügung gestellt. Ich kann meine Einwilligung jederzeit ohne Angabe
                            eines Grundes wie folgt widerrufen: Widerrufsadresse:{' '}
                            <a href="mailto:keinewerbung_vhv@vhv.de?subject=Widerruf Speicherung und Nutzung">
                              keinewerbung_vhv@vhv.de
                            </a>{' '}
                            (Betreff: Widerruf Speicherung und Nutzung).
                          </span>
                        </CheckboxComponent>
                      </fieldset>
                    </div>
                    <FooterArea></FooterArea>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </FormProvider>
    </FullscreenOverlay>
  );
}
export default NewsletterRegistrationDownloadForm;
