import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Icon from '../Icon/Icon';

interface FooterAreaProps {
  children?: JSX.Element | JSX.Element[];
  buttonLabel?: string;
}

const FooterArea: React.FC<FooterAreaProps> = ({ buttonLabel }) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { control } = useFormContext();

  useEffect(() => {
    if (control._formState.isSubmitting) {
      setIsDisabled(true);
      setTimeout(() => {
        setIsDisabled(false);
      }, 5000);
    }
  }, [control._formState.isSubmitting]);

  return (
    <footer>
      <div className="m-box m-box--dark clearfix">
        <span className="m-button m-button--flagged float-right">
          <button
            className="m-button m-button--size-m m-button--icon-right"
            type="submit"
            disabled={isDisabled}
          >
            <i className={`ico-arrow-right${isDisabled ? ' ico-spinner' : ''}`}></i>
            <span>{buttonLabel || 'Abschicken'}</span>
          </button>
        </span>
        <div className="m-footer-meta__info">
          <span>
            <Icon type="lock" className="ico-lock"></Icon>
            <em>Sicher mit SSL-Verschlüsselung</em>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default FooterArea;
