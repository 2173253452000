import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import FormErrorMessage from './FormErrorMessage';
import FormTooltip from './FormTooltip';

interface AddressFieldComponentProps {
  label: string;
  optional?: string;
  name: string;
  errorMessage?: string;
  tooltipText?: string;
}

const AddressFieldComponent: React.FC<AddressFieldComponentProps> = ({
  label,
  optional,
  name,
  errorMessage,
  tooltipText
}) => {
  const {
    register,
    unregister,
    formState: { errors, dirtyFields }
  } = useFormContext();
  useEffect(() => {
    return () => {
      unregister(`${name}Street`);
      unregister(`${name}Number`);
    };
  }, []);

  const getActualErrorMessage = () => {
    if (errors[`${name}Street`]) {
      if (errors[`${name}Street`].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie eine korrekte Strasse ein';
    }
    if (errors[`${name}Number`]) {
      if (errors[`${name}Number`].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie eine korrekte Hausnummer ein';
    }
    return undefined;
  };

  const actualErrorMessage = getActualErrorMessage();
  return (
    <>
      <div className="m-form__group">
        <label>
          {label} {optional && <em>optional</em>}
        </label>
        <div className="m-form__group-fields">
          <div className="l-grid">
            <div className="l-grid__col--9-12 l-grid__col--keep-width">
              <input
                type="text"
                id={`${name}Street`}
                {...register(`${name}Street`, { required: !optional })}
                className={classNames({
                  'ng-touched': dirtyFields[`${name}Street`],
                  'ng-invalid': errors[`${name}Street`]
                })}
              />
            </div>
            <div className="l-grid__col--3-12 l-grid__col--keep-width">
              <input
                type="text"
                id={`${name}Number`}
                {...register(`${name}Number`, { required: !optional })}
                className={classNames({
                  'ng-touched': dirtyFields[`${name}Number`],
                  'ng-invalid': errors[`${name}Number`]
                })}
              />
            </div>
          </div>
          {tooltipText && <FormTooltip label={label} name={name} tooltipText={tooltipText} />}
        </div>
      </div>
      {actualErrorMessage && <FormErrorMessage errorMessage={actualErrorMessage} />}
    </>
  );
};

export default AddressFieldComponent;
