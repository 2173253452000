import './SurveyArea.scss';

import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';

import spinner from '../../styles/assets/images/spinner.svg';
import { brokerSurvey } from './brokerSurvey';
import {
  SurveyFeedback,
  SurveyNoYesButton,
  SurveyRadios,
  SurveyRow,
  SurveySection,
  SurveyStars
} from './SurveyComponents';

interface ISurveyAreaProps {
  brokerIdentifier: string;
  brokerIdentifierGender: string;
  surveyId: string;
}

interface ISurveyArea {
  surveyAreaProps: ISurveyAreaProps;
}
interface IState {
  score?: number;
  tags?: {
    Betreuung?: string;
    Engagement?: string;
    Weiterempfehlung?: string;
    Kompetenz?: string;
    Servicequalität?: string;
  };
  comment?: string;
}

interface IInitalState {
  vd: IState;
  vhv: IState;
}

const initalState: IInitalState = {
  vd: {
    score: 0,
    tags: {
      Engagement: null,
      Weiterempfehlung: null,
      Kompetenz: null,
      Servicequalität: null
    },
    comment: null
  },
  vhv: {
    score: 0,
    tags: {
      Betreuung: null,
      Weiterempfehlung: null,
      Kompetenz: null,
      Servicequalität: null
    },
    comment: null
  }
};

const getHttpConfig = (
  config: {
    url: string;
    credentials: { surveyKeyVHV: string; username: string };
  },
  vdsid: string
) => {
  return {
    vd: {
      headers: {
        Authorization: 'Basic ' + window.btoa(config.credentials.username + ':' + vdsid)
      }
    },
    vhv: {
      headers: {
        Authorization:
          'Basic ' +
          window.btoa(config.credentials.username + ':' + config.credentials.surveyKeyVHV)
      }
    },
    url: config.url
  };
};

const postSurveyData = async (
  config: {
    url: string;
    vd: { headers: { Authorization: string } };
    vhv: { headers: { Authorization: string } };
  },
  selection: IInitalState
) => {
  return axios.all([
    axios.post(config.url, JSON.stringify(selection.vd), config.vd),
    axios.post(config.url, JSON.stringify(selection.vhv), config.vhv)
  ]);
};

const getSurveyData = async (config: {
  url: string;
  vd: { headers: { Authorization: string } };
  vhv: { headers: { Authorization: string } };
}) => {
  return axios.all([axios.get(config.url, config.vd), axios.get(config.url, config.vhv)]);
};

const SurveyArea: React.FC<ISurveyArea> = ({ surveyAreaProps }) => {
  const [selection, setSelection] = useState(initalState);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errors, setErrors] = useState({
    vd: false,
    vhv: false
  });
  const { brokerIdentifier, surveyId, brokerIdentifierGender } = surveyAreaProps;
  const { config } = brokerSurvey(brokerIdentifier, brokerIdentifierGender, surveyId);
  const { Zufriedenheit, headline, Weiterempfehlung } = config.strings;

  const handleSubmit = async () => {
    setIsLoading(true);
    const newErrors = { ...errors };
    let firstErrorElement = '';

    if (selection.vhv.score === 0) {
      newErrors.vhv = true;
      firstErrorElement = 'stars_2';
    }

    if (selection.vd.score === 0) {
      newErrors.vd = true;
      firstErrorElement = 'stars_1';
    }

    setErrors(newErrors);

    if (newErrors.vhv || newErrors.vd) {
      const element = document.getElementById(firstErrorElement);
      scrollTo({ left: 0, top: element.offsetTop - 100, behavior: 'smooth' });
    }

    if (!newErrors.vd && !newErrors.vhv) {
      const requestConfig = getHttpConfig(
        { url: config.postDataUrl, credentials: config.credentials },
        surveyId
      );
      setSubmitDisabled(true);
      setIsLoading(true);
      postSurveyData(requestConfig, selection)
        .then(
          axios.spread((...responses) => {
            if (responses[0]['status'] === 201 && responses[1]['status'] === 201) {
              setIsSuccess(true);
              setIsLoading(false);
            } else {
              setIsSuccess(false);
            }
          })
        )
        .catch(error => {
          console.error(error);
          setIsSuccess(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const requestConfig = getHttpConfig(
      { url: config.getSurveyUrl, credentials: config.credentials },
      surveyId
    );
    getSurveyData(requestConfig)
      .then(data => {
        if (data[0]['status'] !== 200 && data[1]['status'] !== 200) {
          setIsSuccess(false);
        }
      })
      .catch(error => {
        console.error(error);
        setIsSuccess(false);
      });
  }, []);

  return isSuccess === null ? (
    <section className="l-content__section">
      <div>
        <section className="m-survey__faded fade-animate">
          <form
            name=""
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <SurveySection noRow>
              <h3 id="stars_1">{headline.text}</h3>
              <SurveyRow>
                <SurveyStars
                  onClick={e => {
                    setErrors({ ...errors, vd: false });
                    setSelection({
                      ...selection,
                      vd: { ...selection.vd, score: e }
                    });
                  }}
                  error={errors.vd}
                  selectetValue={selection.vd.score}
                  question={Zufriedenheit.questionBroker}
                />
              </SurveyRow>
            </SurveySection>
            <SurveySection sectionExtendClass="spacer" noRow>
              <SurveyRow>
                <SurveyNoYesButton
                  lables={['Ja', 'Nein']}
                  id="vd"
                  question={Weiterempfehlung.questionBroker}
                  onClick={e =>
                    setSelection({
                      ...selection,
                      vd: {
                        ...selection.vd,
                        tags: { ...selection.vd.tags, Weiterempfehlung: e }
                      }
                    })
                  }
                />
                <div className="m-survey__label"></div>
              </SurveyRow>

              <SurveyRadios
                question="questionBroker"
                keys={['Kompetenz', 'Engagement', 'Servicequalität']}
                id="vd"
                strings={config.strings}
                selectionValues={selection}
                onClick={(e, key) =>
                  setSelection({
                    ...selection,
                    vd: {
                      ...selection.vd,
                      tags: { ...selection.vd.tags, [key]: e }
                    }
                  })
                }
              />
            </SurveySection>
            <SurveySection noRow>
              <h3 id="stars_2">Bewerten Sie die VHV im Allgemeinen</h3>
              <SurveyRow>
                <SurveyStars
                  onClick={e => {
                    setErrors({ ...errors, vhv: false });
                    setSelection({
                      ...selection,
                      vhv: { ...selection.vhv, score: e }
                    });
                  }}
                  error={errors.vhv}
                  selectetValue={selection.vhv.score}
                  question={Zufriedenheit.questionVHV}
                />
              </SurveyRow>
            </SurveySection>
            <SurveySection sectionExtendClass="spacer" noRow>
              <SurveyRow>
                <SurveyNoYesButton
                  lables={['Ja', 'Nein']}
                  id="vhv"
                  question={Weiterempfehlung.questionVHV}
                  onClick={e =>
                    setSelection({
                      ...selection,
                      vhv: {
                        ...selection.vhv,
                        tags: { ...selection.vhv.tags, Weiterempfehlung: e }
                      }
                    })
                  }
                />
                <div className="m-survey__label"></div>
              </SurveyRow>
              <SurveyRadios
                question="questionVHV"
                keys={['Servicequalität', 'Betreuung', 'Kompetenz']}
                id="vhv"
                strings={config.strings}
                selectionValues={selection}
                onClick={(e, key) =>
                  setSelection({
                    ...selection,
                    vhv: {
                      ...selection.vhv,
                      tags: { ...selection.vhv.tags, [key]: e }
                    }
                  })
                }
              />
            </SurveySection>
            <SurveySection sectionExtendClass="spacer" headline="Möchten Sie uns noch etwas sagen?">
              <textarea
                rows={8}
                name="comment"
                id="comment"
                onChange={e =>
                  setSelection({
                    ...selection,
                    vd: { ...selection.vd, comment: e.currentTarget.value }
                  })
                }
                placeholder="Bitte geben Sie keine Namen an, damit die Anonymisierung der Bewertung bestehen bleibt."
              />
            </SurveySection>
            <SurveySection sectionExtendClass="m-survey__section--dark" noRow>
              <div className="l-grid">
                <div className="l-grid__col--7-12 l-grid__col--m-8-12">
                  <p className="text-white">
                    Durch das Abschicken Ihrer Bewertung stimmen Sie unseren
                    <a href="/meta/datenschutz"> Datenschutzrichtlinien </a> zu.
                  </p>
                </div>
                <div className="l-grid__col--5-12 l-grid__col--m-4-12 text-right">
                  <button
                    className="m-button m-button--size-l"
                    style={{ position: 'relative' }}
                    disabled={submitDisabled}
                    onClick={() => handleSubmit()}
                  >
                    {!isLoading ? (
                      <span>Abschicken</span>
                    ) : (
                      <img
                        src={spinner}
                        style={{
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          marginTop: '-16px',
                          marginLeft: '-16px'
                        }}
                      />
                    )}
                  </button>
                </div>
              </div>
            </SurveySection>
          </form>
        </section>
      </div>
    </section>
  ) : !isSuccess ? (
    <SurveyFeedback
      type="sad"
      text="Ihre Bewertung konnte leider nicht abgeschickt werden."
      buttonLabel="Erneut versuchen"
      onClick={() => {
        if (window && window.location) {
          window.location.reload();
        }
      }}
    />
  ) : (
    <SurveyFeedback type="happy" text="Vielen Dank für Ihre Bewertung." />
  );
};

export default SurveyArea;
