import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const SeoProductPage = ({ children }) => {
  const {
    headerArea,
    contentArea,
    contentHeaderArea,
    benefitArea,
    socialSharingArea,
    relatedContentArea
  } = extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content">
        {contentHeaderArea}
        {benefitArea}
        {contentArea}
        {socialSharingArea}
        {relatedContentArea}
      </main>
      <Footer />
    </>
  );
};

export default SeoProductPage;
