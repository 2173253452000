import React from 'react';

import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';

const SignetComponent = ({ predicate, image }) => {
  return (
    <div className="l-signet">
      <div className="l-signet__asset">
        <Image src={image} sizes={{ w: 400 }} objectFitContain />
      </div>
      <div>
        <Icon type="check" className="ico-check"></Icon> {predicate}
      </div>
    </div>
  );
};

export default SignetComponent;
