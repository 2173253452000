import { navigate } from 'gatsby-link';
import React, { useEffect } from 'react';

import useSplitPathname from '../hooks/useSplitPathname';

const ContainerPage = ({ pageScope }) => {
  const splitPathname = useSplitPathname();

  const navigationRoot = pageScope === 'corporate' ? '/firmen' : '';

  useEffect(() => {
    if (splitPathname.length > 2) {
      navigate(`${navigationRoot}/${splitPathname[1]}`);
    } else {
      navigate(`${navigationRoot}/`);
    }
  }, [splitPathname]);

  return <main />;
};

export default ContainerPage;
