import { useContext } from 'react';

import SiteDataContext from '../contexts/SiteDataContext';

const useSplitPathname = () => {
  const { location = {} } = useContext(SiteDataContext) || {};
  const { pathname } = location;

  const splitPathname =
    pathname && (pathname.endsWith('/') ? pathname.slice(1, -1) : pathname.substring(1)).split('/');

  return splitPathname;
};

export default useSplitPathname;
