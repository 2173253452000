import React from 'react';

import extractChildren from '../../helpers/extractChildren';

const OrganizationsComponent = ({ text, children }) => {
  const { organizationsArea } = extractChildren(children);

  return (
    <>
      <div className="spacer">
        {text ? (
          text
        ) : (
          <p>
            Die VHV bündelt ihre Erfahrung als führender Spezialversicherer der Bauwirtschaft mit
            der Kompetenz der Bauverbände und Innungen vor Ort. Mit vielen Verbänden haben wir
            Rahmenverträge, dadurch erhalten Verbands-/ Innungsmitglieder besondere Konditionen.
          </p>
        )}
      </div>
      <div className="m-orga-tile__wrapper">{organizationsArea}</div>
    </>
  );
};

export default OrganizationsComponent;
