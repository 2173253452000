import { useEffect, useState } from 'react';

const useVpWidth = () => {
  const [viewportWidth, setViewportWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(Math.max(document.documentElement.clientWidth, window.innerWidth));
    };
    if (viewportWidth === 0) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return viewportWidth;
};

export default useVpWidth;
