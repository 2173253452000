import React from 'react';

const HomeSectionComponent = ({ headline, variant, children }) => {
  return (
    <section
      className={`l-content__section${
        variant === 'banderole' ? ' l-content__section--medium' : ''
      }`}
    >
      <div>
        {headline && <h2>{headline}</h2>}
        {children && <div>{children}</div>}
      </div>
    </section>
  );
};

export default HomeSectionComponent;
