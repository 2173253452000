import './CorporateNavDropdown.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Icon from '../../../components/ui/Icon/Icon';
import Link from '../../../components/ui/Link/Link';
import ProductFinder from '../../../components/ui/ProductFinder/ProductFinder';
import SiteDataContext from '../../../contexts/SiteDataContext';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useSplitPathname from '../../../hooks/useSplitPathname';
import { INavDropdownProps } from '../PrivateNavDropdown';

const CorporateNavDropdown: React.FC<INavDropdownProps> = ({
  isActive,
  items,
  closeDropdown,
  parentRef
}) => {
  const ref = useRef(null);

  const splitPathname = useSplitPathname();

  const { pageScope } = useContext(SiteDataContext) || {};

  const currentPath = splitPathname[splitPathname.length - 1];
  const parentPath = splitPathname[splitPathname.length - 2];

  const [selectedSubmenu, setSelectedSubmenu] = useState('');

  useOutsideClick(ref, () => closeDropdown(), isActive, parentRef);

  const subMenuItems = {};

  useEffect(() => {
    if (parentPath && !!items.find(item => item.name === parentPath)) {
      setSelectedSubmenu(parentPath);
    }
  }, []);

  items.forEach(item => {
    const { name, children } = item;
    if (name && children) {
      subMenuItems[name] = children;
    }
  });

  return (
    <CSSTransition in={isActive} mountOnEnter unmountOnExit timeout={500} classNames={'fade-swift'}>
      <div className={`CorporateNavDropdown`}>
        <div className="CorporateNavDropdown__menu" onClick={e => e.stopPropagation()} ref={ref}>
          {items && (
            <div className="CorporateNavDropdown__nav">
              <div className="CorporateNavDropdown__items">
                {items.map((navItem, index) => {
                  const { name, title, label, path, children } = navItem || {};
                  return children ? (
                    <div
                      className={`CorporateNavDropdown__item CorporateNavDropdown__item--has-icon${
                        selectedSubmenu === name ? ' CorporateNavDropdown__item--active' : ''
                      }`}
                      onClick={() => setSelectedSubmenu(name)}
                      key={index}
                    >
                      {label || title}
                      <Icon type="arrow-right" className="ico-arrow-right" />
                    </div>
                  ) : (
                    <Link
                      key={index}
                      extraClass={`CorporateNavDropdown__item${
                        currentPath === name && selectedSubmenu === ''
                          ? ' CorporateNavDropdown__item--active'
                          : ''
                      }`}
                      label={label || title}
                      linkTo={path}
                      onClick={() => closeDropdown()}
                    />
                  );
                })}
              </div>

              <div className="CorporateNavDropdown__items">
                {Object.keys(subMenuItems).map((name, index) => {
                  const children = subMenuItems[name];
                  return (
                    <CSSTransition
                      in={selectedSubmenu === name}
                      mountOnEnter
                      unmountOnExit
                      timeout={500}
                      classNames={'fade-transform-left-delay-in-absolute'}
                      key={index}
                    >
                      <div>
                        {children &&
                          children.map((subNavItem, index) => {
                            const { title, label, name, path } = subNavItem || {};
                            return (
                              <Link
                                key={index}
                                extraClass={`CorporateNavDropdown__item CorporateNavDropdown__item--pri-color${
                                  currentPath === name ? ' CorporateNavDropdown__item--active' : ''
                                }`}
                                label={label || title}
                                linkTo={path}
                                onClick={() => closeDropdown()}
                              />
                            );
                          })}
                      </div>
                    </CSSTransition>
                  );
                })}
              </div>
            </div>
          )}
          {pageScope === 'corporate' && <ProductFinder noCollapseBottom />}
        </div>
        <div className="CorporateNavDropdown__backdrop" />
      </div>
    </CSSTransition>
  );
};

export default CorporateNavDropdown;
