import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import { BusinessCardPageContext } from '../../containers/BusinessCardPage';
import { CyberLandingPageContext } from '../../containers/CyberLandingPage';
import { LandingPageContext } from '../../containers/LandingPage';
import BrokerBcContext from '../../contexts/BrokerBcContext';
import agentImg from '../../styles/assets/images/callcenter-agent.jpg';
import LossArea from '../LossArea/LossArea';

const ContactArea = ({
  availability,
  telephoneNumber,
  landingPageStyle,
  children,
  parentTemplate
}) => {
  const { lpContact, disableBroker, isContactHidden } = useContext(LandingPageContext) || {};
  const { contactInfo } = useContext(CyberLandingPageContext) || {};
  const { broker } = useContext(BusinessCardPageContext) || {};
  const { setVisibleBrokerBc } = useContext(BrokerBcContext) || {};

  const { telephoneNumber: brokerTelephoneNumber, faxNumber } = broker || {};
  const contact = parentTemplate === 'CyberLandingPage' ? contactInfo : lpContact;

  const getContent = !isContactHidden ? (
    <section
      className={`l-content__section${
        parentTemplate === 'CyberLandingPage' ? ' l-content__section--dark' : ''
      }${
        !landingPageStyle || landingPageStyle === 'transparent'
          ? ' l-content__section--medium'
          : ' l-content__section--' + landingPageStyle
      }`}
    >
      {!disableBroker && contact ? (
        <div>
          <Columns widths={[2, 1]} gutter="s" wrap={false}>
            <div>
              {contact.photoUrl ? (
                <div
                  className={`l-landing__contact-img l-landing__contact-img--broker${
                    contact.photoIsLogo ? ' l-landing__contact-img--broker--with-logo' : ''
                  }`}
                >
                  <Image src={contact.photoUrl} alt={contact.name} sizes={{ w: 300 }} />
                </div>
              ) : landingPageStyle !== 'black' ? (
                <div className="l-landing__contact-img">
                  <Image src={agentImg} sizes={{ h: 400 }} />
                </div>
              ) : null}
              <div
                className={`l-landing__contact-text l-landing__contact-text--broker${
                  !contact.photoUrl ? ' l-landing__contact-text--no-image' : ''
                }`}
              >
                <div className="l-landing__contact-headline">
                  <>Ihr/e Ansprechpartner/in freut sich auf Ihren Anruf!</>
                </div>
                <a
                  href={`tel:${contact.telephoneNumber}`}
                  className="l-landing__contact-tel ga-tel-bottom"
                >
                  {contact.telephoneNumber}
                </a>
                <>
                  <div className="l-landing__contact-subline">{contact.name}</div>
                  {contact.contact && (
                    <div className="l-landing__contact-broker-name">{contact.contact}</div>
                  )}
                </>
              </div>
            </div>
            <div>
              {contact && !contact.hideFormButtons && (
                <div className="l-landing__contact-cta">
                  <div>
                    <strong>Haben Sie Fragen?</strong>
                  </div>
                  Dann setzen Sie sich gerne mit Ihrem/Ihrer Ansprechpartner/in in Verbindung.
                  {/* TODO @sophie E-Mail Fromular Overlay */}
                  <a
                    onClick={() => {
                      setVisibleBrokerBc('email');
                    }}
                    className="m-button ga-cta-bottom m-button--full-width"
                  >
                    <span>Nachricht schreiben</span>
                  </a>
                </div>
              )}
            </div>
          </Columns>
        </div>
      ) : (
        <div>
          <Columns widths={[2, 1]} gutter="s" wrap={false}>
            <div>
              {landingPageStyle !== 'black' ? (
                <div className="l-landing__contact-img">
                  <Image src={agentImg} sizes={{ w: 300 }} />
                </div>
              ) : null}
              <div
                className={`l-landing__contact-text${
                  landingPageStyle === 'black' ? ' l-landing__contact-text--no-image' : ''
                }`}
              >
                <div className="l-landing__contact-headline">
                  <>
                    Sie haben noch Fragen?
                    <br />
                    Unsere Expert/innen freuen sich auf Ihren Anruf!
                  </>
                </div>
                <a href={`tel:${telephoneNumber}`} className="l-landing__contact-tel ga-tel-bottom">
                  {telephoneNumber}
                </a>

                <div className="l-landing__contact-subline">{availability}</div>
              </div>
            </div>
            <div>{children}</div>
          </Columns>
        </div>
      )}
    </section>
  ) : null;

  return (
    {
      ContactDoorPage: <LossArea />,
      BusinessCardPage: (
        <section className="l-content__section ">
          <div className="m-box text-left">
            <Columns widths={[2, 1]} gutter="s" wrap={false}>
              <div>
                <h3>Wir beraten Sie gerne!</h3>
                <p className="l-broker-bc__phone">
                  <Icon type="phone" className="ico-phone"></Icon>
                  {brokerTelephoneNumber}
                </p>
                <p>oder faxen Sie uns: {faxNumber}</p>
              </div>

              <div className="l-broker-bc__service-hours">
                <h3>Servicezeiten:</h3>
                <table className="l-broker-bc__opening">
                  <tbody>
                    <tr>
                      <td> Montag - Freitag: </td>
                      <td> 08:00 - 18:00 Uhr </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Columns>
          </div>
        </section>
      )
    }[parentTemplate] || getContent
  );
};

export default ContactArea;
