import { format } from 'date-fns';
import React, { useContext } from 'react';

import Link from '../../components/ui/Link/Link';
import Tabs from '../../components/ui/Tabs/Tabs';
import ExpertEventsContext from '../../contexts/ExpertEventsContext';

const EventsArea = () => {
  const { upcomingEvents, archievedEvents } = useContext(ExpertEventsContext) || {};

  const tabTitles =
    archievedEvents.length > 0
      ? ['Unsere Veranstaltungen', 'Veranstaltungsarchiv']
      : ['Unsere Veranstaltungen'];

  return (
    <section className="l-content__section l-content__section--medium">
      <div className="m-event-list">
        <Tabs tabTitles={tabTitles} variant="corporate">
          <ul className="m-event-list__list" dom-filter="filterUpcoming">
            {upcomingEvents.length > 0 ? (
              upcomingEvents.map((upcomingEvent, key) => {
                const { startDate, title, kind, path, type, street, postalCode, city } =
                  upcomingEvent;
                return (
                  <li className="js-dom-filter" key={key}>
                    <time>{format(new Date(startDate), 'dd.MM.yyyy')}</time>
                    <Link linkTo={path} linkType="internal" label={title} />
                    <span>{type === 'virtual' ? kind : `${street}, ${postalCode} ${city}`}</span>
                  </li>
                );
              })
            ) : (
              <div>Es gibt keine zukünftigen Veranstaltungen.</div>
            )}
          </ul>
          {archievedEvents && (
            <ul className="m-event-list__list" dom-filter="filterUpcoming">
              {archievedEvents.map((upcomingEvent, key) => {
                const { startDate, title, kind, path, type, street, postalCode, city } =
                  upcomingEvent;
                return (
                  <li className="js-dom-filter" key={key}>
                    <time>{format(new Date(startDate), 'dd.MM.yyyy')}</time>
                    <Link linkTo={path} linkType="internal" label={title} />
                    <span>{type === 'virtual' ? kind : `${street}, ${postalCode} ${city}`}</span>
                  </li>
                );
              })}
            </ul>
          )}
        </Tabs>
      </div>
    </section>
  );
};
export default EventsArea;
