import './SignetSliderComponent.scss';

import Image from '../ui/Image/Image';
import React from 'react';
import Slider from 'react-slick';
import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';
import useBreakpoint from '../../hooks/useBreakpoint';

const SignetSliderComponent = ({ title, images }) => {
  const imageList = mapIndexedNodeProps(images, 'images');
  const BreakpointS = useBreakpoint('s');

  const settings = {
    dots: true,
    slidesToShow: BreakpointS ? 1 : 3,
    slidesToScroll: 1,
    arrows: true,
    dotsClass: 'SignetSliderComponent__dots',
    variableWidth: true
  };

  return (
    <div className="SignetSliderComponent">
      <h2 className="SignetSliderComponent__title">{title}</h2>
      <Slider {...settings}>
        {imageList?.map((imageElement, index) => {
          return (
            <Image
              src={imageElement.image}
              sizes={BreakpointS ? { w: 200 } : { w: 245 }}
              objectFitContain
              key={index}
            />
          );
        })}
      </Slider>
    </div>
  );
};
export default SignetSliderComponent;
