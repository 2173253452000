import { format } from 'date-fns';
import React, { useContext } from 'react';

import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import { ArticlePageContext } from '../../containers/ArticlePage';
import SiteDataContext from '../../contexts/SiteDataContext';
import browserBack from '../../helpers/browserBack';

const SubHeaderArea = () => {
  const { tags } = useContext(SiteDataContext) || {};

  const { artikel: allTags } = tags || {};

  const articlePageProps = useContext(ArticlePageContext) || {};

  const { publicationDate, leadText, tags: tagIds } = articlePageProps || {};

  const tagsArray = tagIds?.map(tagId => allTags && allTags.find(tag => tag.id === tagId));

  const magazinePageLink = '/firmen/magazin';

  return (
    <section className="l-content__section">
      <div>
        <nav className="m-magazine-subheader">
          <div
            onClick={() => browserBack()}
            className="m-button m-button--link m-button--single-line"
          >
            <Icon type="arrow-left" className="ico-arrow-left"></Icon> Zurück
          </div>

          <div className="m-magazine-subheader__info">
            <strong>{format(new Date(publicationDate), 'dd.MM.yyyy')}</strong>
            {tagsArray?.map((tag, index) => {
              const { label, name } = tag || {};
              return (
                tag && (
                  //TODO @ilja add filter logic with browserback
                  <Link linkTo={`${magazinePageLink}?filter=${name}`} key={`${label}}-${index}`}>
                    <Icon type="tag" className="ico-tag"></Icon>
                    {`\u00a0${label}`}
                  </Link>
                )
              );
            })}
          </div>
        </nav>

        <p>{leadText}</p>
      </div>
    </section>
  );
};

export default SubHeaderArea;
