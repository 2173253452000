import { useEffect, useState } from 'react';

export type Viewport = 'xs' | 's' | 'm' | 'l' | 'xl';

const useBreakpoint = (viewport: Viewport): boolean => {
  const [isMaxVp, setIsMaxVp] = useState<boolean>(false);

  const breakpoints = {
    xxs: 0,
    xs: 480,
    s: 568,
    m: 768,
    l: 1024,
    xl: 1200
  };

  let currentViewport;

  const isMaxViewport = (viewport: string) => {
    let vpIndex: number;
    let currentIndex: number;
    Object.keys(breakpoints).forEach((bp, i) => {
      if (viewport === bp) {
        vpIndex = i;
      }
      if (currentViewport === bp) {
        currentIndex = i;
      }
    });
    setIsMaxVp(vpIndex >= currentIndex);
  };
  const setWidths = () => {
    const currentWidth = window.innerWidth;
    let maxBp = 'xxs';

    Object.keys(breakpoints).forEach(bp => {
      if (currentWidth >= breakpoints[bp]) {
        maxBp = bp;
      }
    });

    currentViewport = maxBp;
    isMaxViewport(viewport);
  };

  useEffect(() => {
    setWidths();
    window.addEventListener('resize', setWidths);
    return () => {
      window.removeEventListener('resize', setWidths);
    };
  }, []);

  return isMaxVp;
};

export default useBreakpoint;
