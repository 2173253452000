import './CorporateContactFooter.scss';

import React, { useContext, useState } from 'react';

import BrokerBcContext from '../../../contexts/BrokerBcContext';
import ExpertSearchContext from '../../../contexts/ExpertSearchContext';
import SiteDataContext from '../../../contexts/SiteDataContext';
import { getCopyrightLabel } from '../../../helpers/getCurrentYear';
import Image from '../../ui/Image/Image';
import CorporateContact from './CorporateContact';

interface ICorporateContactFooterProps {
  isExpertSearchVariant?: boolean;
}

const CorporateContactFooter: React.FC<ICorporateContactFooterProps> = ({
  isExpertSearchVariant
}) => {
  const { footer: { homeFooterLinkArea = null } = {} } = useContext(SiteDataContext) || {};

  const { isLoading, expert, setIsExpertSearchModalVisible, findExpert } =
    useContext(ExpertSearchContext) || {};

  const { setVisibleBrokerBc } = useContext(BrokerBcContext) || {};

  const { name, photoUrl, faxNumber, telephoneNumber } = expert || {};

  const [inputValue, setInputValue] = useState('');

  const postalCodeRegex = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;

  const isValidInput = inputValue.match(postalCodeRegex);

  const footerWindup = (
    <footer className="l-footer nocontent">
      <div>
        <div className="l-footer__windup l-footer__windup--minimal">
          <div className="float-left">{homeFooterLinkArea}</div>
          <div className="float-right">{getCopyrightLabel()}</div>
        </div>
      </div>
    </footer>
  );

  return isExpertSearchVariant ? (
    <>
      <section className="l-content__section l-content__section--medium">
        <div>
          {expert && Object.keys(expert).length > 0 ? (
            <div className="l-grid">
              <div className="l-grid__col l-grid__col--m-8-12">
                <div className="clearfix">
                  <figure className="l-broker-bc__broker-image l-broker-bc__broker-image--footer">
                    <Image src={photoUrl} />
                  </figure>
                  <div>Ihr VHV-Bauexperte vor Ort</div>
                  <h3>{name}</h3>
                  <p className="l-broker-bc__phone">
                    <i className="ico-phone"></i> {telephoneNumber}
                  </p>
                  <p>Fax: {faxNumber}</p>
                </div>
              </div>
              <div className="l-grid__col l-grid__col--m-4-12">
                <div className="">
                  <div className="spacer-small">
                    <button
                      onClick={() => setVisibleBrokerBc('termin')}
                      className="m-button m-button--sec m-button--full-width ga-footer-termin"
                    >
                      Einen Termin vereinbaren
                    </button>
                  </div>
                  <div className="spacer-small">
                    <button
                      onClick={() => setVisibleBrokerBc('rueckruf')}
                      className="m-button m-button--sec m-button--full-width ga-footer-anruf"
                    >
                      Einen Rückruf anfordern
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => setVisibleBrokerBc('email')}
                      className="m-button m-button--sec m-button--full-width ga-footer-e-mail]"
                    >
                      Eine E-Mail-Anfrage stellen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <h3>Ihr VHV-Bauexperte vor Ort</h3>
              <div className="spacer">
                Haben Sie noch Fragen? Wir helfen Ihnen gern weiter. Finden Sie schnell und
                unkompliziert den VHV-Bauexperten in Ihrer Nähe &ndash; einfach PLZ eingeben!
              </div>
              <form
                noValidate
                onSubmit={e => {
                  e.preventDefault();
                  if (isValidInput) {
                    findExpert(inputValue, () => {
                      setIsExpertSearchModalVisible(true);
                    });
                  }
                }}
                className="l-broker-bc__zip-search"
              >
                <input
                  className={`${inputValue === '' || isValidInput ? '' : 'invalid'}`}
                  type="number"
                  value={inputValue}
                  placeholder="Ihre Postleitzahl"
                  pattern="\d{5}"
                  required
                  onChange={e => setInputValue(e.target.value)}
                />
                <button
                  className="m-button m-button--square"
                  onClick={() => {
                    if (isValidInput) {
                      findExpert(inputValue, () => {
                        setIsExpertSearchModalVisible(true);
                      });
                    }
                  }}
                >
                  {isLoading ? <i className="ico-spinner" /> : <i className="ico-search" />}
                </button>
              </form>
            </div>
          )}
        </div>
      </section>
      {footerWindup}
    </>
  ) : (
    <div className="CorporateContactFooter">
      <div className="l-content__section l-content__section--medium">
        <CorporateContact />
      </div>

      {footerWindup}
    </div>
  );
};

export default CorporateContactFooter;
