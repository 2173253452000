import React, { useContext } from 'react';

import { BusinessCardPageContext } from '../../containers/BusinessCardPage';
import Link from '../ui/Link/Link';

const CalculatorLinkComponent = props => {
  const { broker } = useContext(BusinessCardPageContext) || {};

  const { brokerNumber } = broker || {};

  const linkParam = brokerNumber
    ? `iVMNr=${brokerNumber.replace('-', '').length < 9 ? `0${brokerNumber}` : brokerNumber}`
    : '';

  return (
    <li className="external">
      <Link {...props} linkParam={linkParam} />
    </li>
  );
};

export default CalculatorLinkComponent;
