import React from 'react';

import Link from '../ui/Link/Link';

const DownloadComponent = props => {
  const { title, description, attachment } = props;
  return (
    <div className="m-box spacer-small">
      <h3>{title}</h3>
      {description && <p>{description}</p>}
      <Link linkTo={attachment} extraClass="m-button" linkType="asset" showIcon label="Download" />
    </div>
  );
};
export default DownloadComponent;
