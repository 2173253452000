import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import RichText from '../RichText/RichText';
import FormErrorMessage from './FormErrorMessage';
import FormTooltip from './FormTooltip';

interface FormCheckBoxProps {
  label: string;
  optional?: boolean;
  name: string;
  description?: string;
  tooltipText?: string;
  errorMessage?: string;
  children?: any;
}

const FormCheckBox: React.FC<FormCheckBoxProps> = ({
  label,
  optional,
  name,
  description,
  tooltipText,
  errorMessage,
  children
}) => {
  const {
    register,
    unregister,
    formState: { errors, dirtyFields }
  } = useFormContext();
  const hasError = errors[name];

  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  return (
    <>
      <div className="m-form__group">
        <label></label>
        <div
          className={classNames(
            'm-form__group-fields',
            description ? 'm-form__group-fields--no-label' : ''
          )}
        >
          <div className="l-grid">
            <div className="l-grid__col--12-12">
              <input
                type="checkbox"
                id={name}
                {...register(name, { required: !optional })}
                className={classNames({
                  'ng-touched': dirtyFields[name],
                  'ng-invalid': errors[name]
                })}
              />
              <label
                className={classNames('checkbox', description ? 'medium' : 'boxstyle')}
                htmlFor={name}
              >
                {description ? (
                  <span>
                    <strong>{label}</strong>
                    <RichText richText={description} />
                  </span>
                ) : !Array.isArray(children) ? (
                  children
                ) : (
                  <span>{label}</span>
                )}
              </label>
            </div>
          </div>
          {tooltipText && <FormTooltip label={label} name={name} tooltipText={tooltipText} />}
        </div>
      </div>
      {hasError && errorMessage && <FormErrorMessage errorMessage={errorMessage} />}
    </>
  );
};

export default FormCheckBox;
