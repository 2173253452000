import axios from 'axios';

const reducer = (accumulator, curr) => accumulator + curr;
const getRate = (rating, totalRatings, i) => {
  return ((i + 1) * rating) / totalRatings;
};

export const getCheck24Ratings = async () => {
  const result = await axios
    .get(
      'https://api.efeedback.de/reports/comments/products/15/providers/177?auth_token=cmVtb3RlLXZodjpaYnhtaGt0OQ'
    )
    .then((res: any) => res.data.result);

  if (result) {
    const neutralCount = result.ratings
      .slice(4, 7)
      .map(rating => rating)
      .reduce(reducer);

    const recommendationRate = Math.round(
      (result.ratings
        .slice(7, 11)
        .map(rating => rating)
        .reduce(reducer) /
        (result.total_ratings - neutralCount)) *
        100
    );

    const averageRating =
      result.ratings
        .slice(0, 11)
        .map((rating: number, i: number) => getRate(rating, result.total_ratings, i))
        .reduce(reducer) / result.ratings.slice(0, 11).length;
    const stars = Math.round((averageRating * 4 + 1) * 10) / 10;
    return { stars, recommendationRate, totalRating: result.total_ratings };
  }
};
