import './SignetCardsComponent.scss';

import React from 'react';

import useSignetScrollTransitionFallback from '../../hooks/useSignetScrollTransitionFallback';
import Container from '../ui/Container/Container';
import SignetCardComponent from './SignetCardComponent';

interface ISignet {
  title: string;
  description: string;
  signetImage: any;
}

const mapSignets = (signets: ISignet[]) => {
  return Object.values(signets).filter(signet => signet.signetImage);
};

const SignetCardsComponent = ({ title, signets }: { title: string; signets: ISignet[] }) => {
  if (!signets) return null;

  const cards = mapSignets(signets).map(signet => ({
    img: signet.signetImage,
    title: signet.title,
    description: signet.description
  }));

  useSignetScrollTransitionFallback('.SignetCards__container', '.SignetCard');

  return (
    <Container>
      <div className="SignetCards">
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <div className="SignetCards__container">
          <div className="SignetCards__inner">
            {cards.map((card, i) => {
              return <SignetCardComponent card={card} key={i} index={i} />;
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignetCardsComponent;
