import { ILinkProps } from '../Link/Link';
export interface IContactData {
  private: ICustomer[];
  corporate: ICustomer[];
}
export interface ICustomer {
  name?: string;
  activeProductSelectors?: string[];
  title: string;
  icon: { name: string };
  text: string;
  subProducts?: ISubProduct[];
  clientTypes?: ISubProduct[];
  newClient?: INewClient;
  existingClient?: IExistingClient;
}
export interface ISubProduct {
  name: string;
  activeProductSelectors?: string[];
  title: string;
  icon: { name: string };
  subProducts?: ISubProduct[];
  clientTypes?: ISubProduct[];
  newClient?: INewClient;
  existingClient?: IExistingClient;
  damageProducts?: ISubProduct[];
}

interface INewClient {
  hotline?: IHotline;
  mailForm?: ILinkProps; ///kontaktformular/email-anfrage
}

interface IExistingClient {
  hotline?: IHotline;
  mailForm?: ILinkProps; ///kontaktformular/email-anfrage
  damage?: IDamage;
  additionalTabs?: IAdditionalTab[];
}
interface IHotline {
  phone?: string; //new: 0511-9078811    existing:
  availability?: string; //Montag-Freitag 8-18 Uhr
  recallForm?: ILinkProps; ///kontaktformular/rueckrufwunsch
}
interface IDamage {
  phone?: string;
  availability?: string; //Montag-Freitag 8-18 Uhr
  mailForm?: { [key: string]: string | ILinkProps } | string | ILinkProps;
  link?: ILinkProps;
  additionalLinks?: ILinkProps[];
  additionalTabs?: IAdditionalTab[];
}

interface IAdditionalTab {
  title?: string;
  text?: string;
  label?: string;
  link?: ILinkProps;
}

const clientTypes = [
  {
    title: 'Neukunde',
    name: 'newClient',
    icon: { name: 'neukunde' }
  },
  {
    title: 'Bestands­kunde',
    name: 'existingClient',
    icon: { name: 'bestandskunde' }
  }
];

const kundenportalTab = {
  title: 'Kundenportal',
  text: 'Verwalten Sie Ihr Anliegen selbst',
  label: 'Zum Kundenportal',
  link: {
    linkTo: 'https://www.vhv.de/kundenportal',
    linkType: 'external',
    label: 'Zum Kundenportal'
  }
};

const contactData: IContactData = {
  private: [
    {
      name: 'kfz',
      activeProductSelectors: ['quad', 'trike', 'telematik', 'kfz-versicherung'],
      title: 'KFZ',
      icon: { name: 'auto' },
      text: 'Pkw, Wohnmobil, Motorrad, Quad, Trike, Telematik',
      subProducts: [
        {
          name: 'pkw',
          title: 'Pkw, Wohnmobil, Motorrad',
          icon: { name: 'auto' },
          damageProducts: [
            {
              name: 'general',
              title: 'Alle Schäden',
              icon: { name: 'auto' }
            },
            {
              name: 'hail',
              title: 'Hagelschaden',
              icon: { name: 'unwetter' }
            }
          ],
          clientTypes,
          activeProductSelectors: ['auto', 'wohnmobil', 'motorrad'],
          existingClient: {
            hotline: {
              phone: '0511-65505050'
              // availability: 'Montag, Mittwoch, Freitag 8-18 Uhr',
            },
            additionalTabs: [kundenportalTab],
            damage: {
              phone: '0511-65505020',
              availability: '24 Std.',
              mailForm: {
                general: '/schaden-melden/kfz',
                hail: '/schaden-melden/kfz/hagel'
              },
              additionalTabs: [
                {
                  title: 'Schutzbriefnummern',
                  text: 'Inland gebührenfrei: 0800 570 11 11 – Anrufe aus dem Ausland: +49 221 827 73 04'
                }
              ]
            }
          }
        },
        {
          name: 'telematik',
          activeProductSelectors: ['pkw-telematik'],
          title: 'Telematik',
          icon: { name: 'fahrzeuge' },
          damageProducts: [
            {
              name: 'general',
              title: 'Alle Schäden',
              icon: { name: 'auto' }
            },
            {
              name: 'hail',
              title: 'Hagelschaden',
              icon: { name: 'unwetter' }
            }
          ],
          newClient: {
            hotline: {
              phone: '0511-9078811'
              // availability: 'Montag-Freitag 9-17 Uhr',
            }
          },
          clientTypes,
          existingClient: {
            additionalTabs: [kundenportalTab],
            hotline: {
              phone: '0511-65505050'
              // availability: 'Montag, Mittwoch, Freitag 8-18 Uhr',
            },
            damage: {
              phone: '0511-65505020',
              availability: '24 Std.',
              mailForm: {
                general: '/schaden-melden/kfz',
                hail: '/schaden-melden/kfz/hagel'
              },
              additionalTabs: [
                {
                  title: 'Schutzbriefnummern',
                  text: 'Inland gebührenfrei: 0800 570 11 11 – Anrufe aus dem Ausland: +49 221 827 73 04'
                }
              ]
            }
          }
        },
        {
          name: 'moped',
          title: 'Moped',
          icon: { name: 'moped' },
          clientTypes,
          activeProductSelectors: ['moped'],
          newClient: {
            hotline: {
              phone: '0511-9076731'
            }
          },
          existingClient: {
            hotline: {
              phone: '0511-9076731'
              // availability: 'Montag-Freitag 9-17 Uhr',
            },
            additionalTabs: [kundenportalTab],
            damage: {
              phone: '0511-65505020',
              availability: '24 Std.',
              mailForm: '/schaden-melden/kfz',
              additionalTabs: [
                {
                  title: 'Schutzbriefnummern',
                  text: 'Inland gebührenfrei: 0800 570 11 11 – Anrufe aus dem Ausland: +49 221 827 73 04'
                }
              ]
            }
          }
        }
      ]
    },
    {
      name: 'haftpflicht',
      activeProductSelectors: ['haftpflichtversicherung'],
      title: 'Haft­pflicht',
      icon: { name: 'haftpflicht' },
      text: 'Privat, Tierhalter, Haus & Grundbesitz, Bauherren',
      subProducts: [
        {
          name: 'privat',
          title: 'Privat­haftpflicht',
          icon: { name: 'haftpflicht' },
          clientTypes,
          activeProductSelectors: ['privat'],
          newClient: {
            hotline: {
              phone: '0511-9078811'
              // availability: 'Montag-Freitag 9-17 Uhr',
            }
          },
          existingClient: {
            additionalTabs: [kundenportalTab],
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505040',
              mailForm: '/schaden-melden/haftpflicht/privathaftpflicht'
              // additionalTabs: [
              //   {
              //     link: { linkTo: '112.0001.02', linkType: 'printed_media' },
              //   },
              // ],
            }
          }
        },
        {
          name: 'tierhalter',
          title: 'Tierhalter',
          icon: { name: 'tierhalter' },
          clientTypes,
          activeProductSelectors: ['tierhalter'],
          newClient: {
            hotline: {
              phone: '0511-9078811'
              // availability: 'Montag-Freitag 9-17 Uhr',
            }
          },
          existingClient: {
            additionalTabs: [kundenportalTab],
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505040',
              mailForm: '/schaden-melden/haftpflicht/tierhalter'

              // additionalTabs: [
              //   {
              //     link: { linkTo: '112.0001.02', linkType: 'printed_media' },
              //   },
              // ],
            }
          }
        },
        {
          name: 'hausundgrundbesitz',
          title: 'Haus und Grundbesitz',
          icon: { name: 'hausundgrund' },
          clientTypes,
          activeProductSelectors: ['hausundgrundbesitz'],
          newClient: {
            hotline: {
              phone: '0511-9078811'
              // availability: 'Montag-Freitag 9-17 Uhr',
            }
          },
          existingClient: {
            additionalTabs: [kundenportalTab],
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505040',
              mailForm: '/schaden-melden/haftpflicht/hausundgrundbesitz'
              // additionalTabs: [
              //   {
              //     link: { linkTo: '112.0001.02', linkType: 'printed_media' },
              //   },
              // ],
            }
          }
        },
        {
          name: 'bauherren  ',
          title: 'Bau­herren',
          icon: { name: 'bauherren' },
          clientTypes,
          activeProductSelectors: ['bauherren'],
          newClient: {
            hotline: {
              phone: '0511-9078811'
              // availability: 'Montag-Freitag 9-17 Uhr',
            }
          },
          existingClient: {
            additionalTabs: [kundenportalTab],
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505040',
              mailForm: '/schaden-melden/haftpflicht/bauherren'
              // additionalTabs: [
              //   {
              //     link: { linkTo: '112.0001.02', linkType: 'printed_media' },
              //   },
              // ],
            }
          }
        }
      ]
    },
    {
      name: 'unfallversicherung',
      activeProductSelectors: ['unfallversicherung'],
      title: 'Unfall­versicherung',
      icon: { name: 'unfall' },
      text: 'Unfall­versicherung',
      newClient: {
        hotline: {
          phone: '0511-9078811'
          // availability: 'Montag-Freitag 9-17 Uhr',
        }
      },
      clientTypes,
      existingClient: {
        additionalTabs: [kundenportalTab],
        hotline: {
          phone: '0511-65505030'
        },
        damage: {
          phone: '0511-65505060',
          mailForm: '/schaden-melden/unfall'
          // additionalTabs: [
          //   {
          //     link: { linkTo: '310.0001.02', linkType: 'printed_media' },
          //   },
          // ],
        }
      }
    },
    {
      name: 'hausundwohnen',
      activeProductSelectors: ['hausversicherung'],
      title: 'Haus & Wohnen',
      icon: { name: 'wohngebaude' },
      text: 'Hausrat, Glas, Wohngebäude, Photovoltaik, Wärmepumpe',
      subProducts: [
        {
          name: 'wohngebaeude',
          activeProductSelectors: ['wohngebaeude', 'wohngebaeudeversicherung'],
          title: 'Wohngebäude',
          icon: { name: 'wohngebaude' },
          damageProducts: [
            {
              name: 'feuer',
              title: 'Feuer',
              icon: { name: 'wohngebaeude-feuer' }
            },
            {
              name: 'elementar',
              title: 'Elementar',
              icon: { name: 'wohn-elementar' }
            },
            {
              name: 'sturm-hagel',
              title: 'Sturm und Hagel',
              icon: { name: 'unwetter' }
            },
            {
              name: 'leitungswasser',
              title: 'Leitungs­­wasser',
              icon: { name: 'wohngebaeude-leitungswasser' }
            },
            {
              name: 'sonstiges',
              title: 'Sonstige Schäden',
              icon: { name: 'wohngebaude' }
            }
          ],
          // newClient: {
          //   hotline: {
          //     availability: 'Montag-Freitag 9-17 Uhr',
          //   },
          // },
          clientTypes,
          existingClient: {
            additionalTabs: [kundenportalTab],
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505044',
              mailForm: {
                feuer: '/schaden-melden/wohngebaeude/feuer',
                elementar: '/schaden-melden/wohngebaeude/elementar',
                'sturm-hagel': '/schaden-melden/wohngebaeude/sturm-hagel',
                leitungswasser: '/schaden-melden/wohngebaeude/leitungswasser',
                sonstiges: '/schaden-melden/wohngebaeude/sonstiges'
              }
            }
          }
        },
        {
          name: 'photovoltaik',
          activeProductSelectors: ['photovoltaik'],
          title: 'Photovoltaik',
          icon: { name: 'photovoltaik' },
          newClient: {
            hotline: {
              phone: '0221-2070296'
            }
          },
          clientTypes,
          existingClient: {
            hotline: {
              phone: '0511-9073194'
            },
            damage: {
              phone: '0511-9073792',
              mailForm: '/kontaktformular/photovoltaik-schaden'
            }
          }
        },
        {
          name: 'hausrat',
          activeProductSelectors: ['hausrat', 'hausratversicherung', 'hausundgrundbesitz'],
          title: 'Hausrat',
          icon: { name: 'hausrat' },
          damageProducts: [
            {
              name: 'fahrrad',
              title: 'Fahrrad',
              icon: { name: 'fahrrad-schaden' }
            },
            {
              name: 'sturm-hagel',
              title: 'Sturm und Hagel',
              icon: { name: 'unwetter' }
            },
            {
              name: 'blitzschlag-ueberspannung',
              title: 'Blitzschlag und Überspannung',
              icon: { name: 'flash' }
            },
            {
              name: 'diebstahl-aus-kfz',
              title: 'Diebstahl aus Kfz',
              icon: { name: 'diebstahl' }
            },
            {
              name: 'elementar',
              title: 'Elementar',
              icon: { name: 'hausrat-elementar' }
            },
            {
              name: 'feuer',
              title: 'Feuer',
              icon: { name: 'hausrat-feuer' }
            },
            {
              name: 'leitungswasser',
              title: 'Leitungs­­wasser',
              icon: { name: 'wohngebaeude-leitungswasser' }
            },
            {
              name: 'einbruch-diebstahl',
              title: 'Einbruch und Diebstahl',
              icon: { name: 'einbruch-diebstahl' }
            },
            {
              name: 'sonstiges',
              title: 'Sonstige Schäden',
              icon: { name: 'hausrat' }
            }
          ],
          newClient: {
            hotline: {
              phone: '0511-9078811'
              // availability: 'Montag-Freitag 9-17 Uhr',
            }
          },
          clientTypes,
          existingClient: {
            additionalTabs: [kundenportalTab],
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505044',
              mailForm: {
                fahrrad: '/schaden-melden/hausrat/fahrrad',
                'sturm-hagel': '/schaden-melden/hausrat/sturm-hagel',
                'blitzschlag-ueberspannung': '/schaden-melden/hausrat/blitzschlag-ueberspannung',
                'diebstahl-aus-kfz': '/schaden-melden/hausrat/diebstahl-aus-kfz',
                elementar: '/schaden-melden/hausrat/elementar',
                feuer: '/schaden-melden/hausrat/feuer',
                leitungswasser: '/schaden-melden/hausrat/leitungswasser',
                'einbruch-diebstahl': '/schaden-melden/hausrat/einbruch-diebstahl',
                sonstiges: '/schaden-melden/hausrat/sonstiges'
              }
            }
          }
        },
        {
          name: 'glas',
          activeProductSelectors: ['wohngebaeude'],
          title: 'Glas',
          icon: { name: 'glas' },
          // newClient: {
          //   hotline: {
          //     availability: 'Montag-Freitag 9-17 Uhr',
          //   },
          // },
          clientTypes,
          existingClient: {
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505044',
              mailForm: '/schaden-melden/glas'
            }
          }
        },
        {
          name: 'waermepumpe',
          activeProductSelectors: ['waermepumpe'],
          title: 'Wärmepumpe',
          icon: { name: 'waermepumpe' },
          clientTypes,
          newClient: {
            hotline: {
              phone: '0511-9078811'
            }
          },
          existingClient: {
            hotline: {
              phone: '0511-9078811'
            },
            damage: {
              phone: '0511-9073792',
              mailForm: '/schaden-melden/waermepumpe'
            }
          }
        }
      ]
    },
    {
      name: 'bau',
      activeProductSelectors: ['bauversicherung', 'bauhelfer'],
      title: 'Bau',
      icon: { name: 'bauherren' },
      text: 'Bauleistung, Bauherren-Haftpflicht, Feuerrohbau',
      subProducts: [
        {
          name: 'bauleistung',
          activeProductSelectors: ['bauleistung'],
          title: 'Bauleistung',
          icon: { name: 'bauherren' },
          newClient: {
            hotline: {
              phone: '0180-2232100'
            }
          },
          clientTypes,
          existingClient: {
            hotline: {
              phone: '0180-2232100 '
            },
            damage: {
              phone: '0511-65505070',
              mailForm: '/kontaktformular/bau-schadenmeldung'
            }
          }
        },
        {
          name: 'bauherren',
          activeProductSelectors: ['bauherren'],
          title: 'Bauherren',
          icon: { name: 'bauherren' },
          newClient: {
            hotline: {
              phone: '0511-9078811'
              // availability: 'Montag-Freitag 9-17 Uhr',
            }
          },
          clientTypes,
          existingClient: {
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505040',
              mailForm: '/schaden-melden/haftpflicht/bauherren'
            }
          }
        },
        {
          name: 'feuerrohbau',
          activeProductSelectors: ['feuerrohbau'],
          title: 'Feuerrohbau',
          icon: { name: 'bauherren' },
          newClient: {
            hotline: {
              phone: '0511-9078811'
              // availability: 'Montag-Freitag 9-17 Uhr',
            }
          },
          clientTypes,
          existingClient: {
            hotline: {
              phone: '0511-65505030'
            },
            damage: {
              phone: '0511-65505070',
              mailForm: '/kontaktformular/bau-schadenmeldung'
            }
          }
        }
      ]
    },
    {
      name: 'rechtsschutzversicherung',
      activeProductSelectors: ['rechtsschutzversicherung'],
      title: 'Rechtsschutz',
      icon: { name: 'rechtsschutz' },
      text: 'Rechtsschutz-Versicherungen',
      newClient: {
        hotline: {
          phone: '089-53299649',
          recallForm: { linkTo: '/formulare/rechtsschutz-rueckrufformular' }
        },
        mailForm: { linkTo: '/formulare/rechtsschutz-kontaktformular' }
      },
      clientTypes,
      existingClient: {
        additionalTabs: [kundenportalTab],
        hotline: {
          phone: '0621-4204888'
        },
        damage: {
          phone: '0621-4204222',
          mailForm: '/kontaktformular/rechtsschutz-schaden'
        }
      }
    },
    {
      name: 'leben',
      activeProductSelectors: ['leben', 'risikoleben', 'berufsunfaehigkeit', 'sterbegeld'],
      title: 'Leben',
      icon: { name: 'all-products' },
      text: 'Risikoleben, Berufsunfähigkeit, Sterbegeld',
      newClient: {
        hotline: {
          phone: '0511-907 6565',
          recallForm: { linkTo: '/formulare/hannoversche-rueckrufformular' }
        },
        mailForm: { linkTo: '/formulare/hannoversche-kontaktformular' }
      },
      clientTypes,
      existingClient: {
        hotline: {
          phone: '0511-907 6565',
          recallForm: { linkTo: '/formulare/hannoversche-rueckrufformular' }
        },
        mailForm: { linkTo: '/formulare/hannoversche-kontaktformular' },
        damage: {
          phone: '0511-9565658',
          link: {
            linkTo: '/formulare/hannoversche-kontaktformular?appView=true',
            linkType: 'page'
          }
        }
      }
    },
    {
      name: 'kaution-buergschaft',
      activeProductSelectors: ['kaution-buergschaft'],
      title: 'Kaution & Bürgschaft',
      icon: { name: 'kaution-buergschaft' },
      text: 'Kaution & Bürgschaft',
      newClient: {
        hotline: {
          phone: '',
          availability: '24 Std.',
          recallForm: { linkTo: '/formulare/kaution-schadenmeldung' }
        },
        mailForm: { linkTo: '/formulare/kaution-schadenmeldung' }
      },
      clientTypes,
      existingClient: {
        hotline: {
          // phone: '???',
          availability: '',
          recallForm: { linkTo: '/' }
        },
        mailForm: { linkTo: '/' },
        damage: {
          // phone: '???',
          availability: '24 Std.',
          link: {
            linkTo: '/formulare/kaution-schadenmeldung',
            linkType: 'page'
          },
          additionalTabs: [
            {
              link: {
                linkTo: '/google_map/kaution_buergschaft.pdf',
                linkType: 'asset'
              }
            }
          ]
        }
      }
    }
  ],
  corporate: [
    {
      name: 'haftpflicht',
      activeProductSelectors: ['haftpflichtversicherung'],
      title: 'Haftplicht',
      icon: { name: 'haftpflicht' },
      text: 'Betrieb, Beruf, Betreiber, Vermögensschaden, Haus-/Grundbesitzer und Bauherren',
      newClient: {
        hotline: {
          availability: '24 Std.'
        }
      },
      clientTypes,
      existingClient: {
        damage: {
          phone: '0511-65505040',
          mailForm: '/kontaktformular/haftpflicht-schaden',
          additionalTabs: [
            {
              link: { linkTo: '112.0001.02', linkType: 'printed_media' }
            }
          ]
        }
      },

      subProducts: [
        {
          name: 'betrieb',
          title: 'Betriebs­haft­pflicht',
          icon: { name: 'betriebshaftpflicht' },
          clientTypes,
          existingClient: {
            damage: {
              phone: '0511-65505070',
              // availability: '24 Std.',
              link: {
                linkTo: '/firmen/firmenformulare/betriebshaftpflicht-schaden',
                linkType: 'page'
              },
              additionalTabs: [
                {
                  link: {
                    linkTo: '111.0001.10',
                    linkType: 'printed_media'
                  }
                }
              ]
            }
          }
        },
        {
          name: 'beruf',
          title: 'Be­rufs­haft­pflicht',
          icon: {
            name: 'berufshaftpflicht-architekten-ingenieure'
          },
          clientTypes,
          newClient: {
            hotline: {
              phone: '0180 - 2232 100'
            }
          },
          existingClient: {
            damage: {
              phone: '0511-65505090',
              // availability: '24 Std.',
              link: {
                linkTo: '/firmen/firmenformulare/berufshaftpflicht-schaden',
                linkType: 'page'
              },
              additionalTabs: [
                {
                  link: {
                    linkTo: '113.0001.01',
                    linkType: 'printed_media'
                  }
                }
              ]
            }
          }
        },
        {
          name: 'betreiber',
          title: 'Be­trei­ber­haft­pflicht',
          icon: { name: 'betreiberhaftpflicht' },
          clientTypes,
          newClient: {
            hotline: {
              phone: '0221 20702-96'
            }
          },
          existingClient: {
            damage: {
              phone: '0511-65505070',
              // availability: '24 Std.',
              link: {
                linkTo: '/firmen/firmenformulare/berufshaftpflicht-schaden',
                linkType: 'page'
              }
            }
          }
        },
        {
          name: 'vermoegensschaden',
          title: 'Ver­mö­gens­scha­den­haft­pflicht',
          icon: { name: 'vermoegenschaden-haftpflicht' },
          clientTypes,
          existingClient: {
            damage: {
              phone: '0511-65505090',
              // availability: '24 Std.',
              link: {
                linkTo: '/firmen/firmenformulare/berufshaftpflicht-schaden',
                linkType: 'page'
              }
            }
          }
        }
      ]
    },
    {
      name: 'kfz',
      activeProductSelectors: ['kfz-versicherung'],
      title: 'KFZ',
      icon: { name: 'auto' },
      text: 'Auto, Flotte',
      newClient: {
        hotline: {
          phone: '0511-9078811',
          availability: '24 Std.'
        }
      },
      clientTypes,
      existingClient: {
        hotline: {
          availability: '24 Std.'
        },
        damage: {
          phone: '0511-65505020',
          mailForm: '/kontaktformular/kfz-schaden'
        }
      }
    },
    {
      name: 'gebaeude',
      activeProductSelectors: ['gebauedeversicherung'],
      title: 'Gebäude & Inhalt',
      icon: { name: 'gebaeude-inhalt' },
      text: 'Gebäude, Inhalt, Betriebsunterbrechung',
      newClient: {
        hotline: {
          availability: '24 Std.'
        }
      },
      clientTypes,
      existingClient: {
        hotline: {
          phone: '0511-65505030',
          availability: '24 Std.'
        },
        damage: {}
      },
      subProducts: [
        {
          name: 'gebaeude',
          title: 'Gebäude',
          icon: { name: 'gebaeude' },
          clientTypes,
          existingClient: {
            damage: {
              phone: '0511-65505044',
              link: {
                linkTo: '/firmen/firmenformulare/sach-schaden',
                linkType: 'page'
              },
              additionalTabs: [
                {
                  link: {
                    linkTo: '600.0001.20',
                    linkType: 'printed_media'
                  }
                }
              ]
            }
          }
        },
        {
          name: 'inhalt',
          title: 'Inhalt',
          icon: { name: 'gebaeude-inhalt' },
          clientTypes,
          existingClient: {
            damage: {
              phone: '0511-65505044',
              link: {
                linkTo: '/firmen/firmenformulare/sach-schaden',
                linkType: 'page'
              },
              additionalTabs: [
                {
                  link: {
                    linkTo: '600.0001.20',
                    linkType: 'printed_media'
                  }
                }
              ]
            }
          }
        }
      ]
    },
    {
      name: 'bauleistung',
      activeProductSelectors: ['bauversicherung'],
      title: 'Bauen & Technik',
      icon: { name: 'bauleistung' },
      text: 'Bauleistung, Baugewährleistung, Montage, Baugeräte, Maschinen',
      newClient: {
        hotline: {
          availability: '24 Std.'
        }
      },
      clientTypes,
      existingClient: {
        hotline: {
          phone: '0511-65505030',
          availability: '24 Std.'
        }
      },
      subProducts: [
        {
          name: 'bauleistung',
          title: 'Bauleistung',
          icon: { name: 'bauleistung' },
          clientTypes,
          existingClient: {
            damage: {
              phone: '0511-9073792',
              link: {
                linkTo: '/firmen/firmenformulare/bauleistung-schaden',
                linkType: 'page'
              },
              additionalTabs: [
                {
                  link: {
                    linkTo: '410.0001.02',
                    linkType: 'printed_media'
                  }
                }
              ]
            }
          }
        },
        {
          name: 'baugewaehrleistung',
          title: 'Baugewähr-leistung',
          icon: { name: 'baugewaehrleistung' },
          clientTypes,
          existingClient: {
            damage: {
              link: {
                linkTo: '/firmen/firmenformulare/baugewaehrleistung-schaden',
                linkType: 'page'
              },
              additionalTabs: [
                {
                  link: {
                    linkTo: '410.0001.02',
                    linkType: 'printed_media'
                  }
                }
              ]
            }
          }
        },
        {
          name: 'montage',
          title: 'Montage',
          icon: { name: 'montage' },
          clientTypes,
          existingClient: {
            damage: {
              phone: '0511-9073792',
              additionalTabs: [
                {
                  link: {
                    linkTo: '410.0001.06',
                    linkType: 'printed_media'
                  }
                }
              ]
            }
          }
        },
        {
          name: 'baumaschinen',
          title: 'Bau­ge­rä­te­ver­si­che­rung',
          icon: { name: 'baumaschienen-geraete' },
          clientTypes,
          existingClient: {
            damage: {
              phone: '0511-9073792',
              link: {
                linkTo: '/firmen/firmenformulare/baugeraete-schaden',
                linkType: 'page'
              },
              additionalTabs: [
                {
                  link: {
                    linkTo: '410.0001.02',
                    linkType: 'printed_media'
                  }
                }
              ]
            }
          }
        }
      ]
    },
    {
      name: 'kaution-buergschaft',
      activeProductSelectors: ['kautionsversicherung'],
      title: 'Kaution & Bürgschaft',
      icon: { name: 'kaution-buergschaft' },
      text: 'Kaution & Bürgschaft',
      newClient: {
        hotline: {
          phone: '',
          availability: '24 Std.',
          recallForm: { linkTo: '/formulare/kaution-schadenmeldung' }
        },
        mailForm: { linkTo: '/formulare/kaution-schadenmeldung' }
      },
      clientTypes,
      existingClient: {
        hotline: {
          // phone: '???',
          availability: '',
          recallForm: { linkTo: '/' }
        },
        mailForm: { linkTo: '/' },
        damage: {
          // phone: '???',
          availability: '24 Std.',
          link: {
            linkTo: '/formulare/kaution-schadenmeldung',
            linkType: 'page'
          },
          additionalTabs: [
            {
              link: {
                linkTo: '/google_map/kaution_buergschaft.pdf',
                linkType: 'asset'
              }
            }
          ]
        }
      }
    },
    {
      name: 'elektronik-software',
      activeProductSelectors: ['elektronikversicherung'],
      title: 'Elektronik & Software',
      icon: { name: 'elektronik-software' },
      text: 'Elektronik & Software',
      newClient: {
        hotline: {
          phone: '',
          availability: '24 Std.',
          recallForm: { linkTo: '/formulare/kaution-schadenmeldung' }
        },
        mailForm: { linkTo: '/formulare/kaution-schadenmeldung' }
      },
      clientTypes,
      existingClient: {
        hotline: {
          availability: '',
          recallForm: { linkTo: '/' }
        },
        mailForm: { linkTo: '/' },
        damage: {
          phone: '0511-907-3792',
          link: {
            linkTo: '/firmen/firmenformulare/baugeraete-schaden',
            linkType: 'page'
          },
          additionalTabs: [
            {
              link: {
                linkTo: '421.0001.01',
                linkType: 'printed_media'
              }
            }
          ]
        }
      }
    },
    {
      name: 'erneuerbare-energien',
      activeProductSelectors: ['energieversicherung'],
      title: 'Erneuerbare Energien',
      icon: { name: 'erneuerbare-energien' },
      text: 'Photovoltaik, Biogas',
      newClient: {
        hotline: {
          phone: '',
          availability: '24 Std.',
          recallForm: { linkTo: '/formulare/kaution-schadenmeldung' }
        },
        mailForm: { linkTo: '/formulare/kaution-schadenmeldung' }
      },
      clientTypes,
      existingClient: {
        hotline: {
          availability: '',
          recallForm: { linkTo: '/' }
        },
        mailForm: { linkTo: '/' },
        damage: {
          phone: '0511-907-3792',
          additionalTabs: [
            {
              link: {
                linkTo: '421.0001.02',
                linkType: 'printed_media'
              }
            }
          ]
        }
      }
    }
  ]
};

export default contactData;
