import React, { useContext, useEffect, useState } from 'react';

import SiteDataContext from '../../../contexts/SiteDataContext';
import useUrlParams from '../../../hooks/useUrlParams';
import Image from '../Image/Image';
import Link from '../Link/Link';
import RichText from '../RichText/RichText';
import GuidebookDropdown from './GuidebookDropdown';

interface ISelectetTags {
  thema: string[];
  produkt: string[];
  saison: string[];
}

type Teaser = {
  description: string;
  guideTags: ISelectetTags;
  path: string;
  ogImage: string;
  title: string;
  metaDescription?: string;
};

function intersects<T>(array1: T[], array2: T[]): boolean {
  return array2.length === 0 || array1?.filter(value => array2.includes(value)).length > 0;
}

const filterTeasers = (
  items: Teaser[],
  themen: string[],
  produkte: string[],
  saisons: string[]
): Teaser[] => {
  return items?.filter(item => {
    return (
      intersects(item?.guideTags?.produkt || [], produkte) &&
      intersects(item?.guideTags?.thema || [], themen) &&
      intersects(item?.guideTags?.saison || [], saisons)
    );
  });
};

const Guidebook = ({ targetpage }) => {
  const [numberOfShowedTeaser, setNumberOfShowedTeaser] = useState<number>(5);
  const [selectedTags, setSelectedTags] = useState<ISelectetTags>({
    thema: [],
    produkt: [],
    saison: []
  });

  const [themaParams] = useUrlParams('thema');
  const [produktParams] = useUrlParams('produkt');
  const [saisonParams] = useUrlParams('saison');

  useEffect(() => {
    setSelectedTags({
      thema: themaParams !== '' ? themaParams.split(',') : [],
      produkt: produktParams !== '' ? produktParams.split(',') : [],
      saison: saisonParams !== '' ? saisonParams.split(',') : []
    });
  }, [themaParams, produktParams, saisonParams]);

  const { guidebookData = { guidebookTeasers: [], tags: {} } } = useContext(SiteDataContext) || {};

  const { tags, guidebookTeasers } = guidebookData || {};
  const { ratgeber } = tags || {};

  const ratgeberTags =
    ratgeber && ratgeber.thema && ratgeber.produkt && ratgeber.saison ? ratgeber : null;

  const filteredGuidebookTeasers: Teaser[] =
    filterTeasers(
      guidebookTeasers,
      selectedTags.thema || [],
      selectedTags.produkt || [],
      selectedTags.saison || []
    ) ||
    guidebookTeasers ||
    [];

  const setTag = (category: string, tag: string): void => {
    const newTags = { ...selectedTags };
    if (!newTags[category].includes(tag)) {
      newTags[category] = [...newTags[category], tag];
    } else {
      newTags[category] = newTags[category].filter(value => value !== tag);
    }
    setSelectedTags(newTags);
  };

  const isPinnedTeaser =
    !!targetpage &&
    selectedTags.produkt.length === 0 &&
    selectedTags.thema.length === 0 &&
    selectedTags.saison.length === 0;

  const teaserOutput = filteredGuidebookTeasers.slice(
    0,
    isPinnedTeaser ? numberOfShowedTeaser : numberOfShowedTeaser + 2
  );

  const getTeasers = (teasers: Teaser[], extendClass?: string) => {
    return teasers.map((teaser, index) => {
      const { path, ogImage, title, description, metaDescription } = teaser;
      return (
        <article
          key={index}
          style={{ display: 'block' }}
          className={`m-guidebook__tile${extendClass ? ` ${extendClass}` : ''}`}
        >
          <Link linkTo={path} linkType="page">
            <Image
              src={ogImage}
              extraClass="m-guidebook__header"
              isBackgroundImage
              sizes={{ w: 700 }}
            />
            <div className="m-guidebook__content">
              <h2>{title}</h2>
              <RichText richText={metaDescription || description} />
            </div>
          </Link>
        </article>
      );
    });
  };

  return (
    <div className="l-content__section">
      <div>
        <header className="m-guidebook__filter-bar">
          <div className="m-guidebook__filter-detail">
            <div>
              <div className="l-grid">
                {ratgeberTags &&
                  Object.keys(ratgeberTags).map((key: string) => {
                    return (
                      <GuidebookDropdown
                        key={key}
                        category={key}
                        options={ratgeberTags[key]}
                        selectedTags={selectedTags}
                        setTag={setTag}
                      />
                    );
                  })}
              </div>
              <div className="text-right">
                <a
                  className="text-s"
                  onClick={() => setSelectedTags({ thema: [], produkt: [], saison: [] })}
                  style={{ cursor: 'pointer' }}
                >
                  Filter zurücksetzen
                </a>
              </div>
            </div>
          </div>
        </header>
        {teaserOutput.length === 0 && (
          <div className="m-guidebook__no-item">
            Zu dieser Filterauswahl gibt es leider keine passenden Artikel.
          </div>
        )}
        <div className={`m-guidebook__wrapper ${isPinnedTeaser ? 'has-pinned' : 'has-no-pinned'}`}>
          {isPinnedTeaser && getTeasers([targetpage], 'm-guidebook__tile--pinned')}

          {teaserOutput.length > 0 && getTeasers(teaserOutput)}
        </div>
        {
          <div className="spacer-top text-center">
            <button
              className="m-button"
              onClick={() => setNumberOfShowedTeaser(numberOfShowedTeaser + 6)}
            >
              Mehr Artikel
            </button>
          </div>
        }
      </div>
    </div>
  );
};
export default Guidebook;
