import React, { useContext, useState } from 'react';

import AnnouncementBanner from '../../components/ui/AnnouncementBanner/AnnouncementBanner';
import Columns from '../../components/ui/Columns/Columns';
import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import ExpertSearchContext from '../../contexts/ExpertSearchContext';

const ExpertSearchPageHeaderArea = ({
  announcementText,
  linkTo,
  linkType,
  backgroundImage,
  subHeadline,
  headline,
  teaserText
}) => {
  const { isLoading, setIsExpertSearchModalVisible, findExpert } =
    useContext(ExpertSearchContext) || {};
  const [inputValue, setInputValue] = useState('');

  const postalCodeRegex = /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/;
  const isValidInput = inputValue.match(postalCodeRegex);

  return (
    <header>
      {announcementText && (
        <AnnouncementBanner
          announcementText={announcementText}
          linkTo={linkTo}
          linkType={linkType}
          variant="expert"
        />
      )}
      <Image
        isBackgroundImage
        src={backgroundImage}
        extraClass="l-content__header l-content__header--search"
      >
        <div>
          {subHeadline && <div>{subHeadline}</div>}
          {headline && <h1>{headline}</h1>}
          {teaserText && <p>{teaserText}</p>}
        </div>
      </Image>
      <div className="l-content__searchbar">
        <div>
          <Columns widths={[2, 1]}>
            <div>
              <label>Ihr persönlicher Berater – auch in Ihrer Nähe</label>
            </div>
            <div>
              <form
                noValidate
                onSubmit={e => {
                  e.preventDefault();
                  if (isValidInput) {
                    findExpert(inputValue, () => {
                      setIsExpertSearchModalVisible(true);
                    });
                  }
                }}
                className="l-content__search-input"
              >
                <i className="ico-location"></i>
                <input
                  className={`${inputValue === '' || isValidInput ? '' : 'invalid'}`}
                  value={inputValue}
                  type="number"
                  inputMode="numeric"
                  placeholder="Ihre Postleitzahl"
                  pattern="\d{5}"
                  onChange={e => setInputValue(e.target.value)}
                />
                <button type="submit">
                  {isLoading ? (
                    <Icon type="spinner" className="ico-spinner" />
                  ) : (
                    <Icon type="arrow-right" className="ico-arrow-right" />
                  )}
                </button>
              </form>
            </div>
          </Columns>
        </div>
      </div>
    </header>
  );
};

export default ExpertSearchPageHeaderArea;
