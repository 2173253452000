const getCalculatorPath = (
  calculatorPage: {
    path: string;
    template: string;
  },
  type?: string
) => {
  const appViewTemplates = ['CalculatorSeoPage'];
  const internalLinkTemplates = ['CalculatorSeoPage', 'FormPage'];
  const { path, template } = calculatorPage || {};
  const linkType = internalLinkTemplates.includes(template)
    ? 'page'
    : (type !== 'page' && type) || 'external';

  return {
    linkTo: calculatorPage && calculatorPage.path ? `${path}` : calculatorPage,
    linkParam: appViewTemplates.includes(template) ? 'appView=true' : '',
    linkType
  };
};

export default getCalculatorPath;
