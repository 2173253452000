import React from 'react';

import extractChildren from '../../helpers/extractChildren';

const GalleryComponent = ({ children, galleryType }) => {
  const { imageArea } = extractChildren(children);

  return galleryType === 'slider' ? (
    <div>
      <ul className="slides">{imageArea}</ul>
    </div>
  ) : (
    <div className="m-gallery">
      <div className="media-lightbox">{imageArea}</div>
    </div>
  );
};

export default GalleryComponent;
