import React, { useContext } from 'react';

import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import { BusinessCardPageContext } from '../../containers/BusinessCardPage';
import BrokerBcContext from '../../contexts/BrokerBcContext';

const BusinessCardPageStageArea = props => {
  const { subHeadline, backgroundImage: customBackgroundImage } = props;

  const { stageImage, broker } = useContext(BusinessCardPageContext) || {};
  const { setVisibleBrokerBc } = useContext(BrokerBcContext) || {};

  const { backgroundImage, name, photoUrl } = broker || {};

  return (
    <>
      <header
        className={`l-content__header l-broker-bc__header${
          stageImage ? ' l-broker-bc__header--stage' : ''
        }`}
        style={{
          backgroundImage: customBackgroundImage
            ? `url(${customBackgroundImage.source})`
            : !stageImage && backgroundImage
              ? `url(${backgroundImage.source})`
              : null
        }}
      >
        <div>
          {photoUrl && (
            <figure className="l-broker-bc__broker-image">
              <Image src={photoUrl} sizes={{ w: 250 }} />
            </figure>
          )}

          {stageImage && (
            <figure className="l-broker-bc__broker-stage-image">
              <Image src={stageImage} alt={name} sizes={{ w: 300 }} />
            </figure>
          )}

          <h1 className="l-broker-bc__head">{name}</h1>
          <div className="l-broker-bc__subhead">{subHeadline || broker?.subHeadline}</div>
          <div className="l-broker-bc__head-ctas">
            <a
              className="l-broker-bc__head-cta ga-header-anruf"
              style={{ cursor: 'pointer' }}
              onClick={() => setVisibleBrokerBc('rueckruf', broker)}
            >
              <span className="l-broker-bc__head-cta-button ga-header-anruf">
                <Icon type="hotline" className="ico-hotline ga-header-anruf"></Icon>
              </span>
              <span className="ga-header-anruf">Rückruf anfordern</span>
            </a>
            <a
              className="l-broker-bc__head-cta ga-header-e-mail"
              style={{ cursor: 'pointer' }}
              onClick={() => setVisibleBrokerBc('email', broker)}
            >
              <span className="l-broker-bc__head-cta-button ga-header-e-mail">
                <Icon type="mail" className="ico-mail ga-header-e-mail"></Icon>
              </span>
              <span className="ga-header-e-mail">E-Mail schreiben</span>
            </a>
            <a
              className="l-broker-bc__head-cta ga-header-termin"
              style={{ cursor: 'pointer' }}
              onClick={() => setVisibleBrokerBc('termin', broker)}
            >
              <span className="l-broker-bc__head-cta-button ga-header-termin">
                <Icon type="calendar" className="ico-calendar ga-header-termin"></Icon>
              </span>
              <span className="ga-header-termin">Vor-Ort-Termin vereinbaren</span>
            </a>
          </div>
        </div>
      </header>
    </>
  );
};
export default BusinessCardPageStageArea;
