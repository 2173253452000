import './Icon.scss';

import React from 'react';

import icons from '../../../styles/assets/icons';

interface IconProps {
  type: string;
  className?: string;
  size?: string | { width: string; height: string };
  viewBox?: string;
  onClick?: () => void;
  newSprites?: boolean;
}

const Icon = ({
  type,
  className: classNameProp,
  size,
  viewBox: viewBoxFromProps,
  onClick,
  newSprites
}: IconProps) => {
  const sizeStyle = size
    ? typeof size === 'string'
      ? { width: size, height: size }
      : { width: size.width, height: size.height }
    : {};

  const modifiedType = newSprites ? `${type}_2024` : type;
  const className = `Icon${classNameProp ? ` ${classNameProp}` : ''}${
    type && classNameProp !== `ico-${modifiedType}` ? ` ico-${modifiedType}` : ''
  }`;

  const { viewBox: viewBoxFromSprite, url } = icons[modifiedType] || {};

  const viewBox = viewBoxFromProps || viewBoxFromSprite || '0 0 512 512';

  return (
    <i
      className={className}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <svg
        viewBox={viewBox}
        style={{
          ...sizeStyle
        }}
      >
        <use href={url || `/.resources/vhv/webresources/static/media/sprite.svg#${modifiedType}`} />
      </svg>
    </i>
  );
};

export default Icon;
