import { RefObject, useEffect, useState } from 'react';

type Nullable<T> = T | null;
type Rect = Nullable<DOMRect>;

export const useRect = (target: RefObject<HTMLElement>): Rect => {
  const [rect, setRect] = useState<Rect>(null);

  useEffect(() => {
    const obs = new ResizeObserver(([el]: ResizeObserverEntry[]) => setRect(el.contentRect));
    if (target.current) {
      obs.observe(target.current);
    }
    return () => obs.disconnect();
  }, [target, setRect]);

  return rect;
};
