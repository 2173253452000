import React from 'react';

import extractChildren from '../helpers/extractChildren';
import { getCopyrightLabel } from '../helpers/getCurrentYear';

const ExpertHomePage = ({ children }) => {
  const {
    headerArea,
    expertSearchHeaderArea,
    solutionArea,
    contentArea,
    footerContactArea,
    footerLinkArea
  } = extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content l-content--corporate">
        {expertSearchHeaderArea}
        {solutionArea}
        {contentArea}
      </main>
      {footerContactArea}
      <footer className="l-footer nocontent">
        <div>
          <div className="l-footer__windup l-footer__windup--minimal">
            <div className="float-left">{footerLinkArea}</div>
            <div className="float-right">{getCopyrightLabel()}</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default ExpertHomePage;
