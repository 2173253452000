import React, { createContext, useState } from 'react';
export type TKnownExternalLinks = 'hannoversche' | 'hansemerkur';
type TShowLinkBox = TKnownExternalLinks | false;

interface ITransferLinkBoxContext {
  showLinkBox: TShowLinkBox;
  setShowLinkBox: (arg: TShowLinkBox) => void;
  image: string;
  linkToBox: string;
  setLinkToBox: (arg: string) => void;
}
const TransferLinkBoxContext = createContext<ITransferLinkBoxContext>(null);

export const TransferLinkBoxContextProvider = ({ children }) => {
  const [showLinkBox, setShowLinkBox] = useState<TShowLinkBox>(false);
  const [linkToBox, setLinkToBox] = useState<string>('');
  const image = '';

  return (
    <TransferLinkBoxContext.Provider
      value={{
        showLinkBox,
        setShowLinkBox,
        image,
        linkToBox,
        setLinkToBox
      }}
    >
      {children}
    </TransferLinkBoxContext.Provider>
  );
};
export default TransferLinkBoxContext;
