import React, { createContext, useState } from 'react';

const ContactBarContext = createContext(null);

export const ContactBarContextProvider = ({ children }) => {
  const [variant, setVariant] = useState('dark');

  return (
    <ContactBarContext.Provider
      value={{
        variant,
        setVariant
      }}
    >
      {children}
    </ContactBarContext.Provider>
  );
};
export default ContactBarContext;
