import './ContactMethodComponent.scss';

import React, { useContext } from 'react';

import ContactInformationContext from '../../contexts/ContactInformationContext';
import getVariantClasses from '../../helpers/getVariantClasses';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

type TContacMethod = 'phone' | 'mail' | 'damage';
type TVariants = '' | 'light';

interface IContactMethodProperties {
  method: TContacMethod;
  variants?: TVariants[];
}

const ContactMethodPhone = () => {
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};
  return (
    <Link
      linkTo={''}
      onClick={() => {
        setIsContactInformationModalVisible(true);
      }}
    >
      <div>
        <Icon type="phone-outlined" />
        <div className="ContactMethodComponent__contactMethodText">
          <div>Rufen Sie uns an!</div>
          <div>Montag - Freitag 8-18 Uhr</div>
        </div>
      </div>
      <Icon type="arrow-line-right" />
    </Link>
  );
};

const ContactMethodMail = () => {
  return (
    <Link linkTo="/kontaktformular/email-anfrage">
      <div>
        <Icon type="mail-outlined" />
        <div className="ContactMethodComponent__contactMethodText">
          <div>Schreiben Sie uns!</div>
          <div>Bequem und direkt per E-Mail</div>
        </div>
      </div>
      <Icon type="arrow-line-right" />
    </Link>
  );
};

const ContactMethodDamage = () => {
  return (
    <Link linkTo="/kundenservice/schaden-melden">
      <div>
        <Icon type="alert" />
        <div className="ContactMethodComponent__contactMethodText">
          <div>Schaden melden</div>
          <div>Wenn es schnell gehen muss</div>
        </div>
      </div>
      <Icon type="arrow-line-right" />
    </Link>
  );
};

const getContactMethod = (method: TContacMethod) => {
  const methods: { [key in TContacMethod]?: React.ReactNode } = {
    phone: <ContactMethodPhone />,
    mail: <ContactMethodMail />,
    damage: <ContactMethodDamage />
  };
  return methods[method];
};

const ContactMethodComponent = ({ method, variants }: IContactMethodProperties) => {
  return (
    <div className={getVariantClasses('ContactMethodComponent', variants)}>
      {getContactMethod(method)}
    </div>
  );
};

export default ContactMethodComponent;
