import React, { useContext } from 'react';

import RichText from '../../components/ui/RichText/RichText';
import { BusinessCardPageContext } from '../../containers/BusinessCardPage';

const IntroTextArea = ({ text }) => {
  const { broker } = useContext(BusinessCardPageContext) || {};
  const { firstName, lastName, gender } = broker || {};
  return (
    <section className="l-content__section">
      <div>
        <h3>
          {firstName} {lastName} &ndash; {gender === 'f' ? 'Ihre Expertin' : 'Ihr Experte'} vor Ort!
        </h3>
        <RichText richText={text} />
      </div>
    </section>
  );
};

export default IntroTextArea;
