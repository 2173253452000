import React, { FunctionComponent } from 'react';

import { getComponentDef } from '../components/components';
import capitalizeString from './capitalizeString';
import getChildNodes from './getChildNodes';

const getDynamicComponent = (node: any, props?: any) => {
  const { id, name, template } = node || {};

  const children = getChildNodes(node);

  const compName =
    template && typeof template === 'string'
      ? template.split('/').pop()
      : name && typeof name === 'string'
        ? capitalizeString(
            name
              .split('/')
              .pop()
              .replace(/[^a-zA-Z]/g, '')
          )
        : '';
  if (!compName) {
    return null;
  }

  const DynamicComp: FunctionComponent<any> =
    (getComponentDef(compName) && getComponentDef(compName).component[compName]) ||
    (children ? getComponentDef('Area').component['Area'] : null);

  return DynamicComp ? (
    <DynamicComp key={id} {...node} {...props}>
      {children}
    </DynamicComp>
  ) : null;
  // <div key={id} style={{ color: 'red' }}>
  //   Es fehlt das Template: {compName}
  // </div>
};

export default getDynamicComponent;
