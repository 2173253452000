import React, { useContext } from 'react';

import { FaqContext } from '../../FaqComponent/FaqComponent';

const Tile = ({ question, answer }) => {
  const { setLightBoxContent } = useContext(FaqContext) || {};
  return (
    <article
      className="tag-faq-question m-content-tiles__tile l-grid__col--4-12"
      onClick={() => setLightBoxContent(question, answer)}
    >
      <div className="Tile">
        <h4>{question}</h4>
      </div>
    </article>
  );
};

export default Tile;
