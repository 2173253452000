import React, { useContext } from 'react';

import { CalculatorSeoPageContext } from '../../containers/CalculatorSeoPage';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

const ContentInfoBox = ({ tel, availability }) => {
  const { productPageUrl } = useContext(CalculatorSeoPageContext) || {};
  return (
    <div className="l-landingseo__infobox">
      {tel && (
        <>
          <h3>
            Sie haben Fragen?
            <br /> Wir helfen gerne weiter!
          </h3>
          <p className="l-landingseo__telephone">
            <Icon type="phone" className="ico-phone"></Icon> {tel}
          </p>
        </>
      )}

      {availability && <p className="l-landingseo__availability">{availability}</p>}

      {productPageUrl && (
        <>
          <h3>Sie möchten weitere Infos zu diesem Produkt?</h3>
          <Link linkTo={productPageUrl}>
            <Icon type="arrow-right" className="ico-arrow-right"></Icon> Zur Produktseite
          </Link>
        </>
      )}
    </div>
  );
};
export default ContentInfoBox;
