import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const ProductPrivatePage = ({ children }) => {
  const { headerArea, stageArea, contentArea, downloadArea } = extractChildren(children);
  return (
    <>
      {headerArea}
      <main className="l-content">
        {stageArea}
        {contentArea}
        {downloadArea}
      </main>
      <Footer />
    </>
  );
};

export default ProductPrivatePage;
