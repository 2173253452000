import React, { createContext, useContext } from 'react';

import SiteDataContext from './SiteDataContext';
const ExpertEventsContext = createContext(null);

export const ExpertEventsContextProvider = ({ children }) => {
  const { expertEvents = [] } = useContext(SiteDataContext) || {};
  const today = new Date();

  const upcomingEvents = expertEvents
    .filter(page => new Date(page.startDate) > today)
    .sort((a, b) => Number(new Date(a.startDate)) - Number(new Date(b.startDate)));

  const archievedEvents = expertEvents
    .filter(page => new Date(page.startDate) < today)
    .sort((a, b) => Number(new Date(b.startDate)) - Number(new Date(a.startDate)));

  return (
    <ExpertEventsContext.Provider
      value={{
        upcomingEvents,
        archievedEvents
      }}
    >
      {children}
    </ExpertEventsContext.Provider>
  );
};
export default ExpertEventsContext;
