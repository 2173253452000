import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

interface INewsletterRegistrationContext {
  isRegistrationVisible: boolean;
  setIsRegistrationVisible: Dispatch<SetStateAction<boolean>>;
}

const initialValue = {
  isRegistrationVisible: false,
  setIsRegistrationVisible: null
};

const NewsletterRegistrationContext = createContext<INewsletterRegistrationContext>(initialValue);

export const NewsletterRegistrationContextProvider = ({ children }) => {
  const [isRegistrationVisible, setIsRegistrationVisible] = useState(
    initialValue.setIsRegistrationVisible
  );

  return (
    <NewsletterRegistrationContext.Provider
      value={{
        isRegistrationVisible,
        setIsRegistrationVisible
      }}
    >
      {children}
    </NewsletterRegistrationContext.Provider>
  );
};

export default NewsletterRegistrationContext;
