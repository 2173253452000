import './BenefitPointsComponent.scss';

import React from 'react';

import extractChildren from '../../helpers/extractChildren';

const BenefitPointsComponent = ({ title, children }) => {
  const { pointArea } = extractChildren(children);

  return (
    <div className="BenefitPointsComponent">
      {title && <h2 className="BenefitPointsComponent__title">{title}</h2>}
      {pointArea}
    </div>
  );
};

export default BenefitPointsComponent;
