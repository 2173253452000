import React from 'react';

import Columns from '../../components/ui/Columns/Columns';

const DownloadArea = ({ headline, children }) => {
  return (
    <section className="l-content__section">
      <div>
        {headline && <h2>{headline}</h2>}
        <Columns>{children}</Columns>
      </div>
    </section>
  );
};

export default DownloadArea;
