import React, { useContext } from 'react';

import Link from '../../components/ui/Link/Link';
import MultiButton from '../../components/ui/MultiButton/MultiButton';
import CorporateContactContext from '../../contexts/CorporateContactContext';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';

const BenefitTableFooterArea = props => {
  const { activeProduct } = useContext(ProductGroupsContext) || {};

  const { linkTo, calcIcon, calcLabel, showExpert, contactMethod } = activeProduct || {};

  const { getExpertSearchCta } = useContext(CorporateContactContext) || {};

  const showExpertSearch = contactMethod == 'expert' || (!contactMethod && showExpert);

  const { expertCallback, expertLabel, expertIconClass } =
    getExpertSearchCta(showExpertSearch) || {};

  const links = mapIndexedNodeProps(props, 'links');

  const { hideCalculatorButton, hideContactButton, hideDownloadButton } = props || {};

  return (
    <footer className="m-component-footer">
      <div>
        <div>
          {!hideCalculatorButton && linkTo && (
            <Link linkTo={linkTo} extraClass="m-button m-button--link tag-benefit-table-calc">
              {calcIcon && <i className={`ico-${calcIcon}`} />}
              {calcLabel || 'Beitrag berechnen'}
            </Link>
          )}
          {!hideContactButton && (
            <button
              className="m-button m-button--link tag-benefit-table-contact"
              onClick={expertCallback}
            >
              <i className={expertIconClass} />
              {expertLabel}
            </button>
          )}
        </div>
        {!hideDownloadButton && (
          <div>
            <MultiButton links={links} label="Download Produkt-Katalog" buttonVariant="condensed" />
          </div>
        )}
      </div>
    </footer>
  );
};

export default BenefitTableFooterArea;
