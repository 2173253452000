import './Sustainability2024StageComponent.scss';

import React, { useEffect, useState } from 'react';

import House from '../../styles/assets/images/sustainability-2024-stage/static-Sustainability2024StageImage.webp';
import Trusted from '../../styles/assets/images/sustainability-2024-stage/static-vhv-trusted-2024.webp';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import ContactBar from '../ui/ContactBar/ContactBar';
import Image from '../ui/Image/Image';

const buttonLink = '/unternehmen/nachhaltigkeit/nachhaltiger-versicherungsschutz';

const oldStyleStage = loaded => {
  return (
    <header className={`Sustainability2024StageComponent${loaded ? ' loaded' : ''}`}>
      <div className="Sustainability2024StageComponent__inner">
        <div className="Sustainability2024StageComponent__contentWrapper">
          <h1 className="Sustainability2024StageComponent__headline">
            Morgen ist jetzt: <br /> Versicherungsschutz, <br /> der mitwächst
          </h1>
          <ButtonComponent
            label="Jetzt entdecken"
            linkTo={buttonLink}
            linkType="page"
            variant="size-m"
          />
        </div>
        <Image objectFitContain src={House} />
      </div>
      <div className="Sustainability2024StageComponent__contact-area">
        <ContactBar />
      </div>
    </header>
  );
};

const newStyleStage = loaded => {
  return (
    <header className={`Sustainability2024StageComponentNew${loaded ? ' loaded' : ''}`}>
      <div className="Sustainability2024StageComponentNew__inner">
        <div className="Sustainability2024StageComponentNew__contentWrapper">
          <h1 className="Sustainability2024StageComponentNew__headline">
            <strong>
              Morgen ist jetzt:
              <br />
            </strong>
            Versicherungsschutz, <br /> der <strong>mitwächst</strong>
          </h1>
          <NewButtonComponent
            icon="arrow-line-right"
            linkTo={buttonLink}
            label="Jetzt kennenlernen"
          />
          <div className="Sustainability2024StageComponentNew__trustedWrapper">
            <Image src={Trusted} />
          </div>
        </div>
        <Image extraClass="Sustainability2024StageComponentNew__house" src={House} />
      </div>
    </header>
  );
};

const Sustainability2024StageComponent = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setLoaded(true);
    }, 200);
  }, []);
  return newStyleStage(loaded);
};

export default Sustainability2024StageComponent;
