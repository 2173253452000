import React from 'react';

import TransferLinkBox from './components/TransferLinkBox/TransferLinkBox';
import BrokerBcModal from './components/ui/BrokerBcModal/BrokerBcModal';
import BrokerSearchApp from './components/ui/BrokerSearch/BrokerSearchApp';
import CompetenceSegment from './components/ui/CompetenceSegment/CompetenceSegment';
import ContactInformationModal from './components/ui/ContactInformation/ContactInformationModal';
import CorporateContactModal from './components/ui/CorporateContact/CorporateContactModal';
import ExpertSearchModal from './components/ui/ExpertSearch/ExpertSearchModal';
import NewsletterRegistrationDownloadForm from './components/ui/NewsletterRegistrationDownloadForm/NewsletterRegistrationDownloadForm';
import ProductFinderModal from './components/ui/ProductFinder/ProductFinderModal/ProductFinderModal';
import ScrollToTopButton from './components/ui/ScrollToTopButton/ScrollToTopButton';
import { BrokerBcContextProvider } from './contexts/BrokerBcContext';
import { BrokerSearchContextProvider } from './contexts/BrokerSearchContext';
import { ContactBarContextProvider } from './contexts/ContactBarContext';
import { ContactInformationContextProvider } from './contexts/ContactInformationContext';
import { CorporateContactContextProvider } from './contexts/CorporateContactContext';
import { ExpertEventsContextProvider } from './contexts/ExpertEventsContext';
import { ExpertSearchContextProvider } from './contexts/ExpertSearchContext';
import { NewsletterRegistrationContextProvider } from './contexts/NewsletterRegistrationContext';
import { ProductFinderContextProvider } from './contexts/ProductFinderContext';
import { ProductGroupsContextProvider } from './contexts/ProductGroupsContext';
import SiteDataContext from './contexts/SiteDataContext';
import { TransferLinkBoxContextProvider } from './contexts/TransferLinkBoxContext';
import getDynamicComponent from './helpers/getDynamicComponent';
import getPageScope from './helpers/getPageScope';

const PageWrapper = ({ children, pageContext, location }) => {
  const {
    page,
    navs,
    footer,
    productGroups,
    relatedTeasers,
    corporateProducts,
    articlePages,
    tags,
    customerRatings,
    guidebookData,
    organizations,
    expertEvents,
    solutionTiles,
    businessCardReferences
  } = pageContext || {};

  const {
    metaTitle,
    subPages,
    template,
    guideTags,
    pageScope = getPageScope(location),
    announcementLabel,
    announcementText,
    announcementVisible,
    announcementTargetTo,
    announcementTargetType,
    announcementIcon,
    maintenanceLabel,
    maintenanceText,
    maintenanceVisible,
    maintenanceTargetTo,
    maintenanceTargetType
  } = page || {};

  const {
    homeSignetArea = {},
    homeFooterColumnsArea = {},
    homeFooterLinkArea = {},
    homeFooterContactArea = {}
  } = footer || {};

  const siteData = {
    navs,
    footer: {
      homeSignetArea: getDynamicComponent(homeSignetArea),
      homeFooterColumnsArea: getDynamicComponent(homeFooterColumnsArea),
      homeFooterLinkArea: getDynamicComponent(homeFooterLinkArea),
      homeFooterContactArea: getDynamicComponent(homeFooterContactArea)
    },
    location,
    pageScope,
    guidebookData,
    subPages,
    articlePages,
    relatedTeasers,
    meta: { metaTitle },
    corporateProducts,
    tags,
    guideTags,
    customerRatings,
    organizations,
    expertEvents,
    solutionTiles,
    businessCardReferences,
    announcement: {
      label: announcementLabel,
      text: announcementText,
      visible: announcementVisible,
      linkTo: announcementTargetTo,
      linkType: announcementTargetType,
      icon: announcementIcon
    },
    maintenance: {
      label: maintenanceLabel,
      text: maintenanceText,
      visible: maintenanceVisible,
      targetTo: maintenanceTargetTo,
      targetType: maintenanceTargetType
    }
  };

  return (
    <>
      <div className="container">
        <SiteDataContext.Provider value={siteData}>
          <ExpertSearchContextProvider>
            <BrokerBcContextProvider>
              <CorporateContactContextProvider>
                <ProductGroupsContextProvider productGroups={productGroups}>
                  <ProductFinderContextProvider>
                    <TransferLinkBoxContextProvider>
                      <ContactInformationContextProvider>
                        <BrokerSearchContextProvider>
                          <ExpertEventsContextProvider>
                            <NewsletterRegistrationContextProvider>
                              <ContactBarContextProvider>
                                <BrokerSearchApp />
                                <ExpertSearchModal />
                                <CorporateContactModal />
                                <ProductFinderModal />
                                <TransferLinkBox />
                                <ContactInformationModal />
                                <BrokerBcModal />
                                <NewsletterRegistrationDownloadForm />
                                {children}
                              </ContactBarContextProvider>
                            </NewsletterRegistrationContextProvider>
                          </ExpertEventsContextProvider>
                        </BrokerSearchContextProvider>
                        {pageScope !== 'expert' &&
                          pageScope !== 'ms' &&
                          template !== 'ExpertIFrame' &&
                          template !== 'ExpertBauforschung' && <CompetenceSegment />}
                      </ContactInformationContextProvider>
                    </TransferLinkBoxContextProvider>
                  </ProductFinderContextProvider>
                </ProductGroupsContextProvider>
              </CorporateContactContextProvider>
            </BrokerBcContextProvider>
          </ExpertSearchContextProvider>
        </SiteDataContext.Provider>
      </div>
      <ScrollToTopButton />
    </>
  );
};

export default PageWrapper;
