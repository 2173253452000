import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import Icon from '../Icon/Icon';
import FormErrorMessage from './FormErrorMessage';
import FormTooltip from './FormTooltip';

interface SelectFieldComponentProps {
  label: string;
  name: string;
  errorMessage?: string;
  tooltipText?: string;
  optional?: string;
  options: { label: string; value: string }[];
}

const SelectFieldComponent = ({
  label,
  name,
  options,
  optional,
  tooltipText,
  errorMessage
}: SelectFieldComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: '',
    value: ''
  });

  const {
    register,
    unregister,
    setValue,
    formState: { errors, dirtyFields }
  } = useFormContext();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    return () => {
      for (const option of options) {
        unregister(option.value);
      }
    };
  }, []);

  const getActualErrorMessage = () => {
    if (errors[name]) {
      if (errors[name].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie eine korrekte Auswahl an';
    }
    return undefined;
  };

  const actualErrorMessage = getActualErrorMessage();
  options = (options['nodes'] && options['nodes'].map(key => options[key])) || options;

  useEffect(() => {
    setValue(name, selectedOption.value);
  }, [selectedOption]);
  return (
    <>
      <div className="m-form__group">
        <label htmlFor={name}>
          {label} {optional && <em>optional</em>}
        </label>
        <div className="m-form__group-fields">
          <div className="l-grid">
            <div className="l-grid__col--12-12">
              <select
                id={name}
                data-fancy-select
                {...register(name, { required: !optional })}
                className={classNames({
                  'ng-invalid': errors[name],
                  'ng-touched': dirtyFields[name]
                })}
              >
                <option value="">Bitte wählen</option>
                <option value={selectedOption.value}></option>
              </select>
              <ul
                className={classNames('m-fancy-select', {
                  open: isOpen
                })}
                tabIndex={0}
                onClick={() => handleClick()}
              >
                <li>
                  <span>{selectedOption.label || 'Bitte wählen'}</span>
                  <Icon type="arrow-down" className="ico-arrow-down"></Icon>
                  <div className="m-fancy-select__option-list">
                    <ul>
                      {options.map(option => (
                        <li
                          className={classNames({ checkbox: false })}
                          key={option.label}
                          onClick={() => setSelectedOption(option)}
                        >
                          <span
                            className={classNames({
                              'checkbox checker-s fake': false,
                              checked: false
                            })}
                          >
                            <span>{option.label}</span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {tooltipText && <FormTooltip label={label} name={name} tooltipText={tooltipText} />}
        </div>
      </div>
      {actualErrorMessage && <FormErrorMessage errorMessage={actualErrorMessage} />}
    </>
  );
};

export default SelectFieldComponent;
