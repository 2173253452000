import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import useOutsideClick from '../../hooks/useOutsideClick';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';
import RichText from '../ui/RichText/RichText';

interface IMinimumRateComponent {
  buttonLabel: string;
  price: string;
  terms: string;
  linkTo: string;
  linkType: string;
  unit?: 'annual' | 'monthly';
}

const MinimumRateComponent: React.FC<IMinimumRateComponent> = ({
  buttonLabel,
  price,
  terms,
  linkTo,
  linkType,
  unit
}) => {
  const priceUnit = unit && unit === 'annual' ? 'Jahr' : 'Monat';
  const contentRef = useRef(null);
  const triggerRef = useRef(null);

  const [showToolbox, setShowToolbox] = useState<boolean>(false);
  useOutsideClick(contentRef, () => setShowToolbox(false), showToolbox, triggerRef);

  return (
    <div>
      <div className="m-callout">
        <div>
          <div className="m-callout-price">
            ab <span>{price}&thinsp;€</span> im {priceUnit}
          </div>
          <div className="m-tooltip" onClick={() => setShowToolbox(!showToolbox)} ref={triggerRef}>
            So haben wir gerechnet
          </div>
          <CSSTransition
            in={showToolbox}
            mountOnEnter
            unmountOnExit
            timeout={200}
            classNames="fade"
          >
            <div
              className="m-tooltip__popup extraMinimumRate"
              ref={contentRef}
              onClick={e => e.stopPropagation()}
            >
              <RichText richText={terms} />
              <button className="m-tooltip__close-button" onClick={() => setShowToolbox(false)}>
                <Icon type="close" className="ico-close"></Icon>
              </button>
            </div>
          </CSSTransition>
        </div>
        <div>
          <div>
            <Link
              linkTo={linkTo}
              linkType={linkType}
              extraClass="m-button tag-min-rate-calc ga-min-rate-calc"
            >
              <Icon type="calculator" className="ico-calculator ga-min-rate-calc"></Icon>
              {buttonLabel}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinimumRateComponent;
