import React from 'react';

import Columns from '../../components/ui/Columns/Columns';
import extractChildren from '../../helpers/extractChildren';

const PointArea = ({ children }) => {
  const formattedChildren = extractChildren(children);

  const widths =
    children && children.length < 4 ? [1, 1, 1] : children.length % 4 ? [1, 1, 1] : [1, 1, 1, 1];

  const points = Object.keys(formattedChildren).map(key => formattedChildren[key]);

  return (
    <Columns wrap widths={widths} flexContent gutter="s">
      {points}
    </Columns>
  );
};

export default PointArea;
