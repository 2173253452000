import React from 'react';

import HeaderArea from '../areas/HeaderArea/HeaderArea';
import Footer from '../components/ui/Footer/Footer';
import Guidebook from '../components/ui/Guidebook/Guidebook';
import extractChildren from '../helpers/extractChildren';

const GuidebookPage = props => {
  const { children, targetpage } = props;
  const { contentHeaderArea } = extractChildren(children);

  return (
    <>
      <HeaderArea />
      <main className="l-content">
        {contentHeaderArea}
        <Guidebook targetpage={targetpage} />
      </main>
      <Footer />
    </>
  );
};

export default GuidebookPage;
