import { useCookies } from 'react-cookie';

const useCookieByKey = (
  key: string,
  dependOnKey: boolean = false,
  defaultValue?: string | boolean | number
) => {
  const dependencies = [];

  if (dependOnKey) {
    dependencies.push(key);
  }

  const [cookies] = useCookies(dependencies);
  return cookies[key] || defaultValue || '';
};

export default useCookieByKey;
