import React, { useState } from 'react';

interface ITabs {
  tabTitles: string[];
  children: any;
  variant?: 'cyber' | 'corporate' | 'buttons';
}

const Tabs: React.FC<ITabs> = ({ tabTitles, children, variant }) => {
  const [selected, setSelected] = useState(0);

  return (
    <div className={`l-tabs${variant ? ' l-tabs--' + variant : ''}`}>
      {tabTitles.length > 1 && (
        <div>
          <nav className={`m-tab-nav${variant ? ' m-tab-nav--' + variant : ''}`}>
            <ul>
              {tabTitles.map((title, index) => {
                return (
                  <li className={`ng-scope${index === selected ? ' selected' : ''}`} key={index}>
                    <button onClick={() => setSelected(index)}>{title}</button>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      )}
      <div
        className={`l-tabs__content${variant ? ' l-tabs__content--' + variant : ''}`}
        style={{ height: 'auto' }}
      >
        {children[selected] || children}
      </div>
    </div>
  );
};

export default Tabs;
