import React, { useRef, useState } from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick';
import Icon from '../Icon/Icon';

const GuidebookDropdown = ({ category, options, selectedTags, setTag }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef(null);

  useOutsideClick(ref, () => setIsOpen(false), isOpen);

  const amountOfSelectedFilters = selectedTags[category].length;

  const catLabel =
    category.charAt(0).toUpperCase() +
    category.substring(1) +
    `${amountOfSelectedFilters > 0 ? ` (${amountOfSelectedFilters})` : ''}`;

  return category ? (
    <div key={category} className="l-grid__col--4-12 spacer-small" ref={ref}>
      <ul
        id={category}
        style={{ display: 'block' }}
        className={`m-fancy-select m-fancy-select--light m-fancy-select--narrow${
          isOpen ? ' open' : ''
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <li>
          <span>{catLabel}</span>
          <Icon type="arrow-down" className="ico-arrow-down" />
        </li>
        {isOpen && (
          <div
            className="m-fancy-select__option-list m-fancy-select--light m-fancy-select--narrow"
            onClick={e => e.stopPropagation()}
          >
            <ul>
              {options
                .sort((a, b) => (a.label > b.label ? 1 : -1))
                .map(tag => (
                  <li onClick={() => setTag(category, tag.name)} key={tag.name}>
                    <div>
                      <span
                        className={`checkbox checker-s fake${
                          selectedTags[category].includes(tag.name) ? ' checked' : ''
                        }`}
                      />
                      <div>{tag.label}</div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </ul>
    </div>
  ) : null;
};

export default GuidebookDropdown;
