import './FlotteStageOld.scss';

import React from 'react';

import useBreakpoint from '../../../hooks/useBreakpoint';
import bgImage from '../../../styles/assets/images/flotte-stage/flotte_background.jpg';
import barbaraImage from '../../../styles/assets/images/flotte-stage/flotte_barbara.png';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import Link from '../Link/Link';
import ProductFinder from '../ProductFinder/ProductFinder';

const FlotteStageOld = () => {
  const BreakpointM = useBreakpoint('m');

  return (
    <>
      <div className="FlotteStageOld">
        <div className="FlotteStageOld__wrapper">
          <div className="FlotteStageOld__bgWrapper">
            <div>
              <Image extraClass="FlotteStageOld__bg" src={bgImage} />
              <div className="FlotteStageOld__fade" />
              <div className="FlotteStageOld__fade FlotteStageOld__fade--right" />
            </div>
          </div>
          <Image extraClass="FlotteStageOld__barbara" src={barbaraImage} />
          <div className="FlotteStageOld__text">
            <p className="FlotteStageOld__subline">Die VHV Flottenversicherung</p>
            <h2 className="FlotteStageOld__headline">
              Top Leistungen zu Top Beiträgen <br /> – für jeden Fuhrpark
            </h2>
            <Link
              linkTo="/firmen/produkte/kfz-versicherung"
              extraClass={`m-button${BreakpointM ? '' : ' m-button--size-l'}`}
            >
              <Icon type="arrow-right" className="ico-arrow-right "></Icon>
              <span className="">Jetzt Informieren</span>
            </Link>
          </div>
        </div>
        {!BreakpointM && <ProductFinder />}
      </div>
      {BreakpointM && <ProductFinder />}
    </>
  );
};

export default FlotteStageOld;
