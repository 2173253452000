'use client';

import './AutoHeight.scss';

import React, { CSSProperties, FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

import { useRect } from '../../../hooks/useRect';

export interface AutoHeightProps extends PropsWithChildren {
  transition?: boolean;
}

export const AutoHeight: FC<AutoHeightProps> = ({ children, transition }) => {
  const inner = useRef<HTMLDivElement>(null);
  const rect = useRect(inner);
  const css = { '--height': rect?.height } as CSSProperties;

  const [hasTransition, setHasTransition] = useState(false);

  useEffect(() => {
    if (transition) {
      setTimeout(() => {
        setHasTransition(true);
      }, 200);
    } else {
      setHasTransition(true);
    }
    return () => setHasTransition(false);
  }, [children]);

  return (
    <div className="AutoHeight" style={css}>
      <div
        className={`AutoHeight__inner${hasTransition ? ' AutoHeight__inner--transition' : ''}`}
        ref={inner}
      >
        {children}
      </div>
    </div>
  );
};
