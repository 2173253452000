import React from 'react';

import stripHtmlTags from '../../helpers/stripHtmlTags';
import Image from '../ui/Image/Image';

const GuidebookTeaserCardComponent = ({ article, ratgeberTags }) => {
  const getLabelForTag = (tagName, category) => {
    const tag = ratgeberTags[category]?.find(tag => tag.name === tagName);
    return tag ? tag.label : tagName;
  };

  return (
    <div className="GuidebookTeaserCardComponent">
      <div className="GuidebookTeaserCardComponent__imageWrapper">
        <Image src={article.ogImage} sizes={{ w: 400 }} />
      </div>
      <div className="GuidebookTeaserCardComponent__textWrapper">
        <div className="GuidebookTeaserCardComponent__pillWrapper">
          {article.guideTags?.thema?.map((tag, index) => (
            <div key={index} className="GuidebookTeaserCardComponent__pill">
              {getLabelForTag(tag, 'thema')}
            </div>
          ))}
        </div>
        <h3 className="GuidebookTeaserCardComponent__headline">{article.title}</h3>
        <div
          className="GuidebookTeaserCardComponent__subline"
          dangerouslySetInnerHTML={{ __html: stripHtmlTags(article.description) }}
        ></div>
      </div>
    </div>
  );
};
export default GuidebookTeaserCardComponent;
