import scopedHostMap from './scopedHostMap';
export type TAssetSrc = string | { path: string };
export type TAssetSizes = number | { w?: number; h?: number };

const getAsset = (src: TAssetSrc, sizes?: TAssetSizes) => {
  const SCOPE = process.env.GATSBY_SCOPE;
  const STAGE = process.env.GATSBY_STAGE;
  const GATSBYENV = process.env.GATSBY_ENV;

  const host = (typeof window !== 'undefined' && window.location.host) || '';

  const isStringSrc = typeof src === 'string';

  const isMagnoliaDamSrc = isStringSrc && src.startsWith('/dam/jcr');

  const isMagnoliaContext =
    isStringSrc &&
    (host === 'localhost:8080' ||
      host.includes('author') ||
      host.includes('preview') ||
      isMagnoliaDamSrc);

  const staticImgHost =
    isStringSrc &&
    src.startsWith('/static') &&
    (host === 'localhost:8080' || host.includes('author') || host.includes('preview'))
      ? '/fallback/.resources/vhv/webresources/static'
      : '';

  const isLocal = GATSBYENV === 'local';

  const regex = /-\d+x\d+\.|-\d+[wh]\./g;

  const getSrcPath = src => (src && src['@path']) || src.path;
  const rawPath =
    src &&
    (isStringSrc
      ? src.startsWith('jcr')
        ? null
        : src.replace(regex, '')
      : getSrcPath(src)?.replace(regex, '.'));
  const width = typeof sizes === 'number' ? sizes : sizes?.w;
  const height = typeof sizes === 'number' ? sizes : sizes?.h;

  const supportedFormatsRegex = /(.jpg|.jpeg|.webp|.png)/g;

  const pathAffix =
    width && height ? `-${width}x${height}` : width ? `-${width}w` : height ? `-${height}h` : '';

  const formattedPath = rawPath && rawPath.replace(supportedFormatsRegex, `${pathAffix}$1`);

  let path = '';

  if (isMagnoliaContext) {
    path = src;
    if (isMagnoliaDamSrc) {
      path = src;
    } else {
      if (src.startsWith('/static')) {
        path = staticImgHost + src;
      } else {
        if (src.startsWith('jcr')) {
          path = '/dam/' + src;
        } else {
          path = '/dam/jcr' + src;
        }
      }
    }
  } else if (
    rawPath &&
    (rawPath.startsWith('/static') ||
      rawPath.startsWith('/dam/broker-dam') ||
      rawPath.startsWith('/api/v2') ||
      rawPath.startsWith('data:image/'))
  ) {
    path = rawPath;
  } else {
    if ((isLocal && rawPath) || (!isLocal && formattedPath)) {
      path = `/assets${isLocal ? rawPath : formattedPath}`;
    }
  }

  if (STAGE === 'prod' && SCOPE === 'bauexperten') {
    path = scopedHostMap[SCOPE][GATSBYENV] + path;
  }

  return { path, width, height, isMagnoliaContext };
};

export default getAsset;
