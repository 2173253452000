import React from 'react';

import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';

const StaticStageComponent = ({
  headline,
  subHeadline,
  description,
  signetImage,
  backgroundImage,
  linkTo,
  linkType,
  label
}) => {
  return (
    <Image
      isBackgroundImage
      src={backgroundImage}
      extraClass={`l-custom-stage${
        backgroundImage ? ' l-custom-stage--dark' : ''
      }${signetImage ? ' l-custom-stage--signet' : ''}`}
    >
      <div>
        <div className="l-custom-stage__cta">
          <div>
            <div className="l-custom-stage__cta-content">
              <h2>{headline}</h2>
              {subHeadline && <h3>{subHeadline}</h3>}
              {description && <p>{description}</p>}
              {linkTo && (
                <Link
                  linkTo={linkTo}
                  linkType={linkType}
                  label={label}
                  extraClass="m-button m-button--size-l"
                />
              )}
              {signetImage && <Image src={signetImage} extraClass="l-custom-stage__signet" />}
            </div>
          </div>
        </div>
      </div>
    </Image>
  );
};

export default StaticStageComponent;
