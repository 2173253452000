import './HausratStageComponent.scss';

import React from 'react';

import useBreakpoint from '../../hooks/useBreakpoint';
import barbaraImage from '../../styles/assets/images/hausrat-stage/hausrat_barbara.webp';
import bgImage from '../../styles/assets/images/hausrat-stage/hausrat_bg.webp';
import mobileImage from '../../styles/assets/images/hausrat-stage/hausrat_mobile.webp';
import ButtonComponent from '../ButtonComponent/ButtonComponent';
import PriceCallToActionComponent from '../PriceCallToActionComponent/PriceCallToActionComponent';
import ContactBar from '../ui/ContactBar/ContactBar';
import Image from '../ui/Image/Image';

const HausratStageComponent = () => {
  const BreakpointM = useBreakpoint('m');
  const BreakpointS = useBreakpoint('s');
  return (
    <header className="HausratStageComponent">
      <div>
        {BreakpointS ? (
          <Image extraClass="HausratStageComponent__mobile" isBackgroundImage src={mobileImage} />
        ) : (
          <>
            <Image extraClass="HausratStageComponent__bg" isBackgroundImage src={bgImage} />
            <Image extraClass="HausratStageComponent__barbara" src={barbaraImage} />
          </>
        )}
        <div className="HausratStageComponent__wrapper">
          {BreakpointM && <div className="HausratStageComponent__fade" />}
          <div className="HausratStageComponent__text">
            <p className="HausratStageComponent__subline">Die neue VHV Hausratversicherung</p>
            <h2 className="HausratStageComponent__headline">Jetzt Top-Diebstahlschutz sichern!</h2>
          </div>
          <div className="HausratStageComponent__button">
            {!BreakpointM && <div className="HausratStageComponent__fade" />}
            <PriceCallToActionComponent
              rightButtonLabel="Jetzt Tarif berechnen"
              linkTo="https://tarifrechner-hausrat.vhv.de/tarifrechner/hausrat/intro"
              linkType="page"
            />
            <ButtonComponent
              label="Mehr zur VHV Hausrat"
              linkTo="/versicherungen/hausversicherung/hausratversicherung"
              linkType="internal"
              variant="underline"
              alignment="center"
            />
          </div>
          <div className="HausratStageComponent__contact-area">
            <ContactBar />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HausratStageComponent;
