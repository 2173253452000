import React, { useContext } from 'react';

import ContactInformationContext from '../../../contexts/ContactInformationContext';
import { ILinkProps } from '../Link/Link';
import Link from '../Link/Link';
import MultiButton from '../MultiButton/MultiButton';

interface IContactInformationTabProps {
  number: number;
  title: string;
  text?: any;
  phone?: string;
  links?: ILinkProps[];
  label?: string;
}

const ContactInformationTab: React.FC<IContactInformationTabProps> = ({
  number,
  title,
  text,
  phone,
  links,
  label
}) => {
  const colNumber = number < 4 ? 12 / number : 6;
  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  return (
    <div className={`l-grid__col l-grid__col--m-${colNumber}-12`}>
      {title && <h3>{title}</h3>}

      {phone && (
        <address className="m-phone-number">
          <a href={`tel://${phone}`}>{phone}</a>
        </address>
      )}
      {text && <small>{text}</small>}
      {links &&
        links.length > 0 &&
        (links.length === 1 ? (
          <Link
            onClick={() => {
              if (!links[0].linkType || links[0].linkType === 'page') {
                setIsContactInformationModalVisible(false);
              }
            }}
            {...links[0]}
            label={label}
            extraClass="m-button m-button--medium tag-service-app-callback-button"
          />
        ) : (
          <MultiButton
            buttonCallback={() => setIsContactInformationModalVisible(false)}
            buttonVariant="medium"
            label={label}
            links={links}
            hideIcon
          />
        ))}
    </div>
  );
};

export default ContactInformationTab;
