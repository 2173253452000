import { useContext, useEffect, useState } from 'react';

import SiteDataContext from '../contexts/SiteDataContext';

const useUrlParams = (paramName?: string) => {
  const siteData = useContext(SiteDataContext) || {};

  const { pathname, search: locationSearch, hash: locationHash } = siteData?.location || {};

  const [urlParams, setUrlParams] = useState<string>('');

  const [isPending, setIsPending] = useState<boolean>(true);

  const search = decodeURI(locationSearch).toLowerCase().replace('?', '');

  const hash = decodeURI(locationHash).toLowerCase().replace('#', '');

  useEffect(() => {
    if (paramName) {
      if (search) {
        const locationParams = search.split('&');
        if (locationParams.find(param => param.startsWith(`${paramName}=`))) {
          setUrlParams(
            locationParams
              .find(param => param.startsWith(`${paramName}=`))
              .replace(`${paramName}=`, '')
              .toLowerCase()
          );
        }
      } else {
        setUrlParams('');
      }
    }
    setIsPending(false);
  }, [paramName, locationSearch, locationHash, search]);

  return [urlParams, pathname, hash, isPending];
};

export default useUrlParams;
