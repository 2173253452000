import React, { createContext } from 'react';

import extractChildren from '../../helpers/extractChildren';
import Columns from '../ui/Columns/Columns';

export const ColumnsContext = createContext(null);

const ColumnsComponent = ({ children }) => {
  const videoSize = 's';
  const { columnArea } = extractChildren(children);

  return (
    <ColumnsContext.Provider value={{ videoSize }}>
      <Columns>{columnArea?.props?.children}</Columns>
    </ColumnsContext.Provider>
  );
};
export default ColumnsComponent;
