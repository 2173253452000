const scopedHostMap = {
  //TODO @ilja dockerize local hosts
  vhvde: {
    local: 'http://localhost:8001',
    dev: 'https://bauexperten.dev.k8s.vhv.de',
    stage: 'https://bauexperten.stage.k8s.vhv.de',
    live: 'https://www.vhv-bauexperten.de'
  },
  bauexperten: {
    local: 'http://localhost:8000',
    dev: 'https://dev.k8s.vhv.de',
    stage: 'https://stage.k8s.vhv.de',
    live: 'https://www.vhv.de'
  }
};

export default scopedHostMap;
