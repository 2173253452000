const extractChildren = (children): any => {
  const extractedChildren = {};

  const extractNameFromString = str =>
    typeof str === 'string' ? str.replace(/[\s\S]*name="([\w]*)"[\s\S]*/, '$1') : null;

  const assignKey = node => {
    const key =
      node && node.props
        ? node.props.name ||
          (node.props.children && Array.isArray(node.props.children)
            ? node.props.children[0] && extractNameFromString(node.props.children[0])
            : extractNameFromString(node.props.children))
        : null;

    if (key) {
      extractedChildren[key] = node;
    }
  };

  if (children) {
    if (Array.isArray(children)) {
      children.forEach(child => {
        assignKey(child);
      });
    } else {
      assignKey(children);
    }
  }

  return extractedChildren;
};

export default extractChildren;
