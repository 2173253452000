import { useContext } from 'react';

import BrokerSearchContext from '../contexts/BrokerSearchContext';
import ExpertSearchContext from '../contexts/ExpertSearchContext';

const useLinkFunction = (functionKey: string, body?: { iVMNr: string }) => {
  const { setIsExpertSearchModalVisible } = useContext(ExpertSearchContext) || {};

  const { setIsBrokerSearchModalVisible } = useContext(BrokerSearchContext) || {};

  const functionMap = {
    brokerSearch: () => {
      setIsBrokerSearchModalVisible(true);
    },
    expertSearch: () => {
      setIsExpertSearchModalVisible(true);
    }
  };
  return functionMap[functionKey];
};

export default useLinkFunction;
