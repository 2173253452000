import React from 'react';

import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';

const OrganizationComponent = ({ organization }) => {
  const { website, name, image } = organization || {};

  const getContent = (
    <>
      <div className="m-orga-tile__front">
        <Image src={image} />
      </div>
      <div className="m-orga-tile__back">{name}</div>
    </>
  );

  return (
    <div className="m-orga-tile__item">
      {website ? (
        <Link linkTo={website} linkType={'external'}>
          <div className="m-orga-tile m-orga-tile--link">{getContent}</div>
        </Link>
      ) : (
        <div>
          <div className="m-orga-tile">{getContent}</div>
        </div>
      )}
    </div>
  );
};

export default OrganizationComponent;
