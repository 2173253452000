import { useEffect } from 'react';

const useSignetScrollTransitionFallback = (containerClass, cardClass) => {
  useEffect(() => {
    const container = document.querySelector(containerClass);
    const cards = document.querySelectorAll(cardClass);
    const cardsCount = cards.length;
    const cardHeight = cards[0].clientHeight;
    const gap = calcGap();

    // CHROME 127 FIX
    // const isAnimationTimelineSupported = 'animationTimeline' in document.createElement('div').style;
    const isAnimationTimelineSupported = false;

    function getFactor(element) {
      const windowHeight = window.innerHeight;
      const cardPosition = windowHeight * 0.25; // for 25vh from bottom

      return Math.max(
        0,
        Math.min(
          1,
          (windowHeight - element.getBoundingClientRect().top - cardHeight - cardPosition) /
            (cardHeight - gap)
        )
      );
    }

    function getScrollFactor() {
      let factor = 0;
      cards.forEach(card => {
        factor += getFactor(card);
        getFactor(card);
      });

      return factor;
    }

    if (!isAnimationTimelineSupported) {
      window.addEventListener('scroll', () => {
        container.style.setProperty('--scroll-factor', getScrollFactor());
      });
    }

    function calcGap() {
      return (cardsCount * cardHeight - container.clientHeight) / (cardsCount - 1);
    }

    return () => {
      window.removeEventListener('scroll', getScrollFactor);
    };
  }, []);
};

export default useSignetScrollTransitionFallback;
