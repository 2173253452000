import getDynamicComponent from './getDynamicComponent';

const getChildNodes = (node: any, props?: any) => {
  if (node) {
    const { nodes: childNodes, template } = node;

    if (childNodes && childNodes.length > 0) {
      return childNodes.map((nodeKey: string, index: number) =>
        getDynamicComponent(node[nodeKey], {
          index,
          parentTemplate: template,
          ...props
        })
      );
    }
  }
};

export default getChildNodes;
