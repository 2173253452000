import Link from '../ui/Link/Link';
import React from 'react';

interface IButtonProps {
  label: string;
  alignment?: string;
  variant?: string;
  linkTo?: any;
  linkType?: any;
}

const ButtonComponent: React.FC<IButtonProps> = ({
  alignment,
  variant,
  label,
  linkTo,
  linkType
}) => {
  return (
    <div className={`ButtonComponent${alignment === 'center' ? ' text-center' : ''}`}>
      <Link
        linkTo={linkTo}
        linkType={linkType}
        label={label}
        extraClass={`m-button${variant ? ' m-button--' + variant : ''}`}
      />
    </div>
  );
};

export default ButtonComponent;
