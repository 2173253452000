import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import FormErrorMessage from './FormErrorMessage';
import FormTooltip from './FormTooltip';

interface EmailFieldComponentProps {
  label: string;
  name: string;
  optional?: string;
  errorMessage?: string;
  tooltipText?: string;
}

const EmailFieldComponent: React.FC<EmailFieldComponentProps> = ({
  name,
  label,
  optional,
  errorMessage,
  tooltipText
}) => {
  const {
    register,
    unregister,
    formState: { errors, dirtyFields }
  } = useFormContext();
  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  const getActualErrorMessage = () => {
    if (errors[name]) {
      if (errors[name].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie eine korrekte E-Mail Adresse ein';
    }
    return undefined;
  };

  const actualErrorMessage = getActualErrorMessage();

  return (
    <>
      <div className="m-form__group">
        <label htmlFor="${name}">
          {label} {optional && <em>optional</em>}
        </label>
        <div className="m-form__group-fields">
          <div className="l-grid">
            <div className="l-grid__col--12-12">
              <input
                type="email"
                id={name}
                {...register(name, {
                  required: !optional,
                  pattern: /^\S+@\S+$/i
                })}
                className={classNames({
                  'ng-invalid': errors[name],
                  'ng-touched': dirtyFields[name]
                })}
              />
            </div>
          </div>
          {tooltipText && <FormTooltip label={label} name={name} tooltipText={tooltipText} />}
        </div>
      </div>
      {actualErrorMessage && <FormErrorMessage errorMessage={actualErrorMessage} />}
    </>
  );
};

export default EmailFieldComponent;
