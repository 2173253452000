// eslint-disable
import React, { useEffect } from 'react';

import useUrlParams from '../../hooks/useUrlParams';
import ergebnisseite from '../../styles/assets/external/twt/bedarfsrechner/images/ergebnisseite.jpg';
import image1 from '../../styles/assets/external/twt/bedarfsrechner/images/frage_1.jpg';
import image2 from '../../styles/assets/external/twt/bedarfsrechner/images/frage_2.jpg';
import image3 from '../../styles/assets/external/twt/bedarfsrechner/images/frage_3.jpg';
import image4 from '../../styles/assets/external/twt/bedarfsrechner/images/frage_4.jpg';
import image5 from '../../styles/assets/external/twt/bedarfsrechner/images/frage_5.jpg';
import image6 from '../../styles/assets/external/twt/bedarfsrechner/images/frage_6.jpg';
import image7 from '../../styles/assets/external/twt/bedarfsrechner/images/frage_7.jpg';
import startImage from '../../styles/assets/external/twt/bedarfsrechner/images/startseite.jpg';
/* eslint-disable */
const {
  TwtBedarfsrechner
} = require('../../styles/assets/external/twt/bedarfsrechner/twt-bedarfsrechner');
/* eslint-enable */

const createBedarfsrechner = (ivmnr?: string) =>
  new TwtBedarfsrechner({
    selector: '#bedarfsrechner',
    iVMNr: ivmnr,
    omitWelcome: false,
    scrollIntoViewOffset: null,
    images: {
      slides: [startImage, image1, image2, image3, image4, image5, image6, image7],
      resultPage: ergebnisseite
    },
    fonts: {
      heading: [
        'HelveticaNeue15',
        'HelveticaNeue-Heavy',
        'Helvetica Neue Heavy',
        'HelveticaNeue',
        'Helvetica Neue'
      ],
      base: 'Roboto',
      icon: 'vhv15'
    },
    debug: false
  });

const RequirementCalculatorComponent = () => {
  const [ivmnr, , , isPending] = useUrlParams('ivmnr');
  useEffect(() => {
    if (!isPending) {
      const bedarfsrechner = createBedarfsrechner(ivmnr);
      bedarfsrechner.init();
    }
  }, [ivmnr, isPending]);

  return <div id="bedarfsrechner"></div>;
};

export default RequirementCalculatorComponent;
