import React from 'react';

import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';
import RichText from '../ui/RichText/RichText';

const LargeTeaserComponent = ({ headline, backgroundImage, text, linkTo, linkType, label }) => {
  return headline ? (
    <div className="m-promo-teaser m-promo-teaser--wide">
      <figure>
        <Image isBackgroundImage src={backgroundImage} sizes={{ h: 300 }}>
          <figcaption className="m-promo-teaser__caption">
            <h3 className="m-promo-teaser__caption__headline">{headline}</h3>
            <RichText richText={text} />
            <Link linkType={linkType} linkTo={linkTo} extraClass="m-button" label={label} />
          </figcaption>
        </Image>
      </figure>
    </div>
  ) : (
    <Link linkType={linkType} linkTo={linkTo} extraClass="m-promo-teaser m-promo-teaser--wide">
      <Image src={backgroundImage} />
    </Link>
  );
};
export default LargeTeaserComponent;
