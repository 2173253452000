import React from 'react';

const AgendaArea = ({ children }) => {
  return children ? (
    <>
      <h2>Tagesprogramm</h2>
      <table className="m-table">
        <tbody>{children}</tbody>
      </table>
    </>
  ) : null;
};

export default AgendaArea;
