import React, { useContext } from 'react';

import TransferLinkBoxContext, { TKnownExternalLinks } from '../../contexts/TransferLinkBoxContext';
import hannoverscheLogo from '../../styles/assets/images/hannoversche.webp';
import hansemerkurLogo from '../../styles/assets/images/hansemerkur.webp';
import InfoBox from '../ui/InfoBox/InfoBox';

const TransferLinkBox = () => {
  const { showLinkBox, setShowLinkBox, linkToBox } = useContext(TransferLinkBoxContext);

  type TLinkBox = {
    [key in TKnownExternalLinks]: {
      logo: string;
      text: string;
    };
  };

  const linkBox: TLinkBox = {
    hannoversche: {
      logo: hannoverscheLogo,
      text: 'Sie verlassen jetzt die Website www.vhv.de und werden direkt zur Webseite der Hannoverschen Lebensversicherung AG, einem Unternehmen der VHV Gruppe, weitergeleitet. Dort können Sie Ihre Versicherung direkt abschließen.'
    },
    hansemerkur: {
      logo: hansemerkurLogo,
      text: 'Sie verlassen jetzt die Website www.vhv.de und werden direkt zur Website der HanseMerkur weitergeleitet. Dort können Sie Ihre Versicherung berechnen und direkt abschließen.'
    }
  };

  return showLinkBox ? (
    <InfoBox
      logo={linkBox[showLinkBox].logo}
      text={linkBox[showLinkBox].text}
      linkProps={{
        label: 'Weiter',
        extraClass: 'm-button',
        linkTo: linkToBox,
        isTransferLink: true,
        linkType: 'external',
        onClick: () => {
          setShowLinkBox(false);
        }
      }}
      show={!!showLinkBox}
      onClose={() => setShowLinkBox(false)}
    />
  ) : null;
};
export default TransferLinkBox;
