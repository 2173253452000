const getVariantClasses = (className: string, variant: string | string[]) => {
  const classPrefix = ` ${className}--`;

  const variantClasses = !variant
    ? ''
    : typeof variant === 'string'
      ? classPrefix + variant
      : (variant as string[]).reduce((previousValue, currentValue) => {
          const currentClass = currentValue ? classPrefix + currentValue : '';

          return previousValue + currentClass;
        }, '');

  return className + variantClasses;
};

export default getVariantClasses;
