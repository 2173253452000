import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import SiteDataContext from '../../contexts/SiteDataContext';
import useBreakpoint from '../../hooks/useBreakpoint';

const CrossSellingArea = ({ headline }) => {
  const { activeProduct, getProductGroup } = useContext(ProductGroupsContext) || {};

  const { location } = useContext(SiteDataContext) || {};

  const { pathname = '' } = location;
  const splitPathname =
    pathname && (pathname.endsWith('/') ? pathname.slice(1, -1) : pathname.substring(1)).split('/');
  const parentPath = splitPathname[splitPathname.length - 2];

  const parentProduct = getProductGroup('name', parentPath);
  const { path: productPath, children = {} } = parentProduct || {};

  const crossProducts: any[] = Object.values(children).filter(
    (child: any) => activeProduct && child !== activeProduct && child && !child.hideInOverviews
  );

  const crossProductCount = crossProducts.length;

  // const articleColVariant =
  //   crossProductCount < 5 ? (crossProductCount < 3 ? 4 : 3) : 2;
  const BreakpointL = useBreakpoint('l');

  return crossProducts && crossProducts.length > 0 ? (
    <section className="l-content__section nocontent">
      <div>
        <h2>{headline}</h2>

        <div className="m-content-tiles m-content-tiles--products">
          <Columns
            gutter="xs"
            breakpoint="s"
            wrap
            widths={BreakpointL ? [1, 1, 1, 1] : [1, 1, 1, 1, 1, 1]}
          >
            {crossProducts.map((product, index) => {
              const { path, icon, title } = product;
              return (
                <Link linkTo={path} key={index}>
                  <article className={`m-content-tiles__tile`}>
                    <div>
                      <h4 className={`size-${crossProductCount}`}>
                        <Icon type={icon} className={`ico-${icon}`}></Icon>
                        {title}
                      </h4>
                    </div>
                  </article>
                </Link>
              );
            })}
            {crossProductCount > 0 && crossProductCount !== 4 && (
              <Link linkTo={productPath}>
                <article className={`m-content-tiles__tile`}>
                  <div>
                    <h4 className={`size-${crossProductCount}`}>
                      <Icon type="all-products" className={`ico-all-products`}></Icon>
                      Alle Produkte
                    </h4>
                  </div>
                </article>
              </Link>
            )}
          </Columns>
        </div>
      </div>
    </section>
  ) : null;
};

export default CrossSellingArea;
