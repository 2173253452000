import './TelematikStageComponent.scss';

import ButtonComponent from '../ButtonComponent/ButtonComponent';
import ContactBar from '../ui/ContactBar/ContactBar';
import Image from '../ui/Image/Image';
import PriceCallToActionComponent from '../PriceCallToActionComponent/PriceCallToActionComponent';
import React from 'react';
import barbaraImage from '../../styles/assets/images/telematik-stage/telematik_barbara.webp';
import bgImage from '../../styles/assets/images/telematik-stage/telematik_bg.webp';
import mobileImage from '../../styles/assets/images/telematik-stage/telematik_mobile.webp';
import useBreakpoint from '../../hooks/useBreakpoint';

const TelematikStageComponent = () => {
  const BreakpointM = useBreakpoint('m');
  const BreakpointS = useBreakpoint('s');
  return (
    <header className="TelematikStageComponent">
      <div>
        {BreakpointS ? (
          <Image extraClass="TelematikStageComponent__mobile" isBackgroundImage src={mobileImage} />
        ) : (
          <>
            <Image extraClass="TelematikStageComponent__bg" isBackgroundImage src={bgImage} />
            <Image extraClass="TelematikStageComponent__barbara" src={barbaraImage} />
          </>
        )}
        <div className="TelematikStageComponent__wrapper">
          {BreakpointM && <div className="TelematikStageComponent__fade" />}
          <div className="TelematikStageComponent__text">
            <p className="TelematikStageComponent__subline">VHV Autoversicherung mit TELEMATIK</p>
            <h2 className="TelematikStageComponent__headline">10% Sofortrabatt* sichern!</h2>
          </div>
          <div className="TelematikStageComponent__button">
            {!BreakpointM && <div className="TelematikStageComponent__fade" />}
            <PriceCallToActionComponent
              rightButtonLabel="Jetzt Tarif berechnen"
              linkTo="/versicherungen/kfz-versicherung/auto/tarifrechner"
              linkType="page"
            />
            <ButtonComponent
              label="*Mehr zu TELEMATIK"
              linkTo="/versicherungen/kfz-versicherung/pkw-telematik"
              linkType="internal"
              variant="underline"
              alignment="center"
            />
          </div>
          <div className="TelematikStageComponent__contact-area">
            <ContactBar />
          </div>
        </div>
      </div>
    </header>
  );
};

export default TelematikStageComponent;
