import React from 'react';

import Image from '../ui/Image/Image';

const TestimonialComponent = ({ portrait, name, company, quote }) => {
  return (
    <div className="l-grid">
      <div
        className={`${
          portrait && portrait.source
            ? 'l-grid__col--8-12 l-grid__col--s-9-12 l-grid__col--keep-width'
            : 'l-grid__col l-grid__col--m-9-12 l-grid__col--keep-width'
        }`}
      >
        <blockquote className="m-quote m-quote--simple">
          <p>{quote}</p>
          <footer>
            <cite className="">
              {name}
              {company && `von ${company}`}
            </cite>
          </footer>
        </blockquote>
      </div>

      {portrait && (
        <div className="l-grid__col--4-12 l-grid__col--s-3-12 l-grid__col--keep-width">
          <Image
            extraClass="m-quote__image"
            src={portrait}
            sizes={250}
            alt={portrait.alternativeText}
          />
        </div>
      )}
    </div>
  );
};

export default TestimonialComponent;
