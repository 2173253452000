import React, { useEffect, useState } from 'react';

import useBreakpoint from '../../hooks/useBreakpoint';
import bgImageDesktop from '../../styles/assets/images/wohngeb-stage/wohngeb-stage.jpg';
import bgImageMobile from '../../styles/assets/images/wohngeb-stage/wohngeb-stage_mobile.jpg';
import ContactBar from '../ui/ContactBar/ContactBar';
import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';

const WohngebStageComponent = () => {
  const BreakpointS = useBreakpoint('s');
  const [bgImage, setBgImage] = useState(bgImageDesktop);

  useEffect(() => {
    setBgImage(BreakpointS ? bgImageMobile : bgImageDesktop);
  }, [BreakpointS]);
  return (
    <header className="l-wohngeb-stage">
      <div>
        <Image extraClass="l-wohngeb-stage__bg" isBackgroundImage src={bgImage} />
        <div className="l-wohngeb-stage__wrapper">
          <div className="l-wohngeb-stage__text">
            <p className="l-wohngeb-stage__subline">
              <span>Setzen Sie auf 48 neue Top-Leistungen.</span>
            </p>
            <h2 className="l-wohngeb-stage__headline">
              <span>Die neue VHV Wohngebäude</span>
            </h2>
            <Link
              linkTo="https://www.vhv-partner.de//magazin/2022/01/wohnen"
              extraClass="m-button m-button--size-l m-button-barbara"
              linkType="external"
            >
              <Icon type="arrow-right" />
              <span className="">Jetzt informieren</span>
            </Link>
          </div>
          <div className="l-wohngeb-stage__contact-area">
            <ContactBar />
          </div>
        </div>
      </div>
    </header>
  );
};

export default WohngebStageComponent;
