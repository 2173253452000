import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import useOutsideClick from '../../../hooks/useOutsideClick';
import useVpHeight from '../../../hooks/useVpHeight';
import Icon from '../Icon/Icon';
import RichText from '../RichText/RichText';

interface ITooltip {
  label: any;
  extraClassTooltip?: string;
  extraClassPopup?: string;
  children: any;
}

const Tooltip: React.FC<ITooltip> = ({ children, label, extraClassTooltip, extraClassPopup }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentClass, setContentClass] = useState('m-tooltip__popup');
  const triggerRef = useRef(null);
  const contentRef = useRef(null);
  const vpHeight = useVpHeight();

  const onTrigger = () => {
    if (
      triggerRef &&
      triggerRef.current &&
      triggerRef.current.getBoundingClientRect() &&
      triggerRef.current.getBoundingClientRect().top > vpHeight / 2
    ) {
      setContentClass('m-tooltip__popup m-tooltip__popup--top');
    } else {
      setContentClass('m-tooltip__popup m-tooltip__popup--bottom');
    }
    setIsOpen(!isOpen);
  };

  useOutsideClick(contentRef, () => setIsOpen(false), isOpen, triggerRef);
  return (
    <div
      className={`m-tooltip${extraClassTooltip ? ' ' + extraClassTooltip : ''}`}
      ref={triggerRef}
      onClick={() => {
        onTrigger();
      }}
    >
      {label}
      <CSSTransition in={isOpen} mountOnEnter unmountOnExit timeout={200} classNames="fade">
        <div
          className={contentClass + (extraClassPopup ? ' ' + extraClassPopup : '')}
          ref={contentRef}
          onClick={e => e.stopPropagation()}
        >
          <RichText richText={children} />
          <button className="m-tooltip__close-button" onClick={() => setIsOpen(false)}>
            <Icon type="close" className="ico-close"></Icon>
          </button>
        </div>
      </CSSTransition>
    </div>
  );
};
export default Tooltip;
