import './RatingsComponent.scss';

import React, { useEffect, useState } from 'react';

import { getCheck24Ratings } from '../../helpers/getCheck24Ratings';
import RatingSignetComponent from '../RatingSignetComponent/RatingSignetComponent';

const RatingsComponent: React.FC = () => {
  const [rating, setRating] = useState<any>();

  useEffect(() => {
    getCheck24Ratings()
      .then(item => setRating(item))
      .catch(error => console.error(error));
  }, []);

  return (
    (rating && (
      <div className="l-grid text-center">
        <div className="l-grid__col l-grid__col--12-12">
          <div className="RatingsComponent">
            <p className="spacer-small">
              Die Bewertungen werden unabhängig erfasst. Eine Bewertung von 10 bis 7 gilt als
              Empfehlung, eine Bewertung von 3 bis 0 als ein Abraten.
            </p>

            <RatingSignetComponent rating={rating} />

            <small>
              bei insgesamt {rating.totalRating.toLocaleString('de')}
              Bewertungen
            </small>
          </div>
        </div>
      </div>
    )) ||
    null
  );
};

export default RatingsComponent;
