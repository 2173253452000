import React from 'react';

import Icon from '../ui/Icon/Icon';

interface IComparisonCell {
  icon?: 'none' | 'check' | 'dash';
  boldDescription?: string;
  description?: string;
}
const ComparisonCellComponentNew: React.FC<IComparisonCell> = ({
  icon,
  boldDescription,
  description
}) => {
  return (
    <div className="ComparisonCellComponentNew">
      {
        {
          check: <Icon type="check" className="ico-check"></Icon>,
          dash: <>&mdash;</>
        }[icon]
      }
      {boldDescription && <strong>{boldDescription}</strong>}
      {description && <small>{description}</small>}
    </div>
  );
};
export default ComparisonCellComponentNew;
