import React, { createContext, useContext, useState } from 'react';

import getFormEngineWebBaseUrl from '../helpers/getFormEngineWebBaseUrl';
import useBroker from '../hooks/useBroker';
import ExpertSearchContext from './ExpertSearchContext';
import SiteDataContext from './SiteDataContext';
const BrokerBcContext = createContext(null);

export const BrokerBcContextProvider = ({ children }) => {
  const initialValues = {
    brokerFromProps: null,
    visibleBrokerBc: ''
  };
  const urlParamBroker = useBroker();
  const [brokerFromProps, setBrokerFromProps] = useState(initialValues.brokerFromProps);
  const [visibleBrokerBc, dispatchVisibleBrokerBc] = useState<string>(
    initialValues.visibleBrokerBc
  );
  const { expert: expertSearchBroker } = useContext(ExpertSearchContext) || {};

  const broker = brokerFromProps || urlParamBroker || expertSearchBroker;

  const { email: encryptedEmail } = broker || {};

  const param = encryptedEmail ? `?datato=${encryptedEmail}` : '';

  const { pageScope } = useContext(SiteDataContext) || {};

  const hideBrokerBc = () => {
    dispatchVisibleBrokerBc(initialValues.visibleBrokerBc);
    setBrokerFromProps(initialValues.brokerFromProps);
  };

  const getFrameSrc = (brokerBc?: string) => {
    return `${getFormEngineWebBaseUrl()}/formEngineWeb/vhv/formular-dba-${brokerBc || visibleBrokerBc}.htm${param}`;
  };

  const setVisibleBrokerBc = (visibleBrokerBc: string, broker?: any) => {
    if (pageScope === 'expert') {
      window.open(getFrameSrc(visibleBrokerBc), '_blank');
      return;
    }
    dispatchVisibleBrokerBc(visibleBrokerBc);
    setBrokerFromProps(broker);
  };

  const brokerBcFrameSrc = getFrameSrc(visibleBrokerBc);

  return (
    <BrokerBcContext.Provider
      value={{
        brokerBcFrameSrc,
        visibleBrokerBc,
        setVisibleBrokerBc,
        hideBrokerBc
      }}
    >
      {children}
    </BrokerBcContext.Provider>
  );
};
export default BrokerBcContext;
