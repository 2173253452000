import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import RichText from '../../components/ui/RichText/RichText';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';
import SiteDataContext from '../../contexts/SiteDataContext';
import sortList from '../../helpers/sortList';

interface ITeaserArea {
  headline?: string;
  children?: any;
  parentTemplate: string;
  pageScope?: string;
}
const TeaserArea: React.FC<ITeaserArea> = ({ headline, children, parentTemplate, pageScope }) => {
  const { subPages } = useContext(SiteDataContext) || {};
  const {
    productGroups = {},
    activeProduct,
    hideProducts
  } = useContext(ProductGroupsContext) || {};
  const mainProducts = productGroups[pageScope];
  const { children: childProducts } = activeProduct || {};

  const teaserAreaVariant = () => {
    switch (parentTemplate) {
      case 'HomePage':
        return (
          <section className="l-content__section">
            <div>
              {headline && <h2>{headline}</h2>}
              <Columns wrap breakpoint="m" widths={[1, 1]} spaceBetween gutter="s">
                {children}
              </Columns>
            </div>
          </section>
        );
      case 'DoorPage':
        return (
          <>
            <div className="l-content__section">
              <div>
                <Columns wrap breakpoint="m" widths={[1, 1]} spaceBetween gutter="s">
                  {subPages &&
                    subPages
                      .sort((a, b) => {
                        const prioA = parseInt(a.sortPriority) || 0;
                        const prioB = parseInt(b.sortPriority) || 0;
                        return prioA > prioB ? 1 : -1;
                      })
                      .map((page, index) => {
                        const { path, title, description, image, template, hideInOverviews } =
                          page || {};
                        return (
                          path &&
                          title &&
                          !hideInOverviews &&
                          !hideProducts && (
                            <Link linkTo={{ path, template }} key={index}>
                              <div className={`m-doorpage-teaser m-doorpage-teaser--alt`}>
                                <header
                                  className={`m-doorpage-teaser__header${image ? '' : ' no-image'}`}
                                >
                                  {image && <Image src={image} sizes={{ w: 500 }} />}
                                  <h2>{title}</h2>
                                  <RichText richText={description} />
                                </header>
                              </div>
                            </Link>
                          )
                        );
                      })}
                </Columns>
              </div>
            </div>
          </>
        );
      case 'ProductDoorPage':
        return (
          <div className="l-content__section">
            <div className="m-doorpage-teasers">
              <Columns wrap breakpoint="m" widths={[1, 1, 1]} spaceBetween gutter="s">
                {childProducts &&
                  sortList(childProducts, 'sortPriority').map(key => {
                    const { title, description, path, icon, hideInOverviews } = childProducts[key];
                    return path && title && description && !hideInOverviews && !hideProducts ? (
                      <div key={key} className="m-doorpage-teaser">
                        <header className="m-doorpage-teaser__header">
                          <Link linkTo={path}>
                            <Icon size="50px" type={icon} className={`ico-${icon}`}></Icon>
                            <h2>{title}</h2>
                          </Link>
                        </header>
                        <div className="m-doorpage-teaser__info">
                          <RichText richText={description} />
                          <Link linkTo={path} extraClass="m-button m-button--full-width">
                            Zum Produkt
                          </Link>
                        </div>
                      </div>
                    ) : null;
                  })}
              </Columns>
            </div>
          </div>
        );
      case 'AllProductsDoorPage':
        return (
          <div className="l-content__section">
            <div className="m-doorpage-teasers">
              <Columns wrap breakpoint="m" widths={[1, 1, 1]} spaceBetween gutter="s">
                {mainProducts &&
                  sortList(mainProducts, 'sortPriority').map((key, index) => {
                    const product = mainProducts[key];
                    const { title, path, icon, children, hideInOverviews } = product || {};
                    return (
                      title &&
                      !hideInOverviews && (
                        <div className="m-doorpage-teaser" key={index}>
                          <header className="m-doorpage-teaser__header">
                            <Link linkTo={path} noBaseClass>
                              <i className={`ico-${icon}`} />
                              <h2>{title}</h2>
                            </Link>
                          </header>
                          <div className="m-doorpage-teaser__info">
                            <ul className="m-doorpage-teaser__info__sub-nav">
                              {children && Object.keys(children).length > 0 ? (
                                Object.keys(children).map((key, index) => {
                                  const subProduct = children[key];
                                  const { title, path, hideInOverviews } = subProduct || {};
                                  return (
                                    title &&
                                    !hideInOverviews && (
                                      <li key={index}>
                                        <Link linkTo={path} noBaseClass>
                                          <Icon type="arrow-right" className="ico-arrow-right" />
                                          {` ${title}`}
                                        </Link>
                                      </li>
                                    )
                                  );
                                })
                              ) : (
                                <li>
                                  <Link linkTo={path}>
                                    <Icon type="arrow-right" className="ico-arrow-right" />
                                    {title}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      )
                    );
                  })}
              </Columns>
            </div>
          </div>
        );
      default:
        return children;
    }
  };

  return children || childProducts || mainProducts || subPages ? teaserAreaVariant() : null;
};

export default TeaserArea;
