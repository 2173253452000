import './RatgeberArea.scss';

import Columns from '../../components/ui/Columns/Columns';
import RatgeberTeaserComponent from '../../components/RatgeberTeaserComponent/RatgeberTeaserComponent';
import React from 'react';
import useBreakpoint from '../../hooks/useBreakpoint';

const RatgeberArea = ({ headline, ratgeberArea }) => {
  const isMaxVpM = useBreakpoint('m');
  const childLength = ratgeberArea?.nodes.length;
  const columnWidths = isMaxVpM || childLength <= 2 ? [1, 1] : [1, 1, 1];

  return ratgeberArea?.nodes ? (
    <section className="l-content__section">
      <div className="RatgeberArea">
        <h2 className="RatgeberArea__title">{headline}</h2>
        <Columns gutter="m" widths={columnWidths} wrap centered flexContent>
          {ratgeberArea?.nodes.map((node, index) => (
            <div className="RatgeberArea__teaser" key={index}>
              <RatgeberTeaserComponent {...ratgeberArea[node]} />
            </div>
          ))}
        </Columns>
      </div>
    </section>
  ) : null;
};

export default RatgeberArea;
