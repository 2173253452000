import React, { useEffect } from 'react';

import useScrollPosition from '../../../hooks/useScrollPosition';
import Icon from '../Icon/Icon';

const ScrollToTopButton = () => {
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    import('smoothscroll-polyfill').then(smoothscroll => smoothscroll.polyfill());
  }, []);

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  const isVisible = scrollPosition > 2000;

  return (
    <div className={`m-topscroll${isVisible ? ' is-visible' : ''}`} onClick={() => scrollToTop()}>
      <Icon type="arrow-up" className="ico-arrow-up"></Icon>
    </div>
  );
};

export default ScrollToTopButton;
