import './FrequentlyAskedComponent.scss';

import React, { useState } from 'react';

import NewButtonComponent from '../NewButtonComponent/NewButtonComponent';
import { AutoHeight } from '../ui/AutoHeight/AutoHeight';
import Collapsible from '../ui/Collapsible/Collapsible';
import Container from '../ui/Container/Container';

const mapAskedQuestions = askedQuestions => {
  const result = [];
  for (const key in askedQuestions) {
    if (askedQuestions[key].question && askedQuestions[key].answer) {
      result.push({
        question: askedQuestions[key].question,
        answer: askedQuestions[key].answer
      });
    }
  }
  return result;
};

// Property interface
// interface IFrequentlyAskedProps {
//   askedQuestions: { question: string; answer: string }[];
//   maxInitialQuestionCount: number;
//   hideQuestions: boolean;
// }

const FrequentlyAskedComponent = ({ title, askedQuestions }) => {
  const hideQuestions = true;
  const [showAll, setShowAll] = useState(!hideQuestions);
  const maxInitialQuestionCount = 5;

  const mappedQuestions = mapAskedQuestions(askedQuestions);
  const getQuestions = () => {
    return showAll ? mappedQuestions : [...mappedQuestions.slice(0, maxInitialQuestionCount)];
  };

  const isButtonVisible = !showAll && maxInitialQuestionCount < mappedQuestions.length;

  return (
    <Container>
      <div className="FrequentlyAsked">
        {title ? (
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        ) : (
          <h2>
            Häufige <strong>Fragen</strong> und <strong>Antworten</strong>
          </h2>
        )}
        <AutoHeight>
          <div className="FrequentlyAsked__questionsWrapper">
            {getQuestions().map((section, i) => {
              return (
                <Collapsible
                  key={i}
                  header={section.question}
                  variants={['styled-html']}
                  content={section.answer}
                />
              );
            })}
          </div>
          {isButtonVisible && (
            <div className="FrequentlyAsked__buttonWrapper">
              <NewButtonComponent
                label="Alle Fragen anzeigen"
                variant="light"
                icon="plus"
                onClick={() => setShowAll(true)}
              />
            </div>
          )}
        </AutoHeight>
      </div>
    </Container>
  );
};

export default FrequentlyAskedComponent;
