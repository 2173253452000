import { format } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';

import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import SiteDataContext from '../../contexts/SiteDataContext';
import useUrlParams from '../../hooks/useUrlParams';

interface IArticleAreaProps {
  variant?: 'teaser';
}

const ArticlesArea: React.FC<IArticleAreaProps> = ({ variant }) => {
  const { articlePages, tags } = useContext(SiteDataContext) || {};

  const { artikel: articleTags = [] } = tags || {};

  const [selectedTags, dispatchSelectedTags] = useState([]);

  articlePages &&
    articlePages.sort((a, b) => {
      return a.publicationDate <= b.publicationDate || !a.publicationDate ? 1 : -1;
    });

  const isTeaserVariant = variant === 'teaser';

  const initialItems = isTeaserVariant ? 6 : 9;
  const itemIncrement = 9;

  const [maxItems, setMaxItems] = useState(initialItems);

  const increaseMaxItems = () => {
    setMaxItems(maxItems + itemIncrement);
  };

  const setSelectedTag = value => {
    if (selectedTags.includes(value)) {
      dispatchSelectedTags(selectedTags.filter(tag => tag !== value));
    } else {
      dispatchSelectedTags([...selectedTags, value]);
    }
    setMaxItems(initialItems);
  };

  const [filterParams] = useUrlParams('filter');

  useEffect(() => {
    if (filterParams !== '') {
      dispatchSelectedTags(filterParams.split(','));
    }
  }, [filterParams]);

  const getFilteredArticlePages = (selectedTags: string[]) =>
    articlePages
      ? articlePages.filter(page => {
          const { tags: tagIds = [], image } = page || {};

          const tags =
            tagIds.map(tagId =>
              articleTags?.find(articleTag => {
                return articleTag.id === tagId;
              })
            ) || [];

          return (
            image &&
            (selectedTags.length === 0 || tags.some(tag => tag && selectedTags.includes(tag.name)))
          );
        })
      : [];

  const filteredArticlePages = getFilteredArticlePages(selectedTags);

  return articlePages ? (
    <section className="l-content__section">
      <div>
        {isTeaserVariant && <h2>Unser Magazin</h2>}
        <div>
          {!isTeaserVariant && (
            <nav className="m-tag-list spacer">
              <button
                className={`m-tag-list__tag${selectedTags.length === 0 ? ' active' : ''}`}
                onClick={() => dispatchSelectedTags([])}
              >
                {`Alle (${articlePages.length})`}
              </button>
              {articleTags?.map(tag => {
                const { name, label, id } = tag || {};

                const isActive = selectedTags.includes(name);

                const amountOfArticles = getFilteredArticlePages(name).length;

                return amountOfArticles && amountOfArticles > 0 ? (
                  <button
                    key={id}
                    className={`m-tag-list__tag${isActive ? ' active' : ''}`}
                    onClick={() => setSelectedTag(name)}
                  >
                    {`${label} (${amountOfArticles})`}
                  </button>
                ) : null;
              })}
            </nav>
          )}

          <ul className="l-magazine">
            {filteredArticlePages?.slice(0, maxItems).map(article => {
              const { path, template, image, title, leadText, publicationDate } = article || {};

              return (
                <li className="l-magazine__item" key={path}>
                  {image && (
                    <Link linkTo={{ path, template }}>
                      <Image src={image} sizes={{ w: 567, h: 130 }} />
                    </Link>
                  )}
                  <div>
                    <header>
                      <time>{format(new Date(publicationDate), 'dd.MM.yyyy')}</time>
                      <Link linkTo={path} extraClass="l-magazine__item__title">
                        <h3>{title}</h3>
                      </Link>
                    </header>
                    <p>{leadText}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {isTeaserVariant ? (
          <Link linkTo="/firmen/magazin" extraClass="m-button m-button--full-width">
            Lesen Sie weitere Artikel in unserem Magazin
          </Link>
        ) : (
          filteredArticlePages.length >= maxItems && (
            <button
              className="m-button m-button--full-width m-button--sec"
              onClick={() => increaseMaxItems()}
            >
              Weitere Artikel anzeigen
            </button>
          )
        )}
      </div>
    </section>
  ) : null;
};

export default ArticlesArea;
