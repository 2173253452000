import React from 'react';
import { useState } from 'react';

import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';

interface IRuleProps {
  bgImage: string;
  title: string;
  children: any;
  downloadLabel?: string;
  link: string;
}

const RuleComponent: React.FC<IRuleProps> = ({
  bgImage,
  title,
  children,
  downloadLabel = 'Schadenfall als PDF downloaden',
  link
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div className={`regel-entry${isOpen ? ' open' : ' closed'}`}>
        <Image src={bgImage} isBackgroundImage sizes={{ w: 500 }}>
          <div className="regel-img" style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="regel-title">
              <h3>{title}</h3>
            </div>
          </div>
        </Image>
        <div className="regel-txt">
          {children}
          <div className="txt-bottom-fade"></div>
        </div>
        <button
          className={`btn--readmore${isOpen ? ' open' : ' closed'}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? 'Weniger lesen' : 'Mehr lesen'}
        </button>
        <div className="tipp">
          <p>
            <a className="m-button m-button--link text-m" href={link}>
              <Icon type="download" className="ico-download"></Icon>
              {downloadLabel}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RuleComponent;
