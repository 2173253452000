import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const ProductPage = ({ children }) => {
  const {
    benefitArea,
    headerArea,
    billboardArea,
    contentHeaderArea,
    contentArea,
    pricePreviewArea,
    crossSellingArea
  } = extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content">
        {contentHeaderArea}
        {billboardArea}
        {benefitArea}
        {pricePreviewArea}
        {contentArea}
        {crossSellingArea}
      </main>
      <Footer />
    </>
  );
};

export default ProductPage;
