import './MediaTeaserComponent.scss';

import React from 'react';

import CustomerPortalTeaserComponent from '../CustomerPortalTeaserComponent/CustomerPortalTeaserComponent';
import Container from '../ui/Container/Container';
import MediaTeaserCardComponent from './MediaTeaserCardComponent';

export interface IMediaTeaser {
  mediaType: 'image' | 'youtube';
  youtubeId?: string;
  videoLabel?: string;
  image: any;
  alignment: string;
  title: string;
  description: string;
  buttonType: string;
  label: string;
  icon: string;
  linkTo: any;
}

const MediaTeaserComponent = ({
  mediaTeasers,
  customerPortalTeaser
}: {
  mediaTeasers: IMediaTeaser[];
  customerPortalTeaser?: boolean;
}) => {
  const teasers = mediaTeasers
    ? Object.values(mediaTeasers).filter(mediaTeaser => mediaTeaser.title)
    : null;
  return (
    <Container>
      {teasers &&
        teasers.map((teaser, i) => (
          <div key={i} className="MediaTeaserComponent">
            <MediaTeaserCardComponent {...teaser} />
          </div>
        ))}

      {customerPortalTeaser && (
        <CustomerPortalTeaserComponent bigTeaser={false} withoutContainer={true} />
      )}
    </Container>
  );
};

export default MediaTeaserComponent;
