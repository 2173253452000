import React from 'react';

import BenefitTableFooterArea from '../BenefitTableFooterArea/BenefitTableFooterArea';
import ComparisonFooterArea from '../ComparisonFooterArea/ComparisonFooterArea';

const FooterArea = props => {
  const { children, parentTemplate } = props || {};

  const footerAreaVariant = () => {
    switch (parentTemplate) {
      case 'BenefitTableComponent':
        return <BenefitTableFooterArea {...props} />;
      default:
        return <ComparisonFooterArea {...props} />;
    }
  };

  return children ? footerAreaVariant() : null;
};

export default FooterArea;
