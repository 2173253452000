import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { usePikaday } from '../../../hooks/usePikaday';
import Icon from '../Icon/Icon';
import FormErrorMessage from './FormErrorMessage';
import FormTooltip from './FormTooltip';

interface DateFieldComponentProps {
  name: string;
  optional?: string;
  label: string;
  minYear?: string;
  maxYear?: string;
  tooltipText?: string;
  errorMessage?: string;
}

const DateFieldComponent: React.FC<DateFieldComponentProps> = ({
  name,
  optional,
  label,
  minYear,
  maxYear,
  tooltipText,
  errorMessage
}) => {
  const containerRef = useRef(null);
  const fieldRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef(null);
  usePikaday(containerRef, fieldRef, triggerRef);
  const {
    register,
    unregister,
    formState: { errors, dirtyFields }
  } = useFormContext();
  useEffect(() => {
    return () => {
      unregister(name);
    };
  }, []);

  const { ref, ...inputProps } = register(name, { required: !optional });

  const getActualErrorMessage = () => {
    if (errors[name]) {
      if (errors[name].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie eine korrektes Datum ein';
    }
    return undefined;
  };

  const actualErrorMessage = getActualErrorMessage();

  return (
    <>
      <div className="m-form__group">
        <label htmlFor={name}>
          {label} {optional && <em>optional</em>}
        </label>
        <div className="m-form__group-fields">
          <div className="l-grid">
            <div className="l-grid__col--12-12">
              <div className="m-datepicker">
                <div
                  className="m-datepicker__input"
                  ref={containerRef}
                  data-min-year={minYear}
                  data-max-year={maxYear}
                >
                  <input
                    type="text"
                    className={classNames('m-form__dateinput', {
                      'ng-invalid': errors[name],
                      'ng-touched': dirtyFields[name]
                    })}
                    id={name}
                    placeholder="TT.MM.JJJJ"
                    {...inputProps}
                    ref={e => {
                      ref(e);
                      // @ts-ignore
                      fieldRef.current = e;
                    }}
                  />
                </div>
                <div className="m-datepicker__trigger" ref={triggerRef}>
                  <Icon type="calendar" className="ico-calendar"></Icon>
                </div>
              </div>
            </div>
          </div>
          {tooltipText && <FormTooltip label={label} name={name} tooltipText={tooltipText} />}
        </div>
      </div>
      {actualErrorMessage && <FormErrorMessage errorMessage={actualErrorMessage} />}
    </>
  );
};

export default DateFieldComponent;
