import React from 'react';

import Image from '../ui/Image/Image';

const LargeImageComponent = ({ caption, image }) => {
  return (
    <figure>
      <Image src={image} />
      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  );
};
export default LargeImageComponent;
