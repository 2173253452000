import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const PrivateHomePage = ({ children }) => {
  const { headerArea, stageArea, contentArea, footerColumnsArea, footerLinkArea } =
    extractChildren(children);
  return (
    <>
      {headerArea}
      {stageArea}
      <main className="l-content l-content--home">{contentArea}</main>
      <Footer footerColumnsArea={footerColumnsArea} footerLinkArea={footerLinkArea} />
    </>
  );
};

export default PrivateHomePage;
