import './Lightbox.scss';

import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import getVariantClasses from '../../helpers/getVariantClasses';
import useOutsideClick from '../../hooks/useOutsideClick';
import Icon from '../ui/Icon/Icon';

type TLightBoxVariants = '' | 'noBg' | 'fullscreen' | 'video';

interface ILighBoxProps {
  variants?: TLightBoxVariants[];
  visible: boolean;
  turnOffHandler: () => void;
  children: any;
}

const Lightbox: React.FC<ILighBoxProps> = ({ visible, variants, children, turnOffHandler }) => {
  const [portalEntry, setPortalEntry] = useState(null);
  const [isCloseVisible, setIsCloseVisible] = useState(false);
  const innerRef = useRef(null);
  useOutsideClick(innerRef, turnOffHandler, visible);

  useEffect(() => {
    if (visible === true) {
      setTimeout(() => {
        setIsCloseVisible(true);
      }, 1000);
    }
  }, [visible]);

  const handleClose = () => {
    setIsCloseVisible(false);
    turnOffHandler();
  };

  useEffect(() => setPortalEntry(document.querySelector('#lightbox-container')), []);
  return portalEntry
    ? createPortal(
        <CSSTransition in={visible} mountOnEnter unmountOnExit timeout={0}>
          <div className={getVariantClasses('LightBox', variants)}>
            <div className="LightBox__inner" ref={innerRef}>
              <div
                className={`LightBox__close ${isCloseVisible ? 'is-visible' : ''}`}
                onClick={handleClose}
              >
                Schließen <Icon type="plus" />
              </div>
              <div className="LightBox__content">{children}</div>
            </div>
          </div>
        </CSSTransition>,
        portalEntry
      )
    : null;
};

export default Lightbox;
