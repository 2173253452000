import React from 'react';

import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';
import RichText from '../ui/RichText/RichText';

const DisqTeaserComponent = ({ text, linkTo, label, linkType, image }) => {
  return (
    <div className="m-disq">
      <div className="m-disq__column">{image && <Image src={image} sizes={{ h: 200 }} />}</div>
      <div className="m-disq__column">
        <div>
          {text && <RichText extraClass="m-quote m-quote--simple" richText={text} />}
          <Link
            linkTo={linkTo}
            label={label}
            linkType={linkType}
            extraClass="m-button m-button--link"
          />
        </div>
      </div>
    </div>
  );
};

export default DisqTeaserComponent;
