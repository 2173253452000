import React from 'react';
import { useState } from 'react';

import Icon from '../../components/ui/Icon/Icon';
import RichText from '../../components/ui/RichText/RichText';

export const SurveyLabel: React.FC<{ label: string }> = ({ label }) => {
  return (
    <div className="m-survey__label" style={{ paddingTop: '0.9rem' }}>
      {<div className="m-survey__faded fade-animate">{label}</div>}
    </div>
  );
};

export const SurveyQuestion: React.FC<{
  question: string;
  inlineStyle?: Record<string, string>;
}> = ({ question, inlineStyle }) => {
  return (
    <div className="m-survey__question" style={inlineStyle}>
      {question}
    </div>
  );
};

export const SurveyRow: React.FC<{
  rowExtendClass?: string;
  children?: any;
}> = ({ children, rowExtendClass }) => {
  return <div className={`m-survey__row ${rowExtendClass ? rowExtendClass : ''}`}>{children}</div>;
};

export const SurveySection: React.FC<{
  children?: JSX.Element | JSX.Element[];
  sectionExtendClass?: string;
  rowExtendClass?: string;
  headline?: string;
  noRow?: boolean;
}> = ({ sectionExtendClass, rowExtendClass, headline, noRow, children }) => {
  return (
    <section className={`m-survey__section ${sectionExtendClass ? sectionExtendClass : ''}`}>
      {headline && <h3>{headline}</h3>}
      {noRow ? children : <SurveyRow rowExtendClass={rowExtendClass}>{children}</SurveyRow>}
    </section>
  );
};

export const SurveyFeedback: React.FC<{
  type: 'sad' | 'happy';
  text: string;
  buttonLabel?: string;
  onClick?: () => void;
}> = ({ type, text, buttonLabel, onClick }) => {
  return (
    <>
      <section className="m-survey__faded fade-animate">
        <p className="text-center spacer" style={{ color: '#bfc0bf', fontSize: '8rem' }}>
          <Icon type={type} className={`ico-smiley-${type}`}></Icon>
        </p>
        <RichText extraClass="text-center" richText={text} />
        {onClick && buttonLabel && (
          <div className="text-center">
            <button className="m-button" onClick={onClick}>
              {buttonLabel}
            </button>
          </div>
        )}
      </section>
    </>
  );
};

export const SurveyRadios: any = ({
  question,
  keys,
  id,
  strings,
  selectionValues,
  onClick
}: {
  question: string;
  keys: string[];
  id: 'vd' | 'vhv';
  strings: Record<string, Record<string, string>>;
  selectionValues: Record<string, Record<string, unknown>>;
  onClick: (e: string, key: string) => void;
}) => {
  const [currentRating, setCurrentRating] = useState<Record<string, string>>();
  return keys.map(tagVD => (
    <div className="m-survey__row ng-scope" key={tagVD}>
      <div key={tagVD} className="m-survey__question">
        {strings[tagVD][question]}
      </div>
      <div className="m-survey__radios">
        <div className="has-rule">
          {[1, 2, 3, 4, 5].map(index => (
            <span
              className="m-survey__radio"
              key={index}
              onClick={() => onClick(index.toString(), tagVD)}
            >
              <input type="radio" name={`VD_${id}_${tagVD}`} id={`VD_${id}_${tagVD + index}`} />
              <label
                className="radio"
                htmlFor={`VD_${id}_${tagVD + index}`}
                onMouseMove={() =>
                  setCurrentRating({
                    ...currentRating,
                    [tagVD]: strings[tagVD].ratings[index]
                  })
                }
                onMouseLeave={() =>
                  setCurrentRating({
                    ...currentRating,
                    [tagVD]: ''
                  })
                }
              ></label>
            </span>
          ))}
        </div>
        <div className="m-survey__desc">
          <div>{strings[tagVD].ratings[1]}</div>
          <div className="text-right">{strings[tagVD].ratings[5]}</div>
        </div>
      </div>
      <aside className="m-survey__label">
        {currentRating && currentRating[tagVD] && (
          <div className="m-survey__faded fade-animate">
            {(currentRating && currentRating[tagVD]) ||
              strings[tagVD].ratings[selectionValues.vd.tags[tagVD]]}
          </div>
        )}
      </aside>
    </div>
  ));
};

export const SurveyNoYesButton: React.FC<{
  lables: string[];
  question: string;
  id: 'vd' | 'vhv';
  onClick: (e: any) => void;
}> = ({ lables, question, id, onClick }) => {
  const values = {
    Nein: 0,
    Ja: 5
  };

  return (
    <>
      <div className="m-survey__question">{question}</div>
      <div className="m-survey__buttons">
        <div>
          {lables.map(label => (
            <div
              key={id + label}
              className="m-survey__button"
              onClick={() => onClick(values[label])}
            >
              <input
                type="radio"
                name={`${id}_Weiterempfehlung`}
                id={`${id}_Weiterempfehlung_${label}`}
                value={label}
              />

              <label className="" htmlFor={`${id}_Weiterempfehlung_${label}`}>
                {label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const SurveyStars: React.FC<{
  onClick: (e: number) => void;
  error?: boolean;
  selectetValue: number;
  question: string;
}> = ({ onClick, error, selectetValue, question }) => {
  const [currentState, setCurrentState] = useState<number>();
  const labels = [
    '',
    'gar nicht zufrieden',
    'weniger zufrieden',
    'mäßig zufrieden',
    'eher zufrieden',
    'sehr zufrieden'
  ];

  return (
    <>
      <div className="m-survey__question" style={{ paddingTop: '0.7rem' }}>
        {question}
      </div>

      <div className="m-survey__radios">
        <div>
          {[2, 4, 6, 8, 10].map(i => (
            <span
              className={`m-survey__radio ${
                i <= currentState || i <= selectetValue ? 'highlighted' : ''
              }`}
              key={i}
              onClick={() => onClick(i)}
            >
              <input type="radio" id={`VD_score${i}`} value={i} required />
              <label
                className=""
                onMouseOver={() => setCurrentState(i)}
                onMouseLeave={() => setCurrentState(0)}
              >
                <svg
                  width="42px"
                  height="40px"
                  viewBox="0 0 42 40"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(-214, 0)" className="m-survey__star">
                    <path d="M234.086346,1.59708269 L229.729884,14.6682029 L215.777404,14.6682029 C215.40278,14.669898 215.070537,14.9106078 214.955279,15.2665843 C214.838315,15.6225609 214.963754,16.012464 215.263794,16.2362105 L226.597363,24.5238589 L222.240902,38.0510645 C222.123938,38.4087352 222.252768,38.8003264 222.557892,39.0206881 C222.863013,39.2427509 223.274914,39.2410541 223.578377,39.0189926 L234.911947,30.7313443 L246.245517,39.0189926 C246.548946,39.2410554 246.960846,39.2427478 247.266002,39.0206881 C247.571124,38.800322 247.699956,38.4087265 247.582992,38.0493694 L243.226531,24.5221638 L254.5601,16.2345155 C254.86014,16.0124553 254.985579,15.6225566 254.868615,15.2665843 C254.753349,14.9106078 254.421122,14.669898 254.04649,14.669898 L240.09401,14.669898 L235.737548,1.59877771 C235.617196,1.22584829 235.261197,0.980043883 234.869641,0.998703893 C234.510136,1.01735045 234.198219,1.25466716 234.086346,1.59708269 Z"></path>
                  </g>
                </svg>
              </label>
            </span>
          ))}
        </div>
        <div className="m-survey__desc">
          <div>gar nicht zufrieden</div>
          <div className="text-right">sehr zufrieden</div>
        </div>
      </div>
      <div
        className={`m-survey__label ${!currentState && error ? 'm-survey__label--error' : ''}`}
        style={{ paddingTop: '0.9rem' }}
        // ng-class="{'m-survey__label--error': ctrl.surveyForm.$submitted && !ctrl.currentRating.vd.score && !ctrl.selection.vd.score}"
      >
        {(currentState > 0 || (!currentState && error)) && (
          <div className="m-survey__faded fade-animate">
            {labels[currentState / 2] || 'Bitte bewerten'}
          </div>
        )}
      </div>
    </>
  );
};
