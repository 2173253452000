import React, { useState } from 'react';

import Image from '../ui/Image/Image';
import LightBox from '../ui/LightBox/LightBox';
import RichText from '../ui/RichText/RichText';

const RichTextComponent = props => {
  const {
    alignment,
    listVariant,
    richText,
    video,
    image,
    imagezoomable,
    subcaption
    // mediaType
  } = props;

  const asset = video || image;
  const [isLightboxVisible, setIsLightboxVisible] = useState(false);

  //Video ist bis jetzt noch nicht auf der Content Seite

  const getMediaContent = className => {
    return (
      <figure className={className}>
        {image ? (
          imagezoomable ? (
            <button
              className="RichText__button"
              onClick={() => setIsLightboxVisible(!isLightboxVisible)}
            >
              <Image src={image} sizes={{ w: 320 }} />
            </button>
          ) : (
            <Image src={image} sizes={{ w: 320 }} />
          )
        ) : // ) : (
        //   <Video id={video} />
        null}
        {subcaption && <figcaption>{subcaption}</figcaption>}
      </figure>
    );
  };

  const getContent = () => {
    return asset ? (
      // <figure class="desktop" media-lightbox ${cmsfn:isEditMode() ? 'edit-mode="true"' : ''}>
      <>
        {getMediaContent('desktop')}
        <RichText richText={richText} />
        {getMediaContent('mobile')}
      </>
    ) : (
      <RichText richText={richText} />
    );
  };

  return richText ? (
    <div className="spacer">
      <div
        className={`l-richtext${
          listVariant === 'checkmarks' ? ' l-richtext--checked-lists' : ''
        }${video || image ? ' l-richtext--with-media-' + alignment : ''}`}
      >
        <LightBox
          media={image}
          onClick={() => setIsLightboxVisible(!isLightboxVisible)}
          isVisible={isLightboxVisible}
        />

        {getContent()}
      </div>
    </div>
  ) : null;
};

export default RichTextComponent;
