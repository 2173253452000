import React from 'react';

import Image from '../ui/Image/Image';
import Link from '../ui/Link/Link';

const TeaserComponent = ({ teaserReference }) => {
  const { image, headline, text, clickClass } = teaserReference || {};

  const fallBackImage = '/fallback_teaser_image.png';

  const getContent = (
    <figure>
      <div>
        <Image
          src={image || fallBackImage}
          extraClass={clickClass}
          alt={headline}
          sizes={{ w: 500 }}
        />
        {headline && (
          <figcaption className={`m-promo-teaser__caption${clickClass ? ' ' + clickClass : ''}`}>
            <h3
              className={`m-promo-teaser__caption__headline${clickClass ? ' ' + clickClass : ''}`}
            >
              {headline}
            </h3>
            <p className={clickClass}>{text}</p>
          </figcaption>
        )}
      </div>
    </figure>
  );

  return headline && text ? (
    <div className="TeaserComponent">
      <Link {...teaserReference} extraClass="m-promo-teaser">
        {getContent}
      </Link>
    </div>
  ) : null;
};
export default TeaserComponent;
