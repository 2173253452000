const getFormEngineWebBaseUrl = () => {
  switch (process.env.GATSBY_ENV) {
    case 'local':
      return 'https://cmswebapps-t.vhv.de';
    case 'dev':
      return 'https://cmswebapps-t.vhv.de';
    case 'stage':
      return 'https://cmswebapps-t.vhv.de';
    case 'v':
      return 'https://cmswebapps-v.vhv.de';
    case 'live':
      return 'https://cmswebapps-p.vhv.de';
  }
};

export default getFormEngineWebBaseUrl;
