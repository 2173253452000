import './PartnerArea.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import SiteDataContext from '../../contexts/SiteDataContext';
import useOutsideClick from '../../hooks/useOutsideClick';
import statesTagMap from './statesTagMap';

const PartnerArea = () => {
  const { organizations } = useContext(SiteDataContext) || {};
  const [selectedState, setSelectedState] = useState('');
  const [isSelectorOpen, setIsSelectorOpen] = useState<boolean>(false);
  const [optionsHeight, setOptionsHeight] = useState(0);

  const getTileContent = (name, image) => (
    <>
      <div className="m-orga-tile__front">
        <Image src={image} />
      </div>
      <div className="m-orga-tile__back">{name}</div>
    </>
  );
  const getPartnerTile = (name, image, website) => {
    return (
      <div className="m-orga-tile__item js-dom-filter">
        {website ? (
          <Link linkTo={website} linkType="external">
            <div className="m-orga-tile m-orga-tile--link">{getTileContent(name, image)}</div>
          </Link>
        ) : (
          <div>
            <div className="m-orga-tile__plain">{getTileContent(name, image)}</div>
          </div>
        )}
      </div>
    );
  };

  const getSelector = () => {
    const buttonRef = useRef(null);
    const optionsRef = useRef(null);

    useOutsideClick(optionsRef, () => setIsSelectorOpen(false), isSelectorOpen, buttonRef);

    useEffect(() => {
      if (optionsRef?.current) {
        setOptionsHeight(optionsRef.current.getBoundingClientRect()?.height);
      }
    }, [isSelectorOpen]);

    return (
      <div className="PartnerAreaSelector">
        <div
          className={`PartnerAreaSelector__button${
            isSelectorOpen ? ' PartnerAreaSelector__button--active' : ''
          }`}
          ref={buttonRef}
          onClick={() => setIsSelectorOpen(!isSelectorOpen)}
        >
          <span className="PartnerAreaSelector__label">
            {statesTagMap[selectedState] || 'Alle Bundesländer'}
          </span>
          {statesTagMap && Object.keys(statesTagMap).length > 0 && (
            <Icon type="arrow-down" className="ico-arrow-down" />
          )}
          <CSSTransition
            in={isSelectorOpen}
            mountOnEnter
            unmountOnExit
            timeout={200}
            classNames={'fade-transform-top-2'}
          >
            <ul className="PartnerAreaSelector__options" ref={optionsRef}>
              {selectedState !== '' && (
                <li
                  className="PartnerAreaSelector__option"
                  onClick={() => {
                    setIsSelectorOpen(false);
                    setSelectedState('');
                  }}
                >
                  Alle Bundesländer
                </li>
              )}
              {Object.keys(statesTagMap)
                .filter(state => state !== selectedState)
                .map((state, key) => (
                  <li
                    key={key}
                    className="PartnerAreaSelector__option"
                    onClick={() => {
                      setIsSelectorOpen(false);
                      setSelectedState(state);
                    }}
                  >
                    {statesTagMap[state]}
                  </li>
                ))}
            </ul>
          </CSSTransition>
        </div>
      </div>
    );
  };
  return (
    <section className="l-content__section" style={{ minHeight: optionsHeight }}>
      <div>
        <div className="m-event-list__filter m-event-list__filter--medium PartnerArea__selector">
          <div>Wähle ein Bundesland</div>
          {getSelector()}
        </div>

        <div className="m-orga-tile__wrapper">
          {statesTagMap &&
            Object.keys(statesTagMap).map((state, key) => {
              const filteredOrgas = organizations?.filter(organisation =>
                organisation.states?.includes(state)
              );
              return filteredOrgas?.length > 0 &&
                (selectedState === '' || selectedState === state) ? (
                <>
                  <div key={key} className="m-orga-tile__item js-dom-filter">
                    <div>
                      <div className="m-orga-tile__plain">
                        <div>{statesTagMap[state]}</div>
                      </div>
                    </div>
                  </div>
                  {filteredOrgas.map(organization => {
                    const { name, image, website } = organization;
                    return getPartnerTile(name, image, website);
                  })}
                </>
              ) : null;
            })}
        </div>
      </div>
    </section>
  );
};

export default PartnerArea;
