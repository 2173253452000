import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import SiteDataContext from '../../contexts/SiteDataContext';

const ProductArea = ({ tiles }) => {
  const { solutionTiles } = useContext(SiteDataContext) || {};

  const filteredSolutionTiles =
    solutionTiles?.filter(solutionTile => tiles.includes(solutionTile.id)) || [];

  return (
    <section className="l-content__section">
      <div>
        <h2>Wir sind auch für Ihre Branche da.</h2>
        <Columns gutter="xs" extraClass="m-content-tiles--products" centered={true}>
          {filteredSolutionTiles.map((tile, key) => {
            const { path, title, tileHeadline, icon } = tile;
            return (
              <article key={key} className="m-content-tiles__tile">
                <div>
                  <Link linkTo={path} linkType="internal">
                    <h4 className="size-6">
                      <Icon type={icon} />
                      {tileHeadline || title}
                    </h4>
                  </Link>
                </div>
              </article>
            );
          })}
        </Columns>
      </div>
    </section>
  );
};

export default ProductArea;
