import React, { useContext } from 'react';

import ContactInformationContext from '../../../contexts/ContactInformationContext';
import Icon from '../Icon/Icon';
import ContactInformationProduct from './ContactInformationProduct';
import ContactInformationTabs from './ContactInformationTabs';

interface IContactInformationSlide {
  variant?: 'contact';
  products: any;
  title?: string;
  extraClass?: string;
  pushProductSlide?: any;
}

const ContactInformationSlide: React.FC<IContactInformationSlide> = ({
  variant,
  products,
  title,
  extraClass,
  pushProductSlide
}) => {
  const { isDamageVariant } = useContext(ContactInformationContext) || {};
  const icon = isDamageVariant ? (
    <Icon type="warning" className="ico-warning" />
  ) : (
    <Icon type="hotline" className="ico-hotline" />
  );

  return (
    <div>
      <header>
        {icon}
        <h2>{title || (isDamageVariant ? 'Schaden melden' : 'Kontakt')}</h2>
      </header>
      <nav className="m-contact-selection m-contact-selection--inverted m-contact-selection--centered">
        {variant === 'contact' ? (
          <ContactInformationTabs product={products} />
        ) : (
          products &&
          products.map((product, index) => {
            return (
              <ContactInformationProduct
                product={product}
                key={index}
                extraClass={extraClass}
                pushProductSlide={pushProductSlide}
              />
            );
          })
        )}
      </nav>
    </div>
  );
};
export default ContactInformationSlide;
