import React from 'react';

import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import Link, { ILinkProps } from '../Link/Link';

interface IInfoBoxProps {
  title?: string;
  text: string;
  show: boolean;
  onClose?: () => void;
  icon?: string;
  linkProps: ILinkProps;
  arrowLinkProps?: ILinkProps;
  logo?: any;
}

const InfoBox: React.FC<IInfoBoxProps> = ({
  title,
  text,
  show,
  onClose,
  icon = 'phone',
  linkProps,
  arrowLinkProps,
  logo
}) => {
  return show ? (
    <div className="m-modal InfoBox">
      {logo ? (
        <Image src={logo} alt="" sizes={{ w: 200 }} />
      ) : (
        <Icon type={icon} className={`ico-${icon}`}></Icon>
      )}
      {title && (
        <p className="spacer-small text-l">
          <strong>{title}</strong>
        </p>
      )}
      <p className="spacer">{text}</p>
      <Link {...linkProps} extraClass="m-button" />
      {arrowLinkProps && (
        <div>
          <Link {...arrowLinkProps} showIcon />
        </div>
      )}

      {onClose && (
        <Link onClick={() => onClose()} extraClass="m-modal__close-button" linkTo="">
          <Icon type="close" className="ico-close"></Icon>
        </Link>
      )}
    </div>
  ) : null;
};
export default InfoBox;
