import './BrokerSearch.scss';

import MarkerClusterer from '@googlemaps/markerclustererplus';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import getFormEngineWebBaseUrl from '../../../helpers/getFormEngineWebBaseUrl';
import useBreakpoint from '../../../hooks/useBreakpoint';
import type { Marker } from '../../../hooks/useBrokerSearch';
import useGoogleMap from '../../../hooks/useGoogleMap';
import markerIcon from '../../../styles/assets/images/location-marker.png';
import pinIcon from '../../../styles/assets/images/location-marker-pin.png';
import Icon from '../Icon/Icon';

const mapOptions: google.maps.MapOptions = {
  zoomControl: true,
  zoom: 12,
  center: {
    lat: 52.3625312,
    lng: 9.7108987
  },
  disableDefaultUI: true
};

// offset for the map center
const offset = {
  x: 260,
  y: -100
};

const indexMap = new WeakMap();

const toGoogleMapMarker = (marker: Marker, index: number) => {
  const googleMapMarker = new google.maps.Marker({
    position: {
      lat: parseFloat(marker.latlng.latitude),
      lng: parseFloat(marker.latlng.longitude)
    },
    icon: markerIcon
  });
  indexMap.set(googleMapMarker, index);
  return googleMapMarker;
};

function BrokerInfoWindow({ marker }: { marker: Marker }) {
  return (
    <>
      <div className="l-broker-search__result-window-info">
        <address>
          <strong>{marker.organization}</strong>
          {marker.street} <br />
          {marker.zip} {marker.city}
          {marker.phone && (
            <span>
              <br />
              <br />
              Tel.: {marker.phone}
            </span>
          )}
          {marker.fax && (
            <span>
              <br />
              Fax: {marker.fax}
            </span>
          )}
        </address>
        <a
          className="m-button m-button--outline-pri m-button--condensed float-right"
          href={`https://www.google.de/maps/dir//${marker.street},+${marker.zip}+${marker.city}`}
          target="_blank"
          rel="noreferrer"
        >
          <Icon type="route" className="ico-route"></Icon> Route&nbsp;berechnen
        </a>
      </div>
      <aside className="broker-search-buttons">
        <a
          fullscreen-overlay-slide-trigger="brokerSearchCallback"
          className="m-button m-button--sec m-button--condensed tag-broker-search-callback-button"
          href={`${getFormEngineWebBaseUrl()}/formEngineWeb/vhv/formular-vm-rueckruf.htm?datato=${
            marker.email
          }`}
        >
          Rückruf-Wunsch
        </a>
        <a
          fullscreen-overlay-slide-trigger="brokerSearchContact"
          className="m-button m-button--sec m-button--condensed tag-broker-search-mail-button"
          href={`${getFormEngineWebBaseUrl()}/formEngineWeb/vhv/formular-vm-e-mail.htm?datato=${
            marker.email
          }`}
        >
          E-Mail schreiben
        </a>
      </aside>
    </>
  );
}

function BrokerGoogleMap({
  markers,
  selectedMarker,
  selectMarker
}: {
  markers: Marker[];
  selectedMarker: Marker | undefined;
  selectMarker: (marker: Marker) => void;
}) {
  const isMobile = useBreakpoint('m');
  const mapRef = useRef<HTMLDivElement>(null);
  const { googleMap } = useGoogleMap(mapRef, mapOptions);
  const [googleMapMarkers, setGoogleMapMarkers] = useState<google.maps.Marker[]>([]);
  const [info, setInfo] = useState<{
    infoWindow: google.maps.InfoWindow;
    $Element: HTMLElement;
  } | null>(null);

  const onMarkerClick = (googleMapMarker: google.maps.Marker) => {
    const index = indexMap.get(googleMapMarker);
    const marker = markers[index];
    selectMarker(marker);
  };

  useEffect(() => {
    if (!selectedMarker || !googleMap || !markers.includes(selectedMarker)) {
      return;
    }
    if (!info) {
      return;
    }
    const index = markers.indexOf(selectedMarker);
    const googleMapMarker = googleMapMarkers[index];
    const position = googleMapMarker.getPosition();
    if (!position) {
      return;
    }

    const latLng = {
      lat: 1 * position.lat(),
      lng: 1 * position.lng()
    };
    if (!isMobile) {
      latLng.lat -= offset.y / Math.pow(2, googleMap.getZoom());
      latLng.lng -= offset.x / Math.pow(2, googleMap.getZoom());
    }
    googleMapMarker.setIcon(pinIcon);
    googleMap.panTo(latLng);

    if (!isMobile) {
      // Timeout for better transition
      info.infoWindow.close();
      setTimeout(() => {
        ReactDOM.render(<BrokerInfoWindow marker={selectedMarker} />, info.$Element);
        info.infoWindow.open(googleMap, googleMapMarker);
      }, 50);
    }

    return () => {
      if (info) {
        info.infoWindow.close();
      }
      googleMapMarker.setIcon(markerIcon);
    };
  }, [googleMap, selectedMarker, googleMapMarkers, markers, isMobile]);

  useEffect(() => {
    if (!googleMap || markers.length === 0) {
      return;
    }
    const $Element = document.createElement('div');
    $Element.className = 'l-broker-search__result-window';
    const infoWindow = new google.maps.InfoWindow({
      content: $Element,
      disableAutoPan: true,
      // @ts-ignore
      pixelOffset: {
        width: 0,
        height: -56
      },
      zIndex: 999999999 // really?
    });
    setInfo({ infoWindow, $Element });
    const listeners: google.maps.MapsEventListener[] = [];
    const googleMapMarkers = markers.map(toGoogleMapMarker);
    setGoogleMapMarkers(googleMapMarkers);
    const bounds = new google.maps.LatLngBounds();
    for (const googleMapMarker of googleMapMarkers) {
      listeners.push(
        googleMapMarker.addListener('click', () => {
          onMarkerClick(googleMapMarker);
        })
      );
      const position = googleMapMarker.getPosition();
      if (position) {
        bounds.extend(position);
      }
      googleMapMarker.setMap(googleMap);
    }
    googleMap.fitBounds(bounds);
    new MarkerClusterer(googleMap, googleMapMarkers, {
      // ToDo fix the path
      imagePath: '/assets/google_map/cluster',
      imageExtension: 'png',
      clusterClass: 'l-broker-search__cluster'
    });

    return () => {
      for (const listener of listeners) {
        listener && listener.remove();
      }
    };
  }, [googleMap, markers]);

  return <div className="GoogleMap" ref={mapRef}></div>;
}

export default BrokerGoogleMap;
