import React, { useContext, useRef } from 'react';

import Link from '../../components/ui/Link/Link';
import SiteDataContext from '../../contexts/SiteDataContext';
import CorporateNavDropdown from './CorporateNavDropdown/CorporateNavDropdown';
import PrivateNavDropdown from './PrivateNavDropdown';

const MainNavItem = ({
  mainNavItem,
  toggleSelectedMainNavItem,
  clearSelectedMainNavItem,
  selectedMainNavItem
}) => {
  const menuItemRef = useRef(null);

  const { pageScope } = useContext(SiteDataContext) || {};

  const { name, children, path, template, navigationTitle, title, label } = mainNavItem || {};

  const subNavItems = children?.filter(c => c && !c.hideInOverviews);

  const hasTitle = title && path;
  const isActive = selectedMainNavItem === name;
  return hasTitle ? (
    <li
      className={`${subNavItems ? `with-sub-nav${isActive ? ' open' : ''}` : ''}`}
      ref={menuItemRef}
      onClick={() => {
        toggleSelectedMainNavItem(name);
      }}
    >
      {subNavItems ? (
        <>
          <a>{label || title}</a>
          {pageScope === 'private' ? (
            <PrivateNavDropdown
              isActive={selectedMainNavItem === name}
              items={subNavItems}
              closeDropdown={clearSelectedMainNavItem}
              allProductsTitle={navigationTitle}
              allProductsPath={{ path, template }}
              parentRef={menuItemRef}
            />
          ) : (
            <CorporateNavDropdown
              isActive={selectedMainNavItem === name}
              items={subNavItems}
              closeDropdown={clearSelectedMainNavItem}
              allProductsTitle={navigationTitle}
              allProductsPath={{ path, template }}
              parentRef={menuItemRef}
            />
          )}
        </>
      ) : (
        <Link linkTo={{ path, template }}>{label || title}</Link>
      )}
    </li>
  ) : null;
};

export default MainNavItem;
