import React from 'react';

import getCalculatorPath from '../../helpers/getCalculatorPath';
import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';
import Tooltip from '../ui/Tooltip/Tooltip';

const PriceCallToActionComponent = ({
  leftButtonLabelTop = null,
  leftButtonLabelBottom = null,
  leftButtonPopup = null,
  rightButtonLabel,
  leftButtonLabel = null,
  linkTo,
  linkType
}) => {
  return (
    <div className="m-button m-button--flagged">
      {!!leftButtonPopup && !!leftButtonLabelBottom && (
        <div className="m-button__flag m-button__flag--price">
          <Tooltip
            label={
              !leftButtonLabelTop || !leftButtonLabelBottom ? (
                leftButtonLabel
              ) : (
                <>
                  {leftButtonLabelTop}
                  <strong>{leftButtonLabelBottom} €*</strong>
                </>
              )
            }
            extraClassTooltip="m-button__flag m-button__flag--price m-tooltip__with-calc"
            extraClassPopup="m-tooltip__popup--wide"
          >
            {leftButtonPopup}
          </Tooltip>
        </div>
      )}
      <Link
        {...getCalculatorPath(linkTo, linkType)}
        extraClass="m-button m-button--size-m m-button--opaque tag-stage-calc ga-cta-top-normal"
      >
        <Icon type="calculator" className="ico-calculator ga-cta-top-normal"></Icon>
        <span className="ga-cta-top-normal">{rightButtonLabel}</span>
      </Link>
    </div>
  );
};

export default PriceCallToActionComponent;
