import React, { useContext } from 'react';

import Columns from '../../components/ui/Columns/Columns';
import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import { BusinessCardPageContext } from '../../containers/BusinessCardPage';
import BrokerBcContext from '../../contexts/BrokerBcContext';
import ExpertSearchContext from '../../contexts/ExpertSearchContext';

const FooterContactArea = () => {
  const { broker: brokerFromBusinessCard } = useContext(BusinessCardPageContext) || {};
  const { expert: expertSearchBroker } = useContext(ExpertSearchContext) || {};

  const broker = brokerFromBusinessCard || expertSearchBroker;

  const { setVisibleBrokerBc } = useContext(BrokerBcContext) || {};
  const { photoUrl, faxNumber, telephoneNumber, children } = broker || {};
  const links = [
    {
      label: 'Einen Termin vereinbaren',
      onClick: () => setVisibleBrokerBc('termin', broker)
    },
    {
      label: 'Einen Rückruf anfordern',
      onClick: () => setVisibleBrokerBc('rueckruf', broker)
    },
    {
      label: 'Eine E-Mail-Anfrage stellen',
      onClick: () => setVisibleBrokerBc('email', broker)
    }
  ];

  const [phone, text, fax] = broker
    ? [telephoneNumber, 'Sie haben noch Fragen?\nIch freue mich auf Ihren Anruf!', faxNumber]
    : ['0180 - 2232 100', `Sie haben Fragen an unsere Bauexperten?`, '0511 - 907 3929'];

  return (
    <section className="l-content__section l-content__section--medium">
      <div>
        <Columns widths={[2, 1]} wrap={false}>
          <div>
            <div className="clearfix">
              {photoUrl && (
                <figure className="l-broker-bc__broker-image l-broker-bc__broker-image--footer">
                  <Image src={photoUrl} sizes={{ w: 250 }} />
                </figure>
              )}
              <h3>
                {text.split('\n').map((x, i) => (
                  <React.Fragment key={i}>
                    {i !== 0 && <br />}
                    {x}
                  </React.Fragment>
                ))}
              </h3>
              <p className="l-broker-bc__phone">
                <Icon type="phone" className="ico-phone"></Icon> {phone}
              </p>
              <p>oder faxen Sie uns: {fax}</p>
            </div>
          </div>
          <div>
            <div className="l-broker-bc__linklist">
              <h3>Sie können auch:</h3>
              <ul className="m-list m-list--links m-list--indent">
                {links.map((link, key) => {
                  const { label, onClick } = link;
                  return (
                    <li key={key}>
                      <a onClick={onClick}>{label}</a>
                    </li>
                  );
                })}
                {children}
              </ul>
            </div>
          </div>
        </Columns>
      </div>
    </section>
  );
};

export default FooterContactArea;
