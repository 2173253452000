import React from 'react';

const TilesComponent = ({ variant, text, headline, children }) => {
  return (
    <div>
      {variant != 'simple' && (
        <>
          <h2 className="">{headline}</h2>
          <p className="l-landing__tiles-intro">{text}</p>
        </>
      )}
      <div className="l-landing__tiles l-grid">{children}</div>
    </div>
  );
};

export default TilesComponent;
