import React from 'react';

import Link from '../ui/Link/Link';

const QuoteComponent = ({ text, source, linkTo, linkType }) => {
  return (
    <div>
      <blockquote className="m-quote">
        <p>{text}</p>
        <footer>
          <cite>
            <Link linkTo={linkTo} linkType={linkType} label={source} />
          </cite>
        </footer>
      </blockquote>
    </div>
  );
};

export default QuoteComponent;
