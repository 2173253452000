import React, { useState } from 'react';

import Icon from '../ui/Icon/Icon';
import QuickCheckContent from './QuickCheckContent';

const QuickCheckComponent = () => {
  const { packages, items } = QuickCheckContent;

  const [selectedPackage, setSelectedPackage] = useState(0);

  return (
    <div className="m-info-graphic">
      <div className="l-tabs l-tabs--">
        <div>
          <nav className="m-info-graphic__nav ng-scope">
            {packages.map((label, index) => (
              <button
                className={`m-info-graphic__nav-button${
                  selectedPackage === index ? ' m-info-graphic__nav-button--active' : ''
                }`}
                onClick={() => setSelectedPackage(index)}
                key={index}
              >
                {label}
              </button>
            ))}
          </nav>
        </div>
        <div className="l-tabs__content l-tabs__content--">
          <div className="m-tab">
            <div>
              <div className="m-info-graphic__info">
                {items.map((item, index) => {
                  const { icon, label, packages: scope } = item;

                  return (
                    <div
                      className={`m-info-graphic__info-box${
                        scope.includes(selectedPackage) ? ' active' : ''
                      }`}
                      key={index}
                    >
                      <div>
                        <Icon type={icon} className={`ico-${icon}`} />
                        <strong>{label}</strong>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickCheckComponent;
