import React, { useContext } from 'react';

import { FooterLinkAreaContext } from '../../areas/FooterLinkArea/FooterLinkArea';
import SiteDataContext from '../../contexts/SiteDataContext';
import Link from '../ui/Link/Link';

const FooterLinkComponent = props => {
  //Todo @ilja fix isLastChild logic in mgnl context
  const isLastChild = useContext(FooterLinkAreaContext) || {};
  const { pageScope } = useContext(SiteDataContext) || {};
  //Todo remove /firmen scope hack

  const { linkTo } = props || {};

  const { path, template } = linkTo || {};

  return (
    <>
      {pageScope === 'corporate' && path ? (
        <Link {...props} linkTo={{ path: '/firmen' + path, template }} />
      ) : (
        <Link {...props} />
      )}
      {!isLastChild && '\u00A0/ '}
    </>
  );
};

export default FooterLinkComponent;
