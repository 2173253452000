const quickCheckContent = {
  packages: ['Haftpflicht', 'Haftpflicht + Teilkasko', 'Haftpflicht + Vollkasko'],
  items: [
    {
      icon: 'unfallschaden-fremd',
      label: 'Unfallschaden am fremden Fahrzeug',
      packages: [0, 1, 2]
    },
    {
      icon: 'unfallschaden',
      label: 'Unfallschaden am eigenen Fahrzeug',
      packages: [2]
    },
    {
      icon: 'tier',
      label: 'Zusammenstoß mit einem Tier',
      packages: [1, 2]
    },
    {
      icon: 'autoschluessel',
      label: 'Autoschlüssel-Diebstahl',
      packages: [1, 2]
    },
    {
      icon: 'unwetter',
      label: 'Unwetterschäden',
      packages: [1, 2]
    },
    {
      icon: 'feuer',
      label: 'Schaden durch Brand/Explosion',
      packages: [1, 2]
    },
    {
      icon: 'marder',
      label: 'Folge-/Schaden durch Tierbiss',
      packages: [1, 2]
    },
    {
      icon: 'vandalismus',
      label: 'Schaden durch Vandalismus',
      packages: [2]
    },
    {
      icon: 'diebstahl',
      label: 'Diebstahl',
      packages: [1, 2]
    },
    {
      icon: 'glasbruch',
      label: 'Glasbruch',
      packages: [1, 2]
    }
  ]
};

export default quickCheckContent;
