import React, { useContext } from 'react';

import CorporateContactContext from '../../contexts/CorporateContactContext';
import ProductGroupsContext from '../../contexts/ProductGroupsContext';

//TODO expert search with PLZ

const BrokerSearchCallToActionComponent = () => {
  const { activeProduct: product } = useContext(ProductGroupsContext) || {};
  const { linkTo: calculatorPage, showExpert, contactMethod } = product || {};
  const { getExpertSearchCta } = useContext(CorporateContactContext) || {};

  const showExpertSearch = contactMethod == 'expert' || (!contactMethod && showExpert);

  const { expertCallback, expertLabel, expertIconClass } =
    getExpertSearchCta(showExpertSearch) || {};

  return (
    <a
      className={`m-button m-button--opaque ${
        calculatorPage ? 'm-button--light' : ''
      } m-button--single-line tag-content-header-contact ga-content-header-contact`}
      onClick={expertCallback}
    >
      <i className={expertIconClass} />
      {expertLabel}
    </a>
  );
};
export default BrokerSearchCallToActionComponent;
