import React from 'react';

import Columns from '../../components/ui/Columns/Columns';
import extractChildren from '../../helpers/extractChildren';

const ContentListComponent = ({ children, headline, desc }) => {
  const { contentListArea, contentSidebarArea } = extractChildren(children);

  return (
    <section className="l-content__section l-content__section--wide l-content__section--medium">
      <div>
        <Columns widths={[2, 1]}>
          <div>
            <h2 className="l-landingseo__content-head">{headline}</h2>
            <p className="spacer">{desc}</p>
            <ul className="l-landingseo__list">{contentListArea}</ul>
          </div>
          <div>{contentSidebarArea}</div>
        </Columns>
      </div>
    </section>
  );
};
export default ContentListComponent;
