import React from 'react';
import bgImage from '../../styles/assets/images/broker-search-background.jpg';

const SectionComponent = props => {
  const { headline, variant, children, contentArea } = props || {};

  const isBrokerSearchVariant =
    contentArea && contentArea[0] && contentArea[0].template === 'BrokerSearchComponent';

  return (
    <section
      className={`l-content__section${
        variant === 'banderole' || isBrokerSearchVariant ? ' l-content__section--medium' : ''
      }`}
      style={isBrokerSearchVariant ? { backgroundImage: `url(${bgImage})` } : {}}
    >
      <div>
        {headline && <h2>{headline}</h2>}
        {children && <div>{children}</div>}
      </div>
    </section>
  );
};

export default SectionComponent;
