import React, { useContext } from 'react';

import SiteDataContext from '../../../contexts/SiteDataContext';
import { getCopyrightLabel } from '../../../helpers/getCurrentYear';
import FooterComponent from '../../FooterComponent/FooterComponent';

interface IFooter {
  signetArea?: any;
  footerColumnsArea?: any;
  footerContactArea?: any;
  footerLinkArea?: any;
}

const Footer: React.FC<IFooter> = ({
  signetArea,
  footerColumnsArea,
  footerContactArea,
  footerLinkArea
}) => {
  const { footer, pageScope } = useContext(SiteDataContext) || {};
  const {
    homeSignetArea = {},
    homeFooterColumnsArea = {},
    homeFooterContactArea = {},
    homeFooterLinkArea = {}
  } = footer || {};

  const isFooterVisible =
    (homeFooterLinkArea && homeFooterLinkArea.props && homeFooterContactArea) ||
    (footerLinkArea && footerContactArea) ||
    (footerColumnsArea && footerLinkArea) ||
    (homeSignetArea &&
      homeSignetArea.props &&
      homeFooterColumnsArea &&
      homeFooterColumnsArea.props &&
      homeFooterLinkArea &&
      homeFooterLinkArea.props);
  return isFooterVisible
    ? {
        corporate: (
          <footer className="l-footer nocontent">
            <div>
              <div className="l-footer__windup l-footer__windup--minimal">
                <div className="float-left">{footerLinkArea || homeFooterLinkArea}</div>
                <div className="float-right">{getCopyrightLabel()}</div>
              </div>
            </div>
          </footer>
        ),
        expert: (
          <>
            {footerContactArea || homeFooterContactArea}
            <footer className="l-footer nocontent">
              <div>
                <div className="l-footer__windup l-footer__windup--minimal">
                  <div className="float-left">{footerLinkArea || homeFooterLinkArea}</div>
                  <div className="float-right">{getCopyrightLabel()}</div>
                </div>
              </div>
            </footer>
          </>
        )
      }[pageScope] || (
        <>
          <FooterComponent
            columnsArea={footerColumnsArea || homeFooterColumnsArea}
            linkArea={footerLinkArea || homeFooterLinkArea}
          />
        </>
      )
    : null;
};

export default Footer;
