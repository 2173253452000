import React, { createContext, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import Icon from '../Icon/Icon';

interface IFullscreenOverlaySlider {
  children: any;
  initialSlide?: number;
  onBackClick?: any;
}

export const FullScreenOverlaySliderContext = createContext(null);

const FullscreenOverlaySlider: React.FC<IFullscreenOverlaySlider> = ({
  children,
  initialSlide = 0,
  onBackClick
}) => {
  const [activeSlide, setActiveSlide] = useState(initialSlide);
  const sliderRef = useRef(null);

  const sliderSettings: Settings = {
    dots: false,
    arrows: false,
    infinite: false,
    initialSlide: activeSlide,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const slideTransition = direction => {
    switch (direction) {
      case 'back':
        sliderRef && sliderRef.current && sliderRef.current.slickPrev();
        setActiveSlide(activeSlide - 1);
        if (onBackClick) {
          onBackClick();
        }
        break;
      case 'next':
        sliderRef && sliderRef.current && sliderRef.current.slickNext();
        setActiveSlide(activeSlide + 1);
        break;

      default:
        break;
    }
  };

  return (
    <FullScreenOverlaySliderContext.Provider value={slideTransition}>
      {activeSlide > 0 && (
        <button
          onClick={() => slideTransition('back')}
          className="l-fullscreen-overlay__back-button"
        >
          <Icon type="arrow-left" className="ico-arrow-left" />
          <span>Zurück</span>
        </button>
      )}
      <div>
        <Slider ref={sliderRef} {...sliderSettings}>
          {children}
        </Slider>
      </div>
    </FullScreenOverlaySliderContext.Provider>
  );
};

export default FullscreenOverlaySlider;
