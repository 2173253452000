import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const ArticleOverviewPage = ({ children }) => {
  const { headerArea, contentHeaderArea, articlesArea } = extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content l-content--corporate">
        {contentHeaderArea}
        {articlesArea}
        <Footer />
      </main>
    </>
  );
};

export default ArticleOverviewPage;
