import React from 'react';

import AnnouncementBanner from '../../components/ui/AnnouncementBanner/AnnouncementBanner';

const HomePageStageArea = ({ children, announcementText, linkTo, linkType, label }) => {
  return (
    <>
      {announcementText && (
        <AnnouncementBanner
          announcementText={announcementText}
          linkTo={linkTo}
          linkType={linkType}
          label={label}
        />
      )}
      {children}
    </>
  );
};

export default HomePageStageArea;
