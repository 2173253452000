import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Image from '../../ui/Image/Image';
import Icon from '../Icon/Icon';

const LightBox = ({ media, onClick, isVisible, caption = null }) => {
  // const [selected, setSelected] = useState(0);
  const getImage = Array.isArray(media) ? media[0] : media;

  // Noch nicht umgestezt
  // Mehere Bilder, arrows & animation
  // Ladezustand

  // const Next = () => {
  //   selected < media.length && setSelected(selected + 1);
  // };
  // const Prev = () => {
  //   selected > 0 && setSelected(active - 1);
  // };

  return (
    <CSSTransition in={isVisible} mountOnEnter unmountOnExit timeout={500} classNames={'fade'}>
      <div className="m-media-lightbox" onClick={onClick}>
        <div className="m-media-lightbox__media-container">
          {/* <button onClick={Prev}>
              <Icon type="arrow-left" className="ico-arrow-left"></Icon>
            </button>
            <button onClick={Next}>
              <Icon type="arrow-right" className="ico-arrow-right"></Icon>
            </button> */}
          <Image src={getImage} />
          {caption && <figcaption>{caption}</figcaption>}
        </div>
        <button className="m-media-lightbox__close-button">
          <Icon type="close" className="ico-close"></Icon>
        </button>
        {/* <img
        src={getAsset(getImage).path}
        alt="Lade..."
        className="m-media-lightbox__spinner"
      ></img> */}
      </div>
    </CSSTransition>
  );
};

export default LightBox;
