import React, { useContext } from 'react';

import BusinessCardPageStageArea from './BusinessCardPageStageArea';
import CalculatorSeoPageStageArea from './CalculatorSeoPageStageArea';
import ContactBarContext from '../../contexts/ContactBarContext';
import HomePageStageArea from './HomePageStageArea';
import LandingPageStageArea from './LandingPageStageArea';

const StageArea = props => {
  const { children, parentTemplate, contactBoxVariant } = props;

  const { setVariant } = useContext(ContactBarContext) || {};
  contactBoxVariant && setVariant(contactBoxVariant);

  return (
    {
      HomePage: <HomePageStageArea {...props} />,
      LandingPage: <LandingPageStageArea {...props} />,
      CalculatorSeoPage: <CalculatorSeoPageStageArea {...props} />,
      BusinessCardPage: <BusinessCardPageStageArea {...props} />
    }[parentTemplate] || (children ? <>{children}</> : null)
  );
};

export default StageArea;
