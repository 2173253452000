import React, { useContext } from 'react';

import ContactInformationContext from '../../../contexts/ContactInformationContext';
import ProductGroupsContext from '../../../contexts/ProductGroupsContext';
import SiteDataContext from '../../../contexts/SiteDataContext';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import contactData from './contactData';
import ContactInformationSlides from './ContactInformationSlides';

const ContactInformationModal = () => {
  const {
    isActiveProductVariant,
    isContactInformationModalVisible,
    setIsContactInformationModalVisible
  } = useContext(ContactInformationContext) || {};

  const { activeProduct } = useContext(ProductGroupsContext) || {};

  const { pageScope } = useContext(SiteDataContext) || {};

  const products = contactData[pageScope] || contactData['private'];

  const flatProductContacts = [];

  const flattenProductContacts = node => {
    node &&
      Object.keys(node).forEach(key => {
        if (typeof node[key] === 'object') {
          flattenProductContacts(node[key]);
        } else if (
          node.name &&
          !flatProductContacts.find(productGroup => productGroup.name === node.name)
        ) {
          flatProductContacts.push(node);
        }
      });
  };

  flattenProductContacts(products);

  return (
    <FullscreenOverlay
      isVisible={isContactInformationModalVisible}
      hideOverlay={() => setIsContactInformationModalVisible(false)}
      contentVariants={['wide']}
    >
      <ContactInformationSlides
        activeProduct={
          isActiveProductVariant &&
          flatProductContacts.find(
            productContact =>
              activeProduct && productContact.activeProductSelectors?.includes(activeProduct.name)
          )
        }
      />
    </FullscreenOverlay>
  );
};

export default ContactInformationModal;
