import React from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';

const ExpertSolutionPage = ({ children }) => {
  const { headerArea, contentHeaderArea, benefitArea, contentArea } = extractChildren(children);
  return (
    <>
      {headerArea}
      <main className="l-content l-content--corporate">
        {contentHeaderArea}
        {benefitArea}
        {contentArea}
        <Footer />
      </main>
    </>
  );
};

export default ExpertSolutionPage;
