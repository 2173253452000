import React, { Children } from 'react';

import Columns from '../ui/Columns/Columns';
import extractChildren from '../../helpers/extractChildren';
import mapIndexedNodeProps from '../../helpers/mapIndexedNodeProps';
import useBreakpoint from '../../hooks/useBreakpoint';

const ComparisonComponent = props => {
  const { children } = props;
  const { rowArea, footerArea } = extractChildren(children);
  const BreakpointS = useBreakpoint('s');

  const packages = mapIndexedNodeProps(props, 'packages');

  const getWidths = () => {
    const widthArray = new Array(packages.length).fill(1);

    // !BreakpointS && widthArray.unshift(2);
    return widthArray;
  };

  const getDynamicHeader = () => {
    return (
      <Columns widths={getWidths()} gutter={BreakpointS ? 'xs' : 'xl--content'} wrap={false}>
        {Array.isArray(packages) &&
          packages.map((pkg, index) => {
            const { name } = pkg || {};

            return (
              <div className="ComparisonComponent__th" key={index}>
                {name}
              </div>
            );
          })}
      </Columns>
    );
  };

  return (
    <div className="ComparisonComponent m-comparison">
      {BreakpointS ? (
        getDynamicHeader()
      ) : (
        <Columns widths={[1, 2]} gutter={'xl--content'} wrap={false}>
          <div className="ComparisonComponent__first-col">Die wichtigsten Leistungen</div>
          {getDynamicHeader()}
        </Columns>
      )}
      {rowArea}

      {footerArea}
    </div>
  );
};
export default ComparisonComponent;
