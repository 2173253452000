import { format } from 'date-fns';
import React, { useContext } from 'react';

import Link from '../../components/ui/Link/Link';
import SiteDataContext from '../../contexts/SiteDataContext';

const ArticlesComponents = () => {
  const { articlePages } = useContext(SiteDataContext) || {};

  articlePages &&
    articlePages.sort((a, b) => {
      return a.publicationDate <= b.publicationDate || !a.publicationDate ? 1 : -1;
    });

  return (
    <>
      <ul className="l-magazine">
        {articlePages.slice(0, 3).map((article, key) => {
          const { path, title, leadText, publicationDate, pageScope, template } = article;

          return (
            <li className="l-magazine__item" key={key}>
              <div>
                <div>
                  <header>
                    <time>{format(new Date(publicationDate), 'dd.MM.yyyy')}</time>
                    <Link
                      extraClass="l-magazine__item__title"
                      linkTo={{ path, pageScope, template }}
                      linkType="external"
                    >
                      <h3>{title}</h3>
                    </Link>
                  </header>
                  <p>{leadText}</p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <Link
        extraClass="m-button m-button--sec m-button--size-m m-button--full-width"
        linkTo="/aktuelles/magazin"
        linkType="internal"
        showIcon
        label="Zum Magazin"
      />
    </>
  );
};

export default ArticlesComponents;
