import React from 'react';

import RichText from '../ui/RichText/RichText';

const AgendaItemComponent = ({ headline, time, description }) => {
  return (
    <tr>
      <td>
        <strong>{time}</strong>
      </td>
      <td>
        <strong>{headline}</strong>
        <RichText richText={description} />
      </td>
    </tr>
  );
};

export default AgendaItemComponent;
