import { useEffect, useState } from 'react';

const useVpHeight = () => {
  const [vpHeight, setVpHeight] = useState(0);

  useEffect(() => {
    setVpHeight(Math.max(document.documentElement.clientHeight, window.innerHeight));
  }, []);

  return vpHeight;
};

export default useVpHeight;
