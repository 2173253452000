import React from 'react';

import './RichText.scss';

import extractLinkPath from '../../../helpers/extractLinkPath';

interface ITextProps {
  richText: string;
  extraClass?: string;
  elem?: 'div' | 'blockquote' | 'li' | 'p' | 'span';
  style?: any;
}

const RichText: React.FC<ITextProps> = ({ richText, extraClass = '', style }) => {
  const innerHtml = richText && { __html: extractLinkPath(richText) };

  return richText ? (
    <div style={style} className={`RichText ${extraClass}`} dangerouslySetInnerHTML={innerHtml} />
  ) : null;
};

export default RichText;
