import './ProductVariantsArea.scss';

import Columns from '../../components/ui/Columns/Columns';
import React from 'react';
import extractChildren from '../../helpers/extractChildren';
import useBreakpoint from '../../hooks/useBreakpoint';

const ProductVariantsArea = ({ headline, children }) => {
  const { variantsArea } = extractChildren(children) || {};
  const isMaxVpL = useBreakpoint('l');
  const childLength = variantsArea?.props?.children.length;
  const columnWidths = isMaxVpL || childLength <= 2 ? [1, 1] : [1, 1, 1];

  return variantsArea ? (
    <div className="ProductVariantsArea">
      <h2 className="ProductVariantsArea__headline">{headline}</h2>
      <div className="ProductVariantsArea__items">
        <Columns
          extraClass="ProductVariantsArea__col"
          gutter="xs"
          widths={columnWidths}
          wrap
          centered
          flexContent
        >
          {variantsArea?.props?.children}
        </Columns>
      </div>
    </div>
  ) : null;
};

export default ProductVariantsArea;
