import './ProductVariantComponent.scss';

import Link from '../ui/Link/Link';
import React from 'react';
import useBreakpoint from '../../hooks/useBreakpoint';

interface IProductVariant {
  title: string;
  icon: any;
  benefits?: any;
  links?: any;
}

const ProductVariantComponent: React.FC<IProductVariant> = ({ title, benefits, icon, links }) => {
  const isMaxVpS = useBreakpoint('s');
  const buttonWidthClass = isMaxVpS ? '' : ' m-button--full-width';

  return (
    <div className="ProductVariantComponent">
      <div className="ProductVariantComponent__wrapper">
        <div className="ProductVariantComponent__icon">
          <i className={`ico-${icon}`} />
        </div>
        <h3 className="ProductVariantComponent__title">{title}</h3>
        {benefits && (
          <ul className="ProductVariantComponent__benefits">
            {benefits['nodes'].map((node, subIndex) => {
              const { text } = benefits[node];
              return (
                <li key={`benefit-${subIndex}`} className="ProductVariantComponent__benefit">
                  {text}
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="ProductVariantComponent__buttons">
        {links &&
          links['nodes'].map((node, index) => {
            const { linkTo, linkType, label } = links[node];

            return (
              <Link
                key={index}
                linkTo={linkTo}
                linkType={linkType}
                label={label}
                extraClass={
                  index === 1
                    ? `m-button m-button--medium${buttonWidthClass}`
                    : `m-button ${buttonWidthClass}`
                }
              />
            );
          })}
      </div>
    </div>
  );
};

export default ProductVariantComponent;
