import './BrokerSearch.scss';

import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import BrokerSearchContext from '../../../contexts/BrokerSearchContext';
import browserBack from '../../../helpers/browserBack';
import { useBrokerSearch } from '../../../hooks/useBrokerSearch';
import Icon from '../Icon/Icon';
import BrokerGoogleMap from './BrokerGoogleMap';
import BrokerSearch from './BrokerSearch';

const BrokerSearchApp = () => {
  const { isBrokerSearchModalVisible, inputPLZ, setIsBrokerSearchModalVisible } =
    useContext(BrokerSearchContext) || {};
  const {
    reset,
    markers,
    state,
    error,
    geoLocate,
    selectMarker,
    selectedMarker,
    searchValue,
    updateSearchResults
  } = useBrokerSearch();

  return (
    <CSSTransition
      in={isBrokerSearchModalVisible}
      mountOnEnter
      unmountOnExit
      timeout={200}
      classNames={'fade-transform-top-2'}
    >
      <div className="container l-initialized BrokerSearchApp">
        <div className="l-fullscreen-overlay--hidden-overflow BrokerSearchApp__wrapper">
          <BrokerSearch
            inputPLZ={inputPLZ}
            searchValue={searchValue}
            reset={reset}
            markers={markers}
            state={state}
            error={error}
            geoLocate={geoLocate}
            selectMarker={selectMarker}
            selectedMarker={selectedMarker}
            updateSearchResults={updateSearchResults}
          />
          <BrokerGoogleMap
            markers={markers}
            selectedMarker={selectedMarker}
            selectMarker={selectMarker}
          />
          <button
            className="l-fullscreen-overlay__close-button"
            style={{ display: 'block' }}
            onClick={() => {
              browserBack();
              setIsBrokerSearchModalVisible(false);
            }}
          >
            <Icon type="close" className="ico-close"></Icon>
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};

export default BrokerSearchApp;
