import { RefObject, useEffect, useState } from 'react';

import { loadGoogle } from '../helpers/loadGoogle';

const useGoogleMap = (mapRef: RefObject<HTMLDivElement>, mapOptions: google.maps.MapOptions) => {
  const [googleMap, setGoogleMap] = useState<google.maps.Map<HTMLDivElement> | null>(null);

  const [google, setGoogle] = useState(null);

  useEffect(() => {
    const createMap = async () => {
      const google = await loadGoogle();
      setGoogle(google);
      if (mapRef.current) {
        const googleMap = new google.maps.Map(mapRef.current, mapOptions);
        setGoogleMap(googleMap);
      }
    };
    createMap();
  }, []);
  return {
    googleMap,
    google
  };
};

export default useGoogleMap;
