import React, { useContext } from 'react';

import CorporateContactFooter from '../components/ui/CorporateContact/CorporateContactFooter';
import CorporateProductBar from '../components/ui/CorporateProductBar/CorporateProductBar';
import ProductGroupsContext from '../contexts/ProductGroupsContext';
import extractChildren from '../helpers/extractChildren';

const CorporateProductPage = ({
  corporateBranchTags = [],
  secondaryCorporateBranchTags = [],
  sideProductReferences,
  children
}) => {
  const {
    headerArea,
    contentHeaderArea,
    billboardArea,
    benefitArea,
    // pricePreviewArea,
    contentArea,
    crossSellingArea
  } = extractChildren(children);

  const { activeProduct } = useContext(ProductGroupsContext) || {};

  const { showExpert, contactMethod } = activeProduct || {};
  const showExpertSearch = contactMethod == 'expert' || (!contactMethod && showExpert);

  return (
    <>
      {headerArea}
      <main className="l-content l-content--corporate">
        {contentHeaderArea}
        <CorporateProductBar
          sideProducts={sideProductReferences}
          branchTags={[...corporateBranchTags, ...secondaryCorporateBranchTags]}
        />
        {billboardArea}
        {benefitArea}
        {/* {pricePreviewArea} */}
        {contentArea}
        {crossSellingArea}
        <CorporateContactFooter isExpertSearchVariant={showExpertSearch} />
      </main>
    </>
  );
};

export default CorporateProductPage;
