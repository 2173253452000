import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

// import { IS_MAGNOLIA_EDIT_MODE } from '../config';
import FormErrorMessage from './FormErrorMessage';
import FormTooltip from './FormTooltip';

interface RadioGroupComponentProps {
  label: string;
  optional?: string;
  name: string;
  errorMessage?: string;
  tooltipText?: string;
  showAdditions?: boolean;
  additionsTrigger?: string;
  additionsTriggerLeft?: string;
  options: {
    label: string;
    value: string;
  }[];
  children?: React.ReactNode;
}

const RadioGroupComponent: React.FC<RadioGroupComponentProps> = ({
  optional,
  name,
  label,
  options,
  tooltipText,
  showAdditions,
  additionsTrigger,
  additionsTriggerLeft,
  children,
  errorMessage
}) => {
  const [selectedValue, setSelectedValue] = useState('');

  const showHorizontal = options.length === 2 && options.some(option => option.label.length <= 6);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const renderChildren =
    // IS_MAGNOLIA_EDIT_MODE ||
    false || (showAdditions && selectedValue === additionsTrigger);

  const {
    register,
    unregister,
    formState: { errors, dirtyFields }
  } = useFormContext();

  useEffect(() => {
    return () => {
      for (const option of options) {
        unregister(option.value);
      }
    };
  }, []);

  const getActualErrorMessage = () => {
    if (errors[name]) {
      if (errors[name].type.toString() === 'required') {
        return 'Bitte füllen Sie dieses Feld aus';
      }
      return errorMessage || 'Bitte geben Sie eine korrekte Auswahl an';
    }
    return undefined;
  };

  const actualErrorMessage = getActualErrorMessage();

  options = (options['nodes'] && options['nodes'].map(key => options[key])) || options;
  return (
    <>
      <div className="m-form__group">
        <label htmlFor="${name}">
          {label} {optional && <em>optional</em>}
        </label>
        <div className="m-form__group-fields">
          {showHorizontal ? (
            <div className="l-grid">
              {options.map((option, index) => {
                const { onChange, ...rest } = register(name, {
                  required: !optional
                });
                return (
                  <div className="l-grid__col--6-12" key={index}>
                    <input
                      type="radio"
                      value={option.value}
                      id={`${name}${index}`}
                      className={classNames({
                        'ng-invalid': errors[name],
                        'ng-touched': dirtyFields[name]
                      })}
                      {...rest}
                      onChange={event => {
                        onChange(event);
                        handleChange(event);
                      }}
                    />
                    <label className="radio boxstyle" htmlFor={`${name}${index}`}>
                      <span>{option.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="l-grid">
              <div className="l-grid__col--12-12">
                {options.map((option, index) => (
                  <React.Fragment key={index}>
                    <input
                      type="radio"
                      value={option.value}
                      // name={name}
                      id={`${name}${index}`}
                      // required={!optional}
                      {...register(name, { required: !optional })}
                      className={classNames({
                        'ng-invalid': errors[name],
                        'ng-touched': dirtyFields[name]
                      })}
                    />
                    <label className="radio boxstyle" htmlFor={`${name}${index}`}>
                      <span>{option.label}</span>
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}
          {tooltipText && <FormTooltip label={label} name={name} tooltipText={tooltipText} />}
        </div>
      </div>
      {actualErrorMessage && <FormErrorMessage errorMessage={actualErrorMessage} />}
      {renderChildren && (
        <div className="m-form__group-additions">
          <div>
            <div
              className={classNames(
                'm-bordered-box',
                !showHorizontal
                  ? 'm-bordered-box--75'
                  : additionsTriggerLeft
                    ? 'm-bordered-box--62'
                    : ' m-bordered-box--88'
              )}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RadioGroupComponent;
