import React, { useContext, useEffect, useRef } from 'react';

import Icon from '../../components/ui/Icon/Icon';
import { BusinessCardPageContext } from '../../containers/BusinessCardPage';
import useBreakpoint from '../../hooks/useBreakpoint';
import useGeoPosition from '../../hooks/useGeoPosition';
import useGoogleMap from '../../hooks/useGoogleMap';
import icon from '../../styles/assets/images/location-marker-pin.png';

interface IMapArea {
  address: {
    location: string;
    street: string;
    plz: string;
  };
}

const MapArea: React.FC<IMapArea> = () => {
  const { broker } = useContext(BusinessCardPageContext) || {};

  const { street, zip, city, addressEncoded } = broker || {};

  const mapRef = useRef(null);

  const position = street + ' ' + zip + ' ' + city;

  const [currentPos, isGeoLoading]: any = useGeoPosition(
    //Todo@ ilja use vhv-tu api key instead of partner api key,
    //once the vhv-tu key is configured to use the geoposition api
    'AIzaSyC6_hdZenit19lPzCl6JiljQzB_w5-zji4',
    position
  );

  const isMaxVpS = useBreakpoint('s');

  const offset =
    typeof window !== 'undefined' && window.innerWidth > 568 ? 0.000006 * window.innerWidth : 0;

  const { lat, lng } = currentPos || {};

  const mapCenter = { lat, lng: lng - offset };

  const map = useGoogleMap(
    mapRef,

    {
      center: mapCenter,
      zoom: 15,
      fullscreenControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      backgroundColor: 'transparent',
      scrollwheel: false
    }
  );
  const { googleMap, google } = map;

  useEffect(() => {
    google &&
      !isGeoLoading &&
      new google.maps.Marker({
        position: currentPos,
        title: position,
        map: googleMap,
        icon: {
          url: icon,
          anchor: new google.maps.Point(22, 58)
        }
      });
  }, [google, isGeoLoading]);

  useEffect(() => {
    //overwrite initial position (0,0) when useGeoPosition loading is complete.
    if (googleMap) {
      googleMap.setOptions({
        center: mapCenter,
        zoomControlOptions: {
          position: isMaxVpS
            ? google.maps.ControlPosition.RIGHT_TOP
            : google.maps.ControlPosition.RIGHT_BOTTOM
        }
      });
    }
  }, [mapCenter, isMaxVpS]);

  return (
    <section className="l-content__section l-content__section--medium l-broker-bc__contact-section">
      <div>
        <div className="l-broker-bc__address">
          <p>
            <strong>Oder kommen Sie doch direkt vorbei!</strong>
          </p>
          <address>
            {street}
            <br />
            {zip}&nbsp;{city}
          </address>
          <p>
            <a
              href={`https://www.google.de/maps/dir//${addressEncoded}`}
              target="_blank"
              rel="noreferrer"
            >
              <Icon type="location" className="ico-location"></Icon> Anfahrt planen
            </a>
          </p>
          {/* Todo @ilja: add broker.imageObjs iteration once broker obj from api gets the prop */}
          {/* <c:if test="${not empty broker.imageObjs}">
        <div className="l-grid">
          <c:forEach items="${broker.imageObjs}" var="image" end="3">
            <div className="l-grid__col--3-12 l-grid__col--keep-width">
              <img src="${image.getSource("w=200&h=200")}" alt="${broker.name}">
            </div>
          </c:forEach>
        </div>
      </c:if> */}
        </div>
      </div>
      <div id="map" className="m-map m-map--bc" ref={mapRef} />
    </section>
  );
};

export default MapArea;
