import axios from 'axios';
import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import getFunctionUrl from '../helpers/getFunctionUrl';
import useCookieByKey from '../hooks/useCookieByKey';
import { pushToDataLayer } from '../hooks/useCookiePolicy';

interface IExpertSearchContextValue {
  isExpertSearchModalVisible: boolean;
  setIsExpertSearchModalVisible: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  findExpert: (plz: string, callback?: () => void, saveToken?: boolean) => void;
  expert: any;
}

const initialValue = {
  isExpertSearchModalVisible: false,
  setIsExpertSearchModalVisible: null,
  isLoading: false,
  findExpert: null,
  expert: null
};

const ExpertSearchContext = createContext<IExpertSearchContextValue>(initialValue);

export const ExpertSearchContextProvider = ({ children }) => {
  const [, setCookies, removeCookie] = useCookies();
  const [isLoading, setIsLoading] = useState(initialValue.isLoading);

  const [isExpertSearchModalVisible, setIsExpertSearchModalVisible] = useState(
    initialValue.isExpertSearchModalVisible
  );

  const dayInSeconds = 86400;

  const findExpert = (plz: string, callback: () => void, saveToken: boolean) => {
    setIsLoading(true);
    pushToDataLayer([{ dbaSearchZip: plz }, { event: 'dbaSearchRequest' }]);
    return axios
      .get(`${getFunctionUrl('sales-representatives')}/${plz}`)
      .then((res: any) => {
        setIsLoading(false);
        if (res.data) {
          removeCookie('vhv-dba');
          setCookies(
            'vhv-dba',
            JSON.stringify(res.data),
            saveToken
              ? {
                  maxAge: dayInSeconds * 30,
                  path: '/',
                  sameSite: 'strict',
                  secure: true
                }
              : {
                  path: '/',
                  sameSite: 'strict',
                  secure: true
                }
          );
          if (callback) {
            setTimeout(() => callback());
          }
        }
      })
      .catch((reason: any) => {
        if (reason && reason.response && reason.response.status) {
          // eslint-disable-next-line no-console
          console.log(reason.response.status);
        }
      });
  };

  const expertFromCookie = useCookieByKey('vhv-dba', true);

  const [expert, setExpert] = useState(null);

  useEffect(() => {
    setExpert(expertFromCookie);
  }, [expertFromCookie]);

  return (
    <ExpertSearchContext.Provider
      value={{
        isExpertSearchModalVisible,
        setIsExpertSearchModalVisible,
        isLoading,
        findExpert,
        expert
      }}
    >
      {children}
    </ExpertSearchContext.Provider>
  );
};

export default ExpertSearchContext;
