import './ProductFinderModal.scss';

import React, { useContext } from 'react';

import ProductFinderContext from '../../../../contexts/ProductFinderContext';
import Columns from '../../Columns/Columns';
import {
  corporateContactAvailability,
  corporateContactPhone
} from '../../CorporateContact/CorporateContact';
import FullscreenOverlay from '../../FullscreenOverlay/FullscreenOverlay';
import Icon from '../../Icon/Icon';
import Link from '../../Link/Link';
import ProductFinderSelector from '../ProductFinderSelector/ProductFinderSelector';

const ProductFinderModal = () => {
  const { isProductFinderModalVisible, setIsProductFinderModalVisible, filteredProducts } =
    useContext(ProductFinderContext) || {};

  const { primary: products, secondary: secondaryProducts } = filteredProducts || {};

  const getProductSection = (items, headline, variant) => {
    const isSecondaryVariant = variant === 'secondary';
    const widths = isSecondaryVariant ? [1, 1, 1] : [1, 1];
    const productClassName = `ProductFinderModal__product${
      isSecondaryVariant ? ' ProductFinderModal__product--secondary' : ''
    }`;

    return (
      items &&
      items.length > 0 && (
        <div className="ProductFinderModal__section">
          <span>{headline}</span>
          <Columns gutter="s" flexContent widths={widths} wrap>
            {items.map((product, key) => {
              const { title, teaserText, icon, path } = product;
              return (
                <div className={productClassName} key={key}>
                  <Link linkTo={path} onClick={() => setIsProductFinderModalVisible(false)}>
                    <div className="ProductFinderModal__product-content">
                      <i className={`ico-${isSecondaryVariant ? 'arrow-right' : icon}`} />
                      <div className="ProductFinderModal__text">
                        {title && <h3 className="ProductFinderModal__title">{title}</h3>}
                        {teaserText && (
                          <p className="ProductFinderModal__teaser-text">{teaserText}</p>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </Columns>
        </div>
      )
    );
  };

  return (
    <FullscreenOverlay
      isVisible={isProductFinderModalVisible}
      hideOverlay={() => setIsProductFinderModalVisible(false)}
    >
      <div className="ProductFinderModal">
        <div className="ProductFinderModal__header">
          <Icon type="Union" viewBox="0 0 24 24" size={{ width: '5rem', height: '5rem' }} />
          <h2>Die passenden Versicherungen für Ihre Situation</h2>
          <Columns gutter="s">
            <ProductFinderSelector noCollapseTop color="gray" type="occupation" />
            <ProductFinderSelector noCollapseTop color="gray" type="branch" />
          </Columns>
        </div>
        {getProductSection(products, 'Unsere Top-Seller', 'primary')}
        {getProductSection(secondaryProducts, 'Weitere Produkte', 'secondary')}

        <div className="ProductFinderModal__footer">
          <div className="ProductFinderModal__footer-teaser">
            <div className="ProductFinderModal__cta">
              Brauchen Sie Hilfe bei der Produktauswahl - wir freuen uns auf Ihren Anruf!
            </div>
            <div className="ProductFinderModal__disclaimer">
              Bitte beachten Sie: Der Produktfinder ersetzt keine individuelle Beratung und dient
              lediglich einer ersten Orientierung.
            </div>
          </div>
          <a href={`tel:${corporateContactPhone}`} className="ProductFinderModal__contact">
            {/* Todo @ilja replace placeholders with contact component */}
            <Icon type="hotline" className="ico-hotline" />
            <div className="ProductFinderModal__details">
              <span className="ProductFinderModal__phone">{corporateContactPhone}</span>
              <span className="ProductFinderModal__availability">
                {corporateContactAvailability}
              </span>
            </div>
          </a>
        </div>
      </div>
    </FullscreenOverlay>
  );
};

export default ProductFinderModal;
