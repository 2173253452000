import React, { useContext, useEffect } from 'react';

import Footer from '../components/ui/Footer/Footer';
import ProductGroupsContext from '../contexts/ProductGroupsContext';
import TeaserArea from '../areas/TeaserArea/TeaserArea';
import extractChildren from '../helpers/extractChildren';

const DoorPage = ({ children, hideProducts }) => {
  const { headerArea, contentArea1, teaserArea, contentArea2, contentHeaderArea } =
    extractChildren(children);

  const { setHideProducts } = useContext(ProductGroupsContext) || {};

  useEffect(() => {
    !!hideProducts && hideProducts ? setHideProducts(true) : setHideProducts(false);
  }, [hideProducts]);

  return (
    <>
      {headerArea}
      <main className="l-content">
        {contentHeaderArea}
        {contentArea1}
        {teaserArea || <TeaserArea parentTemplate="DoorPage" />}
        {contentArea2}
      </main>

      <Footer />
    </>
  );
};

export default DoorPage;
