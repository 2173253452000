import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import Icon from '../../components/ui/Icon/Icon';
import Image from '../../components/ui/Image/Image';
import Link from '../../components/ui/Link/Link';
import RichText from '../../components/ui/RichText/RichText';

const BillboardArea = ({
  headline,
  text,
  label,
  linkTo,
  linkType,
  alignment,
  signetImage,
  children,
  moreLabel
}) => {
  const [isOpen, setisOpen] = useState(false);
  return text || headline ? (
    <section className={`m-billboard ${!linkTo && children ? '' : ' m-billboard--link'}`}>
      <div>
        <div className={`m-billboard__teaser${alignment === 'center' ? ' text-center' : ''}`}>
          {signetImage && alignment === 'left' && (
            <div className="m-billboard__image">
              <Image src={signetImage} sizes={{ w: 240 }} />
            </div>
          )}
          <div className="m-billboard__teaser-text">
            <h4>{headline}</h4>
            <RichText richText={text} />
            {linkTo && (
              <div className="spacer-top-small">
                <Link label={label} linkTo={linkTo} linkType={linkType} />
              </div>
            )}
            {signetImage && alignment === 'center' && (
              <div className="m-billboard__image">
                <Image src={signetImage} sizes={{ w: 400 }} />
              </div>
            )}
          </div>
        </div>
        <AnimateHeight height={isOpen ? 'auto' : 0}>
          <div className="m-billboard__content js-toggle-slide-container">{children}</div>
        </AnimateHeight>
      </div>
      {children && (
        <div className="m-billboard__expander">
          <a onClick={() => setisOpen(!isOpen)} className={isOpen ? 'is-open' : ''}>
            {isOpen ? (
              <span className="m-billboard__expander--opened">
                Schließen<Icon type="close" className="ico-close"></Icon>
              </span>
            ) : (
              <span className="m-billboard__expander--opened">
                {moreLabel ? 'Mehr Informationen' : moreLabel}
                <Icon type="arrow-down" className="ico-arrow-down"></Icon>
              </span>
            )}
          </a>
        </div>
      )}
    </section>
  ) : null;
};

export default BillboardArea;
