import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import Icon from '../../components/ui/Icon/Icon';
import Link from '../../components/ui/Link/Link';
import getCalculatorPath from '../../helpers/getCalculatorPath';
import useOutsideClick from '../../hooks/useOutsideClick';

export interface INavDropdownProps {
  isActive: boolean;
  items: any;
  closeDropdown: () => void;
  allProductsTitle: string;
  allProductsPath: { path: string; template: string };
  parentRef: any;
}

const PrivateNavDropdown: React.FC<INavDropdownProps> = ({
  isActive,
  items,
  closeDropdown,
  allProductsTitle,
  allProductsPath,
  parentRef
}) => {
  const ref = useRef(null);
  useOutsideClick(ref, () => closeDropdown(), isActive, parentRef);

  return (
    <CSSTransition in={isActive} mountOnEnter unmountOnExit timeout={500} classNames={'fade'}>
      <div
        className={`l-header__nav__sub-nav`}
        ref={ref}
        style={{ display: 'block' }}
        onClick={e => e.stopPropagation()}
      >
        <ul>
          {items &&
            items.map((subNavItem, index) => {
              const {
                title,
                label,
                callOut,
                calcOverrideClickClass,
                calcIcon,
                calcLabel,
                icon,
                path,
                template,
                linkTo: calculatorPage
              } = subNavItem || {};
              return (
                <li key={index}>
                  <div>
                    <button className="m-button m-button--sec">
                      <Icon size="48px" type={icon} className={`ico-${icon}`}></Icon>
                      {label || title}
                      {callOut && <span>${callOut}</span>}
                    </button>
                    <div className="m-button-group">
                      <Link linkTo={{ path, template }} extraClass="m-button m-button--condensed">
                        <Icon type="info" className="ico-info"></Icon>
                        Informieren
                      </Link>

                      {calculatorPage ? (
                        <Link
                          {...getCalculatorPath(calculatorPage)}
                          extraClass={`m-button m-button--condensed calculator-button ${calcOverrideClickClass}`}
                        >
                          <i className={`ico-${calcIcon}`} />
                          {calcLabel || 'Beitrag berechnen'}
                        </Link>
                      ) : (
                        <button className="m-button m-button--condensed calculator-button">
                          <i className={`ico-${calcIcon}`} />
                          {calcLabel}
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          <li>
            <Link
              extraClass="m-button m-button--full-width m-button--outline-pri"
              linkTo={allProductsPath}
            >
              {allProductsTitle || 'Zu allen Produkten'}
            </Link>
          </li>
        </ul>
      </div>
    </CSSTransition>
  );
};

export default PrivateNavDropdown;
