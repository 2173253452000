import React from 'react';

import browserBack from '../../helpers/browserBack';
import Icon from '../ui/Icon/Icon';
import Image from '../ui/Image/Image';

const BrandingHeaderComponent = ({
  backUrl,
  accentColor,
  partnershipDescription,
  logo,
  backgroundColor,
  fontColor
}) => {
  return (
    <div style={{ backgroundColor, color: fontColor }}>
      <a
        href={backUrl || null}
        className="l-branding-header__back"
        style={{ color: accentColor, cursor: 'pointer' }}
        onClick={() => {
          if (!backUrl) {
            browserBack();
          }
        }}
      >
        <Icon type="arrow-left" className="ico-arrow-left"></Icon>
      </a>

      {partnershipDescription && (
        <div className="l-branding-header__text">
          <span>{partnershipDescription}</span>
        </div>
      )}

      {logo && (
        <div className="l-branding-header__logo">
          <div>
            <Image
              src={logo}
              // alt="${logo.alternativeText}"
              sizes={{ w: 1000 }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandingHeaderComponent;
