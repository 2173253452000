import React, { useContext } from 'react';

import { FullScreenOverlaySliderContext } from '../FullscreenOverlaySlider/FullscreenOverlaySlider';
import Icon from '../Icon/Icon';

interface IContactInformationProductProps {
  product: any;
  key: number;
  extraClass?: string;
  pushProductSlide?: (any) => void;
}

const ContactInformationProduct: React.FC<IContactInformationProductProps> = ({
  product,
  extraClass,
  pushProductSlide
}) => {
  const slideTransition = useContext(FullScreenOverlaySliderContext) || {};

  const { title, icon, text } = product || {};
  const { name: iconName } = icon || {};
  return (
    <button
      className={`m-contact-selection__tile tag-service-app-tile-${title}${
        !text ? ' m-contact-selection__tile--static' : ''
      }${extraClass ? ' ' + extraClass : ''}`}
      onClick={() => {
        pushProductSlide(product);
        slideTransition('next');
      }}
    >
      <div>
        <div className="m-contact-selection__tile__front">
          <Icon size="4rem" type={iconName} className={`ico-${iconName}`} />
          <h4>{title}</h4>
        </div>
        <div className="m-contact-selection__tile__back">
          <p>{text}</p>
        </div>
      </div>
    </button>
  );
};

export default ContactInformationProduct;
