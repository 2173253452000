import React, { createContext } from 'react';

import CorporateContactFooter from '../components/ui/CorporateContact/CorporateContactFooter';
import extractChildren from '../helpers/extractChildren';

export const ArticlePageContext = createContext(null);

const ArticlePage = ({ publicationDate, tags, leadText, children, altFooter }) => {
  const { headerArea, contentHeaderArea, subHeaderArea, contentArea, socialSharingArea } =
    extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content l-content--corporate">
        {contentHeaderArea}
        <ArticlePageContext.Provider value={{ publicationDate, leadText, tags }}>
          {subHeaderArea}
        </ArticlePageContext.Provider>
        {contentArea}
        {socialSharingArea}
        <CorporateContactFooter isExpertSearchVariant={!altFooter || altFooter === 'false'} />
      </main>
    </>
  );
};

export default ArticlePage;
