import React from 'react';

import extractChildren from '../helpers/extractChildren';
import { getCopyrightLabel } from '../helpers/getCurrentYear';

const MicrositePage = ({ children }) => {
  const {
    brandingHeaderArea,
    headerArea,
    contentArea1,
    contentHeaderArea,
    teaserArea,
    contentArea2
  } = extractChildren(children);

  return (
    <>
      <header className="nocontent l-branding-header">{brandingHeaderArea}</header>
      {headerArea}
      <main className="l-content l-content--branding">
        {contentHeaderArea}
        {contentArea1}
        {teaserArea}
        {contentArea2}
      </main>
      <footer className="l-footer l-footer--landing">
        <div>
          <div className="l-footer__windup l-footer__windup--minimal l-footer__windup--landing">
            <div className="float-left">
              /
              <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                Cookie-Einstellungen
              </a>
            </div>
            <div className="float-right">
              <span style={{ whiteSpace: 'nowrap' }}>{getCopyrightLabel()}</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default MicrositePage;
