import React, { useContext } from 'react';

import BrokerBcContext from '../../../contexts/BrokerBcContext';
import FullscreenOverlay from '../FullscreenOverlay/FullscreenOverlay';
import FullscreenOverlaySlider from '../FullscreenOverlaySlider/FullscreenOverlaySlider';

const BrokerBcModal = () => {
  const { brokerBcFrameSrc, visibleBrokerBc, hideBrokerBc } = useContext(BrokerBcContext) || {};

  return (
    <FullscreenOverlay isVisible={visibleBrokerBc !== ''} hideOverlay={() => hideBrokerBc()}>
      <FullscreenOverlaySlider>
        <iframe className="itform" src={brokerBcFrameSrc}></iframe>
      </FullscreenOverlaySlider>
    </FullscreenOverlay>
  );
};

export default BrokerBcModal;
