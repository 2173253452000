import React, { createContext } from 'react';

import Footer from '../components/ui/Footer/Footer';
import extractChildren from '../helpers/extractChildren';
export const EventPageContext = createContext(null);

const EventPage = props => {
  const {
    children,
    description,
    city,
    locality,
    postalCode,
    state,
    street,
    title,
    startDate,
    endDate,
    startTime,
    endTime,
    agendaDownload,
    type,
    kind,
    backgroundImage,
    organizerImage,
    icon,
    emailAddress,
    zip,
    customForm,
    registrationButtonLabel
  } = props;

  const {
    headerArea,
    eventHeaderArea,
    descriptionArea,
    downloadArea,
    galleryArea,
    agendaArea,
    footerLinkArea
  } = extractChildren(children);

  return (
    <>
      {headerArea}
      <main className="l-content">
        <EventPageContext.Provider
          value={{
            description,
            city,
            locality,
            postalCode,
            state,
            street,
            title,
            startDate,
            endDate,
            startTime,
            endTime,
            agendaDownload,
            type,
            kind,
            backgroundImage,
            organizerImage,
            icon,
            emailAddress,
            zip,
            customForm,
            registrationButtonLabel
          }}
        >
          {eventHeaderArea}
          {descriptionArea}
          {downloadArea}
          {galleryArea}
          <div className="l-content__section">
            <div>{agendaArea}</div>
          </div>
        </EventPageContext.Provider>
      </main>
      <Footer footerLinkArea={footerLinkArea} />
    </>
  );
};

export default EventPage;
