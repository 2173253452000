import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

const useIdleTime = seconds => {
  const [isTimeOver, setIsTimeOver] = useState(false);

  const handleOnIdle = () => {
    setIsTimeOver(true);
  };

  useIdleTimer({
    timeout: 1000 * seconds,
    onIdle: handleOnIdle,
    debounce: 500
  });

  return isTimeOver;
};
export default useIdleTime;
