import React, { useContext, useState } from 'react';

import ContactInformationContext from '../../../contexts/ContactInformationContext';
import Icon from '../Icon/Icon';
import Link from '../Link/Link';

interface IAnnouncementBannerProps {
  announcementText: string;
  linkTo?: string;
  linkType?: string;
  label?: string;
  variant?: 'expert';
}

const AnnouncementBanner: React.FC<IAnnouncementBannerProps> = ({
  announcementText,
  linkTo,
  linkType,
  label,
  variant
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isRendered, setIsRendered] = useState(true);

  const { setIsContactInformationModalVisible } = useContext(ContactInformationContext) || {};

  const hideBanner = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsRendered(false);
    }, 500);
  };

  return isRendered ? (
    <div
      className={`m-announcement${isVisible ? ' is-unread' : ''}${
        variant ? ' m-announcement--expert' : ''
      }`}
    >
      <div className="m-announcement__content">
        <div className="m-announcement__text">
          <p>{announcementText}</p>
          {linkType != 'none' &&
            (linkType != 'damage' ? (
              <Link
                linkTo={linkTo}
                linkType={linkType}
                label={label}
                extraClass="m-button m-button--light"
              />
            ) : (
              <Link
                linkTo=""
                onClick={() => {
                  setIsContactInformationModalVisible(true, {
                    isDamageVariant: true
                  });
                }}
                extraClass="m-button m-button--light"
              >
                <Icon type="warning" className="ico-warning"></Icon> Schaden melden
              </Link>
            ))}
        </div>
        <button className="m-announcement__close" onClick={() => hideBanner()}>
          <Icon type="close" />
        </button>
      </div>
    </div>
  ) : null;
};

export default AnnouncementBanner;
