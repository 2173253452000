import React from 'react';

import getAsset, { TAssetSizes, TAssetSrc } from '../../../helpers/getAsset';

interface IImageProps {
  src: TAssetSrc;
  alt?: string;
  sizes?: TAssetSizes;
  isBackgroundImage?: boolean;
  children?: any;
  extraClass?: string;
  onClick?: () => void;
  objectFitContain?: boolean;
}

const Image: React.FC<IImageProps> = ({
  src,
  alt,
  sizes,
  isBackgroundImage,
  children,
  extraClass,
  onClick,
  objectFitContain
}) => {
  const { path, width, height, isMagnoliaContext } = getAsset(src, sizes);

  let isLocal = process.env.GATSBY_ENV === 'local' || isMagnoliaContext;
  isLocal = false;

  const localSizes = {
    width: isLocal ? width : '',
    height: isLocal ? height : ''
  };

  const localObjectFit = objectFitContain ? 'contain' : 'cover';

  return isBackgroundImage ? (
    <div
      className={extraClass}
      style={{
        backgroundImage: path ? `url(${path})` : '#eee',
        ...localSizes,
        objectFit: localObjectFit
      }}
      onClick={onClick}
    >
      {children}
    </div>
  ) : path ? (
    <img
      loading="lazy"
      className={extraClass}
      src={path}
      alt={alt}
      style={{ ...localSizes, objectFit: localObjectFit }}
      onClick={onClick}
    />
  ) : null;
};

export default Image;
