import React from 'react';

import Icon from '../ui/Icon/Icon';
import Link from '../ui/Link/Link';

const SelectedOrganizationsComponent = ({ children }) => {
  return (
    <>
      <div className="m-orga-tile__wrapper spacer-small">{children}</div>
      <Link
        extraClass="m-button m-button--sec m-button--size-m m-button--full-width"
        linkTo="/ueber-uns/partner"
        linkType="internal"
      >
        <Icon type="arrow-right" />
        Alle Partner
      </Link>
    </>
  );
};

export default SelectedOrganizationsComponent;
